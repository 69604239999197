import axios from 'axios';

const CUSTOMER_PATH = '/api/customers';
const APPROVER_ENDING = 'approver';
const USERS_ENDING = 'users';
const ALERTS_ENDING = 'alerts';
const CREATE_ENDING = 'create';
class CustomerService {
  async searchCustomers(parameters) {
    const url = `${CUSTOMER_PATH}`;
    const customers = await axios.get(url, {
      params: parameters
    });
    return customers;
  }

  async getCustomer(customerId) {
    const url = `${CUSTOMER_PATH}/${encodeURIComponent(customerId)}`;
    const customer = await axios.get(url);
    return customer;
  }

  async getApprovers(customerId) {
    const url = `${CUSTOMER_PATH}/${encodeURIComponent(customerId)}/${APPROVER_ENDING}`;
    const customer = await axios.get(url);
    return customer;
  }

  async getUsers(customerId) {
    const url = `${CUSTOMER_PATH}/${encodeURIComponent(customerId)}/${USERS_ENDING}`;
    const customer = await axios.get(url);
    return customer;
  }

  async getCustomerAlerts(customerId) {
    const url = `${CUSTOMER_PATH}/${encodeURIComponent(customerId)}/${ALERTS_ENDING}`;
    const notes = await axios.get(url);
    return notes;
  }

  async createCustomer(params) {
    const url = `${CUSTOMER_PATH}/${CREATE_ENDING}`;
    const response = await axios.post(url, params);
    return response;
  }

  async updateCustomer(customer) {
    const url = `${CUSTOMER_PATH}/${encodeURIComponent(customer.customerId)}`;
    const updatedConsumer = await axios.put(url, customer);
    return updatedConsumer;
  }
}

export default new CustomerService();
