<template>
  <div>
    <h5>Repair Contact</h5>
    <h6 class="text-uppercase">{{ estimorderDisplayText }} ID: {{ serviceOrderId }}</h6>

    <div>
      <b-row no-gutters>
        <b-col>
          <span v-if="!readonly" class="required-legend float-right p-0">* Required</span>
        </b-col>
      </b-row>
      <b-row>
        <b-form-group
          class="previous-contacts"
          label="Previous Contacts:"
          label-class="col-head"
          label-for="previousContacts"
        >
          <v-select
            id="previousContacts"
            :options="previousContacts"
            :clearable="false"
            select-on-tab
            @search="onContactSearch"
            @input="selectContact($event)"
          >
            <template #option="contact">
              <span>
                {{ contact.firstName }} {{ contact.lastName }} | {{ contact.email }} |
                {{ contact.phoneNumber | phone }}
              </span>
            </template>
            <template #selected-option="contact">
              <span>
                {{ contact.firstName }} {{ contact.lastName }} | {{ contact.email }} |
                {{ contact.phoneNumber | phone }}
              </span>
            </template>
          </v-select>
        </b-form-group>
      </b-row>
      <b-row no-gutters>
        <b-col>
          <b-form-group label="First Name:" label-class="col-head" label-for="firstName">
            <div v-if="readonly" class="col-form-label">
              {{ firstName || NA }}
            </div>
            <b-input-group v-else>
              <b-form-input
                v-model="firstName"
                :state="!$v.firstName.$error ? null : false"
                class="rounded"
              ></b-form-input>
              <span class="required-asterisk">*</span>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col class="ml-2">
          <b-form-group label="Last Name:" label-class="col-head" label-for="lastName">
            <div v-if="readonly" class="col-form-label">
              {{ lastName || NA }}
            </div>
            <b-input-group v-else>
              <b-form-input
                v-model="lastName"
                :state="!$v.lastName.$error ? null : false"
                class="rounded"
              ></b-form-input>
              <span class="required-asterisk">*</span>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col>
          <b-form-group label="Phone:" label-class="col-head" label-for="phone">
            <div v-if="readonly" class="col-form-label">
              {{ phoneNumber | phone | nullValueToNA }}
            </div>
            <b-input-group v-else>
              <phone-input v-model="phoneNumber" :state="!$v.phoneNumber.$error ? null : false" class="rounded" />
              <span class="required-asterisk">*</span>
            </b-input-group>
            <div v-show="$v.phoneNumber.$error && !$v.phoneNumber.length" class="error">
              Enter a valid Phone Number of 7 or 10 digits.
            </div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col>
          <b-form-group label="Email:" label-class="col-head" label-for="email">
            <div v-if="readonly" class="col-form-label">
              {{ email || NA }}
            </div>
            <b-input-group v-else>
              <b-form-input v-model="email" :state="!$v.email.$error ? null : false" class="rounded"></b-form-input>
              <span class="required-asterisk">*</span>
            </b-input-group>
            <span v-if="!readonly" class="note float-right required-placeholder">Phone and/or Email is required.</span>
            <div
              v-show="($v.phoneNumber.$error && !$v.phoneNumber.required) || ($v.email.$error && !$v.email.required)"
              class="error"
            >
              Enter a valid Phone Number or Email Address.
            </div>
            <div v-show="$v.email.$error && !$v.email.email" class="error">Enter a valid Email Address.</div>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col>
          <b-form-group label="Title:" label-class="col-head" label-for="title">
            <div v-if="readonly" class="col-form-label">
              {{ title || NA }}
            </div>
            <b-input-group v-else>
              <b-form-input v-model="title" class="rounded mr-3"></b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-row no-gutters>
        <b-col>
          <b-form-group label="Comment" label-class="col-head" label-for="comment" class="required-placeholder">
            <div v-if="readonly" class="col-form-label">
              {{ comment || NA }}
            </div>
            <b-textarea v-else id="comment" v-model="comment" class="overflow-auto" size="sm" rows="3" max-rows="4" />
          </b-form-group>
        </b-col>
      </b-row>

      <b-button v-if="!readonly" variant="primary" :disabled="saving" @click="save">
        <div v-show="saving">
          <b-spinner small></b-spinner>
          Saving...
        </div>
        <div v-show="!saving">Save</div>
      </b-button>

      <div class="py-4" />
    </div>
  </div>
</template>

<script>
// Components
import PhoneInput from '@/shared/components/ui/PhoneInput';
import vSelect from 'vue-select';

// Vuex
import { UnitGetters } from '@/shared/store/unit/types';
import { ServiceOrderActions, ServiceOrderGetters, ServiceOrderMutations } from '@/shared/store/service-order/types';
import { mapGetters, mapActions, mapMutations } from 'vuex';

import { required, requiredIf, email } from 'vuelidate/lib/validators';
import ErrorService from '@/shared/services/ErrorService';
import SuccessService from '@/shared/services/SuccessService';

import UserAccessMixin from '@/shared/mixins/UserAccessMixin';

export default {
  name: 'ServiceOrderRepairContact',
  components: {
    'phone-input': PhoneInput,
    'v-select': vSelect
  },
  mixins: [UserAccessMixin],
  data() {
    return {
      NA: 'N/A',
      saving: false
    };
  },
  validations: {
    firstName: {
      required
    },
    lastName: {
      required
    },
    phoneNumber: {
      required: requiredIf(function () {
        return !this.email;
      }),
      length: value => !value || value.length == 7 || value.length == 10
    },
    email: {
      required: requiredIf(function () {
        return !this.phoneNumber;
      }),
      email
    }
  },
  computed: {
    ...mapGetters({
      unit: UnitGetters.GET_UNIT,
      estimorderDisplayText: ServiceOrderGetters.GET_ESTIMORDER_DISPLAY_TEXT,
      serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER,
      repairContact: ServiceOrderGetters.GET_REPAIR_CONTACT,
      branchId: ServiceOrderGetters.GET_BRANCH_ID
    }),
    readonly() {
      return this.$store.state.serviceOrder.serviceOrder.invoiced || !this.branchIsAccessible;
    },
    unitId() {
      return this.unit.unitId;
    },
    serviceOrderId() {
      return this.serviceOrder.serviceOrderId;
    },
    firstName: {
      get() {
        return this.repairContact.firstName;
      },
      set(value) {
        this.setData('firstName', value);
      }
    },
    lastName: {
      get() {
        return this.repairContact.lastName;
      },
      set(value) {
        this.setData('lastName', value);
      }
    },
    phoneNumber: {
      get() {
        return this.repairContact.phoneNumber;
      },
      set(value) {
        this.setData('phoneNumber', value);
      }
    },
    email: {
      get() {
        return this.repairContact.email;
      },
      set(value) {
        this.setData('email', value);
      }
    },
    title: {
      get() {
        return this.repairContact.title;
      },
      set(value) {
        this.setData('title', value);
      }
    },
    comment: {
      get() {
        return this.repairContact.comment;
      },
      set(value) {
        this.setData('comment', value);
      }
    },
    previousContacts() {
      return this.repairContact?.previousContacts;
    }
  },
  async created() {
    this.loading = true;
    await this[ServiceOrderActions.FETCH_REPAIR_CONTACT]();
    this.loading = false;
  },
  methods: {
    ...mapActions([ServiceOrderActions.FETCH_REPAIR_CONTACT, ServiceOrderActions.UPDATE_REPAIR_CONTACT]),
    ...mapMutations([
      ServiceOrderMutations.SET_PROP,
      ServiceOrderMutations.SET_REPAIR_CONTACT,
      ServiceOrderMutations.SET_PREVIOUS_REPAIR_CONTACTS
    ]),
    selectContact(item) {
      const contacts = [];
      this.previousContacts.forEach(contact => {
        contacts.push(contact);
      });
      let con = {
        firstName: item.firstName,
        lastName: item.lastName,
        email: item.email,
        phoneNumber: item.phoneNumber,
        title: item.title,
        previousContacts: contacts
      };
      this[ServiceOrderMutations.SET_REPAIR_CONTACT](con);
    },
    onContactSearch() {},
    async save() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        ErrorService.createErrorToast(this, 'Error saving Repair Contact. See indicated fields below.');
        return;
      }
      try {
        this.saving = this.loading = true;
        await this[ServiceOrderActions.UPDATE_REPAIR_CONTACT]();

        this.$v.$reset();
        SuccessService.createSuccessToast(this.$root, `Repair Contact saved successfully.`);
      } catch (error) {
        ErrorService.createErrorToast(this, 'Failed to save Repair Contact.');
      } finally {
        this.saving = this.loading = false;
      }
    },
    setData(key, newValue) {
      let value = { ...this.repairContact };
      value[key] = newValue;
      this[ServiceOrderMutations.SET_PROP]({ key: 'repairContact', value });
    }
  }
};
</script>
<style>
.previous-contacts {
  margin-bottom: 3vh;
  width: 75%;
}
</style>
