export const getDefaultState = () => {
  return {
    attachments: [],
    dbAttachments: [],
    warrantyAsideAttachments: [],
    numOfAttachmentsCount: 0
  };
};

export default {
  ...getDefaultState()
};
