<template>
  <b-overlay :show="loading" :opacity="0.6">
    <unit-service-order-history :status="status">
      <div class="so-status">
        <b-form-radio-group
          v-model="status"
          class="mr-3"
          :options="statusOptions"
          buttons
          button-variant="secondary"
        ></b-form-radio-group>
      </div>
    </unit-service-order-history>
  </b-overlay>
</template>

<script>
import UnitServiceOrderHistoryComponent from '@/shared/components/service-order/UnitServiceOrderHistoryComponent';
import { UnitGetters } from '@/shared/store/unit/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import { mapGetters } from 'vuex';

export default {
  name: 'UnitServiceOrdersPage',
  components: {
    'unit-service-order-history': UnitServiceOrderHistoryComponent
  },
  data() {
    return {
      status: this.$router.currentRoute.query.filterBy ? this.$router.currentRoute.query.filterBy : 'All'
    };
  },
  computed: {
    ...mapGetters({ loading: UnitGetters.IS_LOADING, uiConfig: LookupGetters.GET_UI_CONFIG }),
    statusOptions() {
      if (this.uiConfig.showOpenSOs) {
        return ['All', 'Open', 'Invoiced'];
      } else {
        return [];
      }
    }
  },
  watch: {
    status(value) {
      this.$router.push({ query: { filterBy: value } }).catch(() => {});
    }
  }
};
</script>
