import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, UserActions, UserMutations, UserGetters } from './types';
import { LookupActions, LookupMutations } from '@/shared/store/lookup/types';
import UserService from '@/shared/services/UserService';
import state from '../state';

const _actions = namespaceHelper.removeNamespace(namespace, UserActions);
const _mutations = namespaceHelper.removeNamespace(namespace, UserMutations);
const _getters = namespaceHelper.removeNamespace(namespace, UserGetters);

export default {
  async [_actions.CLEAR_NAVIGATION]({ commit }) {
    commit(_mutations.SET_AUTHENTICATION_REDIRECT, null);
  },
  async [_actions.FETCH_USER_PROFILE]({ commit }) {
    commit(_mutations.SET_LOADING, true);
    try {
      var userProfileResult = await UserService.getUserProfile();
      commit(_mutations.SET_PROFILE, userProfileResult.data);
    } catch {
      // TODO: Should this be null or empty object?
      commit(_mutations.SET_PROFILE, {});
    } finally {
      commit(_mutations.SET_LOADING, false);
    }
  },
  async [_actions.FETCH_FIRE_USER_PROFILE]({ commit }) {
    commit(_mutations.SET_LOADING, true);
    try {
      var userProfileResult = await UserService.getFireUserProfile();
      commit(_mutations.SET_PROFILE, userProfileResult.data);
    } catch {
      commit(_mutations.SET_PROFILE, {});
    } finally {
      commit(_mutations.SET_LOADING, false);
    }
  },
  // use this after msal login events
  async [_actions.AFTER_LOGIN]({ commit, dispatch }, maslAccount) {
    commit(_mutations.SET_MSAL_ACCOUNT, maslAccount);
    await dispatch(UserActions.FETCH_USER_PROFILE, null, { root: true });
    await dispatch(LookupActions.FETCH_LOOKUPS, null, { root: true });
  },
  // Vams has custom login using vams api
  async [_actions.LOGIN]({ commit }, userCredentials) {
    let loginResult = {};
    let url = state.url;
    if (url.includes('localhost:8050') || url.includes('clarkefire.com')) {
      loginResult = await UserService.loginFire(userCredentials);
    } else {
      loginResult = await UserService.login(userCredentials);
    }
    commit(_mutations.SET_LOGGED_IN, loginResult.data);
  },
  async [_actions.LOGINFIRE]({ commit }, userCredentials) {
    //removed from use for now, may revisit after solution for fire users is implemented
    let loginResult = await UserService.loginFire(userCredentials);
    commit(_mutations.SET_LOGGED_IN, loginResult.data);
  },
  async [_actions.LOGOUT]({ commit }, vamsLogout = true) {
    sessionStorage.clear();
    if (vamsLogout) await UserService.logout();
    commit(_mutations.SET_LOGGED_OUT);
    commit(_mutations.SET_PROFILE, {});
    commit(LookupMutations.RESET_LOOKUPS, null, { root: true });
  },
  [_actions.SET_AUTHENTICATION_REDIRECT]({ commit }, redirect) {
    commit(_mutations.SET_AUTHENTICATION_REDIRECT, redirect);
  },
  async [_actions.IS_INVOICE_ONLY]({ commit, getters }) {
    let isExcedeInvoice =
      getters[_getters.AUTHORIZE_ROLE]('ExcedeIndexing') ||
      getters[_getters.AUTHORIZE_ROLE]('ExcedeApproval') ||
      getters[_getters.AUTHORIZE_ROLE]('ExcedeVouchering') ||
      getters[_getters.AUTHORIZE_ROLE]('CPSAPReview');

    let isM2M =
      getters[_getters.AUTHORIZE_ROLE](['M2MIndexing']) ||
      getters[_getters.AUTHORIZE_ROLE](['M2MApproval']) ||
      getters[_getters.AUTHORIZE_ROLE](['M2MVouchering']) ||
      getters[_getters.AUTHORIZE_ROLE](['M2MAPReview']);
    commit(_mutations.IS_M2M, isM2M);

    let isCUK =
      getters[_getters.AUTHORIZE_ROLE](['CUKIndexing']) ||
      getters[_getters.AUTHORIZE_ROLE](['CUKApproval']) ||
      getters[_getters.AUTHORIZE_ROLE](['CUKVouchering']) ||
      getters[_getters.AUTHORIZE_ROLE](['CUKAPReview']);
    commit(_mutations.IS_CUK, isCUK);

    let notVams = !getters[_getters.AUTHORIZE_ROLE](['VamsUser']);
    let isInvoiceOnly = (isExcedeInvoice || isM2M || isCUK) && notVams;
    commit(_mutations.IS_INVOICE_ONLY, isInvoiceOnly);
    return isInvoiceOnly;
  }
};
