<template>
  <b-row v-if="isTire" no-gutters class="flex-column">
    <div v-if="tireConfigurations">
      <div v-for="(tireConfiguration, tireIndex) in tireConfigurations" :key="tireIndex">
        <tire-inspection
          :ref="`tire-inspection-${sectionId}-${itemId}`"
          :item-id="itemId"
          :tire-id="tireConfiguration.tireConfigurationId"
          :readonly="readonly"
        />
      </div>
    </div>
  </b-row>
  <b-row v-else-if="isBrake" no-gutters class="flex-column">
    <b-col cols="12" class="inspection-item-name">{{ item.name }}</b-col>
    <!-- Show N/A when no brake results -->
    <div v-if="!brakeConfigurations">
      <b-button button-variant="outline-secondary" class="mr-2 mb-3 readonly-btn" :pressed="true">
        {{ NA }}
      </b-button>
    </div>
    <b-col v-for="(brakeConfiguration, brakeIndex) in brakeConfigurations" :key="brakeIndex" cols="12">
      <brake-inspection
        :ref="`brake-inspection-${sectionId}-${itemId}`"
        :item-id="itemId"
        :brake-id="brakeConfiguration.brakeConfigurationId"
        :readonly="readonly"
      />
    </b-col>
  </b-row>
  <b-row v-else no-gutters>
    <b-col cols="12" class="inspection-item-name d-flex">
      <div v-if="readonly && isCheckbox">
        <b-form-checkbox
          :id="`section-${sectionId}-item-${itemId}-checkbox`"
          v-model="result"
          :value="checked"
          :unchecked-value="unchecked"
          disabled
        ></b-form-checkbox>
      </div>
      <div v-if="!readonly && isCheckbox">
        <b-form-checkbox
          :id="`section-${sectionId}-item-${itemId}-checkbox`"
          v-model="result"
          :value="checked"
          :unchecked-value="unchecked"
        ></b-form-checkbox>
      </div>
      <div class="ml-0">
        {{ item.name }}
      </div>
    </b-col>
    <b-col cols="12" md="auto">
      <b-button
        v-if="readonly && isPassFail"
        :id="`section-${sectionId}-item-${itemId}-pass-fail`"
        class="mr-2 mb-3 readonly-btn"
        :pressed="true"
        :variant="buttonVariant(result)"
      >
        {{ result != null ? result.description : NA }}
      </b-button>
      <b-form-radio-group
        v-if="!readonly && isPassFail"
        :id="`section-${sectionId}-item-${itemId}-pass-fail`"
        v-model="result"
        class="w-100 pb-2"
        buttons
      >
        <b-form-radio
          v-for="itemResult in passFailResults"
          :key="itemResult.inspectionResultTypeId"
          :value="itemResult"
          :button-variant="buttonVariant(itemResult)"
        >
          {{ itemResult.description }}
        </b-form-radio>
      </b-form-radio-group>
      <b-button
        v-if="readonly && isYesNo"
        :id="`section-${sectionId}-item-${itemId}-pass-fail`"
        class="mr-2 mb-3 readonly-btn"
        :pressed="true"
        :variant="buttonVariant(result)"
      >
        {{ result != null ? result.description : NA }}
      </b-button>
      <b-form-radio-group
        v-if="!readonly && isYesNo"
        :id="`section-${sectionId}-item-${itemId}-pass-fail`"
        v-model="result"
        class="w-100 pb-2"
        buttons
      >
        <b-form-radio
          v-for="itemResult in yesNoResults"
          :key="itemResult.inspectionResultTypeId"
          :value="itemResult"
          :button-variant="buttonVariant(itemResult)"
        >
          {{ itemResult.description }}
        </b-form-radio>
      </b-form-radio-group>
      <b-button
        v-if="readonly && isIar"
        :id="`section-${sectionId}-item-${itemId}-iar`"
        class="mr-2 mb-3 readonly-btn"
        :pressed="true"
        :variant="buttonVariant(result)"
      >
        {{ result != null ? result.description : NA }}
      </b-button>
      <b-form-radio-group
        v-if="!readonly && isIar"
        :id="`section-${sectionId}-item-${itemId}-iar`"
        v-model="result"
        class="w-100 pb-2"
        buttons
      >
        <b-form-radio
          v-for="itemResult in iarResults"
          :key="itemResult.inspectionResultTypeId"
          :value="itemResult"
          :button-variant="buttonVariant(itemResult)"
        >
          {{ itemResult.description }}
        </b-form-radio>
      </b-form-radio-group>
    </b-col>

    <b-col class="flex-fill col-md-auto pb-3">
      <div
        v-if="readonly && allowComment"
        :id="`section-${sectionId}-item-${itemId}-comment`"
        class="align-middle py-2"
      >
        {{ comment }}
      </div>
      <div>
        <b-form-input
          v-if="!readonly && allowComment"
          :id="`section-${sectionId}-item-${itemId}-comment`"
          v-model="comment"
          :state="vItem.comment.$error ? false : null"
          placeholder="Comment"
        />
      </div>
      <div v-if="vItem.comment.$error && !vItem.comment.maxLength" class="error">Max character limit of 2000.</div>
    </b-col>
  </b-row>
</template>

<script>
// components
import TireInspectionComponent from './TireInspectionComponent';
import BrakeInspectionComponent from './BrakeInspectionComponent';
// vuex
import { mapGetters, mapMutations } from 'vuex';
import { LookupGetters } from '@/shared/store/lookup/types';
import { InspectionGetters, InspectionMutations } from '@/shared/store/inspection/types';

export default {
  name: 'InspectionItem',
  components: {
    'tire-inspection': TireInspectionComponent,
    'brake-inspection': BrakeInspectionComponent
  },
  props: {
    itemId: Number(),
    vItem: Object(),
    sectionId: Number(),
    readonly: Boolean()
  },
  data() {
    return {
      NA: 'N/A'
    };
  },
  computed: {
    ...mapGetters({
      getInspectionItem: InspectionGetters.GET_INSPECTION_ITEM,
      resultTypesMap: LookupGetters.GET_INSPECTION_RESULT_TYPES_MAP,
      resultTypesList: LookupGetters.GET_INSPECTION_RESULT_TYPES_LIST
    }),
    item() {
      return this.getInspectionItem(this.itemId);
    },
    passFailResults() {
      return this.resultTypesList.filter(result => result.resultTypeGroup.toLowerCase() === 'passfail');
    },
    yesNoResults() {
      return this.resultTypesList.filter(result => result.resultTypeGroup.toLowerCase() === 'yesno');
    },
    iarResults() {
      return this.resultTypesList.filter(result => result.resultTypeGroup.toLowerCase() === 'iar');
    },
    checked() {
      return this.resultTypesMap[3];
    },
    unchecked() {
      return this.resultTypesMap[4];
    },
    isCheckbox() {
      return this.item.inspectionItemType.toLowerCase() == 'checkbox';
    },
    isPassFail() {
      return this.item.inspectionItemType.toLowerCase() == 'passfail';
    },
    isYesNo() {
      return this.item.inspectionItemType.toLowerCase() == 'yesno';
    },
    isIar() {
      return this.item.inspectionItemType.toLowerCase() == 'iar';
    },
    allowComment() {
      return this.item.allowComment;
    },
    isBrake() {
      return this.item.inspectionItemType.toLowerCase() == 'brake';
    },
    isTire() {
      return this.item.inspectionItemType.toLowerCase() == 'tire';
    },
    tireConfigurations() {
      return this.item.tireInspection.tireConfigurations;
    },
    brakeConfigurations() {
      return this.item.brakeInspection.brakeConfigurations;
    },
    result: {
      get() {
        return this.item.result;
      },
      set(value) {
        this.setItemProp('result', value);
      }
    },
    comment: {
      get() {
        return this.item.comment;
      },
      set(value) {
        this.setItemProp('comment', value);
      }
    }
  },
  methods: {
    ...mapMutations([InspectionMutations.SET_ITEM_PROP]),
    setItemProp(key, value) {
      this[InspectionMutations.SET_ITEM_PROP]({ id: this.itemId, key, value });
    },
    buttonVariant(result) {
      if (result == null || result.success == null) return 'outline-secondary';
      else if (result.success === true) return 'outline-success';
      else if (result.success === false) return 'outline-danger';
    }
  }
};
</script>
