import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, OperationsForecastActions, OperationsForecastMutations, OperationsForecastGetters } from './types';
import OperationsForecastService from '@/shared/services/OperationsForecastService';

const _mutations = namespaceHelper.removeNamespace(namespace, OperationsForecastMutations);
const _actions = namespaceHelper.removeNamespace(namespace, OperationsForecastActions);
const _getters = namespaceHelper.removeNamespace(namespace, OperationsForecastGetters);
export default {
  async [_actions.FETCH_OPERATIONS_FORECAST]({ commit, getters }, parameters) {
    const operationsForecastResult = await OperationsForecastService.getOperationsForecast(parameters);
    const isSearchPMContractOperations = getters[_getters.GET_IS_SEARCH_PM_CONTRACT_OPERATIONS];
    var list;
    if (isSearchPMContractOperations) {
      list = operationsForecastResult.data.filter(item => item.isPMContractOperation);
    } else {
      list = operationsForecastResult.data;
    }
    commit(_mutations.SET_PROP, { key: 'operationsForecast', value: list });
  }
};
