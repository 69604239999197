<template>
  <div>
    <h5 v-if="!isPaired">Pair Units</h5>
    <h5 v-else>Paired Unit</h5>
    <div>
      <div v-if="!isPaired">
        <b-row no-gutters>
          <b-form-group class="col-md-7" label="Customer Units:" label-class="col-head" label-for="customerUnits">
            <b-input-group>
              <v-select
                id="customerUnits"
                v-model="pairedUnitId"
                :filter-by="filterUnits"
                :options="filteredCustomerUnits"
                :reduce="unit => unit.unitId"
                select-on-tab
                :clearable="false"
                label="fleetUnitId"
                placeholder="Search by VIN or Fleet Unit Id"
                @search="onUnitSearch"
              >
                <template #option="item">
                  <span class="text-uppercase">Fleet Unit Id: {{ item.fleetUnitId }} | VIN: {{ item.vin }}</span>
                </template>
                <template #no-options>No Customer Units Found</template>
                <template #selected-option="item">
                  <span>Fleet Unit Id: {{ item.fleetUnitId }} | VIN: {{ item.vin }}</span>
                </template>
              </v-select>
              <b-form-group v-if="localUnit && localUnit.unitId" class="ml-2 mt-1">
                <b-link id="paired-unit-link" :to="'/units/' + localUnit.unitId" target="_blank">
                  <font-awesome-icon icon="external-link-alt" />
                </b-link>
                <b-tooltip target="paired-unit-link" triggers="hover">View selected unit in new tab</b-tooltip>
              </b-form-group>
            </b-input-group>
          </b-form-group>
        </b-row>
        <b-row v-if="localUnit && localPairedUnitId">
          <b-form-group label="Type:" label-class="col-head" label-for="unitType" class="mr-1">
            <b-input id="unitType" disabled :value="localUnit.unitType" label="Unit Type:"></b-input>
          </b-form-group>
          <b-form-group label="Make:" label-class="col-head" label-for="make" class="mr-1">
            <b-input id="make" disabled :value="localUnit.make"></b-input>
          </b-form-group>
          <b-form-group label="Model:" label-class="col-head" label-for="model" class="mr-1">
            <b-input id="model" disabled :value="localUnit.model"></b-input>
          </b-form-group>
          <b-form-group label="Status:" label-class="col-head" label-for="unitStatus" class="mr-1">
            <b-input id="unitStatus" disabled :value="localUnit.unitStatus"></b-input>
          </b-form-group>
        </b-row>

        <b-button variant="primary mt-2 mb-3" :disabled="!localUnit.unitId" @click="addOrSave()">
          <div v-show="isSaving">
            <b-spinner small></b-spinner>
            Saving...
          </div>
          <div v-if="!isSaving">Pair</div>
        </b-button>
      </div>
    </div>
    <b-overlay :show="isLoading" :opacity="0.6">
      <b-table
        striped
        class="border"
        :sticky-header="readonly ? '71vh' : '42vh'"
        :fields="!$isCustomer ? fields : vcpFields"
        :items="pairedUnitsDetails"
        show-empty
        :empty-text="emptyText"
      >
        <template #cell(unitId)="{ item }">
          <b-link :to="'/units/' + item.unitId" target="_blank" class="text">{{ item.unitId }}</b-link>
        </template>
        <template #cell(vin)="{ item }">
          <b-link :to="'/units/' + item.unitId" target="_blank" class="text">{{ item.vin }}</b-link>
        </template>
        <template #cell(fleetUnitId)="{ item }">
          <span class="text">{{ item.fleetUnitId }}</span>
        </template>
        <template #cell(make)="{ item }">
          <span class="text">{{ item.make }}</span>
        </template>
        <template #cell(model)="{ item }">
          <span class="text">{{ item.model }}</span>
        </template>
        <template #cell(actions)="{ item }">
          <b-button size="xsm" @click="confirmDelete(item)"><font-awesome-icon icon="trash-alt" /></b-button>
        </template>
      </b-table>
    </b-overlay>

    <warning-modal
      id="MountedEquipmentUnpairWarning"
      ref="UnpairWarning"
      title="Warning"
      :warning-text="deleteText"
      continue-btn-text="Unpair Units"
      cancel-btn-text="Cancel"
    ></warning-modal>
  </div>
</template>

<script>
//vuex
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex';
import { UserGetters } from '@/shared/store/user/types';
import { UnitGetters, UnitMutations, UnitActions } from '@/shared/store/unit/types';
//components
import vSelect from 'vue-select';
import WarningModal from '@/shared/components/WarningModal';

// import { required } from 'vuelidate/lib/validators';
import ErrorService from '@/shared/services/ErrorService';
import SuccessService from '@/shared/services/SuccessService';
import debounce from 'lodash.debounce';

export default {
  name: 'PairedUnitComponent',
  components: {
    'warning-modal': WarningModal,
    vSelect
  },
  props: {
    visible: Boolean()
  },
  data() {
    return {
      emptyText: 'No paired units.',
      deleteText: 'Are you sure you want to unpair these units?',
      NA: 'N/A',
      readonly: false,
      isLoading: false,
      isSaving: false,
      localPairedUnitId: null,
      fields: [
        { key: 'unitId', label: 'Unit Id', sortable: true },
        { key: 'fleetUnitId', label: 'Fleet Unit Id', sortable: true },
        { key: 'make', label: 'Make', sortable: true },
        { key: 'model', label: 'Model', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      vcpFields: [
        { key: 'vin', label: 'VIN', sortable: true },
        { key: 'fleetUnitId', label: 'Fleet Unit Id', sortable: true },
        { key: 'make', label: 'Make', sortable: true },
        { key: 'model', label: 'Model', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ]
    };
  },
  computed: {
    ...mapState('unit', ['customerUnits']),
    ...mapGetters({
      unit: UnitGetters.GET_UNIT
    }),
    ...mapGetters([UserGetters.AUTHORIZE_ROLE]),
    pairedUnit: {
      get() {
        return this.unit.pairedUnitId;
      },
      set(value) {
        this.localPairedUnitId = value?.unitId;
      }
    },
    pairedUnitId: {
      get() {
        return this.localPairedUnitId;
      },
      set(value) {
        this.localPairedUnitId = value;
      }
    },
    pairedUnitsDetails() {
      return this.unit?.pairedUnits;
    },
    localUnit() {
      if (this.localPairedUnitId) {
        return this.customerUnits.find(unit => unit?.unitId == this.localPairedUnitId);
      } else {
        return {};
      }
    },
    filteredCustomerUnits() {
      //prevents user from pairing unit with itself or previously paired unit
      let unitList = this.customerUnits?.filter(u => {
        if (!this.unit?.pairedUnits.includes(u.unitId) && u.unitId != this.unit?.unitId) {
          return u;
        }
      });
      return unitList;
    },
    isPaired() {
      return this.unit?.pairedUnits != null && this.unit?.pairedUnits?.length != 0;
    }
  },
  methods: {
    ...mapActions([UnitActions.SEARCH_UNITS, UnitActions.CREATE_PAIRED_UNITS, UnitActions.DELETE_PAIRED_UNITS]),
    ...mapMutations([UnitMutations.SET_CUSTOMER_UNITS]),
    filterUnits(option, label, search) {
      return (
        option.vin.toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        option.fleetUnitId.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    },
    onUnitSearch(search, loading) {
      if (search.length > 0) {
        loading(true);
        this.searchUnits(this.unit.customerId, search, loading, this);
      } else {
        loading(false);
      }
    },
    searchUnits: debounce(async (customerId, search, loading, vm) => {
      await vm[UnitActions.SEARCH_UNITS]({ customerId, search });
      loading(false);
    }, 500),
    confirmDelete: function (item) {
      if (this.isPaired) {
        this.$refs.UnpairWarning.show(this, this.deleteItem.bind(this, item));
      } else {
        this.deleteItem(item);
      }
    },
    deleteItem: async function (item) {
      this.isLoading = true;
      try {
        await this[UnitActions.DELETE_PAIRED_UNITS]({
          unitId: this.unit.unitId,
          pairedUnitId: item.unitId
        });
        SuccessService.createSuccessToast(this.$root, 'Units Successfuly Unpaired.');
      } catch (error) {
        ErrorService.createErrorToast(this, 'Failed to unpair Units.');
      } finally {
        this.localPairedUnitId = null;
        this.isLoading = false;
      }
    },
    addOrSave: async function () {
      try {
        this.isSaving = true;
        await this[UnitActions.CREATE_PAIRED_UNITS]({
          customerId: this.unit.customerId,
          unitId: this.unit.unitId,
          pairedUnitId: this.localPairedUnitId
        });
        this[UnitMutations.SET_CUSTOMER_UNITS]([]);
        SuccessService.createSuccessToast(this.$root, 'Units Successfuly Paired.');
      } catch (error) {
        ErrorService.createErrorToast(this, 'Failed to pair Units.');
      } finally {
        this.isSaving = false;
      }
    }
  }
};
</script>
