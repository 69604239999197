<template>
  <div class="d-flex justify-content-between">
    <div class="flex w-100 mr-2">
      <b-tabs v-model="activeTab" lazy class="estimorder-tabs" @activate-tab="onTabChange">
        <div class="p-1 bg-white border-right border-left"></div>
        <b-card
          ref="scrollCard"
          class="overflow-auto"
          :style="`min-height: 'calc(-125px + 95vh);'; ${
            $isCustomer ? 'max-height: calc(-125px + 95vh);' : 'max-height: calc(-125px + 100vh);'
          }`"
        >
          <b-tab v-if="showEstimateTab">
            <template slot="title">
              <div v-if="loadingEstimate || loading">
                <b-spinner small></b-spinner>
                Estimate
              </div>
              <div v-else>Estimate</div>
            </template>
            <div v-if="notFound">
              <p>Estimate not found.</p>
            </div>
            <div v-else-if="loadingEstimate" class="text-center py-4">
              <b-spinner></b-spinner>
            </div>
            <div v-else-if="hasErrorEstimate">
              <p>This Estimate is associated with multiple Service Orders. Please review in Excede.</p>
            </div>
            <div v-else-if="hasServiceOrder && !hasEstimate">
              <p v-if="hasInvoicedServiceOrder">No Estimate was created for the Service Order.</p>
              <p v-else>Save the Service Order then click the Create Estimate button to build an Estimate.</p>
            </div>
            <div v-else-if="!loading">
              <div v-if="readonly || $isCustomer || hasInvoicedServiceOrder">
                <service-order-details ref="estimate-details" />
              </div>
              <div v-else>
                <service-order-edit ref="estimate-edit" @scrollToError="scrollToError" @cancel="cancel" />
              </div>
            </div>
          </b-tab>
          <b-tab v-if="showServiceOrderTab">
            <template slot="title">
              <div v-if="loadingServiceOrder">
                <b-spinner small></b-spinner>
                Service Or...
              </div>
              <div v-else>Service Order</div>
            </template>
            <div v-if="notFound">
              <p>Service Order not found.</p>
            </div>
            <div v-else-if="$isCustomer && isOpenServiceOrder && !showOpenSOs">
              <p>Service Order in progress.</p>
            </div>
            <div v-else-if="loading || loadingServiceOrder" class="text-center py-4">
              <b-spinner></b-spinner>
            </div>
            <div v-else-if="hasEstimateLinesOnServiceOrder">
              <p v-if="$isCustomer">
                This Service Order cannot be opened. Please contact a VehiCare representative for more information.
              </p>
              <p v-else>
                This Service Order contains estimate line items and cannot be opened in VAMS. Please use Excede to edit
                this Service Order.
              </p>
            </div>
            <div v-else-if="hasErrorServiceOrder">
              <p>The associated Estimate has multiple SOs. Please review in Excede.</p>
            </div>
            <div v-else-if="hasEstimate && !hasServiceOrder">
              <p>Save the Estimate then click the Create Service Order button to build an SO.</p>
            </div>
            <div v-else-if="!loading">
              <div v-if="readonly || $isCustomer">
                <service-order-details />
              </div>
              <div v-else>
                <service-order-edit ref="serviceOrder-edit" @scrollToError="scrollToError" @cancel="cancel" />
              </div>
            </div>
          </b-tab>
        </b-card>
      </b-tabs>
    </div>
    <div v-if="hasEstimorder && !hasEstimateLinesOnServiceOrder" style="width: 54px">
      <floating-actions @change="openAside" />
    </div>
    <b-sidebar
      id="sidebar-1"
      sidebar-class="vams-aside"
      :visible="showAside"
      right
      shadow="lg"
      no-header
      lazy
      no-close-on-esc
      no-close-on-route-change
    >
      <div class="d-flex">
        <floating-actions in-sidebar @change="onAsideChange" />
        <div class="flex w-100 p-4">
          <div class="float-right mb-2 mr-2 mt-neg10">
            <button type="button" class="close" aria-label="Close" @click="onAsideChange(ASIDES.CLOSED)">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="clearfix"></div>
          <div class="d-inline-block w-100">
            <attachments-container
              v-if="activeAside === ASIDES.ATTACHMENTS"
              :ref="`aside-${ASIDES.ATTACHMENTS}`"
              :branch-id="branchId"
              :title="`${estimorderDisplayText} ID: ${serviceOrder.serviceOrderId}`"
              :label="estimorderDisplayText"
              :show-customer="true"
              :customer-id="customerId"
            />
            <notes
              v-else-if="activeAside === ASIDES.NOTES"
              :ref="`aside-${ASIDES.NOTES}`"
              :title="`${estimorderDisplayText} ID: ${serviceOrderId}`"
              :label="estimorderDisplayText"
              :is-service-order="true"
            />
            <service-order-workflow v-else-if="activeAside === ASIDES.WORKFLOW" :ref="`aside-${ASIDES.WORKFLOW}`" />
            <service-order-request-approval v-else-if="activeAside === ASIDES.REQUEST" />
            <service-order-unit-ready v-else-if="activeAside === ASIDES.UNIT_READY" />
            <service-order-repair-contact v-else-if="activeAside === ASIDES.REPAIR_CONTACT" />
            <unit-out-of-service v-else-if="activeAside === ASIDES.OUT_OF_SERVICE" />
            <parts-request
              v-else-if="activeAside === ASIDES.PARTS_REQUEST"
              id="partsRequest "
              ref="partsRequest"
              @close="closeAside()"
            />
          </div>
        </div>
      </div>
    </b-sidebar>
    <unsaved-changes-modal
      ref="UnsavedChangesModal"
      :has-changes="hasChanges"
      stay-on-page
      :continue-btn-text="'Continue with ' + unsavedWorkItem"
    ></unsaved-changes-modal>
  </div>
</template>

<script>
// components
import ServiceOrderDetails from './ServiceOrderDetailsComponent';
import ServiceOrderEdit from './ServiceOrderEditComponent';
import AttachmentsContainer from '@/shared/components/floating-actions/AttachmentsContainer';
import NotesComponent from '@/shared/components/floating-actions/NotesComponent';
import ServiceOrderWorkflowComponent from './floating-actions/ServiceOrderWorkflowComponent';
import ServiceOrderRequestApprovalComponent from './floating-actions/ServiceOrderRequestApprovalComponent';
import ServiceOrderUnitReadyComponent from './floating-actions/ServiceOrderUnitReadyComponent';
import ServiceOrderRepairContactComponent from './floating-actions/ServiceOrderRepairContactComponent';
import PartsRequest from './floating-actions/PartsRequestComponent';

import UnitOutOfService from '@/shared/pages/unit/floating-actions/UnitOutOfServiceComponent';
import FloatingActionsComponent from '@/shared/components/floating-actions/FloatingActionsComponent';
import UnsavedChangesModal from '@/shared/components/UnsavedChangesModal';

// vuex
import { ServiceOrderActions, ServiceOrderMutations, ServiceOrderGetters } from '@/shared/store/service-order/types';
import { LookupActions, LookupGetters } from '@/shared/store/lookup/types';
import { CustomerActions } from '@/shared/store/customer/types';
import { UnitActions, UnitGetters } from '@/shared/store/unit/types';
import { AttachmentGetters } from '@/shared/store/attachment/types';
import { NoteGetters } from '@/shared/store/note/types';
import { PartsRequestGetters } from '@/shared/store/parts-request/types';
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex';

// constants + helpers
import { FORM_STAGES } from '@/shared/store/service-order/state';
import AsideMixin from '@/shared/mixins/AsideMixin';
import { ASIDES } from '@/shared/components/floating-actions/FloatingActionsComponent';
import Cloner from '@/shared/helpers/cloner';
import { OutOfServiceGetters } from '@/shared/store/unit/out-of-service/types';

export default {
  name: 'ServiceOrderPage',
  components: {
    'service-order-details': ServiceOrderDetails,
    'service-order-edit': ServiceOrderEdit,
    'attachments-container': AttachmentsContainer,
    notes: NotesComponent,
    'service-order-workflow': ServiceOrderWorkflowComponent,
    'service-order-request-approval': ServiceOrderRequestApprovalComponent,
    'service-order-unit-ready': ServiceOrderUnitReadyComponent,
    'service-order-repair-contact': ServiceOrderRepairContactComponent,
    'unit-out-of-service': UnitOutOfService,
    'parts-request': PartsRequest,
    'floating-actions': FloatingActionsComponent,
    'unsaved-changes-modal': UnsavedChangesModal
  },
  mixins: [AsideMixin],
  beforeRouteLeave(to, from, next) {
    if (this.hasChanges()) {
      this.$refs.UnsavedChangesModal.show(this, next);
    } else {
      next();
    }
  },
  async beforeRouteUpdate(to, from, next) {
    const updatePage = async (vm, goNext = true) => {
      if (goNext) {
        next();
        if ((!vm.serviceOrder || vm.serviceOrderId != vm.serviceOrder.serviceOrderId) && !this.redirected) {
          vm.loading = true;
          await vm[ServiceOrderActions.RESET_STATE]();
          await vm.loadPage();
          if (vm.$refs[`${vm.estimorder}-edit`]) vm.$refs[`${vm.estimorder}-edit`].resetVuelidate();
          vm.loading = false;
        }
      }
    };

    if (this.hasChanges()) {
      this.$refs.UnsavedChangesModal.show(this, updatePage.bind({}, this));
    } else {
      updatePage(this);
    }
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      if (!vm.$isCustomer) {
        vm[LookupActions.FETCH_VMRS_HIERARCHY]();
      }
    });
  },
  data() {
    return {
      FORM_STAGES: FORM_STAGES,
      loading: false,
      loadingServiceOrder: false,
      loadingEstimate: false,
      notFound: false,
      activeTab: 0,
      redirected: false
    };
  },
  computed: {
    ...mapState('serviceOrder', ['estimorder', 'formStage']),
    ...mapGetters([
      ServiceOrderGetters.HAS_ESTIMORDER_ERROR,
      ServiceOrderGetters.HAS_CHANGES,
      AttachmentGetters.HAS_CHANGES,
      NoteGetters.HAS_CHANGES,
      OutOfServiceGetters.HAS_ANY_CHANGES,
      PartsRequestGetters.HAS_CHANGES
    ]),
    ...mapGetters({
      readonly: ServiceOrderGetters.GET_READONLY,
      serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER,
      isEstimate: ServiceOrderGetters.GET_IS_ESTIMATE,
      estimateId: ServiceOrderGetters.GET_ESTIMATE_ID,
      branchId: ServiceOrderGetters.GET_BRANCH_ID,
      estimorderDisplayText: ServiceOrderGetters.GET_ESTIMORDER_DISPLAY_TEXT,
      oldEstimorder: ServiceOrderGetters.GET_OLD_ESTIMORDER,
      unit: UnitGetters.GET_UNIT,
      serviceOrderHistory: UnitGetters.GET_UNIT_SERVICE_ORDER_HISTORY,
      appointmentServiceOrderId: ServiceOrderGetters.GET_APPOINTMENT_UNIT_SERVICE_ORDERS,
      isOpenServiceOrder: ServiceOrderGetters.GET_IS_OPEN_SERVICE_ORDER,
      uiConfig: LookupGetters.GET_UI_CONFIG
    }),
    showOpenSOs() {
      return this.uiConfig.showOpenSOs;
    },
    unsavedWorkItem() {
      var unsavedWorkItemName = '';

      switch (this.activeAside) {
        case ASIDES.CLOSED:
          unsavedWorkItemName = this.estimorderDisplayText;
          break;
        case ASIDES.ATTACHMENTS:
          unsavedWorkItemName = 'ATTACHMENTS';
          break;
        case ASIDES.NOTES:
          unsavedWorkItemName = 'NOTES';
          break;
        case ASIDES.OUT_OF_SERVICE:
          unsavedWorkItemName = 'OUT OF SERVICE';
          break;
        default:
          unsavedWorkItemName = this.estimorderDisplayText;
      }

      return unsavedWorkItemName;
    },
    unitId() {
      return this.$route.params.unitId;
    },
    serviceOrderIdFromRoute() {
      return this.$route.params.serviceOrderId;
    },
    warnIfOpen() {
      return this.$route.query.warnIfOpen !== undefined;
    },
    customerId() {
      return this.serviceOrder.customerId;
    },
    hasEstimateLinesOnServiceOrder() {
      return this.serviceOrder.hasEstimateLinesOnServiceOrder;
    },
    otherEstimorder() {
      if (this.estimorder === 'estimate') return 'serviceOrder';
      return 'estimate';
    },
    hasEstimorder() {
      if (this.estimorder == 'serviceOrder' && !this.hasServiceOrder) return false;
      if (this.estimorder == 'estimate' && !this.hasEstimate) return false;
      return true;
    },
    hasEstimate() {
      return (
        this.$route.query.mode === 'estimate' ||
        this.$store.state.serviceOrder.estimate.serviceOrderId != null ||
        (this.$store.state.serviceOrder.serviceOrder.estimateId != null &&
          this.$store.state.serviceOrder.serviceOrder.estimateId.substring(0, 1) === 'E')
      );
    },
    hasServiceOrder() {
      return (
        this.$route.query.mode === 'serviceOrder' ||
        this.$store.state.serviceOrder.serviceOrder.serviceOrderId != null ||
        this.$store.state.serviceOrder.estimate.estimateId != null
      );
    },
    hasErrorServiceOrder() {
      return this[ServiceOrderGetters.HAS_ESTIMORDER_ERROR]('serviceOrder');
    },
    hasErrorEstimate() {
      return this[ServiceOrderGetters.HAS_ESTIMORDER_ERROR]('estimate');
    },
    showEstimateTab() {
      if (this.loading || this.isEstimate) return true;
      if (this.$isCustomer) {
        if (this.hasServiceOrder && !this.hasEstimate) return false;
        if (this.hasErrorEstimate) return false;
      }
      return true;
    },
    showServiceOrderTab() {
      if (!this.isEstimate) return true;
      if (this.$isCustomer) {
        if (this.hasEstimate && !this.hasServiceOrder) return false;
        if (this.hasErrorServiceOrder) return false;
      }
      return true;
    },
    hasInvoicedServiceOrder() {
      return this.$store.state.serviceOrder.serviceOrder.invoiced;
    }
  },
  watch: {
    branchId() {
      if (this.branchId) {
        this[LookupActions.FETCH_EMPLOYEES_BY_BRANCH](this.branchId);
        if (!this.$isCustomer) this[ServiceOrderActions.FETCH_TECH_LIST]();
      }
    },
    customerId: {
      handler() {
        if (this.customerId) {
          if (!this.$isCustomer) this[CustomerActions.FETCH_APPROVERS_FOR_CUSTOMER](this.customerId);
          this[CustomerActions.FETCH_USERS_FOR_CUSTOMER](this.customerId);
        }
      },
      immediate: true
    }
  },
  created: async function () {
    await this[ServiceOrderActions.RESET_STATE]();
    await this.getAppointmentUnitServiceOrdersAndRedirect(this.$route.params, this.$route.query);

    await this.loadPage();
  },
  beforeDestroy() {
    this[ServiceOrderActions.RESET_STATE]();
  },
  methods: {
    ...mapActions([
      UnitActions.FETCH_UNIT,
      UnitActions.FETCH_UNIT_STANDARD_OPERATIONS,
      UnitActions.FETCH_UNIT_SERVICE_ORDER_HISTORY,
      UnitActions.HIDE_SERVICE_ORDER_HISTORY_ASIDE,
      ServiceOrderActions.FETCH_SERVICE_ORDER,
      ServiceOrderActions.FETCH_CUSTOMER_SPECIFIC_REPAIR_REASONS,
      ServiceOrderActions.FETCH_BRANCH_SPECIFIC_MISC_CODES,
      ServiceOrderActions.FETCH_LINKED_SERVICE_ORDER,
      ServiceOrderActions.FETCH_CUSTOMER,
      ServiceOrderActions.FETCH_TECH_LIST,
      ServiceOrderActions.RESET_STATE,
      CustomerActions.FETCH_APPROVERS_FOR_CUSTOMER,
      CustomerActions.FETCH_USERS_FOR_CUSTOMER,
      ServiceOrderActions.HAS_CHANGES,
      ServiceOrderActions.SET_SERVICE_ORDER,
      ServiceOrderActions.DEFAULT_PROP,
      LookupActions.FETCH_VMRS_HIERARCHY,
      LookupActions.FETCH_EMPLOYEES_BY_BRANCH,
      ServiceOrderActions.FETCH_APPOINTMENT_UNIT_SERVICE_ORDERS
    ]),
    ...mapMutations([
      ServiceOrderMutations.SET_ESTIMORDER,
      ServiceOrderMutations.SET_ESTIMORDER_ERROR,
      ServiceOrderMutations.ADD_ATTRIBUTES_TO_FORM
    ]),
    async onTabChange(activeTab, prevTab, bvEvt) {
      if (this.loadingEstimate || this.loadingServiceOrder) {
        bvEvt.preventDefault();
        return;
      }
      if (this.$refs[`${this.estimorder}-edit`] && this.$refs[`${this.estimorder}-edit`].hasChanges()) {
        bvEvt.preventDefault();
        await this.beforeNavigation(this.changeTab);
      } else {
        let newEstimorder = 'estimate';
        if (activeTab === 1) {
          newEstimorder = 'serviceOrder';
        }
        this.updatePageAfterTabChange(newEstimorder);
      }
    },
    scrollToError(positionOfError) {
      this.$refs['scrollCard'].scrollTop = positionOfError;
    },
    changeTab(doChange = true) {
      if (doChange) {
        this.discardChanges();
        let newEstimorder = 'estimate';
        if (this.activeTab === 0) {
          this.activeTab = 1;
          newEstimorder = 'serviceOrder';
        } else {
          this.activeTab = 0;
        }
        this.serviceOrder.serviceOrderId;
        this.updatePageAfterTabChange(newEstimorder);
      }
    },
    updatePageAfterTabChange(newEstimorder) {
      this[ServiceOrderMutations.SET_ESTIMORDER](newEstimorder);
      if (
        this.serviceOrderIdFromRoute &&
        this.serviceOrder.serviceOrderId &&
        this.serviceOrderIdFromRoute !== this.serviceOrder.serviceOrderId
      ) {
        this.$router.replace({
          path: `/units/${this.unitId}/service-orders/${this.serviceOrder.serviceOrderId}`
        });
      }
      this.checkOpenedAside();
    },
    beforeNavigation(changeTab) {
      this.$refs.UnsavedChangesModal.show(this, changeTab);
    },
    onAsideChange(asideNumber) {
      if (asideNumber === this.activeAside) return;
      if (
        this.hasChanges('newNote') ||
        this.hasChanges('requestApproval') ||
        this.hasChanges('unitReady') ||
        this.hasChanges('workflow') ||
        this.hasChanges('repairContact') ||
        this.hasChanges('outOfService') ||
        this.hasChanges('partsRequest')
      ) {
        // beforeNavigation will call changeAside() or changeAside(false)
        // .bind will set the 1st argument of asideNumber, so those calls will be setting the 2nd arg of doChange
        this.beforeNavigation(this.changeAside.bind({}, asideNumber));
      } else {
        this.activeAside = asideNumber;
      }
    },
    changeAside(asideNumber, doChange = true) {
      if (doChange) {
        this.discardChanges();
        this.activeAside = asideNumber;
      }
    },
    checkOpenedAside() {
      if (!this.showAside) return;
      if (
        !this.hasEstimorder ||
        this.activeAside === ASIDES.REQUEST ||
        this.activeAside === ASIDES.UNIT_READY ||
        this.activeAside === ASIDES.REPAIR_CONTACT
      ) {
        this.activeAside = ASIDES.CLOSED;
      } else if (this.$refs[`aside-${this.activeAside}`] && this.$refs[`aside-${this.activeAside}`].fetch) {
        this.$refs[`aside-${this.activeAside}`].fetch();
      }
    },
    discardChanges() {
      this[ServiceOrderActions.SET_SERVICE_ORDER]({
        serviceOrder: Cloner.deepClone(this.oldEstimorder)
      });
    },
    async loadPage() {
      this[UnitActions.HIDE_SERVICE_ORDER_HISTORY_ASIDE]();
      if (
        this.$route.query.mode === 'serviceOrder' ||
        (this.serviceOrderIdFromRoute && this.serviceOrderIdFromRoute[0] == 'S')
      ) {
        this.activeTab = 1;
        this.onTabChange(this.activeTab);
      }

      this.loading = true;
      try {
        let { fetchUnit, fetchStandardOperations, fetchServiceOrderHistory } = this.fetchUnitInfo();

        if (!this.$isCustomer && !this.serviceOrderIdFromRoute) {
          await Promise.all([fetchUnit, fetchStandardOperations, fetchServiceOrderHistory]);
          const redirected = this.checkSoHistoryAndRedirectIfNeeded();
          if (redirected) return;
          await this[ServiceOrderActions.FETCH_CUSTOMER](this.unit.customerId);
        }

        await fetchUnit;
        this[ServiceOrderActions.DEFAULT_PROP]({ key: 'licensePlate', value: this.unit.licensePlate });
      } catch (error) {
        if (error.response && error.response.status == 404) {
          this.notFound = true;
        }
      }

      if (!this.notFound && this.serviceOrderIdFromRoute) {
        await this.fetchEstimorder();
        if (this.notFound) {
          this.loading = false;
          return;
        }
        this.fetchLinkedEstimorder();
      } else {
        this.setServiceOrderAttributesFromUnit();
      }
      this.loading = false;
    },
    fetchUnitInfo() {
      let fetchUnit = this[UnitActions.FETCH_UNIT](this.unitId);
      let fetchStandardOperations = null;
      let fetchServiceOrderHistory = null;
      if (!this.$isCustomer) {
        fetchStandardOperations = this[UnitActions.FETCH_UNIT_STANDARD_OPERATIONS](this.unitId);
        fetchServiceOrderHistory = this[UnitActions.FETCH_UNIT_SERVICE_ORDER_HISTORY]({ unitId: this.unitId });
      }
      return { fetchUnit, fetchStandardOperations, fetchServiceOrderHistory };
    },
    async fetchEstimorder() {
      try {
        await this[ServiceOrderActions.FETCH_SERVICE_ORDER]({
          unitId: this.unitId,
          serviceOrderId: this.serviceOrderIdFromRoute
        });

        this[ServiceOrderActions.FETCH_CUSTOMER_SPECIFIC_REPAIR_REASONS](this.serviceOrder.billToCustomerId);
        if (!this.$isCustomer) {
          this[ServiceOrderActions.FETCH_BRANCH_SPECIFIC_MISC_CODES](this.serviceOrder.branchId);
        }
      } catch (error) {
        if (error.response && error.response.status == 404) {
          this.notFound = true;
        } else {
          throw error;
        }
      } finally {
        if (this.isEstimate) {
          this.activeTab = 0;
        } else {
          this.activeTab = 1;
        }
      }
    },
    async fetchLinkedEstimorder() {
      const isEstimate = this.isEstimate;
      if (isEstimate) this.loadingServiceOrder = true;
      else this.loadingEstimate = true;
      try {
        await this[ServiceOrderActions.FETCH_LINKED_SERVICE_ORDER]({
          unitId: this.unitId,
          serviceOrderId: this.serviceOrderIdFromRoute,
          estimateId: this.estimateId
        });
      } catch (error) {
        // TODO The error object can be used for 500s or 404s, might be able to replace this.notFound
        if (error.response && error.response.status == 500) {
          this[ServiceOrderMutations.SET_ESTIMORDER_ERROR]({
            estimorder: this.otherEstimorder,
            error: { ...error.response.data, status: error.response.status }
          });
        } else {
          throw error;
        }
      } finally {
        if (isEstimate) this.loadingServiceOrder = false;
        else this.loadingEstimate = false;
      }
    },
    checkSoHistoryAndRedirectIfNeeded() {
      if (this.warnIfOpen && this.serviceOrderHistory.some(x => !x.invoiced) && !this.redirected) {
        const { appointmentId, branchId } = this.$route.query;
        var path = `/units/${this.unit.unitId}/service-orders/warning?appointmentId=${appointmentId}&branchId=${branchId}`;
        if (this.$route.query.startDate) {
          path = `${path}&startDate=${this.$route.query.startDate}`;
        }
        this.$router.replace(path);
        return true;
      }
      return false;
    },
    setServiceOrderAttributesFromUnit() {
      var serviceOrderAttributes = this.unit.attributes.filter(a => a.editInServiceOrder);
      this[ServiceOrderMutations.ADD_ATTRIBUTES_TO_FORM](serviceOrderAttributes);
    },
    hasChanges(prop) {
      if (this.formStage == FORM_STAGES.SELECT_BRANCH) return false;
      if (!this.hasEstimorder) return false;
      return (
        this[ServiceOrderGetters.HAS_CHANGES](prop) ||
        this[AttachmentGetters.HAS_CHANGES] ||
        this[NoteGetters.HAS_CHANGES] ||
        this[OutOfServiceGetters.HAS_ANY_CHANGES] ||
        this[PartsRequestGetters.HAS_CHANGES]
      );
    },
    async getAppointmentUnitServiceOrdersAndRedirect(params, query) {
      var apptId = query?.appointmentId;
      if (apptId == null) return;
      var appointmentUnitServiceOrders = await this[ServiceOrderActions.FETCH_APPOINTMENT_UNIT_SERVICE_ORDERS](apptId);
      var redirectServiceOrder = appointmentUnitServiceOrders.find(x => x.unitId == params.unitId);
      if (redirectServiceOrder != null && redirectServiceOrder.length != 0) {
        this.redirected = true;
        this.redirect(redirectServiceOrder.unitId, redirectServiceOrder.serviceOrderId);
      }
    },
    redirect(unitId, serviceOrderId) {
      const path = `/units/${unitId}/service-orders/${serviceOrderId}`;
      this.$router.push(path);
    },
    cancel() {
      this.$router.push(`/units/${this.unitId}`);
    }
  }
};
</script>
