var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"page-card"},[_c('h2',{staticClass:"card-header"},[_vm._v("Deferred Work")]),(!_vm.isVcpUser)?_c('responsive-search',{ref:"search",attrs:{"fields":_vm.searchFields,"is-busy":_vm.searchIsBusy,"storage-key":"deferredWorkSearch"},on:{"searchButtonClick":_vm.searchButtonClick,"resetButtonClick":_vm.resetButtonClick}}):_vm._e(),(!_vm.$smallScreen)?_c('b-row',{staticClass:"d-flex justify-content-between"},[_c('b-form-group',[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"filterInput","variant":"position-relative","type":"search","placeholder":"Type to Filter","debounce":500},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1),_c('div',{staticClass:"d-flex"},[_c('div',{staticClass:"mt-2 mr-3"},[_vm._v(_vm._s(_vm.deferredJobsCount)+" Deferred Jobs")]),_c('b-pagination',{staticClass:"ml-auto w-auto",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.pageSize,"aria-controls":"my-table","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1):_vm._e(),_c('b-table',{staticClass:"border",attrs:{"striped":"","no-border-collapse":"","sticky-header":_vm.$isCustomer ? '73vh' : '63vh',"items":_vm.deferredJobs,"fields":!_vm.isVcpUser ? _vm.fields : _vm.vcpFields,"per-page":_vm.pageSize,"current-page":_vm.currentPage,"filter":_vm.filter,"sort-by":_vm.sortBy,"show-empty":"","empty-text":"No jobs have been deferred for your branches.","empty-filtered-text":"No results found."},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(vin)",fn:function(ref){
var item = ref.item;
return [_c('safe-hyperlink',{attrs:{"to":'/units/' + item.unitId}},[_vm._v(" "+_vm._s(item.vin)+" ")])]}},{key:"cell(daysDeferred)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.daysDeferred)+" ")]}},{key:"cell(jobId)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.jobId)+" ")]}},{key:"cell(name)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.name)+" ")]}},{key:"cell(jobSlsId)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.jobSlsId)+" ")]}},{key:"cell(jobSubtotal)",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.jobSubtotal))+" ")]}},(!_vm.isVcpUser)?{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('b-dropdown',{attrs:{"size":"xsm","right":"","variant":"secondary","no-caret":"","boundary":"viewport"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"ellipsis-h"}})]},proxy:true}],null,true)},[(!_vm.$isCustomer && _vm.serviceRequestEnabled)?_c('b-link',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.requestService(item)}}},[_vm._v(" Schedule Service ")]):_vm._e(),(!_vm.$isCustomer)?_c('b-link',{staticClass:"dropdown-item",attrs:{"to":{
                path: ("/units/" + (item.unitId) + "/service-orders/create?mode=estimate")
              }}},[_vm._v(" Create Estimate ")]):_vm._e(),(!_vm.$isCustomer)?_c('b-link',{staticClass:"dropdown-item",attrs:{"to":{
                path: ("/units/" + (item.unitId) + "/service-orders/create?mode=serviceOrder")
              }}},[_vm._v(" Create Service Order ")]):_vm._e(),(!_vm.$isCustomer)?_c('b-link',{staticClass:"dropdown-item",on:{"click":function($event){return _vm.confirmDelete(item)}}},[_vm._v("Remove job")]):_vm._e()],1)],1)]}}:null],null,true)})],1),(_vm.serviceRequestEnabled)?_c('service-request-modal',{ref:"serviceRequestModal"}):_vm._e(),_c('warning-modal',{ref:"DeferredJobDeleteWarning",attrs:{"id":"DeferredJobDeleteWarning","title":"Warning","warning-text":_vm.deleteText,"continue-btn-text":"Yes, Remove","cancel-btn-text":"Cancel"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }