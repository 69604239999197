export const namespace = 'operationsForecast/';

export const OperationsForecastGetters = {
  GET_OPERATIONS_FORECAST: namespace + 'getOperationsForecast',
  GET_CURRENT_PAGE: namespace + 'getCurrentPage',
  GET_TOTAL_UNITS: namespace + 'getTotalUnits',
  GET_TOTAL_ROWS: namespace + 'getTotalRows',
  GET_OPERATIONS_FORECAST_UNIQUE_UNITS: namespace + 'getOperationsForecastUniqueUnits',
  GET_BULK_SERVICE_REQUEST_MODAL_FLAG: namespace + 'getBulkServiceRequestModalFlag',
  GET_IS_SEARCH_PM_CONTRACT_OPERATIONS: namespace + 'getIsSearchPMContractOperations'
};

export const OperationsForecastActions = {
  FETCH_OPERATIONS_FORECAST: namespace + 'fetchOperationsForecast'
};

export const OperationsForecastMutations = {
  SET_PROP: namespace + 'setProp',
  SET_IS_SEARCH_PM_CONTRACT_OPERATIONS: namespace + 'setIsSearchPMContractOperations'
};
