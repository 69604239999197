import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, CustomerGetters } from './types';

const _getters = namespaceHelper.removeNamespace(namespace, CustomerGetters);

export default {
  [_getters.GET_CUSTOMER]: state => {
    return state.customer;
  },
  [_getters.GET_CUSTOMER_PREVIEW]: state => {
    return state.customerPreview;
  },
  [_getters.GET_APPROVER_LIST]: state => {
    return state.approverList;
  },
  [_getters.GET_VCP_USER_LIST]: state => {
    return state.vcpUserList;
  },
  [_getters.GET_BRANCH_ID]: state => {
    return state.customer.branchId;
  },
  [_getters.GET_CUSTOMER_NAME]: state => {
    return state.customer.customerName;
  },
  [_getters.GET_ADDRESS]: state => {
    return state.customer.address1;
  },
  [_getters.GET_ADDRESS_2]: state => {
    return state.customer.address2;
  },
  [_getters.GET_CITY]: state => {
    return state.customer.city;
  },
  [_getters.GET_STATE]: state => {
    return state.customer.state;
  },
  [_getters.GET_ZIP_CODE]: state => {
    return state.customer.zipCode;
  },
  [_getters.GET_PHONE]: state => {
    return state.customer.phone;
  },
  [_getters.GET_FAX]: state => {
    return state.customer.fax;
  },
  [_getters.GET_EMAIL]: state => {
    return state.customer.email;
  },
  [_getters.HAS_CHANGES]: state => {
    const replacer = (k, v) => (v === '' ? null : v);
    return JSON.stringify(state.customer, replacer) !== JSON.stringify(state.oldCustomer, replacer);
  },
  [_getters.GET_SEARCH_RESULTS]: state => {
    return state.customerSearchResults;
  },
  [_getters.GET_TO_EXISTING_CUSTOMER]: state => {
    return state.toExistingCustomer;
  }
};
