import namespaceHelper from '@/shared/helpers/namespace-helper';
import Cloner from '@/shared/helpers/cloner';
import { namespace, AttachmentMutations } from './types';
import { getDefaultState } from './state';
import Vue from 'vue';

const _mutations = namespaceHelper.removeNamespace(namespace, AttachmentMutations);

export default {
  [_mutations.RESET_STATE](state) {
    Object.assign(state, getDefaultState());
  },
  [_mutations.SET_ATTACHMENTS](state, { key, attachments }) {
    key = key || 'attachments';
    Vue.set(state, key, attachments);
    const dbKey = 'db' + key.charAt(0).toUpperCase() + key.slice(1);
    Vue.set(state, dbKey, Cloner.deepClone(attachments));
  },
  [_mutations.SET_WARRANTY_ASIDE_ATTACHMENTS](state, { key, attachments }) {
    key = key || 'warrantyAsideAttachments';
    Vue.set(state, key, attachments);
    state.warrantyAsideAttachments = attachments;
  },
  [_mutations.SET_COUNT_OF_ATTACHMENTS](state, count) {
    state.numOfAttachmentsCount = count;
  },
  [_mutations.SET_ATTACHMENT_DESCRIPTION](state, attachment) {
    const index = state.attachments.map(a => a.attachmentId).indexOf(attachment.attachmentId);
    state.attachments[index].description = attachment.description;
  },
  [_mutations.SET_ATTACHMENT_INTERNAL_FLAG](state, payload) {
    var attachment = payload.attachment;
    var newFlagValue = payload.newFlagValue;
    const index = state.attachments.map(a => a.attachmentId).indexOf(attachment.attachmentId);
    state.attachments[index].internal = newFlagValue;
  }
};
