<template>
  <b-form-group
    :label="label"
    :label-for="id"
    v-bind="{ labelClass, labelColsXl, labelColsLg, labelColsMd, labelColsSm, labelCols }"
  >
    <template #label>
      <slot name="label">{{ label }}</slot>
    </template>
    <div v-if="readonly" :id="id" class="col-form-label">
      <span v-if="hideTime">
        {{ date || null | date }}
      </span>
      <span v-else>
        {{ value || null | dateTime }}
      </span>
    </div>
    <div v-else>
      <b-row no-gutters>
        <b-col :class="`${hideTime ? '' : 'pr-2 '}${dateColClass}`">
          <date-picker
            :id="id"
            v-model="date"
            :disabled-dates="disabledDates"
            :disabled="disabled"
            :placeholder="placeholder"
            :format="dateFormat"
            :typeable="isTypeable"
            :input-class="`w-100 form-control ${validator && validator.$error ? 'is-invalid' : ''}${
              !isTypeable ? 'bg-white' : ''
            }`"
          ></date-picker>
        </b-col>
        <b-col v-if="!hideTime" :class="timeColClass">
          <b-form-input
            :id="timeId"
            v-time-input
            :value="time"
            :disabled="disabled"
            placeholder="HH:MM AM"
            autocomplete="off"
            :state="validator && validator.$error ? false : null"
            class="w-100 vams-time-input"
            @blur="time = $event.target.value"
          />
        </b-col>
        <span v-if="required == true" :class="`required-asterisk`">*</span>
        <span v-else-if="required == false" class="required-placeholder"></span>
        <b-col v-if="showTodayBtn != null" class="pl-2 d-flex col-auto">
          <div :style="`visibility: ${showTodayBtn ? 'visible' : 'hidden'}`">
            <b-button :id="`today-btn-${_uid}`" size="sm" @click="today()">
              <font-awesome-icon size="lg" :icon="['far', 'calendar-plus']" />
            </b-button>
            <b-tooltip :target="`today-btn-${_uid}`" triggers="hover">Add current date and time.</b-tooltip>
          </div>
        </b-col>
      </b-row>
      <slot></slot>
    </div>
  </b-form-group>
</template>

<script>
import moment from 'moment';
import dateSetters from '@/shared/helpers/date-setters';
import timeInput from '@/shared/directives/TimeInput';
import datePicker from 'vuejs-datepicker';
import FormGroupPropsMixin from '@/shared/mixins/FormGroupPropsMixin';

export default {
  name: 'VamsDateTimeInput',
  components: {
    'date-picker': datePicker
  },
  directives: {
    timeInput
  },
  mixins: [FormGroupPropsMixin],
  props: {
    label: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: true
    },
    value: {
      type: [Date, String],
      default: () => null
    },
    required: {
      type: Boolean,
      default: null
    },
    validator: Object(),
    disabledDates: Object(),
    hideTime: Boolean,
    showTodayBtn: {
      type: Boolean,
      default: null
    },
    readonly: Boolean,
    disabled: Boolean,
    dateColClass: String(),
    timeColClass: String(),
    isTypeable: {
      type: Boolean,
      default: true
    },
    isInternational: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    timeId() {
      return `time-${this.id}`;
    },
    date: {
      get() {
        return this.value ? moment(this.value).toDate() : this.value;
      },
      set(value) {
        this.$emit('input', dateSetters.setDate(value, this.date));
      }
    },
    time: {
      get() {
        return this.value ? moment(this.value).format('LT') : '';
      },
      set(value) {
        this.$emit('input', dateSetters.setTime(value, this.date));
      }
    },
    dateFormat() {
      return this.isInternational ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
    },
    placeholder() {
      return this.isInternational ? 'DD/MM/YYYY' : 'MM/DD/YYYY';
    }
  },
  methods: {
    today() {
      if (this.hideTime) {
        this.$emit('input', moment().startOf('day').toDate());
        return;
      }

      this.$emit('input', moment().toDate());
    }
  }
};
</script>
