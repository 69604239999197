export const getDefaultState = () => {
  const partsRequestPrototype = {
    partsRequestTypeId: null,
    requestParts: []
  };

  return {
    allOpsEmployees: [],
    materialsEmployees: [],
    partsRequestSearchResults: [],
    partsRequest: {
      ...partsRequestPrototype
    },
    dbPartsRequest: {
      ...partsRequestPrototype
    },
    requestedParts: []
  };
};

export default {
  ...getDefaultState()
};
