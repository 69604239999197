<template>
  <div class="position-absolute d-flex flex-column" :style="containerStyle">
    <b-button
      v-if="canAddAttachment"
      v-b-tooltip="tooltip"
      variant="primary"
      :class="buttonClass"
      :title="isWarrantyAside ? 'Service Order Attachements' : 'Attachments'"
      @click="sendAside(ASIDES.ATTACHMENTS)"
    >
      <b-spinner v-if="loadingAttachments" class="floating-action-badge" small />
      <b-badge v-else-if="numOfAttachments > 0" class="floating-action-badge" variant="info">
        {{ numOfAttachments }}
      </b-badge>
      <img src="/images/paperclip.png" width="25px" height="auto" alt="Attachments" />
    </b-button>
    <b-button
      v-if="canAddNotes"
      v-b-tooltip="tooltip"
      variant="primary"
      :class="buttonClass"
      title="Notes"
      @click="sendAside(ASIDES.NOTES)"
    >
      <img src="/images/notes.png" width="25px" height="auto" alt="Notes" />
    </b-button>
    <b-button
      v-if="canAddWorkflow"
      v-b-tooltip="tooltip"
      variant="primary"
      :class="buttonClass"
      title="Workflow"
      @click="sendAside(ASIDES.WORKFLOW)"
    >
      <img src="/images/workflow.png" width="28px" height="auto" alt="Workflow" />
    </b-button>
    <b-button
      v-if="canRequestApproval"
      v-b-tooltip="tooltip"
      variant="primary"
      :class="buttonClass"
      title="Request Approval"
      @click="sendAside(ASIDES.REQUEST)"
    >
      <img src="/images/approval_white.png" width="30px" height="auto" alt="Request Approval" />
    </b-button>
    <b-button
      v-if="canSendUnitReady"
      v-b-tooltip="tooltip"
      variant="primary"
      :class="buttonClass"
      title="Unit Ready"
      @click="sendAside(ASIDES.UNIT_READY)"
    >
      <img src="/images/unit-ready.svg" width="32px" height="auto" alt="Unit Ready" />
    </b-button>
    <b-button
      v-if="canAddRepairContact"
      v-b-tooltip="tooltip"
      variant="primary"
      :class="buttonClass"
      title="Repair Contact"
      @click="sendAside(ASIDES.REPAIR_CONTACT)"
    >
      <img src="/images/address-book-white.svg" width="32px" height="auto" alt="Repair Contact Info" />
    </b-button>
    <b-button
      v-if="canViewOOS"
      v-b-tooltip="tooltip"
      :variant="isOutOfService ? 'danger' : 'primary'"
      :class="buttonClass"
      title="Out of Service"
      @click="sendAside(ASIDES.OUT_OF_SERVICE)"
    >
      <img src="/images/exclamation-triangle.svg" width="30px" height="auto" alt="Repair Contact" />
    </b-button>
    <b-button
      v-if="canViewUnitStatus"
      v-b-tooltip="tooltip"
      variant="primary"
      :class="buttonClass"
      title="Unit Status"
      @click="sendAside(ASIDES.UNIT_STATUS)"
    >
      <img src="/images/unit-status.svg" width="30px" height="auto" alt="Unit Status" />
    </b-button>
    <b-button
      v-if="canViewUnitTransfer"
      v-b-tooltip="tooltip"
      variant="primary"
      :class="buttonClass"
      title="Transfer Unit"
      @click="sendAside(ASIDES.UNIT_TRANSFER)"
    >
      <img src="/images/unit-transfer.svg" width="auto" height="30px" alt="Unit Transfer" />
    </b-button>
    <b-button
      v-if="canViewMountedEquipment"
      v-b-tooltip="tooltip"
      variant="primary"
      :class="buttonClass"
      title="Mounted Equipment"
      @click="sendAside(ASIDES.MOUNTED_EQUIPMENT)"
    >
      <img src="/images/mounted.svg" width="36px" height="auto" alt="Mounted Equipment" />
    </b-button>
    <b-button
      v-if="canViewPairedUnits"
      v-b-tooltip="tooltip"
      variant="primary"
      :class="buttonClass"
      title="Pair Units"
      @click="sendAside(ASIDES.PAIRED_UNITS)"
    >
      <img src="/images/pair-units.svg" width="30px" height="auto" alt="Pair Units" />
    </b-button>
    <b-button
      v-if="canRequestParts"
      v-b-tooltip="tooltip"
      variant="primary"
      :class="buttonClass"
      title="Parts Request"
      @click="sendAside(ASIDES.PARTS_REQUEST)"
    >
      <img src="/images/parts-request.svg" width="36px" height="auto" alt="Parts Request" />
    </b-button>
  </div>
</template>

<script>
import { ServiceOrderGetters } from '@/shared/store/service-order/types';
import { UserGetters } from '@/shared/store/user/types';
import { OutOfServiceGetters } from '@/shared/store/unit/out-of-service/types';
import { AttachmentActions, AttachmentGetters } from '@/shared/store/attachment/types';
import { mapActions, mapGetters, mapState } from 'vuex';
import { FORM_STAGES } from '@/shared/store/service-order/state';

import UserAccessMixin from '@/shared/mixins/UserAccessMixin';

export const ASIDES = {
  CLOSED: 0,
  ATTACHMENTS: 1,
  NOTES: 2,
  WORKFLOW: 3,
  REQUEST: 4,
  UNIT_READY: 5,
  REPAIR_CONTACT: 6,
  OUT_OF_SERVICE: 7,
  UNIT_STATUS: 8,
  UNIT_TRANSFER: 9,
  MOUNTED_EQUIPMENT: 10,
  PARTS_REQUEST: 11,
  PAIRED_UNITS: 12
};

export default {
  name: 'FloatingActions',
  mixins: [UserAccessMixin],
  props: {
    inSidebar: Boolean,
    path: {
      type: String(),
      default() {
        return this.$route.path;
      }
    }
  },
  data() {
    return {
      FORM_STAGES: FORM_STAGES,
      ASIDES: ASIDES,
      loadingAttachments: false
    };
  },
  computed: {
    ...mapGetters([AttachmentGetters.GET_ATTACHMENTS, UserGetters.AUTHORIZE_ROLE]),
    ...mapState('serviceOrder', ['formStage']),
    ...mapGetters({
      branchId: ServiceOrderGetters.GET_BRANCH_ID,
      isEstimate: ServiceOrderGetters.GET_IS_ESTIMATE,
      user: UserGetters.GET_USER_PROFILE,
      isOutOfService: OutOfServiceGetters.HAS_OPEN_UNIT_OUT_OF_SERVICE,
      serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER,
      numOfAttachmentsCount: AttachmentGetters.GET_COUNT_OF_ATTACHMENTS
    }),
    numOfAttachments() {
      if (this.isWarrantyAside) {
        return this.numOfAttachmentsCount;
      } else {
        return this[AttachmentGetters.GET_ATTACHMENTS]?.filter(x => !x.dateRemoved).length;
      }
    },
    buttonClass() {
      let selector = 'btn-floating mb-1';
      if (this.inSidebar) selector += ' rounded-right-0';
      return selector;
    },
    containerStyle() {
      let style = 'top: 200px;';
      if (this.inSidebar) style += ' left: -50px;';
      return style;
    },
    tooltip() {
      return {
        placement: 'left',
        boundary: 'window',
        trigger: 'hover'
      };
    },
    isCsrOrCsm() {
      return this[UserGetters.AUTHORIZE_ROLE](['CSR', 'CSM', 'RegionalCSM']);
    },
    hasInvoicedServiceOrder() {
      return this.$store.state.serviceOrder.serviceOrder.invoiced;
    },
    canAddAttachment() {
      return (
        (this.formStage >= FORM_STAGES.EDIT_SO && this.$route.name === 'ServiceOrderDetails') ||
        this.$route.name === 'UnitDetails' ||
        this.$route.name === 'CustomerDetails' ||
        this.$route.name === 'Inspection' ||
        this.$route.name === 'UnitScheduledOperations' ||
        this.$route.name === 'UnitServiceOrders' ||
        this.$route.name === 'UnitInspections' ||
        this.$route.name === 'UnitDvirDefects' ||
        this.$route.name === 'WarrantySubmission'
      );
    },
    isCustomerCreate() {
      return this.$route.fullPath === '/customers/create';
    },
    isServiceOrderCreate() {
      return this.$route.fullPath.includes('/service-orders/create');
    },
    canAddNotes() {
      return (
        (this.$route.name === 'ServiceOrderDetails' && this.formStage >= FORM_STAGES.EDIT_SO) ||
        this.$route.name === 'UnitDetails' ||
        this.$route.name === 'UnitScheduledOperations' ||
        this.$route.name === 'UnitServiceOrders' ||
        this.$route.name === 'UnitInspections' ||
        this.$route.name === 'UnitDvirDefects'
      );
    },
    canAddWorkflow() {
      return !this.$isCustomer && this.$route.name === 'ServiceOrderDetails' && this.formStage >= FORM_STAGES.EDIT_SO;
    },
    canRequestApproval() {
      return (
        !this.$isCustomer &&
        this.isEstimate &&
        this.isCsrOrCsm &&
        !this.hasInvoicedServiceOrder &&
        this.formStage >= FORM_STAGES.EDIT_SO
      );
    },
    canSendUnitReady() {
      return (
        !this.$isCustomer &&
        !this.isEstimate &&
        this.branchIsAccessible &&
        this.formStage >= FORM_STAGES.EDIT_SO &&
        this.$route.name === 'ServiceOrderDetails'
      );
    },
    canAddRepairContact() {
      return !this.$isCustomer && this.formStage >= FORM_STAGES.EDIT_SO && this.$route.name === 'ServiceOrderDetails';
    },
    canViewOOS() {
      return (
        this.$route.name === 'UnitDetails' ||
        this.$route.name === 'UnitScheduledOperations' ||
        this.$route.name === 'UnitServiceOrders' ||
        this.$route.name === 'UnitInspections' ||
        this.$route.name === 'UnitDvirDefects' ||
        (this.$route.name === 'ServiceOrderDetails' && this.formStage >= FORM_STAGES.EDIT_SO)
      );
    },
    canViewUnitStatus() {
      return (
        this.$route.name === 'UnitDetails' ||
        this.$route.name === 'UnitScheduledOperations' ||
        this.$route.name === 'UnitServiceOrders' ||
        this.$route.name === 'UnitInspections' ||
        this.$route.name === 'UnitDvirDefects'
      );
    },
    canViewUnitTransfer() {
      return (
        this.$route.name === 'UnitDetails' ||
        this.$route.name === 'UnitScheduledOperations' ||
        this.$route.name === 'UnitServiceOrders' ||
        this.$route.name === 'UnitInspections' ||
        this.$route.name === 'UnitDvirDefects'
      );
    },
    canViewMountedEquipment() {
      return (
        this.$route.name === 'UnitDetails' ||
        this.$route.name === 'UnitScheduledOperations' ||
        this.$route.name === 'UnitServiceOrders' ||
        this.$route.name === 'UnitInspections' ||
        this.$route.name === 'UnitDvirDefects'
      );
    },
    canViewPairedUnits() {
      return (
        this.$route.name === 'UnitDetails' ||
        this.$route.name === 'UnitScheduledOperations' ||
        this.$route.name === 'UnitServiceOrders' ||
        this.$route.name === 'UnitInspections' ||
        this.$route.name === 'UnitDvirDefects'
      );
    },
    canRequestParts() {
      return (
        !this.$isCustomer &&
        this.formStage >= FORM_STAGES.EDIT_SO &&
        this.$route.name === 'ServiceOrderDetails' &&
        this.serviceOrder.featureFlagPartsRequest
      );
    },
    isWarrantyAside() {
      return this.$route.name === 'WarrantySubmission';
    }
  },
  created() {
    if (!this.inSidebar && !this.isCustomerCreate && !this.isServiceOrderCreate) {
      this.fetchAttachments();
    }
  },
  methods: {
    ...mapActions([AttachmentActions.FETCH_ATTACHMENTS]),
    sendAside(aside) {
      this.$emit('change', aside);
    },
    async fetchAttachments() {
      this.loadingAttachments = true;
      try {
        await this[AttachmentActions.FETCH_ATTACHMENTS]({ route: this.path });
      } finally {
        this.loadingAttachments = false;
      }
    }
  }
};
</script>
