import namespaceHelper from '@/shared/helpers/namespace-helper';
import Cloner from '@/shared/helpers/cloner';
import { namespace, UnitMutations } from './types';
import Vue from 'vue';
import { getDefaultState } from './state';

const _mutations = namespaceHelper.removeNamespace(namespace, UnitMutations);

export default {
  [_mutations.RESET_STATE](state) {
    Object.assign(state, getDefaultState());
  },
  [_mutations.SET_OPERATIONS_FORECAST](state, operationsForecast) {
    state.operationsForecast = operationsForecast;
  },
  [_mutations.SET_UNIT_OPERATIONS_FORECAST](state, unitOperationsForecast) {
    state.unitOperationsForecast = unitOperationsForecast;
  },
  [_mutations.SET_UNIT_SERVICE_ORDER_HISTORY](state, unitServiceOrderHistory) {
    state.unitServiceOrderHistory = unitServiceOrderHistory;
  },
  [_mutations.SET_UNIT](state, unit) {
    if (unit.attributes) {
      unit.attributeMap = unit.attributes.reduce(function (map, obj) {
        map[obj.attributeId] = obj;
        return map;
      }, {});
    }
    state.unit = unit;
  },
  [_mutations.SET_OLD_UNIT](state) {
    state.dbUnit = Cloner.deepClone(state.unit);
  },
  [_mutations.DISCARD_CHANGES](state) {
    state.unit = Cloner.deepClone(state.dbUnit);
  },
  [_mutations.SET_UNIT_STANDARD_OPERATION](state, unitStandardOperation) {
    state.unitStandardOperation = unitStandardOperation;
  },
  [_mutations.SET_UNIT_STANDARD_OPERATIONS](state, unitStandardOperations) {
    state.unitStandardOperations = unitStandardOperations;
  },
  [_mutations.SET_SHOW_IN_OPS_FORECAST](state, showInOpsForecast) {
    state.unit.showInOpsForecast = showInOpsForecast;
  },
  [_mutations.SET_UNIT_INSPECTIONS](state, unitInspections) {
    state.unitInspections = unitInspections;
  },
  [_mutations.SET_UNIT_DVIR_DEFECTS](state, unitDvirDefects) {
    state.unitDvirDefects = unitDvirDefects;
  },
  [_mutations.SET_SERVICE_ORDER_JOBS](state, payload) {
    const matchedExcedeJob = payload.matchedExcedeJob;
    const matchedJob = payload.matchedJob;
    Vue.set(matchedJob, 'excedeJob', matchedExcedeJob);
  },
  [_mutations.SET_UNIT_SERVICE_ORDERS_ASIDE_SHOW](state, unitServiceOrdersAsideShow) {
    state.unitServiceOrdersAsideShow = unitServiceOrdersAsideShow;
  },
  [_mutations.SET_ATTACHMENTS](state, attachments) {
    state.unit.attachments = Cloner.deepClone(attachments);
  },
  [_mutations.SET_PROP_DEFAULT](state, { key, value }) {
    state.dbUnit[key] = value;
    state.unit[key] = value;
  },
  [_mutations.SET_PROP](state, { key, value }) {
    state.unit[key] = value;
  },
  [_mutations.SET_POWER_SOURCE_PROP](state, { key, value }) {
    state.unit.powerSource[key] = value;
  },
  [_mutations.SET_TRANSMISSION_PROP](state, { key, value }) {
    state.unit.transmission[key] = value;
  },
  [_mutations.SET_ATTRIBUTE_VALUE](state, { key, value }) {
    state.unit.attributeMap[key].value = value;
  },
  [_mutations.PUSH_LOADING](state) {
    state.loading.push(true);
  },
  [_mutations.POP_LOADING](state) {
    state.loading.pop();
  },
  [_mutations.SET_CUSTOMER_UNITS](state, units) {
    state.customerUnits = units;
  }
};
