<template>
  <div>
    <b-row class="timeClockRow">
      <b-col xl="3" md="4">
        <div v-if="timeClockData.isAssignedTech && !readonly && !timeClockData.isComplete">
          <span v-if="!timeClockData.canStart">
            <span :id="startButtonId" class="d-inline-block" tabindex="0">
              <b-button disabled="disabled" size="xsm" class="m-1">Start Clock</b-button>
            </span>
            <b-tooltip
              v-if="!timeClockData.canStart && !timeClockData.canWait"
              :id="startButtonTooltipId"
              :target="startButtonId"
              triggers="hover"
            >
              <div>{{ clockInTooltip() }}</div>
            </b-tooltip>
          </span>
          <span v-else class="d-inline-block" tabindex="0">
            <b-button size="xsm" class="m-1" @click="createLaborTimePunchIn()">Start Clock</b-button>
          </span>
          <b-button
            v-if="timeClockData.canSeeRemarks"
            :disabled="!timeClockData.canAddRemarks || timeclockDisabled"
            size="xsm"
            class="m-1"
            @click="addLaborTechRemarks('REMARKS')"
          >
            Remarks
          </b-button>
          <b-button
            :disabled="!timeClockData.canWait || timeclockDisabled"
            size="xsm"
            class="m-1"
            @click="punchOutCheck('WAIT')"
          >
            Wait
          </b-button>
          <b-button
            :disabled="!timeClockData.canHold || timeclockDisabled"
            size="xsm"
            class="m-1"
            @click="punchOutCheck('HOLD')"
          >
            Hold
          </b-button>
        </div>
      </b-col>
      <b-col xl="4" md="3">
        <div v-for="(time, timeIndex) in timeClockData.laborPunches" :key="timeIndex">
          <b-row style="margin-top: 8px; margin-bottom: 8px; color: #333333">
            <div style="width: 175px">
              <strong v-show="time.dateStart != null">Start:</strong>
              {{ formatDate(time.dateStart) }}
            </div>
            <div style="width: 28px">
              <font-awesome-icon icon="arrow-right" />
            </div>
            <div style="width: 175px">
              <strong v-show="time.dateEnd != null">Stop:</strong>
              {{ formatDate(time.dateEnd) }}
            </div>
            <div class="timeDuration">
              {{ time.timeElapsed }}
            </div>
          </b-row>
        </div>
      </b-col>
      <b-col xl="2" md="2" style="text-align: right">
        <div v-if="timeClockData.isAssignedTech && !readonly && !timeClockData.isComplete">
          <b-button
            :disabled="!timeClockData.canComplete || timeclockDisabled"
            size="xsm"
            class="m-1"
            @click="completeLaborCheck()"
          >
            Labor Complete
          </b-button>
        </div>
      </b-col>
      <b-col xl="2" md="2" class="mb-3">
        <b-row v-if="timeClockData.hoursActual != null" class="actualHoursBox">
          <b-col class="actualHoursTitle">HOURS ACTUAL:</b-col>
          <b-col class="actualHoursData">{{ timeClockData.hoursActual }}</b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-modal id="techRemarksModal" size="lg" no-close-on-backdrop centered header-class="remarksModalTitle">
      <template #modal-header="{}">
        <h2>Tech Remarks</h2>
      </template>
      <div>
        <b-form-textarea
          id="techRemarks"
          v-model="timeClockTechRemarks"
          :placeholder="remarksModalPlaceholderText"
          rows="15"
          max-rows="20"
          maxlength="3000"
          style="overflow: hidden"
        ></b-form-textarea>
      </div>
      <template #modal-footer="{}">
        <b-button :disabled="!timeClockTechRemarks || timeclockDisabled" variant="primary" @click="completeTechRemarks">
          {{ remarksModalSaveText }}
        </b-button>
        <b-button @click="techRemarksModalCancel">CANCEL</b-button>
      </template>
    </b-modal>
    <warning-modal
      id="TechRemarksCancelWarning"
      ref="TechRemarksWarning"
      title="Warning"
      :warning-text="techRemarksWarningText"
      :continue-btn-text="techRemarksWarningButtonText"
      cancel-btn-text="Cancel"
    ></warning-modal>
  </div>
</template>

<script>
import moment from 'moment';
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { ServiceOrderGetters, ServiceOrderMutations, ServiceOrderActions } from '@/shared/store/service-order/types';
import ErrorService from '@/shared/services/ErrorService';
import SuccessService from '@/shared/services/SuccessService';
import WarningModal from '@/shared/components/WarningModal';

export default {
  name: 'ServiceOrderLaborLineClockComponent',
  components: {
    'warning-modal': WarningModal
  },
  props: {
    jobKey: {
      type: String,
      required: true
    },
    lbrIndex: {
      type: Number,
      required: true,
      default() {
        return 0;
      }
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      timeclockDisabled: false,
      remarksModalType: '',
      techRemarksWarningText: '',
      techRemarksWarningButtonText: '',
      remarksModalSaveText: '',
      remarksModalPlaceholderText: ''
    };
  },
  computed: {
    ...mapGetters({
      jobs: ServiceOrderGetters.GET_JOBS,
      serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER
    }),
    ...mapGetters([ServiceOrderGetters.GET_JOB_LABOR_TECH_REMARKS_HAS_CHANGES]),
    laborLines() {
      return this.jobs[this.jobKey].details.laborLines;
    },
    timeClockData() {
      return this.jobs[this.jobKey].details.laborLines[this.lbrIndex].jobLaborTimeClock;
    },
    timeClockTechRemarks: {
      get() {
        return this.jobs[this.jobKey].details.laborLines[this.lbrIndex].jobLaborTimeClock.techRemarks;
      },
      set(value) {
        this[ServiceOrderMutations.SET_SERVICE_ORDER_LABOR_TECH_REMARKS]({
          jobKey: this.jobKey,
          lbrIndex: this.lbrIndex,
          techRemarks: value
        });
      }
    },
    startButtonId() {
      return 'start_button_' + this.jobs[this.jobKey].details.laborLines[this.lbrIndex].itemId;
    },
    startButtonTooltipId() {
      return 'start_button_tooltip_' + this.jobs[this.jobKey].details.laborLines[this.lbrIndex].itemId;
    },
    techRemarksHasChanges() {
      return this[ServiceOrderGetters.GET_JOB_LABOR_TECH_REMARKS_HAS_CHANGES](this.jobKey, this.lbrIndex);
    }
  },
  methods: {
    ...mapMutations([
      ServiceOrderMutations.SET_LABOR_TIME_CLOCK_DATA,
      ServiceOrderMutations.SET_SERVICE_ORDER_LABOR_TECH_REMARKS,
      ServiceOrderMutations.RESET_JOB_LABOR_TECH_REMARKS
    ]),
    ...mapActions([
      ServiceOrderActions.CREATE_LABOR_TIME_PUNCH_IN,
      ServiceOrderActions.CREATE_LABOR_TIME_PUNCH_OUT,
      ServiceOrderActions.COMPLETE_UNIT_SERVICE_ORDER_LABOR,
      ServiceOrderActions.SAVE_LABOR_TECH_REMARKS,
      ServiceOrderActions.FETCH_UPDATE_JOB_LABOR_TECH_REMARKS
    ]),
    techRemarksModalCancel() {
      if (this.techRemarksHasChanges) {
        this.$refs.TechRemarksWarning.show(this, this.discardTechRemarks);
      } else {
        this.$bvModal.hide('techRemarksModal');
      }
    },
    async punchOutCheck(modalType) {
      if (this.timeClockData.canSeeRemarks) {
        this.addLaborTechRemarks(modalType);
      } else {
        await this.createLaborTimePunchOut();
      }
    },
    addLaborTechRemarks(modalType) {
      this.remarksModalType = modalType;
      if (modalType.toUpperCase() == 'REMARKS') {
        this.techRemarksWarningText = 'Are you sure you want to discard all unsaved remarks?';
        this.techRemarksWarningButtonText = 'DISCARD UNSAVED REMARKS';
        this.remarksModalSaveText = 'SAVE';
        this.remarksModalPlaceholderText = 'Please enter tech remarks.';
      } else if (modalType.toUpperCase() == 'WAIT' || modalType.toUpperCase() == 'HOLD') {
        this.techRemarksWarningText =
          'Are you sure you want to discard all unsaved remarks and stay punched in to the job?';
        this.techRemarksWarningButtonText = 'DISCARD UNSAVED REMARKS AND STAY PUNCHED IN';
        this.remarksModalSaveText = 'SAVE & PUNCH OFF';
        this.remarksModalPlaceholderText = 'Tech remarks are required before punching out.';
      } else if (modalType.toUpperCase() == 'COMPLETE') {
        this.techRemarksWarningText =
          'Are you sure you want to discard all unsaved remarks, stay punched in to the job, and not complete labor?';
        this.techRemarksWarningButtonText = 'DISCARD UNSAVED REMARKS AND STAY PUNCHED IN';
        this.remarksModalSaveText = 'SAVE & COMPLETE';
        this.remarksModalPlaceholderText = 'Tech remarks are required before punching out.';
      }
      this.$bvModal.show('techRemarksModal');
    },
    formatDate(dateString) {
      if (dateString != null) {
        return moment(dateString).format('MM/DD/YY hh:mm a');
      }
    },
    formatDuration(duration) {
      let timeSpan = moment.duration(duration);
      return moment.utc(timeSpan.as('milliseconds')).format('HH:mm:ss');
    },
    discardTechRemarks(discard = true) {
      if (discard) {
        this.$bvModal.hide('techRemarksModal');
        this[ServiceOrderMutations.RESET_JOB_LABOR_TECH_REMARKS]({
          jobKey: this.jobKey,
          lbrIndex: this.lbrIndex
        });
      }
    },
    async completeTechRemarks() {
      if (this.remarksModalType.toUpperCase() == 'REMARKS') {
        await this.saveRemarks();
      } else if (this.remarksModalType.toUpperCase() == 'WAIT' || this.remarksModalType.toUpperCase() == 'HOLD') {
        await this.createLaborTimePunchOut();
      } else if (this.remarksModalType.toUpperCase() == 'COMPLETE') {
        await this.completeUnitServiceOrderLabor();
      }
      this.remarksModalType = '';
    },
    async createLaborTimePunchIn() {
      this.timeclockDisabled = true;
      var laborLine = this.jobs[this.jobKey].details.laborLines[this.lbrIndex];
      let empId = laborLine.technician.employeeId;
      let laborItemId = laborLine.itemId;
      var param = { empId, laborItemId };
      try {
        const response = await this[ServiceOrderActions.CREATE_LABOR_TIME_PUNCH_IN](param);
        this[ServiceOrderMutations.SET_LABOR_TIME_CLOCK_DATA]({
          jobKey: this.jobKey,
          timeClockData: response,
          lbrIndex: this.lbrIndex
        });
        SuccessService.createSuccessToast(this.$root, `Successfully clocked in to ${laborLine.laborCode}.`);
      } catch (error) {
        let errorMessage = error.response.data.message;
        ErrorService.createErrorToast(this.$root, errorMessage);
      } finally {
        this.timeclockDisabled = false;
      }
    },
    async saveRemarks() {
      this.timeclockDisabled = true;
      var laborLine = this.jobs[this.jobKey].details.laborLines[this.lbrIndex];
      let params = {
        serviceOrderId: this.serviceOrder.serviceOrderId,
        operationId: this.jobs[this.jobKey].operationId,
        laborItemId: this.jobs[this.jobKey].details.laborLines[this.lbrIndex].itemId,
        techRemarks: this.timeClockData.techRemarks,
        empId: this.jobs[this.jobKey].details.laborLines[this.lbrIndex].technician.employeeId,
        unitId: this.serviceOrder.unitId
      };
      try {
        let response = await this[ServiceOrderActions.SAVE_LABOR_TECH_REMARKS](params);
        this[ServiceOrderMutations.SET_LABOR_TIME_CLOCK_DATA]({
          jobKey: this.jobKey,
          timeClockData: response,
          lbrIndex: this.lbrIndex
        });
        this.$bvModal.hide('techRemarksModal');
        SuccessService.createSuccessToast(this.$root, `Successfully added tech remarks for ${laborLine.laborCode}.`);
      } catch (error) {
        let errorMessage = error.response.data.message;
        ErrorService.createErrorToast(this.$root, 'Error saving tech remarks: ' + errorMessage);
      } finally {
        this.timeclockDisabled = false;
      }
    },
    async createLaborTimePunchOut() {
      this.timeclockDisabled = true;
      var job = this.jobs[this.jobKey];
      var laborLine = job.details.laborLines[this.lbrIndex];
      let empId = laborLine.technician.employeeId;
      let laborItemId = laborLine.itemId;
      let operationId = job.operationId;
      let techRemarks = this.timeClockData.techRemarks;
      let canSeeRemarks = this.timeClockData.canSeeRemarks;
      var param = { empId, laborItemId, techRemarks, operationId, canSeeRemarks };
      try {
        const response = await this[ServiceOrderActions.CREATE_LABOR_TIME_PUNCH_OUT](param);
        this[ServiceOrderMutations.SET_LABOR_TIME_CLOCK_DATA]({
          jobKey: this.jobKey,
          timeClockData: response,
          lbrIndex: this.lbrIndex
        });
        this.$bvModal.hide('techRemarksModal');
        SuccessService.createSuccessToast(this.$root, `Successfully clocked out of ${laborLine.laborCode}.`);
      } catch (error) {
        let errorMessage = error.response.data.message;
        ErrorService.createErrorToast(this.$root, errorMessage);
      } finally {
        this.timeclockDisabled = false;
      }
      this.updateJobLaborTechRemarks();
    },
    async completeLaborCheck() {
      if (this.timeClockData.isPunchedIn && this.timeClockData.canSeeRemarks) {
        this.addLaborTechRemarks('COMPLETE');
      } else {
        await this.completeUnitServiceOrderLabor();
      }
    },
    async completeUnitServiceOrderLabor() {
      this.timeclockDisabled = true;
      var job = this.jobs[this.jobKey];
      var laborLine = job.details.laborLines[this.lbrIndex];
      let empId = laborLine.technician.employeeId;
      let laborItemId = laborLine.itemId;
      let operationId = job.operationId;
      let techRemarks = this.timeClockData.techRemarks;
      let canSeeRemarks = this.timeClockData.canSeeRemarks;
      var params = { empId, laborItemId, techRemarks, operationId, canSeeRemarks };
      try {
        const response = await this[ServiceOrderActions.COMPLETE_UNIT_SERVICE_ORDER_LABOR](params);
        this[ServiceOrderMutations.SET_LABOR_TIME_CLOCK_DATA]({
          jobKey: this.jobKey,
          timeClockData: response,
          lbrIndex: this.lbrIndex
        });
        this.$bvModal.hide('techRemarksModal');
        SuccessService.createSuccessToast(this.$root, `Successfully completed ${laborLine.laborCode}.`);
      } catch (error) {
        let errorMessage = error.response.data.message;
        ErrorService.createErrorToast(this.$rooto, errorMessage);
      } finally {
        this.timeclockDisabled = false;
      }

      this.updateJobLaborTechRemarks();
    },
    async updateJobLaborTechRemarks() {
      let job = this.jobs[this.jobKey];
      let params = {
        jobKey: this.jobKey,
        serviceOrderId: this.serviceOrder.serviceOrderId,
        unitId: this.serviceOrder.unitId,
        operationId: job.operationId
      };
      try {
        await this[ServiceOrderActions.FETCH_UPDATE_JOB_LABOR_TECH_REMARKS](params);
      } catch (error) {
        ErrorService.createErrorToast(
          this.$root,
          'Error retreiving job specific tech remarks. Please refersh the sevice order.'
        );
      }
    },
    clockInTooltip() {
      var message = '';
      if (this.timeClockData.isInAttendance && this.timeClockData.isAssignedTech && !this.timeClockData.canWait) {
        message = 'You are clocked into another job. Only one job can be actively clocked in at a time.';
      }
      if (!this.timeClockData.isInAttendance && this.timeClockData.isAssignedTech) {
        message = 'Start your attendance in Excede Web Time Clock before clocking into a job.';
      }
      return message;
    }
  }
};
</script>
<style scoped>
.timeDuration {
  color: #1a8fe8;
}
.actualHoursBox {
  padding: 6px;
  margin-top: 5px;
  line-height: 24px;
  text-align: center;
  border-radius: 0.25rem;
  gap: 5.29px;
  border: 1.32px solid #dbdde0;
  background-color: white;
}
.actualHoursTitle {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: 0em;
}
.actualHoursData {
  font-size: 16px;
  font-weight: 510;
  letter-spacing: -0.02em;
  text-align: center;
}
.timeClockRow {
  background: #f2f2f3;
}
.btn-secondary:disabled {
  background-color: #ffffff;
  opacity: 1;
  filter: grayscale(0);
}
::v-deep .remarksModalTitle {
  background-color: rgb(51, 63, 72);
  color: white;
}
</style>
