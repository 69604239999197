<template>
  <div>
    <b-row class="no-gutters pb-2">
      <b-col>
        <b-row>
          <div class="section-title">Job Details</div>

          <div v-if="!$isCustomer" class="motors-link">
            <b-link @click="motorsClicked">Open MOTORS</b-link>
          </div>
        </b-row>
      </b-col>
    </b-row>
    <b-table
      ref="lineItemsTable"
      selectable
      :items="Object.values(jobs)"
      :fields="fields"
      sort-by="operationId"
      class="full-width-inputs small-gutters border"
      thead-tr-class="col-head"
      tbody-tr-class="bg-half-alpha font-weight-500"
      details-td-class="p-0"
      @row-selected="selectRow"
    >
      <template #head(_showDetails)>
        <div class="text-center">
          <div class="btn-icon" @click="toggleExpandCollapseAll()">
            <img :src="iconString" />
          </div>
        </div>
      </template>
      <template #cell(_showDetails)="data">
        <div class="text-center">
          <div class="btn-icon" @click="toggleDetails(data.item)">
            <font-awesome-icon v-if="data.item._showDetails" icon="minus" class="text-primary" />
            <font-awesome-icon v-else icon="plus" class="text-primary" />
          </div>
        </div>
      </template>

      <template #cell(complaint)="{ item }">
        <span class="px-2">{{ item.complaint }}</span>
      </template>

      <template #cell(subtotal)="{ item }">
        <div class="text-nowrap">
          {{ item.subtotal || 0 | currency }}
          <sup v-if="item.isPackagedPricing">
            <font-awesome-icon
              :id="`package_price_tooltip_${item.jobId}`"
              :icon="['fab', 'product-hunt']"
              size="lg"
              class="text-info align-self-center"
              display="inline-block"
            />
            <b-tooltip :target="`package_price_tooltip_${item.jobId}`" triggers="hover">Packaged Price.</b-tooltip>
          </sup>
        </div>
      </template>

      <template #cell(salesType)="{ item }">
        <!-- sales type -->
        <!-- this div prevents the row from collapsing or expanding when you click on the sales type dropdown -->
        <div @click.stop>
          <div v-if="readonly" id="salesType" class="col-form-label">
            {{ item.salesTypeId ? `${displaySalesType(item.salesTypeId)}` : NA }}
          </div>
          <v-select
            v-else
            id="salesType"
            :value="item.salesTypeId"
            :options="salesTypesList"
            :filter-by="filterSalesTypes"
            :clearable="false"
            :reduce="salesType => salesType.salesTypeId"
            label="salesTypeId"
            @input="updateSalesType(item, $event)"
          >
            <template #selected-option="type">
              <template v-if="type.salesTypeId">
                <span class="text-uppercase">{{ type.salesTypeId }} - {{ type.description }}</span>
              </template>
            </template>
            <template #option="type">
              <span class="text-uppercase">{{ type.salesTypeId }} - {{ type.description }}</span>
            </template>
          </v-select>
        </div>
      </template>
      <template #cell(addlines)="{ item }">
        <div class="text-center">
          <b-dropdown
            v-if="!readOnlyJob(item) && !hasPostedLinkedServiceOrder"
            size="sm"
            variant="secondary"
            class="bg-white"
            @toggle="expandLine(item)"
          >
            <template #button-content>
              <font-awesome-icon icon="plus" class="text-primary" />
            </template>
            <b-dropdown-item @click="addLaborLine(item)">Labor</b-dropdown-item>
            <b-dropdown-item @click="addParts(item)">Parts</b-dropdown-item>
            <b-dropdown-item @click="addMiscLine(item)">Misc</b-dropdown-item>
            <b-dropdown-item @click="addSubletLine(item)">Sublet</b-dropdown-item>
            <b-dropdown-item @click="addCommentLine(item)">Comment</b-dropdown-item>
            <b-dropdown-item v-if="!isEstimate" @click="addInspection(item)">Inspection</b-dropdown-item>
          </b-dropdown>
        </div>
      </template>

      <template #cell(status)="{ item }">
        <div v-if="!isEstimate">
          <b-button
            v-if="serviceOrderApprovedJob(item)"
            :variant="getJobOperationStatusVariant(item.jobOperationStatusId)"
          >
            {{ getJobOperationStatusNameById(item.jobOperationStatusId) }}
          </b-button>
        </div>
        <b-button
          v-else-if="readOnlyJob(item) || hasPostedLinkedServiceOrder"
          :variant="getJobOperationStatusVariant(item.jobOperationStatusId)"
        >
          {{ getJobOperationStatusNameById(item.jobOperationStatusId) }}
        </b-button>
        <b-dropdown
          v-else
          :text="getJobOperationStatusNameById(item.jobOperationStatusId)"
          size="sm"
          :variant="getJobOperationStatusVariant(item.jobOperationStatusId)"
          link-class="text-white"
          menu-class="p-0"
        >
          <b-dropdown-item
            v-for="status in estimateJobOperationStatusesList"
            :key="status.jobOperationStatusId"
            :class="`bg-${status.config.variant} py-1`"
            link-class="text-white"
            @click="updateOperationStatus(item, status.jobOperationStatusId)"
          >
            {{ status.jobOperationStatusName }}
          </b-dropdown-item>
        </b-dropdown>
      </template>

      <template #cell(addToServiceOrder)="{ item }">
        <div class="text-center">
          <font-awesome-icon v-if="item.linkedOperationId != null" icon="check" class="text-success" size="lg" />
          <b-button
            v-else
            variant="secondary"
            size="sm"
            :disabled="blockAddJobToServiceOrderFromEstimate(item)"
            @click="addApprovedJobToServiceOrder(item)"
          >
            <font-awesome-icon icon="plus" size="lg" />
          </b-button>
        </div>
      </template>
      <template #cell(history)="{ item }">
        <div class="text-center">
          <sup>
            <font-awesome-icon
              :id="`operation_history_tooltip_icon_${jobKey(item)}`"
              icon="info-circle"
              size="lg"
              class="mx-1 text-primary align-self-center"
              display="inline-block"
            />
            <b-tooltip
              :id="`operation_history_tooltip_${jobKey(item)}`"
              :target="`operation_history_tooltip_icon_${jobKey(item)}`"
              triggers="hover"
            >
              Added by
              <span class="text-capitalize">
                {{ !item.empNameCreate || item.empIdCreate == '999' ? 'unknown' : item.empNameCreate.toLowerCase() }}
              </span>
              <br />
              {{ item.empNameCreate ? approvalDateFormat(item.dateCreate) : '' }}
              <br />
              <div v-if="item.statusChangedByName">
                <br />
                {{ getJobOperationStatusNameById(item.jobOperationStatusId) }} by
                <span class="text-capitalize">{{ item.statusChangedByName.toLowerCase() }}</span>
                <br />
                {{ approvalDateFormat(item.dateStatusChanged) }}
              </div>
            </b-tooltip>
          </sup>
        </div>
      </template>

      <template v-if="showJobDeleteIcon" #cell(actions)="{ item }">
        <div class="text-center">
          <b-row class="flex-nowrap">
            <b-button
              v-if="!item.complete"
              variant="secondary"
              size="sm"
              class="ml-2"
              :disabled="!canRemove(item) || readOnlyJob(item) || hasPostedLinkedServiceOrder"
              @click="confirmRemove(item)"
            >
              <font-awesome-icon icon="trash-alt" />
            </b-button>
            <div v-else-if="item.complete">
              <img
                :id="`op-${item.operationId}-complete-icon`"
                src="/images/excede-op-complete.svg"
                width="25"
                height="auto"
                class="ml-2"
                alt="Complete"
              />
              <b-tooltip :target="`op-${item.operationId}-complete-icon`" triggers="hover">Complete.</b-tooltip>
            </div>
          </b-row>
        </div>
      </template>

      <template #row-details="row">
        <div class="bg-white pb-2 px-1 font-weight-normal job-line-items">
          <div v-show="showLineItemSection(row.item.details.laborLines)">
            <service-order-labor-line
              :ref="`labor-line-${row.item.operationId}-${row.item.jobId}`"
              :job-key="jobKey(row.item)"
              :readonly="readonly || readOnlyJob(row.item)"
              :is-packaged-pricing="row.item.packagedPricing"
              :allow-edit="!row.item.complete"
            ></service-order-labor-line>
          </div>

          <div v-show="showLineItemSection(row.item.details.parts)">
            <service-order-part-search
              :ref="`part-search-${row.item.operationId}-${row.item.jobId}`"
              :job-key="jobKey(row.item)"
              :readonly="readonly || readOnlyJob(row.item)"
              :is-packaged-pricing="row.item.packagedPricing"
              :allow-edit="!row.item.complete"
            ></service-order-part-search>
          </div>

          <div v-show="showLineItemSection(row.item.details.miscLines)">
            <service-order-misc-line
              :ref="`misc-line-${row.item.operationId}-${row.item.jobId}`"
              :job-key="jobKey(row.item)"
              :readonly="readonly || readOnlyJob(row.item)"
              :is-packaged-pricing="row.item.packagedPricing"
              :allow-edit="!row.item.complete"
            ></service-order-misc-line>
          </div>

          <div v-show="showLineItemSection(row.item.details.subletLines)">
            <service-order-sublet-line
              :ref="`sublet-component-${row.item.operationId}-${row.item.jobId}`"
              :job-key="jobKey(row.item)"
              :readonly="readonly || readOnlyJob(row.item)"
              :is-packaged-pricing="row.item.packagedPricing"
              :allow-edit="!row.item.complete"
            ></service-order-sublet-line>
          </div>

          <div v-show="showLineItemSection(row.item.details.commentLines)">
            <service-order-comment-line
              :ref="`comment-component-${row.item.operationId}-${row.item.jobId}`"
              :job-key="jobKey(row.item)"
              :readonly="readonly || readOnlyJob(row.item)"
              :allow-edit="!row.item.complete"
            ></service-order-comment-line>
          </div>

          <div v-show="showLineItemSection(row.item.details.inspections)">
            <service-order-inspections
              :ref="`inspection-component-${row.item.operationId}-${row.item.jobId}`"
              :job-key="jobKey(row.item)"
              :readonly="readonly || readOnlyJob(row.item)"
              :allow-edit="!row.item.complete"
            />
          </div>

          <div class="collapse-group">
            <tire-replacement-component
              v-if="!$isCustomer && isTireReplacement(row.item)"
              ref="tirereplacement"
              :job-key="jobKey(row.item)"
              :readonly="readonly || readOnlyJob(row.item)"
              :loading="isLoadingTireReplacements"
              @created="tireReplacementCreated = true"
            ></tire-replacement-component>
            <tech-remarks-component
              v-if="!$isCustomer"
              :ref="`tech-remarks-${row.item.operationId}-${row.item.jobId}`"
              :job-key="jobKey(row.item)"
              :readonly="readonly || readOnlyJob(row.item)"
            />
            <three-cs-input
              :ref="`three-cs-${row.item.operationId}-${row.item.jobId}`"
              :job-key="jobKey(row.item)"
              :job="row.item"
              :readonly="readonly || readOnlyJob(row.item)"
            />
            <vmrs-codes-component
              :ref="`vmrs-${row.item.operationId}-${row.item.jobId}`"
              :job-key="jobKey(row.item)"
              :readonly="readonly || readOnlyJob(row.item)"
            />
          </div>
        </div>
      </template>
    </b-table>
    <warning-modal
      id="LineItemDeleteWarning"
      ref="DeleteWarning"
      warning-text="Deleting this operation will remove all attached operation items. What would you like to do?"
      continue-btn-text="Delete Operation"
    ></warning-modal>
  </div>
</template>

<script>
// Vuex
import { ServiceOrderActions, ServiceOrderGetters, ServiceOrderMutations } from '@/shared/store/service-order/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import { UnitGetters } from '@/shared/store/unit/types';
import { UserGetters } from '@/shared/store/user/types';
import { mapMutations, mapActions, mapGetters } from 'vuex';
import { FORM_STAGES, SCOPES, LIFTGATE_JOB_ID, TIRE_REPLACEMENT_JOB_ID } from '@/shared/store/service-order/state';
import { TireActions } from '@/shared/store/tire/types';

import { JOB_OPERATION_STATUS_IDS } from '@/shared/store/lookup/state';
import vSelect from 'vue-select';
// Components
import ServiceOrderLaborLineComponent from './ServiceOrderLaborLineComponent';
import ServiceOrderPartSearchComponent from './ServiceOrderPartSearchComponent';
import ServiceOrderMiscLineComponent from './ServiceOrderMiscLineComponent';
import ServiceOrderSubletComponent from './ServiceOrderSubletComponent';
import ServiceOrderCommentLineComponent from './ServiceOrderCommentLineComponent';
import ServiceOrderInspectionsComponent from './ServiceOrderInspectionsComponent';
import ThreeCsInputComponent from './ThreeCsInputComponent';
import VMRSCodesComponent from './VMRSCodesComponent';
import TireReplacementComponent from './TireReplacementComponent';

import WarningModal from '@/shared/components/WarningModal';
// Services
import ErrorService from '@/shared/services/ErrorService';
import MotorService from '@/shared/services/MotorsService';
import moment from 'moment';

export default {
  name: 'ServiceOrderLineItems',
  components: {
    'service-order-labor-line': ServiceOrderLaborLineComponent,
    'service-order-part-search': ServiceOrderPartSearchComponent,
    'service-order-misc-line': ServiceOrderMiscLineComponent,
    'service-order-sublet-line': ServiceOrderSubletComponent,
    'service-order-comment-line': ServiceOrderCommentLineComponent,
    'service-order-inspections': ServiceOrderInspectionsComponent,
    'three-cs-input': ThreeCsInputComponent,
    'vmrs-codes-component': VMRSCodesComponent,
    'tire-replacement-component': TireReplacementComponent,
    'tech-remarks-component': () => import('./TechRemarksComponent'),
    'warning-modal': WarningModal,
    vSelect
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    },
    hasPostedLinkedServiceOrder: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      NA: 'N/A',
      FORM_STAGES: FORM_STAGES,
      SCOPES: SCOPES,
      LIFTGATE_JOB_ID: LIFTGATE_JOB_ID,
      TIRE_REPLACEMENT_JOB_ID: TIRE_REPLACEMENT_JOB_ID,
      fields: [],
      isExpanded: false,
      expandAllIcon: '/images/expand-all.svg',
      collapseAllIcon: '/images/collapse-all.svg',
      iconString: null,
      editableItems: [],
      isLoadingTireReplacements: false,
      tireReplacementCreated: false
    };
  },
  computed: {
    ...mapGetters({
      jobs: ServiceOrderGetters.GET_JOBS,
      formStage: ServiceOrderGetters.GET_FORM_STAGE,
      isEstimate: ServiceOrderGetters.GET_IS_ESTIMATE,
      unit: UnitGetters.GET_UNIT,
      jobOperationStatusesMap: LookupGetters.GET_JOB_OPERATION_STATUSES_MAP,
      estimateJobOperationStatusesList: LookupGetters.GET_JOB_OPERATION_STATUSES_FOR_ESTIMATE_LIST,
      salesTypesMap: LookupGetters.GET_SALES_TYPES_MAP,
      salesTypesList: LookupGetters.GET_SALES_TYPES_LIST,
      oldEstimorder: ServiceOrderGetters.GET_OLD_ESTIMORDER,
      serviceOrderId: ServiceOrderGetters.GET_SERVICE_ORDER_ID,
      uiConfig: LookupGetters.GET_UI_CONFIG
    }),
    ...mapGetters([ServiceOrderGetters.HAS_CHANGED_JOB_STATUS]),
    unitId() {
      return this.$route.params.unitId;
    },
    hasServiceOrder() {
      return (
        this.$route.query.mode === 'serviceOrder' || this.$store.state.serviceOrder.serviceOrder.serviceOrderId != null
      );
    },
    isVcpUser() {
      return this.$store.getters[UserGetters.AUTHORIZE_ROLE](['VcpUser']);
    },
    isVcpApprover() {
      return this.$store.getters[UserGetters.AUTHORIZE_ROLE](['VcpApprover']);
    },
    showJobDeleteIcon() {
      return this.uiConfig.showJobDeleteIcon;
    }
  },

  watch: {
    jobs: function (val) {
      this.setJobSpecificData(val);
    },
    oldEstimorder: function () {
      //Update the list of editable jobs again, because this changes after jobs are updated when saving, and we need the old and current for the calculation.
      this.setJobSpecificData(this.jobs);
    }
  },
  async created() {
    this.populateTableFields();
    this.setJobSpecificData(this.jobs);
    this.isExpanded = !this.$isCustomer && !this.readonly;
    this.iconString = this.isExpanded ? this.expandAllIcon : this.collapseAllIcon;
    if (!this.$isCustomer) {
      this.isLoadingTireReplacements = true;
      await this[TireActions.FETCH_TIRE_REPLACEMENTS]({
        unitId: this.$route.params.unitId,
        serviceOrderId: this.serviceOrderId
      });
      this.isLoadingTireReplacements = false;
    }
  },
  methods: {
    ...mapMutations([
      ServiceOrderMutations.UPDATE_JOB_OPERATION_STATUS,
      ServiceOrderMutations.SET_SHOW_DETAILS,
      ServiceOrderMutations.SET_JOB_SALES_TYPE,
      ServiceOrderMutations.SET_HAS_IN_SCOPE_LIFTGATE_JOB
    ]),
    ...mapActions([ServiceOrderActions.REMOVE_JOB, TireActions.FETCH_TIRE_REPLACEMENTS]),
    populateTableFields() {
      const commonOptions = { tdClass: 'align-middle', thClass: 'py-1', sortable: false };
      this.fields.push({ key: '_showDetails', label: '', ...commonOptions, thStyle: 'width: 50px' });
      this.fields.push({ key: 'operationId', label: '#', ...commonOptions, thStyle: 'width: 50px' });
      this.fields.push({ key: 'jobId', label: 'Job ID', ...commonOptions, thStyle: 'width: 150px' });
      this.fields.push({
        key: 'complaint',
        label: 'Job Name',
        ...commonOptions,
        tdClass: 'align-middle overflow-ellipsis',
        thStyle: 'min-width: 200px'
      });
      if (!(this.$isCustomer && !this.$store.state.serviceOrder.serviceOrder.invoiced && !this.isEstimate)) {
        this.fields.push({ key: 'subtotal', label: 'Job Total', ...commonOptions, thStyle: 'width: 150px' });
      }
      this.fields.push({ key: 'salesType', label: 'Sales Type', ...commonOptions, thStyle: 'width: 250px' });
      if (!this.readonly) {
        this.fields.push({ key: 'addlines', label: 'Add Lines', ...commonOptions, thStyle: 'width: 75px' });
      }
      this.fields.push({ key: 'status', label: 'Status', ...commonOptions, thStyle: 'width: 100px' });
      if (this.isEstimate) {
        if (!this.$isCustomer && !this.readonly) {
          this.fields.push({ key: 'addToServiceOrder', label: 'Add to SO', ...commonOptions, thStyle: 'width: 75px' });
        }
      }
      this.fields.push({ key: 'history', label: 'History', ...commonOptions, thStyle: 'width: 75px' });
      if (this.uiConfig.showJobDeleteIcon && !this.readonly) {
        this.fields.push({ key: 'actions', label: 'Actions', ...commonOptions, thStyle: 'width: 75px' });
      }
    },
    setJobSpecificData(jobs) {
      this.editableItems = [];

      for (const job of Object.values(jobs)) {
        var key = this.jobKey(job);

        let jobOperationStatusId = job.jobOperationStatusId;

        if (this[ServiceOrderGetters.HAS_CHANGED_JOB_STATUS](key)) {
          jobOperationStatusId = this.oldEstimorder.jobs[key].jobOperationStatusId;
        }

        if (job.jobId == LIFTGATE_JOB_ID) {
          this.toggleLiftgateJobScope(job);
        }
        if (jobOperationStatusId === JOB_OPERATION_STATUS_IDS.ESTIMATE) {
          this.editableItems.push(key);
          continue;
        }
      }
    },
    isTireReplacement(value) {
      return value.jobId === TIRE_REPLACEMENT_JOB_ID;
    },
    serviceOrderApprovedJob(job) {
      return job.jobOperationStatusId === JOB_OPERATION_STATUS_IDS.SERVICE_ORDER_APPROVED;
    },
    readOnlyJob(job) {
      if (!this.isEstimate) return false;

      if ((this.isVcpUser && !this.isVcpApprover) || this.$store.state.serviceOrder.serviceOrder.invoiced) {
        return true;
      }

      return !this.editableItems.includes(this.jobKey(job));
    },
    canRemove(job) {
      if (job.details.parts.length > 0) return false;
      if (job.details.laborLines.some(l => l.complete || l.hasTimePunch)) return false;
      if (job.details.subletLines.some(s => s.complete || s.hasPurchaseOrder)) return false;
      return true;
    },
    confirmRemove(job) {
      this.$refs.DeleteWarning.show(this, this.removeJob.bind(this, job));
    },
    async removeJob(job) {
      try {
        await this[ServiceOrderActions.REMOVE_JOB]({
          unitId: this.unitId,
          jobId: job.jobId,
          operationId: job.operationId
        });
      } catch (error) {
        let errorMessage = `Error deleting Operation ${job.operationId} - ${job.jobId}.`;
        ErrorService.createErrorToast(this, errorMessage);
      }
    },
    updateOperationStatus(job, newJobOperationStatusId) {
      this.$emit('updateApprovalChangesFlag', true);

      this[ServiceOrderMutations.UPDATE_JOB_OPERATION_STATUS]({
        jobKey: this.jobKey(job),
        jobOperationStatusId: newJobOperationStatusId
      });
    },
    selectRow(row) {
      if (row.length) {
        var rowJobKey = JSON.stringify({ jobId: row[0].jobId, operationId: row[0].operationId });
        this[ServiceOrderMutations.SET_SHOW_DETAILS]({ jobKey: rowJobKey, _showDetails: this.isExpanded });
        if (this.isExpanded) {
          this.expandJobAccordians(row[0], rowJobKey);
        }
        this.isExpanded = !this.isExpanded;
        this.$refs.lineItemsTable.clearSelected();
      }
    },
    showLineItemSection(lineItems) {
      return lineItems && lineItems.filter(l => !l.toBeDeleted).length > 0;
    },
    expandLine(item) {
      const jobKey = this.jobKey(item);
      this[ServiceOrderMutations.SET_SHOW_DETAILS]({ jobKey, _showDetails: true });
      this.expandJobAccordians(item, jobKey);
    },
    addLaborLine(job) {
      this.$refs[`labor-line-${job.operationId}-${job.jobId}`].addLaborLine();
    },
    addParts(job) {
      this.$refs[`part-search-${job.operationId}-${job.jobId}`].addParts();
    },
    addSubletLine(job) {
      this.$refs[`sublet-component-${job.operationId}-${job.jobId}`].addSubletLine();
    },
    addMiscLine(job) {
      this.$refs[`misc-line-${job.operationId}-${job.jobId}`].addMiscLine();
    },
    addCommentLine(job) {
      this.$refs[`comment-component-${job.operationId}-${job.jobId}`].addCommentLine();
    },
    addInspection(job) {
      this.$refs[`inspection-component-${job.operationId}-${job.jobId}`].addInspection();
    },
    setAllLinesShowDetails(show) {
      for (const job of Object.values(this.jobs)) {
        const jobKey = this.jobKey(job);
        this[ServiceOrderMutations.SET_SHOW_DETAILS]({ jobKey, _showDetails: !show });
        if (!show) {
          this.expandJobAccordians(job, jobKey);
        }
      }
    },
    toggleDetails(job) {
      const jobKey = this.jobKey(job);
      this[ServiceOrderMutations.SET_SHOW_DETAILS]({ jobKey, _showDetails: !job._showDetails });
    },
    expandJobAccordians(job, jobKey) {
      this.$nextTick(() => {
        const techRemarks = this.$refs[`tech-remarks-${job.operationId}-${job.jobId}`];
        if (techRemarks) techRemarks.$refs[`collapse-${jobKey}`]?.expand();
        const threeCs = this.$refs[`three-cs-${job.operationId}-${job.jobId}`];
        if (threeCs) threeCs.$refs[`collapse-${jobKey}`].expand();
        const vmrs = this.$refs[`vmrs-${job.operationId}-${job.jobId}`];
        if (vmrs) vmrs.$refs[`collapse-${jobKey}`].expand();
      });
    },
    jobKey(job) {
      return JSON.stringify({ jobId: job.jobId, operationId: job.operationId });
    },
    addApprovedJobToServiceOrder(job) {
      this.$emit('addJobsFromEstimate', job);
    },
    async motorsClicked() {
      const { data: token } = await MotorService.getToken();
      const vinEncoded = encodeURIComponent(this.unit.vin);
      const tokenEncoded = encodeURIComponent(token);
      const url = `https://v4.fleetcross.net/account/login.aspx?vin=${vinEncoded}&authtoken=${tokenEncoded}`;
      window.open(url, '_blank');
    },
    statusVariant(job) {
      if (job) {
        if (
          job.jobOperationStatusId === JOB_OPERATION_STATUS_IDS.APPROVED ||
          job.jobOperationStatusId === JOB_OPERATION_STATUS_IDS.SERVICE_ORDER_APPROVED
        )
          return 'success';
        if (job.jobOperationStatusId === JOB_OPERATION_STATUS_IDS.REJECTED) return 'danger';
        if (job.jobOperationStatusId === JOB_OPERATION_STATUS_IDS.DEFERRED) return 'warning';
        return 'secondary-reversed';
      }
    },
    approvalDateFormat(date) {
      return moment(date).format('l LT');
    },
    toggleExpandCollapseAll() {
      this.setAllLinesShowDetails(this.isExpanded);
      this.isExpanded = !this.isExpanded;
      this.iconString = this.isExpanded ? this.collapseAllIcon : this.expandAllIcon;
    },
    blockAddJobToServiceOrderFromEstimate(job) {
      if (!this.hasServiceOrder) return true;
      if (job.jobOperationStatusId != JOB_OPERATION_STATUS_IDS.APPROVED) return true;
      if (job.linkedOperationId != null) return true;
      return false;
    },
    filterSalesTypes(option, label, search) {
      return (
        option.salesTypeId.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        option.description.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    },
    displaySalesType(salesTypeId) {
      const salesType = this.salesTypesMap[salesTypeId];
      return `${salesType.salesTypeId} - ${salesType.description}`;
    },
    updateSalesType(job, salesTypeId) {
      this[ServiceOrderMutations.SET_JOB_SALES_TYPE]({ jobKey: this.jobKey(job), salesTypeId });
      if (job.jobId == LIFTGATE_JOB_ID) {
        this.toggleLiftgateJobScope(job);
      }
    },
    toggleLiftgateJobScope(job) {
      if (job.salesTypeId == SCOPES.IN_SCOPE) {
        this[ServiceOrderMutations.SET_HAS_IN_SCOPE_LIFTGATE_JOB](true);
      } else {
        this[ServiceOrderMutations.SET_HAS_IN_SCOPE_LIFTGATE_JOB](false);
      }
    },
    getJobOperationStatusNameById(jobOperationStatusId) {
      const jobOpStatus = this.jobOperationStatusesMap[jobOperationStatusId];
      return jobOpStatus.jobOperationStatusName;
    },
    getJobOperationStatusVariant(jobOperationStatusId) {
      const jobOpStatus = this.jobOperationStatusesMap[jobOperationStatusId];
      return jobOpStatus.config.variant;
    },
    saveTireOnServiceOrderSave() {
      if (this.tireReplacementCreated) this.$refs.tirereplacement.saveTireOnServiceOrderSave();
    }
  }
};
</script>
