export const namespace = 'user/';

export const UserGetters = {
  GET_NAVIGATION: namespace + 'getNavigation',
  GET_USER_PROFILE: namespace + 'getUserProfile',
  AUTHORIZE_ROLE: namespace + 'authorizeRole',
  GET_LOADING: namespace + 'getLoading',
  IS_AUTHENTICATED: namespace + 'isAuthenticated',
  IS_M2M: namespace + 'isM2M',
  IS_CUK: namespace + 'isCUK',
  IS_INVOICE_ONLY: namespace + 'isInvoiceOnly'
};

export const UserActions = {
  CLEAR_NAVIGATION: namespace + 'clearNavigation',
  FETCH_USER_PROFILE: namespace + 'fetchUserProfile',
  FETCH_FIRE_USER_PROFILE: namespace + 'fetchFireUserProfile',
  AFTER_LOGIN: namespace + 'afteLogin',
  LOGIN: namespace + 'login',
  LOGINFIRE: namespace + 'loginFire',
  LOGOUT: namespace + 'logout',
  SET_AUTHENTICATION_REDIRECT: namespace + 'setAuthenticationRedirect',
  IS_INVOICE_ONLY: namespace + 'isInvoiceOnly'
};

export const UserMutations = {
  SET_AUTHENTICATION_REDIRECT: namespace + 'setAuthenticationRedirect',
  SET_LOGGED_IN: namespace + 'setLoggedIn',
  SET_LOGGED_OUT: namespace + 'setLoggedOut',
  SET_PROFILE: namespace + 'setProfile',
  SET_LOADING: namespace + 'setLoading',
  SET_MSAL_ACCOUNT: namespace + 'setMsalAccount',
  IS_M2M: namespace + 'isM2M',
  IS_CUK: namespace + 'isCUK',
  IS_INVOICE_ONLY: namespace + 'isInvoiceOnly'
};
