<template>
  <div id="vcp">
    <app-header></app-header>
    <div class="container-fluid p-0 d-flex align-items-stretch">
      <sidebar-nav v-show="!$router.currentRoute.meta.hideSidebar"></sidebar-nav>
      <div
        :class="`main flex-grow-1 ${sidebar == SIDEBAR.EXPANDED ? 'show-sidebar' : ''} ${$smallScreen ? 'mobile' : ''}`"
        @click="clickMain"
      >
        <div :class="`flex-grow-1 ${$smallScreen ? '' : 'mx-3 pt-3'}`">
          <div v-if="loading" class="text-center py-4">
            <b-spinner></b-spinner>
          </div>
          <router-view v-else />
        </div>
      </div>
    </div>
    <br />
    <app-footer class="fixed-bottom"></app-footer>
  </div>
</template>

<script>
// components
import AppHeader from '@/vcp/components/layouts/AppHeader';
import AppFooter from '@/vcp/components/layouts/AppFooter';
import SidebarNav from '@/vcp/components/layouts/SidebarNav';
// vuex
import { ConfigGetters } from '@/shared/store/config/types';
import { RootGetters, RootMutations } from '@/shared/store/types';
import { UserGetters } from '@/shared/store/user/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import { UserActions } from '@/shared/store/user/types';
import { SIDEBAR } from '@/shared/store/state';
import { mapGetters } from 'vuex';
// helpers
import Vue from 'vue';
// external services
import HotjarService from '@/shared/services/HotjarService';

export default {
  name: 'VehicareCustomerPortal',
  components: {
    'app-header': AppHeader,
    'sidebar-nav': SidebarNav,
    'app-footer': AppFooter
  },
  data() {
    return {
      msalInProgress: true,
      SIDEBAR: SIDEBAR
    };
  },
  computed: {
    ...mapGetters([ConfigGetters.GET_CONFIG]),
    ...mapGetters({ isAuthenticated: UserGetters.IS_AUTHENTICATED }),
    hotjar() {
      return {
        hjid: Number(this[ConfigGetters.GET_CONFIG].hotjar.hjid),
        hjsv: Number(this[ConfigGetters.GET_CONFIG].hotjar.hjsv)
      };
    },
    loading() {
      return (
        this.msalInProgress ||
        this.$store.getters[LookupGetters.GET_LOADING] ||
        this.$store.getters[UserGetters.GET_LOADING]
      );
    },
    sidebar: {
      get() {
        return this.$store.getters[RootGetters.GET_SIDEBAR];
      },
      set(value) {
        this.$store.commit(RootMutations.SET_SIDEBAR, value);
      }
    },
    environment() {
      const environment = this[ConfigGetters.GET_CONFIG].environment.toUpperCase();
      if (environment.startsWith('PROD')) {
        return '';
      }
      return environment;
    }
  },
  watch: {
    $route: {
      handler(to) {
        document.title = 'Vehicare Customer Portal';
        document.title += to.meta.title ? ' - ' + to.meta.title : '';
      },
      immediate: true
    },
    $smallScreen: {
      handler() {
        if (this.$smallScreen) {
          this.sidebar = SIDEBAR.COLLAPSED;
        } else {
          this.sidebar = SIDEBAR.EXPANDED;
        }
      },
      immediate: true
    }
  },
  created() {
    document.title = 'Vehicare Customer Portal';

    Vue.prototype.$environment = this.environment;

    if (this.hotjar.hjid) {
      HotjarService.setupHotjar(this.hotjar.hjid, this.hotjar.hjsv);
    }

    if (
      this._routerRoot._self._route.name == 'payment-decline' ||
      this._routerRoot._self._route.name == 'payment-process'
    ) {
      this.msalInProgress = false;
    } else {
      this.$msal.events.$on('login-success', async account => {
        try {
          await this.$store.dispatch(UserActions.AFTER_LOGIN, account);
        } finally {
          this.msalInProgress = false;
        }
      });

      this.$msal.events.$on('login-error', async () => {
        this.msalInProgress = false;
      });
    }
  },
  methods: {
    clickMain() {
      if (this.$smallScreen) {
        this.sidebar = SIDEBAR.COLLAPSED;
      }
    }
  }
};
</script>
