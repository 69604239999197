<template>
  <div>
    <b-card class="page-card">
      <div class="page-header d-flex justify-content-between align-items-center pb-2">
        <h2 class="mb-0">Units</h2>
        <div v-if="isCsrOrCsm">
          <b-button size="sm" variant="secondary" :to="{ path: `/units/create` }">Create Unit</b-button>
        </div>
      </div>
      <responsive-search
        ref="search"
        storage-key="unitSearch"
        :fields="searchFields"
        :is-busy="isBusy"
        disable-if-empty
        @searchButtonClick="searchButtonClick"
        @resetButtonClick="resetButtonClick"
      >
        <div v-if="!fleetTechUser" class="mt-21px mr-2">
          <b-form-group class="mb-0 inline-block">
            <b-input-group>
              <b-dropdown variant="secondary" no-caret :disabled="bulkActionImportBusy" class="">
                <template v-if="bulkActionImportBusy" #button-content>
                  <b-spinner v-if="bulkActionImportBusy" small></b-spinner>
                  IMPORTING...
                </template>
                <template v-else #button-content>Import</template>
                <b-dropdown-item v-b-modal.modal-upload>Import Mileage &amp; Engine Hours</b-dropdown-item>
              </b-dropdown>
            </b-input-group>
          </b-form-group>
        </div>
      </responsive-search>
      <div v-if="results.length">
        <b-row v-if="!$smallScreen">
          <b-form-group class="d-inline-block">
            <b-input-group>
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Type to Filter"
                :debounce="500"
                class="mr-2"
              ></b-form-input>
            </b-input-group>
          </b-form-group>

          <b-form-group v-if="!$isMobileApp" class="d-inline-block">
            <b-input-group>
              <b-dropdown
                id="actions"
                variant="secondary"
                no-caret
                :disabled="bulkActionExportBusy"
                @show="toggleActionCheckboxes(true)"
              >
                <b-tooltip target="actions" placement="top" :title="actionsTooltip"></b-tooltip>
                <template #button-content>
                  <b-spinner v-if="bulkActionExportBusy" small></b-spinner>
                  <font-awesome-icon v-else icon="ellipsis-h" />
                </template>
                <b-dropdown-item
                  v-if="isCsrOrCsm"
                  :disabled="bulkActionExportBusy || !results.length"
                  @click="openAside(BULK_UPDATE_ASIDES.STANDARD_OPERATION)"
                >
                  Bulk Update Scheduled Operations
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="isCsrOrCsm"
                  :disabled="bulkActionExportBusy || !results.length"
                  @click="openAside(BULK_UPDATE_ASIDES.UNIT_LOCATION)"
                >
                  Bulk Update Unit Location
                </b-dropdown-item>
                <b-dropdown-item
                  :disabled="bulkActionExportBusy || !results.length"
                  @click="exportMileageEngineClick()"
                >
                  Export Mileage &amp; Engine Hours Template
                </b-dropdown-item>
                <b-dropdown-item :disabled="bulkActionExportBusy || !results.length" @click="exportFleetListClick()">
                  Export Fleet List
                </b-dropdown-item>
              </b-dropdown>
            </b-input-group>
          </b-form-group>

          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="pageSize"
            aria-controls="my-table"
            align="right"
            class="ml-auto w-auto"
          ></b-pagination>

          <div class="clearfix"></div>
        </b-row>
        <mobile-table v-if="$smallScreen" :items="results" :fields="fields">
          <template #[`vin`]="{ item }">
            <label :class="`col-head`">VIN/Serial:</label>
            <safe-hyperlink :to="item.unitId" append>
              {{ item.vin }}
            </safe-hyperlink>
          </template>
          <template #[`customerId`]="{ item }">
            <label :class="`col-head`">Customer:</label>
            <safe-hyperlink :to="'/customers/' + item.customerId">
              {{ item.customerId }} - {{ item.customerName }}
            </safe-hyperlink>
          </template>
          <template #[`make`]="{ item }">
            <label :class="`col-head`">Year Make Model:</label>
            <div>{{ item.modelYear }} {{ item.make }} {{ item.model }}</div>
          </template>
          <template #[`customerName`]=""><span /></template>
          <template #[`outOfService`]=""><span /></template>
          <template #[`model`]=""><span /></template>
          <template #[`modelYear`]=""><span /></template>
          <template v-if="$isMobileApp" #[`collapse-footer`]="{ item }">
            <div class="py-2">
              <b-button
                :disabled="offline"
                class="w-100"
                variant="primary"
                :to="{
                  path: `/units/${item.unitId}/inspections/create`
                }"
              >
                Add Inspection
              </b-button>
            </div>
          </template>
        </mobile-table>
        <b-table
          v-else
          class="border"
          no-border-collapse
          striped
          sticky-header="63vh"
          :items="results"
          :fields="fields"
          :per-page="pageSize"
          :current-page="currentPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-by.sync="sortBy"
          @filtered="onFiltered"
        >
          <template v-if="showActionCheckboxes" #head(selected)>
            <div class="text-center">
              <input v-model="selectAll" type="checkbox" />
            </div>
          </template>
          <template v-if="showActionCheckboxes" #cell(selected)="{ item }">
            <div class="text-center">
              <input v-model="selectedUnits" :value="item" type="checkbox" />
            </div>
          </template>
          <template #cell(vin)="{ item }">
            <safe-hyperlink :to="item.unitId" append>
              {{ item.vin }}
            </safe-hyperlink>
          </template>
          <template #cell(customerId)="{ item }">
            <safe-hyperlink :to="'/customers/' + item.customerId">
              {{ item.customerId }}
            </safe-hyperlink>
          </template>
        </b-table>
      </div>
      <div v-else-if="showTable">
        <h6 class="pt-3">No results found</h6>
      </div>
    </b-card>
    <b-sidebar
      v-if="$isVAMSApp"
      :visible="showAside"
      right
      width="50vw"
      shadow="lg"
      no-header
      lazy
      no-close-on-esc
      sidebar-class="vams-aside"
    >
      <div class="d-flex">
        <div class="flex w-100 p-4">
          <div class="float-right mb-2 mr-2 mt-neg10">
            <button type="button" class="close" aria-label="Close" @click="attemptCloseAside()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="clearfix"></div>
          <div class="d-inline-block w-100">
            <bulk-update-standard-operations
              v-if="activeAside === BULK_UPDATE_ASIDES.STANDARD_OPERATION"
              ref="bulkUpdateStandardOperations"
              :selected-units="selectedUnits"
              :other-bulk-aside-label="otherBulkAsideLabel"
              @close="attemptCloseAside()"
              @openOther="attemptOpenOtherAside"
            />
            <bulk-update-unit-location
              v-if="activeAside === BULK_UPDATE_ASIDES.UNIT_LOCATION"
              ref="bulkUpdateUnitLocation"
              :selected-units="selectedUnits"
              :other-bulk-aside-label="otherBulkAsideLabel"
              @close="attemptCloseAside()"
              @updateLocations="updateLocations"
              @openOther="attemptOpenOtherAside"
            />
          </div>
        </div>
      </div>
    </b-sidebar>
    <b-modal
      v-if="$isVAMSApp"
      id="modal-upload"
      ref="modal-upload"
      title="Upload File"
      header-class="light"
      @hidden="hideImportModal()"
    >
      <p>Select an Excel file.</p>
      <div>
        <b-form-file
          v-model="mileageEngineHoursImportFile"
          accept=".xls, .xlsx"
          placeholder="Choose a file..."
        ></b-form-file>
      </div>
      <div v-if="importFileTypeIsWrong" class="error mt-1">
        File type must be Excel with extension of .xls or .xlsx.
      </div>
      <div v-if="importFileErrorResponse" class="error mt-1">
        <span class="font-weight-bold">Import Error:</span>
        {{ importFileErrorResponse }}
      </div>
      <template #modal-footer="{ cancel }">
        <b-button
          size="sm"
          variant="primary"
          :disabled="!mileageEngineHoursImportFile || bulkActionState.import.mileage"
          @click="importMileageEngineChange()"
        >
          <div v-if="bulkActionState.import.mileage">
            <b-spinner small></b-spinner>
            IMPORTING...
          </div>
          <div v-else>Upload</div>
        </b-button>
        <b-button size="sm" @click="cancel()">Cancel</b-button>
      </template>
    </b-modal>
    <unsaved-changes-modal
      ref="UnsavedChangesModal"
      :has-changes="hasChanges"
      :continue-btn-text="'Continue With Bulk Update'"
    ></unsaved-changes-modal>
  </div>
</template>

<script>
// Component
import ResponsiveSearchComponent from '@/shared/components/ResponsiveSearchComponent';
import SafeHyperlinkComponent from '@/shared/components/ui/SafeHyperlinkComponent';
import UnsavedChangesModal from '@/shared/components/UnsavedChangesModal';
// mixins
import SearchMixin from '@/shared/mixins/SearchMixin';
// vuex
import { RootGetters } from '@/shared/store/types';
import { UnitActions } from '@/shared/store/unit/types';
import { UserGetters } from '@/shared/store/user/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import { mapActions, mapGetters } from 'vuex';
// helpers
import UnitService from '@/shared/services/UnitService';
import SuccessService from '@/shared/services/SuccessService';
import ErrorService from '@/shared/services/ErrorService';
import ExportService from '@/shared/services/ExportService';

const BULK_UPDATE_ASIDES = { CLOSED: 0, STANDARD_OPERATION: 1, UNIT_LOCATION: 2 };

export default {
  name: 'UnitSearch',
  components: {
    'responsive-search': ResponsiveSearchComponent,
    'safe-hyperlink': SafeHyperlinkComponent,
    'mobile-table': () => import('@/shared/components/MobileTableComponent'),
    'bulk-update-standard-operations': () => import('./BulkUpdateStandardOperations'),
    'bulk-update-unit-location': () => import('./BulkUpdateUnitLocation'),
    'unsaved-changes-modal': UnsavedChangesModal
  },
  mixins: [SearchMixin],
  beforeRouteLeave(to, from, next) {
    if (this.hasChanges()) {
      this.$refs.UnsavedChangesModal.show(this, next);
    } else {
      next();
    }
  },
  beforeRouteUpdate(to, from, next) {
    next();
    this.$refs.search?.loadSearchParameters(to.query);
    if (to.query != null && !this.emptyObject(to.query)) {
      this.runSearch();
    } else {
      this.resetButtonClick();
    }
  },
  data: function () {
    return {
      activeAside: 0,
      BULK_UPDATE_ASIDES: BULK_UPDATE_ASIDES,
      pageSize: 25,
      currentPage: 1,
      totalRows: 0,
      fields: [],
      filter: null,
      filterOn: [],
      filteredItems: null,
      results: [],
      sortBy: '',
      showTable: false,
      isBusy: false,
      importFileTypeIsWrong: false,
      importFileErrorResponse: '',
      bulkActionState: {
        export: {
          mileage: false,
          fleetList: false
        },
        import: {
          mileage: false
        }
      },
      showActionCheckboxes: false,
      showExportActions: false,
      selectedUnits: [],
      mileageEngineHoursImportFile: null
    };
  },
  computed: {
    ...mapGetters([UserGetters.AUTHORIZE_ROLE]),
    ...mapGetters({
      isInvoiceOnly: UserGetters.IS_INVOICE_ONLY,
      offline: RootGetters.GET_OFFLINE,
      locationsList: LookupGetters.GET_LOCATIONS_LIST,
      myLocationsList: LookupGetters.GET_MY_LOCATIONS_LIST,
      unitBranchesList: LookupGetters.GET_SERVICE_ORDER_BRANCHES_LIST
    }),
    searchFields() {
      const customerField = this.$isCustomer ? this.myCustomersField : this.customerField;
      const locationsField = this.$isCustomer ? this.myLocationsField : this.locationsField;
      return [
        { ...this.fleetUnitIdField },
        { ...this.vinField, collapsible: true },
        { ...customerField },
        { ...locationsField },
        { ...this.licensePlateField, collapsible: true, hidden: true },
        { ...this.unitTypesField, collapsible: true, hidden: true },
        { ...this.unitStatusesField, collapsible: true, hidden: true }
      ];
    },
    selectAll: {
      get() {
        return this.selectedUnits.length == this.filteredItems.length;
      },
      set(value) {
        this.selectedUnits = [];
        if (value) {
          this.filteredItems.forEach(item => {
            this.selectedUnits.push(item);
          });
        }
      }
    },
    selectedUnitIds() {
      return this.selectedUnits.map(u => u.unitId);
    },
    bulkActionExportBusy() {
      return Object.values(this.bulkActionState.export).some(flag => {
        return flag;
      });
    },
    bulkActionImportBusy() {
      return Object.values(this.bulkActionState.import).some(flag => {
        return flag;
      });
    },
    //any user who is not a fleet tech is considered a CSM, CSR, or Admin
    fleetTechUser() {
      return !this[UserGetters.AUTHORIZE_ROLE](['CSR', 'CSM', 'RegionalCSM']);
    },
    isCsrOrCsm() {
      return this[UserGetters.AUTHORIZE_ROLE](['CSR', 'CSM', 'RegionalCSM']);
    },
    actionsTooltip() {
      if (this.bulkActionExportBusy) {
        return 'Exporting...';
      }
      return 'Actions';
    },
    showAside() {
      return this.activeAside !== BULK_UPDATE_ASIDES.CLOSED;
    },
    otherBulkAsideLabel() {
      let otherAsideLabel;
      if (this.activeAside === BULK_UPDATE_ASIDES.STANDARD_OPERATION) {
        otherAsideLabel = 'Location';
      }
      if (this.activeAside === BULK_UPDATE_ASIDES.UNIT_LOCATION) {
        otherAsideLabel = 'Scheduled Operation';
      }
      return otherAsideLabel;
    },
    locations() {
      if (this.$isCustomer || this.$isMobileApp) {
        return this.myLocationsList.filter(l => !l.inactive);
      }

      return this.locationsList.filter(l => !l.inactive);
    }
  },
  watch: {
    showAside: {
      handler(newValue) {
        if (newValue === false) {
          this.toggleActionCheckboxes(false);
        }
      }
    }
  },
  created() {
    this.fields = this.generateFields();
    if (this.isInvoiceOnly) {
      this.$router.push('/invoices');
    }
  },
  mounted() {
    if (this.$isCustomer) {
      this.fields = this.fields.filter(field => field.key !== 'customerId');
    }
    const query = this.$refs.search?.getQueryString();
    if (query != null && !this.emptyObject(query)) {
      this.$router.replace({ query }).catch(() => {
        this.runSearch();
      });
    }
  },
  methods: {
    ...mapActions([UnitActions.EXPORT_MILEAGE_ENGINE_HOURS, UnitActions.EXPORT_FLEET_LIST]),
    generateFields() {
      const vin = { key: 'vin', label: 'VIN/Serial', sortable: true };
      const fleetUnitId = { key: 'fleetUnitId', label: 'Fleet Unit ID', sortable: true };
      let vinAndFleet = this.$smallScreen ? [vin, fleetUnitId] : [fleetUnitId, vin];
      return [
        ...vinAndFleet,
        { key: 'location', label: 'Location', sortable: true },
        { key: 'customerId', label: 'Customer ID', sortable: true, collapsible: true },
        { key: 'customerName', label: 'Customer', sortable: true, collapsible: true },
        { key: 'unitStatus', label: 'Unit Status', sortable: true, collapsible: true },
        { key: 'unitType', label: 'Unit Type', sortable: true, collapsible: true },
        {
          key: 'outOfService',
          label: 'OOS',
          sortable: true,
          filterByFormatted: true,
          formatter: value => {
            return value === true ? 'OOS' : '';
          },
          collapsible: true
        },
        { key: 'make', label: 'Make', sortable: true, collapsible: true },
        { key: 'model', label: 'Model', sortable: true, collapsible: true },
        { key: 'modelYear', label: 'Model Year', sortable: true, collapsible: true }
      ];
    },
    onFiltered(filteredItems) {
      this.filteredItems = filteredItems;
      if (this.showActionCheckboxes) {
        this.selectAll = true;
      }
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    resetButtonClick: async function () {
      this.filter = '';
      this.sortBy = '';
      this.results = [];
      this.showTable = false;
      await this.$router.push({ query: null }).catch(() => {});
    },
    searchButtonClick: async function (query) {
      if (!query || Object.keys(query).length === 0) {
        ErrorService.createErrorToast(this, `Please enter one or more search parameters.`);
        return;
      }
      await this.$router.push({ query }).catch(() => {});
    },
    runSearch: async function () {
      try {
        this.isBusy = true;
        let resultList = await UnitService.getUnits(this.$route.query);
        this.results = resultList.data;
        this.filteredItems = this.results;
        this.totalRows = this.results.length;
        this.currentPage = 1;
        this.filter = '';
      } catch (error) {
        // TODO: Find more global way to handle errors, or at least abstract this into shared code
        ErrorService.unknownSearchError(this);
      } finally {
        this.showTable = true;
        this.showExportActions = true;
        this.resetBulkActions();
        this.isBusy = false;
      }
    },
    toggleActionCheckboxes(show) {
      if (!this.results.length) {
        return;
      }

      if (this.showActionCheckboxes == show) {
        return;
      }

      if (show) {
        this.fields.unshift({ key: 'selected', label: '', sortable: false });
        this.selectAll = true;
      } else {
        this.fields.shift();
      }

      this.showActionCheckboxes = show;
    },
    checkImportFiletype() {
      var extension = this.mileageEngineHoursImportFile.name.split('.').pop();
      if (extension === 'xls' || extension === 'xlsx') {
        this.importFileTypeIsWrong = false;
      } else {
        this.mileageEngineHoursImportFile = null;
        this.importFileTypeIsWrong = true;
      }
    },
    resetBulkActions() {
      this.toggleActionCheckboxes(false);
    },
    exportMileageEngineClick: async function () {
      try {
        this.bulkActionState.export.mileage = true;
        const unitIds = this.selectedUnitIds;
        const byteArray = await this[UnitActions.EXPORT_MILEAGE_ENGINE_HOURS](unitIds);
        ExportService.downloadByteArray(byteArray, 'Update Mileage and Engine Hours');
      } catch (error) {
        ErrorService.unknownExportError(this);
      } finally {
        this.bulkActionState.export.mileage = false;
      }
    },
    exportFleetListClick: async function () {
      try {
        this.bulkActionState.export.fleetList = true;
        const unitIds = this.selectedUnitIds;
        const byteArray = await this[UnitActions.EXPORT_FLEET_LIST](unitIds);

        const date = new Date();
        const dateStr = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;

        ExportService.downloadByteArray(byteArray, 'Master Fleet List Export' + dateStr);
      } catch (error) {
        ErrorService.unknownExportError(this);
      } finally {
        this.bulkActionState.export.fleetList = false;
      }
    },
    importMileageEngineChange: async function () {
      try {
        this.checkImportFiletype();
        if (!this.importFileTypeIsWrong) {
          this.bulkActionState.import.mileage = true;
          var updatedMileageAndEngineHours = await this.$store.dispatch(
            UnitActions.IMPORT_MILEAGE_ENGINE_HOURS,
            this.mileageEngineHoursImportFile
          );
          this.$refs['modal-upload'].hide();
          SuccessService.createSuccessToast(
            this,
            `${updatedMileageAndEngineHours.rowsUpdatedCount} of ${updatedMileageAndEngineHours.rowsTotalCount} rows updated.`
          );
        }
      } catch (error) {
        this.mileageEngineHoursImportFile = null;
        this.importFileErrorResponse = error.response.data;
      } finally {
        this.bulkActionState.import.mileage = false;
      }
    },
    hideImportModal: function () {
      this.importFileTypeIsWrong = false;
      this.importFileErrorResponse = '';
      this.mileageEngineHoursImportFile = null;
    },
    openAside(asideNumber) {
      if (this.hasChanges()) {
        const discard = (cont = true) => {
          if (cont) {
            this.activeAside = asideNumber;
          }
        };
        this.$refs.UnsavedChangesModal.show(this, discard);
      } else {
        this.activeAside = asideNumber;
      }
    },
    attemptOpenOtherAside() {
      if (this.hasChanges()) {
        this.$refs.UnsavedChangesModal.show(this, this.openOtherAside);
      } else {
        this.openOtherAside();
      }
    },
    openOtherAside(cont = true) {
      if (cont) {
        if (this.activeAside === BULK_UPDATE_ASIDES.STANDARD_OPERATION) {
          this.activeAside = BULK_UPDATE_ASIDES.UNIT_LOCATION;
          return;
        }
        if (this.activeAside === BULK_UPDATE_ASIDES.UNIT_LOCATION) {
          this.activeAside = BULK_UPDATE_ASIDES.STANDARD_OPERATION;
          return;
        }
      }
    },
    attemptCloseAside() {
      if (this.hasChanges()) {
        this.$refs.UnsavedChangesModal.show(this, this.closeAside);
      } else {
        this.closeAside();
      }
    },
    closeAside(cont = true) {
      if (cont) {
        this.activeAside = BULK_UPDATE_ASIDES.CLOSED;
      }
    },
    updateLocations(loc) {
      this.selectedUnits.forEach(unit => (unit.location = loc.description));
    },
    hasChanges() {
      return (
        (this.activeAside === BULK_UPDATE_ASIDES.STANDARD_OPERATION &&
          this.$refs.bulkUpdateStandardOperations.hasChanges()) ||
        (this.activeAside === BULK_UPDATE_ASIDES.UNIT_LOCATION && this.$refs.bulkUpdateUnitLocation.hasChanges())
      );
    }
  }
};
</script>
