<template>
  <div>
    <b-card class="page-card">
      <h2 class="card-header">Deferred Work</h2>
      <responsive-search
        v-if="!isVcpUser"
        ref="search"
        :fields="searchFields"
        :is-busy="searchIsBusy"
        storage-key="deferredWorkSearch"
        @searchButtonClick="searchButtonClick"
        @resetButtonClick="resetButtonClick"
      />
      <b-row v-if="!$smallScreen" class="d-flex justify-content-between">
        <b-form-group>
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              variant="position-relative"
              type="search"
              placeholder="Type to Filter"
              :debounce="500"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
        <div class="d-flex">
          <div class="mt-2 mr-3">{{ deferredJobsCount }} Deferred Jobs</div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="pageSize"
            aria-controls="my-table"
            align="right"
            class="ml-auto w-auto"
          ></b-pagination>
        </div>
      </b-row>
      <b-table
        striped
        class="border"
        no-border-collapse
        :sticky-header="$isCustomer ? '73vh' : '63vh'"
        :items="deferredJobs"
        :fields="!isVcpUser ? fields : vcpFields"
        :per-page="pageSize"
        :current-page="currentPage"
        :filter="filter"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No jobs have been deferred for your branches."
        empty-filtered-text="No results found."
        @filtered="onFiltered"
      >
        <template #cell(vin)="{ item }">
          <safe-hyperlink :to="'/units/' + item.unitId">
            {{ item.vin }}
          </safe-hyperlink>
        </template>
        <template #cell(daysDeferred)="{ item }">
          {{ item.daysDeferred }}
        </template>
        <template #cell(jobId)="{ item }">
          {{ item.jobId }}
        </template>
        <template #cell(name)="{ item }">
          {{ item.name }}
        </template>
        <template #cell(jobSlsId)="{ item }">
          {{ item.jobSlsId }}
        </template>
        <template #cell(jobSubtotal)="{ item }">
          {{ item.jobSubtotal | currency }}
        </template>

        <template v-if="!isVcpUser" #cell(actions)="{ item }">
          <div class="text-center">
            <b-dropdown size="xsm" right variant="secondary" no-caret boundary="viewport">
              <template #button-content>
                <font-awesome-icon icon="ellipsis-h" />
              </template>
              <b-link v-if="!$isCustomer && serviceRequestEnabled" class="dropdown-item" @click="requestService(item)">
                Schedule Service
              </b-link>
              <b-link
                v-if="!$isCustomer"
                :to="{
                  path: `/units/${item.unitId}/service-orders/create?mode=estimate`
                }"
                class="dropdown-item"
              >
                Create Estimate
              </b-link>
              <b-link
                v-if="!$isCustomer"
                :to="{
                  path: `/units/${item.unitId}/service-orders/create?mode=serviceOrder`
                }"
                class="dropdown-item"
              >
                Create Service Order
              </b-link>
              <b-link v-if="!$isCustomer" class="dropdown-item" @click="confirmDelete(item)">Remove job</b-link>
            </b-dropdown>
          </div>
        </template>
      </b-table>
    </b-card>
    <service-request-modal v-if="serviceRequestEnabled" ref="serviceRequestModal" />
    <warning-modal
      id="DeferredJobDeleteWarning"
      ref="DeferredJobDeleteWarning"
      title="Warning"
      :warning-text="deleteText"
      continue-btn-text="Yes, Remove"
      cancel-btn-text="Cancel"
    ></warning-modal>
  </div>
</template>
<script>
// components
import ResponsiveSearchComponent from '@/shared/components/ResponsiveSearchComponent';
import SafeHyperlinkComponent from '@/shared/components/ui/SafeHyperlinkComponent';
import WarningModal from '@/shared/components/WarningModal';
// mixins
import SearchMixin from '@/shared/mixins/SearchMixin';
// vuex
import { RootGetters } from '@/shared/store/types';
import { DeferredJobActions, DeferredJobGetters } from '@/shared/store/deferred-job/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import { mapActions, mapGetters } from 'vuex';
import { UserGetters } from '@/shared/store/user/types';
// helpers
import ErrorService from '@/shared/services/ErrorService';

export default {
  name: 'DeferredWork',
  components: {
    'warning-modal': WarningModal,
    'responsive-search': ResponsiveSearchComponent,
    'safe-hyperlink': SafeHyperlinkComponent,
    'service-request-modal': () => import('@/shared/components/ServiceRequestModal')
  },
  mixins: [SearchMixin],
  beforeRouteUpdate(to, from, next) {
    next();
    this.runSearch();
  },
  data: function () {
    return {
      pageSize: 50,
      currentPage: 1,
      totalRows: 0,
      filter: null,
      sortBy: null,
      searchIsBusy: false,
      deleteText: 'Are you sure you want to remove this job?',
      showList: []
    };
  },
  computed: {
    ...mapGetters({
      offline: RootGetters.GET_OFFLINE,
      deferredJobsSearchResults: DeferredJobGetters.GET_DEFERRED_JOBS_SEARCH_RESULTS,
      isFeatureFlagEnabled: LookupGetters.IS_FEATURE_FLAG_ENABLED
    }),
    isVcpUser() {
      return this.$store.getters[UserGetters.AUTHORIZE_ROLE](['VcpUser']);
    },
    deferredJobs() {
      return this.deferredJobsSearchResults;
    },
    deferredJobsCount() {
      return this.deferredJobsSearchResults.length;
    },
    fields() {
      return [
        { key: 'fleetUnitId', label: 'Fleet Unit Id', sortable: true },
        { key: 'vin', label: 'VIN/Serial', sortable: true },
        { key: 'customerId', label: 'Customer Id', sortable: true },
        { key: 'customerName', label: 'Customer Name', sortable: true },
        { key: 'branch', label: 'Branch', sortable: true },
        { key: 'daysDeferred', label: 'Days Deferred', sortable: true },
        { key: 'jobId', label: 'Job Id', sortable: true },
        { key: 'name', label: 'Job Name', sortable: true },
        { key: 'jobSubtotal', label: 'Job Subtotal', sortable: true },
        { key: 'jobSlsId', label: 'Estimate Id', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ];
    },
    vcpFields() {
      return [
        { key: 'fleetUnitId', label: 'Fleet Unit Id', sortable: true },
        { key: 'vin', label: 'VIN/Serial', sortable: true },
        { key: 'customerId', label: 'Customer Id', sortable: true },
        { key: 'customerName', label: 'Customer Name', sortable: true },
        { key: 'branch', label: 'Branch', sortable: true },
        { key: 'daysDeferred', label: 'Days Deferred', sortable: true },
        { key: 'jobId', label: 'Job Id', sortable: true },
        { key: 'name', label: 'Job Name', sortable: true },
        { key: 'jobSubtotal', label: 'Job Subtotal', sortable: true },
        { key: 'jobSlsId', label: 'Estimate Id', sortable: true }
      ];
    },
    searchFields() {
      const customerField = this.$isCustomer ? this.myCustomersField : this.customerField;
      return [{ ...this.fleetUnitIdField }, { ...customerField }, { ...this.branchField }];
    },
    serviceRequestEnabled() {
      return this.isFeatureFlagEnabled('ServiceRequest') && !this.$isCustomer;
    }
  },
  mounted() {
    const query = this.$refs.search?.getQueryString();
    this.$router.replace({ query }).catch(() => {
      this.runSearch();
    });
  },
  methods: {
    ...mapActions([
      DeferredJobActions.SEARCH_DEFERRED_JOBS,
      DeferredJobActions.FETCH_DEFERRED_JOBS_COUNT,
      DeferredJobActions.DELETE_DEFERRED_JOB
    ]),
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async resetButtonClick() {
      this.filter = '';
      this.sortBy = '';
      this.searchButtonClick(null);
    },
    async searchButtonClick(query) {
      await this.$router.push({ query }).catch(() => {
        this.runSearch();
      });
    },
    async runSearch() {
      try {
        this.searchIsBusy = true;
        await this[DeferredJobActions.SEARCH_DEFERRED_JOBS](this.$route.query);
        this.totalRows = this.deferredJobs.length;
      } catch (error) {
        ErrorService.createErrorToast(this, 'Error searching Deferred Jobs.');
      } finally {
        this.searchIsBusy = false;
      }
    },
    confirmDelete: function (item) {
      this.$refs.DeferredJobDeleteWarning.show(this, this.deleteItem.bind(this, item));
    },
    requestService(item) {
      this.$refs['serviceRequestModal'].showAndLoad(item.unitId, item.jobId);
    },
    deleteItem: async function (item) {
      this.isLoading = true;
      let deletedJob = {
        jobId: item.jobId,
        name: item.name,
        complaint: item.complaint,
        cause: item.cause,
        correction: item.correction,
        deferredByEmployeeName: item.deferredByEmployeeName,
        deferredByEmployeeDate: item.deferredByEmployeeDate,
        jobSubtotal: item.jobSubtotal,
        jobSlsId: item.jobSlsId,
        jobOpsId: item.jobOpsId
      };
      await this[DeferredJobActions.DELETE_DEFERRED_JOB]({ item: deletedJob, unitId: item.unitId });
      await this.runSearch();
      await this[DeferredJobActions.FETCH_DEFERRED_JOBS_COUNT]();
      this.isLoading = false;
    }
  }
};
</script>
