import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, ConfigMutations } from './types';
import Cloner from '@/shared/helpers/cloner';

const _mutations = namespaceHelper.removeNamespace(namespace, ConfigMutations);

export default {
  [_mutations.SET_CONFIG](state, config) {
    state.config = Cloner.deepClone(config);
  },
  [_mutations.SET_VAMS_SYSTEM_UP](state) {
    state.config.vamsSystemUp = 'true';
  },
  [_mutations.SET_FIRE_SYSTEM_UP](state) {
    state.config.fireSystemUp = 'true';
  }
};
