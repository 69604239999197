export function generateThreeCs(params) {
  const NA = 'N/A';
  let complaint,
    cause,
    correction = '';
  const description = params.name;

  if (params.excedeJob?.excedeJobId?.substring(0, 1) == '&') {
    //if params have a description, it is a scheduledOperation
    if (description) {
      cause = `${description} - Due`;
      complaint = `${description} - Due`;
      correction = `${description} - Completed`;
    } else {
      //scheduled operation not associated with unit
      complaint = `${params.complaint ? params.complaint : NA}`;
      cause = `${params.cause ? params.cause : NA}`;
      correction = `${params.correction ? params.correction : NA}`;
    }
  } else {
    // if params doesn't have a description, it is a job
    complaint = `${params.excedeJob.complaint ? params.excedeJob.complaint : NA}`;
    cause = `${params.excedeJob.cause ? params.excedeJob.cause : NA}`;
    correction = `${params.excedeJob.correction ? params.excedeJob.correction : NA}`;
  }
  return {
    complaint,
    cause,
    correction
  };
}
