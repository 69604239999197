<template>
  <div>
    <label
      v-if="field.label && !field.radioOptions && !field.dateRange"
      :class="`${labelClass}`"
      :label-for="`search-field-value-${field.key}`"
    >
      {{ field.label }}:
    </label>

    <b-button-group v-if="field.radioOptions" class="so-status order-neg1 w-100">
      <b-form-radio-group
        :id="`search-field-value-${field.key}`"
        v-model="localValue"
        class="w-100"
        buttons
        button-variant="secondary"
        @change="changeQuickFilter()"
      >
        <b-form-radio
          v-for="radio in field.radioOptions"
          :id="`search-field-${field.key}-radio-${radio.value}`"
          :key="radio.value"
          v-b-tooltip.nofade.dh0
          :value="radio.value"
          :title="radio.tooltip"
        >
          {{ radio.text }}
        </b-form-radio>
      </b-form-radio-group>
    </b-button-group>
    <v-select
      v-else-if="field.options"
      :id="`search-field-value-${field.key}`"
      v-model.trim="localValue"
      class="min-w195px search-field-select"
      :placeholder="field.placeholder"
      :options="field.options"
      :reduce="reduce"
      :multiple="multiple"
      :label="selectLabel"
      :clearable="true"
      :close-on-select="false"
      select-on-tab
    />
    <b-form-group v-else-if="field.dateRange">
      <date-time-input
        :id="`search-field-value-${field.key}-start`"
        v-model="startDate"
        placeholder="MM/DD/YYYY"
        :label="`${field.label} Start Date:`"
        label-class="col-head"
        format="MM/dd/yyyy"
        class="d-inline-block mr-3"
        typeable
        :disabled-dates="disabledStartDates"
        hide-time
      ></date-time-input>
      <date-time-input
        :id="`search-field-value-${field.key}-end`"
        v-model="endDate"
        placeholder="MM/DD/YYYY"
        :label="`${field.label} End Date:`"
        label-class="col-head"
        format="MM/dd/yyyy"
        class="d-inline-block"
        typeable
        :disabled-dates="disabledEndDates"
        hide-time
      ></date-time-input>
    </b-form-group>
    <b-form-input
      v-else
      :id="`search-field-value-${field.key}`"
      v-model.trim="localValue"
      :class="inputClass"
      :placeholder="field.placeholder"
      autocomplete="off"
    />
  </div>
</template>

<script>
// components
import vSelect from 'vue-select';
import DateTimeInput from '@/shared/components/ui/DateTimeInput';
import moment from 'moment';

export default {
  name: 'SearchFieldComponent',
  components: {
    'v-select': vSelect,
    'date-time-input': DateTimeInput
  },
  props: {
    value: {
      type: [String, Array, Object],
      default: () => null
    },
    field: {
      type: Object,
      required: true
    },
    labelClass: {
      type: String,
      default: 'col-head'
    },
    inputClass: {
      type: String,
      default: null
    }
  },
  data: function () {
    return {
      dateRange: {
        startDateValue: '',
        endDateValue: ''
      }
    };
  },
  computed: {
    localValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      }
    },
    startDate: {
      get() {
        this.$emit('input', this.dateRange);
        return this.dateRange.startDateValue;
      },
      set(value) {
        this.dateRange.startDateValue = value;
        this.$emit('input', this.dateRange);
      }
    },
    endDate: {
      get() {
        this.$emit('input', this.dateRange);
        return this.dateRange.endDateValue;
      },
      set(value) {
        this.dateRange.endDateValue = value;
        this.$emit('input', this.dateRange);
      }
    },
    selectLabel() {
      return this.field.selectLabel ?? 'description';
    },
    reduce() {
      return this.field.reduce ?? (x => x.description);
    },
    multiple() {
      return this.field.multiple ?? true;
    },
    disabledStartDates() {
      var endDate;
      var startDate;
      if (this.endDate) {
        endDate = this.endDate;
      } else if (this.field.maximumDate) {
        endDate = this.maximumDate;
      } else {
        endDate = this.field.futureDates ? this.today : '';
      }
      if (this.minimumDate > this.today && this.field.minimumDate) {
        startDate = this.minimumDate;
      } else {
        startDate = this.field.futureDates ? this.today : '';
      }
      return { to: startDate, from: endDate };
    },
    disabledEndDates() {
      var endDate;
      var startDate;
      if (this.field.maximumDate) {
        endDate = this.maximumDate;
      } else {
        endDate = this.field.futureDates ? this.today : '';
      }
      if (this.startDate) {
        startDate = this.startDate;
      } else if (this.field.minimumDate) {
        startDate = this.minimumDate;
      } else {
        startDate = this.field.futureDates ? this.today : '';
      }
      return { to: startDate, from: endDate };
    },
    minimumDate() {
      return moment(this.field.minimumDate).startOf('day').toDate();
    },
    maximumDate() {
      return moment(this.field.maximumDate).startOf('day').toDate();
    },
    today() {
      return moment(this.dateCreate).startOf('day').toDate();
    }
  },
  mounted() {
    if (this.field.key == 'submissionDateRange') {
      this.dateRange.startDateValue = this.$route.query?.submissionStartDate
        ? moment(this.$route.query?.submissionStartDate).toDate()
        : null;
      this.dateRange.endDateValue = this.$route.query?.submissionEndDate
        ? moment(this.$route.query?.submissionEndDate).toDate()
        : null;
    }
    if (this.field.key == 'deliveryDateRange') {
      this.dateRange.startDateValue = this.$route.query?.deliveryStartDate
        ? moment(this.$route.query?.deliveryStartDate).toDate()
        : null;
      this.dateRange.endDateValue = this.$route.query?.deliveryEndDate
        ? moment(this.$route.query?.deliveryEndDate).toDate()
        : null;
    }
  },
  methods: {
    changeQuickFilter() {
      this.$nextTick(function () {
        this.$emit('quick-search');
      });
    }
  }
};
</script>
