<template>
  <div>
    <b-navbar
      v-if="$smallScreen"
      class="navbar-light bg-light shadow fixed-top header-nav mobile"
      style="z-index: 1039"
    >
      <b-nav class="w-100 d-flex justify-content-between align-items-center">
        <b-nav-item class="p-1">
          <div class="btn-icon" @click="goBack()">
            <font-awesome-icon icon="arrow-left" fixed-width />
            Back
          </div>
        </b-nav-item>
        <div v-if="!$isCustomer">
          <input
            id="attachmentInput"
            type="file"
            hidden
            multiple
            :accept="supportedTypesList.join(',')"
            @input="inputFiles($event.target.files)"
          />
          <span v-if="numberOfAttachments > 0" class="text-primary px-1">({{ numberOfAttachments }})</span>
          <b-button variant="secondary" size="sm" :disabled="offline || uploadingFiles" @click="selectAttachment()">
            <div v-if="uploadingFiles" class="text-center px-1">
              <b-spinner small></b-spinner>
            </div>
            <font-awesome-icon v-else icon="camera" fixed-width size="lg" />
          </b-button>
        </div>
      </b-nav>
    </b-navbar>

    <div class="d-flex justify-content-between">
      <div :class="`flex w-100 ${$smallScreen ? '' : 'mr-2'}`">
        <b-card id="inspection-card" class="page-card">
          <div v-if="notFound">
            <p>Inspection not found.</p>
          </div>
          <div v-else-if="loading" class="text-center py-4">
            <b-spinner />
          </div>
          <div v-else class="pb-3">
            <h2 class="card-header">{{ inspection.name }}</h2>
            <!-- Print Button -->
            <b-row v-if="!$smallScreen" no-gutters>
              <div class="ml-auto">
                <b-dropdown size="sm" variant="secondary" class="btn-sm">
                  <template #button-content>
                    <b-spinner v-show="printIsBusy"></b-spinner>
                    <img
                      v-show="!printIsBusy"
                      v-b-tooltip
                      title="Print to PDF"
                      triggers="hover"
                      src="/images/printer-filled.svg"
                      width="20px"
                      height="auto"
                      alt="print"
                    />
                  </template>
                  <b-dropdown-item
                    v-for="form in printFormsList.filter(f => f.showOnInspection)"
                    :key="form.formId"
                    @click="printSelectedForm(form)"
                  >
                    {{ form.name }}
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </b-row>

            <b-row no-gutters>
              <b-col>
                <!-- Branch -->
                <b-form-group
                  label-cols-sm="12"
                  label-cols-lg="5"
                  label-cols-xl="2"
                  label="Branch:"
                  label-for="so-id-link"
                  :label-class="`${$smallScreen ? 'col-head py-0' : ''}`"
                >
                  <b-input-group>
                    {{ displayBranch }}
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row no-gutters>
              <b-col>
                <!-- SO ID -->
                <b-form-group
                  label-cols-sm="12"
                  label-cols-lg="5"
                  label-cols-xl="2"
                  label="SO ID:"
                  label-for="so-id-link"
                  :label-class="`${$smallScreen ? 'col-head py-0' : ''}`"
                >
                  <b-input-group>
                    <safe-hyperlink
                      v-if="inspection.serviceOrderId"
                      id="so-id-link"
                      :to="'/units/' + inspection.unitId + '/service-orders/' + inspection.serviceOrderId"
                    >
                      {{ inspection.serviceOrderId }}
                    </safe-hyperlink>
                    <div v-else>{{ NA }}</div>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row no-gutters>
              <b-col>
                <!-- Customer ID -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="5"
                  label-cols-xl="2"
                  label="Customer:"
                  label-for="customer-link"
                  :label-class="`${$smallScreen ? 'col-head py-0' : ''}`"
                >
                  <b-input-group>
                    <safe-hyperlink id="customer-link" :to="'/customers/' + inspection.unitCustomerId">
                      {{ inspection.unitCustomerId }} - {{ inspection.unitCustomerName }}
                    </safe-hyperlink>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="inspection.fleetUnitId" no-gutters>
              <b-col>
                <!-- Fleet Unit ID -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="5"
                  label-cols-xl="2"
                  label="Fleet Unit ID:"
                  label-for="fleet-id"
                  :label-class="`${$smallScreen ? 'col-head py-0' : ''}`"
                >
                  <b-input-group>
                    {{ inspection.fleetUnitId }}
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row no-gutters>
              <b-col>
                <!-- VIN -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="5"
                  label-cols-xl="2"
                  label="VIN:"
                  label-for="vin-link"
                  :label-class="`${$smallScreen ? 'col-head py-0' : ''}`"
                >
                  <b-input-group>
                    <safe-hyperlink id="vin-link" :to="'/units/' + inspection.unitId">
                      {{ inspection.vin }}
                    </safe-hyperlink>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="readonlyInspection" no-gutters>
              <b-col>
                <!-- Completion Date -->
                <date-time-input
                  id="dateCompleted"
                  :value="inspection.dateCompleted"
                  label="Inspection Completed:"
                  :label-class="`${$smallScreen ? 'col-head py-0' : ''}`"
                  label-cols="12"
                  label-cols-lg="5"
                  label-cols-xl="2"
                  date-col-class="col-auto"
                  hide-time
                  :readonly="true"
                />
              </b-col>
            </b-row>

            <b-row v-if="readonly" no-gutters>
              <b-col>
                <!-- Completed By -->
                <b-form-group
                  label-cols="12"
                  label-cols-lg="5"
                  label-cols-xl="2"
                  label="Completed By:"
                  label-for="completed-by"
                  :label-class="`${$smallScreen ? 'col-head py-0' : ''}`"
                >
                  <b-input-group>
                    <employee-display :employee="empCompleted" :show-email="false" />
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row v-if="axleInspections" no-gutters class="flex-column">
              <b-col cols="12" class="inspection-item-name">
                Axle Configuration
                <sup v-if="axleConfigurationReadOnly">
                  <font-awesome-icon
                    :id="`axle_configuration_tooltip`"
                    icon="info-circle"
                    size="lg"
                    class="text-primary align-self-center"
                    display="inline-block"
                  />
                  <b-tooltip :target="`axle_configuration_tooltip`" triggers="hover">
                    Axle Configuration cannot be edited. Please contact the Data Team if the current configuration is
                    incorrect
                  </b-tooltip>
                </sup>
              </b-col>
              <b-col cols="12" xl="4">
                <div
                  v-if="readonlyInspection || axleConfigurationReadOnly"
                  :id="`tire-load-range-${_uid}`"
                  class="pl-0"
                >
                  {{ axleConfiguration != null ? axleConfiguration.description : NA }}
                </div>
                <v-select
                  v-else
                  id="axle"
                  v-model="axleConfiguration"
                  label="description"
                  :options="axleConfigurationsList"
                  :clearable="true"
                  select-on-tab
                />
                <b-alert
                  :show="!readonlyInspection && !axleConfigurationReadOnly"
                  variant="warning"
                  class="axle-config-alert"
                >
                  <div>Changes to Inspection Axle Configuration will update Unit Axle Configuration</div>
                  <div>and can not be edited after saving</div>
                </b-alert>
              </b-col>

              <div v-if="axleConfiguration">
                <b-col cols="12" xl="5">
                  <img
                    class="pt-2"
                    :src="`/images/axles/${axleConfiguration.imageName}`"
                    width="150px"
                    height="auto"
                    style="min-height: 1px"
                    :alt="axleConfiguration.description"
                  />
                </b-col>
              </div>
            </b-row>

            <div v-if="showInspectionSections">
              <!-- Mobile View -->
              <div v-if="$smallScreen">
                <b-row
                  v-for="section in $v.inspection.sections.$each.$iter"
                  :key="section.$model.inspectionSectionId"
                  no-gutters
                >
                  <b-col sm="12" xl="8">
                    <b-button
                      :id="`section-${section.$model.inspectionSectionId}`"
                      variant="card-header btn-section rounded-0"
                      :class="`my-1 ${section.$anyError ? 'text-danger' : ''}`"
                      @click="openInspectionAside(section)"
                    >
                      {{ section.$model.name }}
                      <font-awesome-icon icon="arrow-circle-right" size="lg" fixed-width class="float-right" />
                      <font-awesome-icon
                        v-if="section.$anyError"
                        icon="exclamation-circle"
                        size="lg"
                        fixed-width
                        class="float-right text-danger"
                      />
                    </b-button>
                  </b-col>
                </b-row>

                <!-- Open Section -->
                <b-sidebar
                  id="inspection-sidebar"
                  sidebar-class="vams-aside full-screen"
                  :visible="showInspectionAside"
                  right
                  no-header
                  lazy
                  no-close-on-esc
                  no-close-on-backdrop
                >
                  <div v-if="showInspectionAside" class="card-body">
                    <div
                      v-for="(item, itemIndex) in openSection.items.$each.$iter"
                      :key="item.$model.inspectionItemId"
                      :class="`inspection-item ${itemIndex > 0 ? 'pt-3' : ''}`"
                    >
                      <inspection-item
                        :item-id="item.$model.inspectionItemId"
                        :v-item="item"
                        :section-id="openSection.$model.inspectionSectionId"
                        :readonly="readonlyInspection"
                      />
                    </div>
                  </div>
                </b-sidebar>
              </div>

              <!-- Desktop View -->
              <div v-else class="pt-3">
                <b-row v-if="inspection.sections.length > 1" no-gutters class="pb-3">
                  <b-col sm="12" xl="8">
                    <b-button
                      class="btn-collapse-all"
                      :class="allExpanded ? '' : 'collapsed'"
                      @click="expandCollapseAll()"
                    >
                      <div>
                        <span v-if="allExpanded">Collapse</span>
                        <span v-else>Expand</span>
                        All
                      </div>
                    </b-button>
                  </b-col>
                </b-row>
                <b-row
                  v-for="section in $v.inspection.sections.$each.$iter"
                  :key="section.$model.inspectionSectionId"
                  no-gutters
                >
                  <b-col sm="12" xl="8">
                    <vams-collapse
                      :ref="`section-${section.$model.inspectionSectionId}`"
                      :title="section.$model.name"
                      :text-color="section.$anyError ? 'text-danger' : ''"
                    >
                      <div
                        v-for="(item, itemIndex) in section.items.$each.$iter"
                        :key="item.$model.inspectionItemId"
                        :class="`inspection-item ${itemIndex > 0 ? 'pt-3' : ''}`"
                      >
                        <inspection-item
                          :item-id="item.$model.inspectionItemId"
                          :v-item="item"
                          :section-id="section.$model.inspectionSectionId"
                          :readonly="readonlyInspection"
                        />
                      </div>
                    </vams-collapse>
                  </b-col>
                </b-row>
              </div>

              <b-row no-gutters class="pt-3">
                <b-col sm="12" xl="8">
                  <b-form-group label="Overall Comments:" label-for="inspection-note">
                    <div v-if="readonlyInspection" id="inspection-note">{{ inspection.note || NA }}</div>
                    <b-textarea
                      v-else
                      id="inspection-note"
                      v-model="inspection.note"
                      :state="$v.inspection.note.$error ? false : null"
                      class="overflow-auto"
                      size="sm"
                      rows="4"
                    />
                  </b-form-group>
                  <div v-if="$v.inspection.note.$error && !$v.inspection.note.maxLength" class="error">
                    Max character limit of 2000.
                  </div>
                </b-col>
              </b-row>

              <b-row v-if="!readonlyInspection" no-gutters class="pt-3">
                <b-col>
                  <b-button
                    v-if="stage <= STAGES.COMPLETING"
                    class="mr-3"
                    variant="primary"
                    :disabled="stage >= STAGES.SAVING"
                    @click="save()"
                  >
                    <div v-show="stage == STAGES.SAVING">
                      <b-spinner small></b-spinner>
                      Saving...
                    </div>
                    <div v-show="stage != STAGES.SAVING">Save</div>
                  </b-button>
                  <b-button variant="secondary" :disabled="stage >= STAGES.SAVING" @click="confirmComplete()">
                    <div v-show="stage == STAGES.COMPLETING" :disabled="stage >= STAGES.COMPLETING">
                      <b-spinner small></b-spinner>
                      Completing...
                    </div>
                    <div v-show="stage != STAGES.COMPLETING">Complete Inspection</div>
                  </b-button>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-card>
      </div>
      <div v-if="!$smallScreen && !notFound && !loading && !$isCustomer" style="width: 54px">
        <floating-actions @change="openAside" />
      </div>
      <b-sidebar
        id="sidebar-2"
        :visible="showAside"
        right
        shadow="lg"
        no-header
        lazy
        no-close-on-esc
        sidebar-class="vams-aside"
      >
        <div class="d-flex">
          <floating-actions in-sidebar @change="onAsideChange" @cancel="changeAside(ASIDES.CLOSED)" />
          <div class="flex w-100 p-4">
            <div class="float-right mb-2 mr-2 mt-neg10">
              <button type="button" class="close" aria-label="Close" @click="onAsideChange(ASIDES.CLOSED)">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="clearfix"></div>
            <div class="d-inline-block w-100">
              <attachments-component
                v-if="activeAside === ASIDES.ATTACHMENTS"
                :readonly="readonlyInspection"
                :branch-id="inspection.branchId"
                :title="`Inspection: ${inspection.name}`"
                label="Inspection"
              />
            </div>
          </div>
        </div>
      </b-sidebar>
    </div>
    <unsaved-changes-modal
      ref="UnsavedChangesModal"
      :has-changes="hasChanges"
      stay-on-page
      continue-btn-text="Continue with Inspection"
    ></unsaved-changes-modal>

    <b-modal id="OfflineModal" ref="OfflineModal" title="Offline Mode" ok-only centered>
      <p>
        Internet access has dropped. You can continue to work offline and changes will be saved when the connection is
        restored.
      </p>
    </b-modal>

    <b-modal
      id="complete-inspection-modal"
      ref="complete-inspection-modal"
      title="Complete Inspection"
      header-class="alert-warning"
      no-close-on-backdrop
      :return-focus="returnFocus"
    >
      <p v-if="numOfUnanswered > 0">
        {{ numOfUnanswered }} Items without a status will be marked as N/A when completed.
      </p>
      <p>Completed Date will default to today's date. Or enter an alternate date below.</p>
      <b-row no-gutters>
        <b-col>
          <!-- Completed Date -->
          <date-time-input
            id="dateCompleted"
            v-model="dateCompleted"
            label="Inspection Completed:"
            label-cols="12"
            label-cols-lg="5"
            date-col-class="col-auto"
            hide-time
          />
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button size="sm" variant="primary" @click="complete()">Complete Inspection</b-button>
        <b-button size="sm" @click="cancelComplete">Cancel</b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
// components
import InspectionItemComponent from './InspectionItemComponent';
import DateTimeInput from '@/shared/components/ui/DateTimeInput';
import CollapseComponent from '@/shared/components/ui/CollapseComponent';
import EmployeeDisplayComponent from '@/shared/components/ui/EmployeeDisplayComponent';
import SafeHyperlinkComponent from '@/shared/components/ui/SafeHyperlinkComponent';
import UnsavedChangesModal from '@/shared/components/UnsavedChangesModal';
import vSelect from 'vue-select';
// vuex
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { RootGetters } from '@/shared/store/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import { InspectionActions, InspectionGetters, InspectionMutations } from '@/shared/store/inspection/types';
import { AttachmentGetters } from '@/shared/store/attachment/types';
import { STAGES } from '@/shared/store/inspection/state';
// helpers
import { maxLength } from 'vuelidate/lib/validators';
import Cloner from '@/shared/helpers/cloner';
import ErrorService from '@/shared/services/ErrorService';
import SuccessService from '@/shared/services/SuccessService';
import ExportService from '@/shared/services/ExportService';
import moment from 'moment';
// mixins
import { ASIDES } from '@/shared/components/floating-actions/FloatingActionsComponent';
import AsideMixin from '@/shared/mixins/AsideMixin';
import UploadMixin from '@/shared/mixins/UploadMixin';

export default {
  name: 'InspectionPage',
  components: {
    'vams-collapse': CollapseComponent,
    'date-time-input': DateTimeInput,
    'unsaved-changes-modal': UnsavedChangesModal,
    'employee-display': EmployeeDisplayComponent,
    'safe-hyperlink': SafeHyperlinkComponent,
    'inspection-item': InspectionItemComponent,
    'v-select': vSelect,
    'floating-actions': () => import('@/shared/components/floating-actions/FloatingActionsComponent'),
    'attachments-component': () => import('@/shared/components/floating-actions/AttachmentsComponent')
  },
  mixins: [UploadMixin, AsideMixin],
  beforeRouteLeave(to, from, next) {
    if (this.showInspectionAside) {
      this.closeInspectionAside();
      next(false);
    } else {
      if (this.hasChanges()) {
        this.beforeNavigation(next);
      } else {
        next();
      }
    }
  },
  data() {
    return {
      NA: 'N/A',
      STAGES: STAGES,
      notFound: false,
      loading: false,
      uploadingFiles: false,
      dateCompleted: moment().toDate(),
      returnFocus: null,
      allExpanded: false,
      printIsBusy: false,
      inspectionLoaded: false,
      showInspectionAside: false,
      possibleAxleConfig: {},
      openSection: {}
    };
  },
  validations: {
    inspection: {
      note: {
        maxLength: maxLength(2000)
      },
      sections: {
        $each: {
          items: {
            $each: {
              comment: {
                maxLength: maxLength(2000)
              }
            }
          }
        }
      }
    }
  },
  computed: {
    ...mapGetters([AttachmentGetters.HAS_CHANGES]),
    ...mapGetters({
      inspection: InspectionGetters.GET_INSPECTION,
      dbInspection: InspectionGetters.GET_DB_INSPECTION,
      offline: RootGetters.GET_OFFLINE,
      stage: InspectionGetters.GET_STAGE,
      axleConfigurationsList: LookupGetters.GET_AXLE_CONFIGURATIONS_LIST,
      axleInspections: InspectionGetters.GET_AXLE_INSPECTIONS,
      printFormsList: LookupGetters.GET_PRINT_FORMS_LIST,
      myBranchesMap: LookupGetters.GET_MY_BRANCHES_MAP
    }),
    unitInspectionId() {
      return this.$route.params.unitInspectionId;
    },
    readonlyInspection() {
      return this.stage == STAGES.READONLY || this.inspection.readonly || !this.myBranchesMap[this.inspection.branchId];
    },
    validator() {
      return this.$v.inspection.sections;
    },
    displayBranch() {
      return this.inspection.branchId ? `${this.inspection.branchId} - ${this.inspection.branchName}` : this.NA;
    },
    itemsUnanswered() {
      if (!this.inspectionLoaded) return null;
      let unanswered = {};

      for (const section of this.inspection.sections) {
        for (const item of section.items) {
          if (item.inspectionItemType == 'passfail' && item.result == null) {
            if (!unanswered[section.inspectionSectionId]) unanswered[section.inspectionSectionId] = 0;
            unanswered[section.inspectionSectionId] = unanswered[section.inspectionSectionId] += 1;
          }
        }
      }
      return unanswered == {} ? null : unanswered;
    },
    numOfUnanswered() {
      if (this.itemsUnanswered == null) return 0;
      let i = 0;
      Object.values(this.itemsUnanswered).forEach(s => (i += s));
      return i;
    },
    empCompleted() {
      return this.inspection.empCompleted ?? {};
    },
    axleConfiguration: {
      get() {
        return this.inspection.axleConfiguration;
      },
      set(axleConfig) {
        this[InspectionActions.SET_AXLE_CONFIGURATION](axleConfig);
      }
    },
    axleConfigurationReadOnly() {
      return this.dbInspection?.axleConfiguration?.axleConfigurationId > -1;
    },
    showInspectionSections() {
      return this.readonly || this.axleInspections == null || this.axleConfiguration != null;
    },
    attachmentInput() {
      return document.getElementById('attachmentInput');
    },
    numberOfAttachments() {
      return this.inspection?.attachments?.length;
    }
  },
  async created() {
    this.loading = true;
    try {
      await this[InspectionActions.FETCH_INSPECTION](this.unitInspectionId);
      this.inspectionLoaded = true;
    } catch (error) {
      if (error.response && error.response.status == 404) this.notFound = true;
      const errorMessage = 'Unable to load Inspection.';
      ErrorService.createErrorToast(this, errorMessage);
    } finally {
      this.loading = false;
    }
  },
  beforeDestroy() {
    this[InspectionMutations.RESET_STATE]();
    document.body.classList.remove('stop-scrolling');
  },
  methods: {
    ...mapMutations([InspectionMutations.RESET_STATE, InspectionMutations.SET_ATTACHMENTS]),
    ...mapActions([
      InspectionActions.FETCH_INSPECTION,
      InspectionActions.UPDATE_INSPECTION,
      InspectionActions.SET_AXLE_CONFIGURATION
    ]),
    ...mapActions({
      print: InspectionActions.PRINT_INSPECTION
    }),
    async switchStage(newStage) {
      return await this.$store.dispatch(InspectionActions.SWITCH_STAGE, { newStage, that: this });
    },
    async printSelectedForm(printForm) {
      try {
        this.printIsBusy = true;
        const fileName = `Inspection-${this.unitInspectionId}`;

        const parameters = {
          inspectionId: this.unitInspectionId,
          printFormId: printForm.formId
        };
        const byteArray = await this.print(parameters);
        ExportService.downloadByteArray(byteArray, fileName, true, '.pdf');
      } catch (error) {
        ErrorService.unknownPrintError(this);
      } finally {
        this.printIsBusy = false;
      }
    },
    async save() {
      if (this.stage >= STAGES.SAVING) return;
      const switched = await this.switchStage(STAGES.SAVING);
      if (!switched) return;

      try {
        const response = await this[InspectionActions.UPDATE_INSPECTION]({
          unitInspectionId: this.unitInspectionId,
          inspection: this.inspection
        });
        if (response.status == 205) {
          this.$bvModal.show('OfflineModal');
        } else {
          SuccessService.createSuccessToast(this.$root, `${this.inspection.name} saved successfully.`);
        }
        this.$v.$reset();
      } catch (err) {
        const errorMessage = `Error saving ${this.inspection.name}.`;
        ErrorService.createErrorToast(this, errorMessage);
        throw Error(errorMessage);
      } finally {
        this.switchStage(STAGES.EDIT);
      }
    },
    async confirmComplete() {
      const switched = await this.switchStage(STAGES.ATTEMPT_COMPLETE);
      if (!switched) return;

      this.$bvModal.show('complete-inspection-modal');
      this.openUnansweredItems();
    },
    cancelComplete() {
      this.switchStage(STAGES.EDIT);
      this.$bvModal.hide('complete-inspection-modal');
    },
    async complete() {
      this.switchStage(STAGES.COMPLETING);
      this.$bvModal.hide('complete-inspection-modal');
      if (!this.dateCompleted) this.dateCompleted = moment().toDate();
      let inspection = Cloner.deepClone(this.inspection);
      inspection.dateCompleted = this.dateCompleted;
      inspection.readonly = true;

      try {
        const response = await this[InspectionActions.UPDATE_INSPECTION]({
          unitInspectionId: this.unitInspectionId,
          inspection
        });
        if (response.status == 205) {
          this.$bvModal.show('OfflineModal');
        } else {
          SuccessService.createSuccessToast(this.$root, `${this.inspection.name} completed successfully.`);
        }
        this.allExpanded = false;
        this.$v.$reset();
        this.switchStage(STAGES.READONLY);
      } catch (err) {
        const errorMessage = `Error completing ${this.inspection.name}.`;
        ErrorService.createErrorToast(this, errorMessage);
        this.switchStage(STAGES.EDIT);
        throw Error(errorMessage);
      }
    },
    openUnansweredItems() {
      const sectionIds = Object.keys(this.itemsUnanswered);
      if (sectionIds.length == 0) return;

      for (const sectionId of sectionIds) {
        this.openCollapse(`section-${sectionId}`);
      }
      const collapsible = this.$refs[`section-${sectionIds[0]}`][0];
      document.getElementById(collapsible.$refs['collapse'].id).focus();
      this.returnFocus = collapsible.$refs['collapse'];
    },
    openCollapse(ref) {
      const collapsible = this.$refs[ref][0];
      collapsible.expand();
    },
    hasChanges() {
      if (!this.inspectionLoaded) return false;
      return this.$store.getters[InspectionGetters.HAS_CHANGES];
    },
    expandCollapseAll() {
      for (const section of this.inspection.sections) {
        const collapsible = this.$refs[`section-${section.inspectionSectionId}`][0];
        const sectionExpanded = collapsible.$refs['collapse'].getAttribute('aria-expanded') == 'true';
        if (sectionExpanded == this.allExpanded) {
          collapsible.toggleCollapse();
        }
      }
      this.allExpanded = !this.allExpanded;
    },
    openInspectionAside(section) {
      this.showInspectionAside = true;
      this.openSection = section;
      document.body.classList.add('stop-scrolling');
    },
    closeInspectionAside() {
      this.showInspectionAside = false;
      document.body.classList.remove('stop-scrolling');
    },
    goBack() {
      if (this.showInspectionAside) {
        this.closeInspectionAside();
      } else {
        this.$router.back();
      }
    },
    selectAttachment() {
      this.attachmentInput.click();
    },
    async inputFiles(fileList) {
      const files = [...fileList];
      var attachments = await this.uploadFiles(files);
      this[InspectionMutations.SET_ATTACHMENTS](attachments);
      this.attachmentInput.value = null;
    },
    beforeNavigation(next) {
      this.$refs.UnsavedChangesModal.show(this, next);
    },
    onAsideChange(asideNumber) {
      if (asideNumber === this.activeAside) return;

      if (this.activeAside === ASIDES.ATTACHMENTS && this[AttachmentGetters.HAS_CHANGES]) {
        this.beforeNavigation(this.changeAside.bind({}, asideNumber));
      } else {
        this.activeAside = asideNumber;
      }
    }
  }
};
</script>
<style>
.axle-config-alert {
  display: inline-block;
  width: 100%;
}
</style>
