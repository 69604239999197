import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, OperationsForecastMutations } from './types';

const _mutations = namespaceHelper.removeNamespace(namespace, OperationsForecastMutations);

export default {
  [_mutations.SET_PROP](state, { key, value }) {
    state[key] = value;
  },
  [_mutations.SET_IS_SEARCH_PM_CONTRACT_OPERATIONS](state, selected) {
    state.isSearchPMContractOperations = selected;
  }
};
