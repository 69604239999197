<template>
  <div>
    <b-row>
      <b-form :inline="!$smallScreen" class="w-100 align-items-end" @submit.stop.prevent="searchButtonClick">
        <div v-if="$smallScreen" class="mobile-search">
          <div
            v-for="(field, fIndex) in mainFields"
            :key="fIndex"
            :class="`ms-main-field ${!$smallScreen ? ' mr-3' : ''}`"
          >
            <slot :name="`${field.key}`" :field="field" :data="data">
              <search-field v-model="data[field.key]" :field="field" @quick-search="searchButtonClick" />
            </slot>
          </div>
          <b-collapse v-if="$smallScreen" id="ms-collapse" v-model="showMore" class="ms-collapse">
            <div v-for="(field, fIndex) in collapsibleFields" :key="fIndex" class="ms-collapse-field">
              <slot :name="`${field.key}`" :field="field" :data="data">
                <search-field v-model="data[field.key]" :field="field" @quick-search="searchButtonClick" />
              </slot>
            </div>
          </b-collapse>
        </div>

        <div v-else class="form-inline">
          <div
            v-for="(field, fIndex) in shownFields"
            :key="fIndex"
            :class="`ms-main-field ${!$smallScreen ? 'align-self-end mr-3' : ''} mt-2`"
          >
            <slot :name="`${field.key}`" :field="field" :data="data">
              <search-field v-model="data[field.key]" :field="field" @quick-search="searchButtonClick" />
            </slot>
          </div>
        </div>

        <b-row v-if="$smallScreen" no-gutters class="justify-content-between">
          <b-col>
            <b-button
              v-if="collapsibleFields && collapsibleFields.length > 0"
              class="p-0 text-capitalize"
              variant="link"
              size="xsm"
              @click="toggleMoreFields()"
            >
              {{ showMore ? 'Hide' : 'More' }} Search Options
            </b-button>
          </b-col>
          <b-col>
            <b-button class="p-0 text-capitalize float-right" variant="link" size="xsm" @click="resetForm()">
              Clear Filters
            </b-button>
          </b-col>
        </b-row>
        <div v-else-if="!noMore" class="mt-21px mr-2">
          <b-dropdown ref="searchOptionRef" text="MORE" variant="secondary">
            <b-dropdown-item v-for="field in fields" :key="field.key" @click="toggleShownFields(field)">
              <b-form-checkbox v-model="field.shown">{{ field.label }}</b-form-checkbox>
            </b-dropdown-item>
          </b-dropdown>
        </div>

        <b-row no-gutters :class="`mt-21px ${$smallScreen ? '' : 'mr-2'}`">
          <b-col>
            <b-button class="w-100" variant="primary" :disabled="isBusy || (disableIfEmpty && isEmpty)" type="submit">
              <b-spinner v-show="isBusy" small />
              {{ isBusy ? 'Searching...' : 'Search' }}
              <font-awesome-icon v-if="!$smallScreen && !isBusy" icon="search" />
            </b-button>
          </b-col>
        </b-row>

        <div v-if="!$smallScreen" class="mt-21px mr-2">
          <b-button variant="secondary" @click="resetForm()">Reset</b-button>
        </div>
        <slot v-if="!$smallScreen"></slot>
      </b-form>
    </b-row>
    <hr v-if="!$smallScreen" />
    <div v-else class="pb-4"></div>
  </div>
</template>

<script>
// components
import SearchFieldComponent from '@/shared/components/SearchFieldComponent';
// helper
import Vue from 'vue';

export default {
  name: 'ResponsiveSearchComponent',
  components: {
    'search-field': SearchFieldComponent
  },
  props: {
    fields: {
      type: Array,
      required: true
    },
    labelClass: {
      type: String,
      default: 'col-head'
    },
    inputClass: {
      type: String,
      default: null
    },
    storageKey: {
      type: String,
      required: true
    },
    isBusy: {
      type: Boolean,
      default: () => false
    },
    disableIfEmpty: {
      type: Boolean,
      default: () => false
    },
    resetData: {
      type: Object,
      default() {
        return {};
      }
    }
  },
  data() {
    return {
      data: {},
      shownFields: [],
      noMore: false,
      showMore: false
    };
  },
  computed: {
    mainFields() {
      return this.fields.filter(field => !field.collapsible);
    },
    collapsibleFields() {
      return this.fields.filter(field => field.collapsible);
    },
    allowMore() {
      return this.collapsibleFields.length > 0;
    },
    isEmpty() {
      return Object.keys(this.data).length == 0;
    },
    fieldKeys() {
      return this.fields.map(field => field.key);
    }
  },
  async created() {
    this.setShownFields();
  },
  methods: {
    reset() {
      Object.assign(this.$data, this.$options.data.call(this));
    },
    toggleMoreFields() {
      this.showMore = !this.showMore;
    },
    setShownFields() {
      let shownFields = [];
      this.fields.forEach(x => {
        if (!x.hidden) {
          x.shown = true;
          shownFields.push(x);
        } else {
          x.shown = false;
        }
      });
      this.shownFields = shownFields;
      if (this.shownFields.length == this.fields.length) this.noMore = true;
    },
    toggleShownFields(field) {
      this.setShownField(field, !field.shown);
    },
    setShownField(field, value) {
      Vue.set(field, 'shown', value);
      let shownFields = [];
      this.fields.forEach(x => {
        if (x.shown) shownFields.push(x);
        else delete this.data[x.key];
      });
      this.shownFields = shownFields;
    },
    resetForm() {
      this.data = { ...this.resetData };
      this.showMore = false;
      this.setShownFields();
      sessionStorage.setItem(this.storageKey, JSON.stringify(this.data));
      this.$emit('resetButtonClick');
    },
    clearData() {
      for (let [key, value] of Object.entries(this.data)) {
        if (key != 'submissionDateRange' && key != 'deliveryDateRange') {
          if (value == null || this.emptyObject(value)) delete this.data[key];
        }
      }
    },
    searchButtonClick() {
      this.clearData();
      sessionStorage.setItem(this.storageKey, JSON.stringify(this.data));
      this.$emit('searchButtonClick', this.data);
    },
    getQueryString() {
      let query = this.$route.query;
      const queryKeys = Object.keys(query);
      if (this.emptyObject(query) || !queryKeys.some(k => this.fieldKeys.includes(k))) {
        const sessionQuery = this.parseJsonFromSession(this.storageKey);
        query = sessionQuery;
      }
      this.loadSearchParameters(query);
      return query;
    },
    loadSearchParameters(query) {
      this.data = { ...this.resetData };
      if (!query || this.emptyObject(query)) return;
      for (const [key, value] of Object.entries(query)) {
        const field = this.fields.find(x => x.key == key);
        if (!field) continue;
        if (this.collapsibleFields.find(x => x.key == key)) {
          this.showMore = true;
          this.setShownField(field, true);
        }
        Vue.set(this.data, key, value);
      }
    },
    parseJsonFromSession(key) {
      const value = sessionStorage.getItem(key);
      if (!value) return null;
      try {
        return JSON.parse(value);
      } catch {
        return null;
      }
    }
  }
};
</script>
