<template>
  <div>
    <b-card class="page-card">
      <div class="d-flex justify-content-between page-header">
        <h2>Operation Forecast</h2>
        <div class="d-flex">
          <b-link :to="'/deferred-work'">
            <div class="ops-forecast-notification">
              <span class="icon">
                <img src="/images/deferred-work-gray.svg" alt="Deferred Work" />
                <span v-if="hasDeferredJobs" class="notification notification-has-jobs"></span>
                <span v-else class="notification notification-no-jobs"></span>
              </span>
              <span class="ml-1">Deferred Work</span>
              <span v-if="hasDeferredJobs" class="ml-1">({{ deferredJobsCount.count }})</span>
            </div>
          </b-link>

          <b-link :to="'/dvirs'" class="ml-3">
            <div class="ops-forecast-notification">
              <span class="icon">
                <img src="/images/road-gray.svg" alt="DVIR Defects" />
                <span v-if="hasDvirDefects" class="notification notification-has-jobs"></span>
                <span v-else class="notification notification-no-jobs"></span>
              </span>
              <span class="ml-1">DVIR Defects</span>
              <span v-if="hasDvirDefects" class="ml-1">
                ({{ dvirsCount.unsafeCount ? dvirsCount.unsafeCount : 0 }}/{{ dvirsCount.count }})
              </span>
            </div>
          </b-link>
        </div>
      </div>

      <responsive-search
        ref="search"
        storage-key="operationForecastSearch"
        :fields="searchFields"
        :is-busy="searchIsBusy"
        @searchButtonClick="searchButtonClick"
        @resetButtonClick="resetButtonClick"
      />

      <div v-if="!$smallScreen" class="filter-pagination-row">
        <b-form-group>
          <b-input-group class="ops-forecast-filter">
            <b-form-input
              id="filterInput"
              v-model="filter"
              variant="position-relative"
              type="search"
              placeholder="Type to Filter"
              :debounce="500"
            ></b-form-input>
            <b-input-group-append>
              <div v-show="exportIsBusy">
                <b-button variant="primary" disabled class="ml-2">
                  <b-spinner small></b-spinner>
                  EXPORTING...
                </b-button>
              </div>
              <div class="ml-4 pr-2">
                <b-button v-show="!exportIsBusy" variant="secondary" @click="exportClick">Export</b-button>
              </div>
            </b-input-group-append>
            <b-button v-if="serviceRequestEnabled && $isCustomer" class="ml-2" @click="multiunitServiceClick">
              MULTI-UNIT SERVICE
            </b-button>
            <b-form-checkbox
              v-model="isSearchPMContractOperation"
              switch
              class="mt-1 ml-2"
              @input="toggleSearchPMContractOperations(selected)"
            >
              PM Contract Operations
            </b-form-checkbox>
          </b-input-group>
        </b-form-group>
        <div class="align-self-center flex-grow-1 text-right mr-4 mb-2">{{ unitCount }} Units</div>

        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="pageSize"
          aria-controls="my-table"
        ></b-pagination>
      </div>

      <div>
        <mobile-table v-if="$smallScreen" :items="operationsForecast" :fields="fields">
          <template #[`vin`]="{ item }">
            <label :class="`col-head`">VIN/Serial:</label>
            <safe-hyperlink :to="'units/' + item.unitId">
              {{ item.vin }}
            </safe-hyperlink>
          </template>
          <template #[`customerId`]="{ item }">
            <label :class="`col-head`">Customer:</label>
            <safe-hyperlink :to="'/customers/' + item.customerId">
              {{ item.customerId }} - {{ item.customerName }}
            </safe-hyperlink>
          </template>
          <template #[`operation`]="{ item }">
            <label :class="`col-head`">Operation:</label>
            <div>
              {{ item.operation }}
              <b-badge :variant="CssHelper.getOperationStatusCssClass(item.operationStatus)" class="mr-1">
                {{ item.operationStatus }}
              </b-badge>
            </div>
          </template>

          <template #[`currentMileageAndEngineHours`]="{ item }">
            <label :class="`col-head`">Current:</label>
            <div class="date-mileage-hours">
              <div>{{ item.mileage | number }} Miles</div>
              <div>{{ item.engineHours | number }} Hours</div>
            </div>
          </template>

          <template #[`lastCompleted`]="{ item }">
            <label :class="`col-head`">Last Complete:</label>
            <div class="date-mileage-hours">
              <div v-if="item.lastCompletedDate">{{ item.lastCompletedDate | date }}</div>
              <div v-if="item.lastCompletedMileage">{{ item.lastCompletedMileage | number }} Miles</div>
              <div v-if="item.lastCompletedEngineHours">{{ item.lastCompletedEngineHours | number }} Hours</div>
            </div>
          </template>

          <template #[`nextDue`]="{ item }">
            <label :class="`col-head`">Next Due:</label>
            <div class="date-mileage-hours">
              <div v-if="item.nextDueDate">{{ item.nextDueDate | date }}</div>
              <div v-if="item.nextDueMileage">{{ item.nextDueMileage | number }} Miles</div>
              <div v-if="item.nextDueEngineHours">{{ item.nextDueEngineHours | number }} Hours</div>
            </div>
          </template>
          <template #[`operationStatus`]=""><span /></template>
          <template #[`customerName`]=""><span /></template>
          <template #[`actions`]=""><span /></template>
        </mobile-table>

        <b-table
          v-else
          striped
          sticky-header="63vh"
          class="border"
          no-border-collapse
          :items="operationsForecast"
          :fields="fields"
          :per-page="pageSize"
          :current-page="currentPage"
          :filter="filter"
          :filter-included-fields="filterOn"
          :sort-by.sync="sortBy"
          show-empty
          empty-text="No results found."
          empty-filtered-text="No results found."
          @filtered="onFiltered"
        >
          <template #cell(vin)="{ item }">
            <safe-hyperlink :to="'units/' + item.unitId">
              {{ item.vin }}
            </safe-hyperlink>
          </template>
          <template #cell(customerId)="{ item }">
            <safe-hyperlink :to="'/customers/' + item.customerId">
              {{ item.customerId }}
            </safe-hyperlink>
          </template>
          <template #cell(currentMileageAndEngineHours)="{ item }">
            {{ item.mileage | number }} Miles
            <br />
            {{ item.engineHours | number }} Hours
          </template>

          <template #cell(location)="{ item }">
            <div v-if="item.location">{{ item.location }}</div>
          </template>

          <template #cell(lastCompleted)="{ item }">
            <div v-if="item.lastCompletedDate">{{ item.lastCompletedDate | date }}</div>
            <div v-if="item.lastCompletedMileage">{{ item.lastCompletedMileage | number }} Miles</div>
            <div v-if="item.lastCompletedEngineHours">{{ item.lastCompletedEngineHours | number }} Hours</div>
          </template>

          <template #cell(nextDue)="{ item }">
            <div v-if="item.nextDueDate">{{ item.nextDueDate | date }}</div>
            <div v-if="item.nextDueMileage">{{ item.nextDueMileage | number }} Miles</div>
            <div v-if="item.nextDueEngineHours">{{ item.nextDueEngineHours | number }} Hours</div>
          </template>

          <template #cell(operationStatus)="{ item }">
            <div class="text-nowrap">
              <b-badge :variant="CssHelper.getOperationStatusCssClass(item.operationStatus)" class="mr-1">
                {{ item.operationStatus }}
              </b-badge>
              <sup v-if="item.operationStatus == 'Undetermined'">
                <font-awesome-icon
                  :id="`undetermined_tooltip_${item.key}`"
                  icon="question-circle"
                  size="lg"
                  class="text-primary align-self-center"
                  display="inline-block"
                />
                <b-tooltip :target="`undetermined_tooltip_${item.key}`" triggers="hover">
                  Unable to forecast due to missing data.
                  {{ $isCustomer ? 'Contact Clarke/VehiCare representative to update.' : '' }}
                </b-tooltip>
              </sup>
            </div>
          </template>

          <template #cell(serviceStatus)="{ item, index }">
            <div v-if="item.serviceRequest && item.serviceRequest.appointment" :id="`startDate_${index}`">
              <b-badge
                v-if="$isCustomer"
                variant="light"
                :style="AppointmentCssHelper.badgeStyle(item.serviceRequest.appointment)"
              >
                {{ item.serviceRequest.appointment.appointmentStatus.name }}
              </b-badge>
              <b-badge
                v-else
                :href="`${shopSchedulerUrl}/schedule/${item.serviceRequest.appointment.branchId}`"
                variant="light"
                :style="AppointmentCssHelper.badgeStyle(item.serviceRequest.appointment)"
              >
                {{ item.serviceRequest.appointment.appointmentStatus.name }}
              </b-badge>
            </div>
            <!-- TODO make this a link to the VCP Customer Calendar -->
            <div v-else-if="item.serviceRequest" :id="`serviceDateRequested_${index}`">
              <b-badge variant="light" :style="AppointmentCssHelper.requestBadgeStyle()">Requested</b-badge>
            </div>
            <b-tooltip
              v-if="item.serviceRequest && item.serviceRequest.appointment"
              :target="`startDate_${index}`"
              boundary="window"
              triggers="hover"
            >
              {{ item.serviceRequest.appointment.startDate | dateTime }}
            </b-tooltip>
            <b-tooltip
              v-else-if="item.serviceRequest && item.serviceRequest.serviceDateRequested"
              :target="`serviceDateRequested_${index}`"
              boundary="window"
              triggers="hover"
            >
              {{ item.serviceRequest.serviceDateRequested | date }}
            </b-tooltip>
          </template>

          <template #cell(actions)="{ item }">
            <div class="text-center">
              <b-dropdown size="xsm" right variant="secondary" no-caret boundary="viewport">
                <template #button-content>
                  <font-awesome-icon icon="ellipsis-h" />
                </template>
                <b-link :to="'/units/' + item.unitId + '/scheduled-operations'" class="dropdown-item">
                  View Schedule
                </b-link>
                <b-dropdown-item
                  v-if="!$isCustomer"
                  @click="openServiceOrderAside(item.unitId, item.standardOperationId)"
                >
                  Mark Complete
                </b-dropdown-item>
                <b-link
                  v-if="!$isCustomer"
                  class="dropdown-item"
                  @click="goToCreateRoute(item.unitId, item.standardOperationId)"
                >
                  Create Service Order
                </b-link>
                <b-link
                  v-if="!$isCustomer && item.isCloneable"
                  :to="{
                    name: 'UnitServiceOrders',
                    params: { unitId: item.unitId },
                    query: { filterBy: 'Cloneable' }
                  }"
                  class="dropdown-item"
                >
                  Clone Service Order
                </b-link>
                <b-dropdown-item v-if="serviceRequestEnabled" @click="requestService(item.unitId)">
                  {{ $isCustomer ? 'Service Request' : 'Schedule Service' }}
                </b-dropdown-item>
                <div v-if="!$isCustomer && isRegionalCSM" class="ml-4">
                  . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . .
                </div>
                <b-dropdown-item v-if="!$isCustomer && isRegionalCSM" @click="hideUnitFromOpsForecast(item.unitId)">
                  <div class="dropdown-remove-option">Remove Unit From Operations Forecast</div>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </template>
        </b-table>
      </div>

      <transition v-if="!$smallScreen" name="slide">
        <unit-service-order-history-aside
          v-if="showServiceOrderAside"
          v-model="serviceOrderWithJob"
          :loading="asideIsBusy"
          class="fixed-right fixed-top"
          mark-complete
          @completed="serviceOrderCompleted"
        />
      </transition>
    </b-card>

    <service-request-modal v-if="serviceRequestEnabled" ref="serviceRequestModal" />

    <bulk-service-request-modal v-if="serviceRequestEnabled" />
  </div>
</template>

<script>
// components
import ResponsiveSearchComponent from '@/shared/components/ResponsiveSearchComponent';
import SafeHyperlinkComponent from '@/shared/components/ui/SafeHyperlinkComponent';
import BulkServiceRequestModal from './BulkServiceRequestModal';
import ServiceRequestModal from '@/shared/components/ServiceRequestModal';
import UnitServiceOrderHistoryAside from '@/shared/components/service-order/UnitServiceOrderHistoryAside';
import MobileTable from '@/shared/components/MobileTableComponent';
// mixins
import SearchMixin from '@/shared/mixins/SearchMixin';
// vuex
import { mapGetters, mapActions, mapMutations } from 'vuex';
import {
  OperationsForecastActions,
  OperationsForecastGetters,
  OperationsForecastMutations
} from '@/shared/store/operations-forecast/types';
import { UnitActions, UnitGetters } from '@/shared/store/unit/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import { ConfigGetters } from '@/shared/store/config/types';
import { DeferredJobGetters } from '@/shared/store/deferred-job/types';
import { DvirGetters } from '@/shared/store/dvir/types';
import { UserGetters } from '../../store/user/types';
// Services
import ExportService from '@/shared/services/ExportService';
import SuccessService from '@/shared/services/SuccessService';
// helpers
import CssHelper from '@/shared/helpers/operation-status-css-class-helper';
import ErrorService from '@/shared/services/ErrorService';
import AppointmentCssHelper from '@/shared/helpers/appointment-css-helper';

export default {
  name: 'OperationsForecastPage',
  components: {
    'mobile-table': MobileTable,
    'safe-hyperlink': SafeHyperlinkComponent,
    'bulk-service-request-modal': BulkServiceRequestModal,
    'responsive-search': ResponsiveSearchComponent,
    'service-request-modal': ServiceRequestModal,
    'unit-service-order-history-aside': UnitServiceOrderHistoryAside
  },
  mixins: [SearchMixin],
  beforeRouteUpdate(to, from, next) {
    next();
    this.$refs.search?.loadSearchParameters(to.query);
    this.runSearch();
  },
  data: function () {
    return {
      searchIsBusy: false,
      serviceOrderWithJob: {},
      CssHelper: CssHelper,
      AppointmentCssHelper: AppointmentCssHelper,
      filterOn: [
        'fleetUnitId',
        'vin',
        'unitType',
        'city',
        'state',
        'customerId',
        'customerName',
        'operation',
        'mileage',
        'engineHours',
        'location',
        'lastCompletedDate',
        'lastCompletedMileage',
        'lastCompletedEngineHours',
        'nextDueDate',
        'nextDueMileage',
        'nextDueEngineHours',
        'operationStatus'
      ],
      filter: null,
      sortBy: '',
      filteredItems: null,
      currentPage: 1,
      pageSize: 50,
      exportIsBusy: false,
      asideIsBusy: false,
      selected: null,
      fields: [
        { key: 'fleetUnitId', label: 'Fleet Unit Id', sortable: true },
        { key: 'vin', label: 'VIN/Serial', sortable: true, collapsible: true },
        { key: 'unitType', label: 'Unit Type', sortable: true, collapsible: true },
        { key: 'location', label: 'Location', sortable: true },
        { key: 'customerId', label: 'Customer ID', sortable: true },
        { key: 'customerName', label: 'Customer', sortable: true },
        { key: 'operation', label: 'Operation', sortable: true },
        { key: 'currentMileageAndEngineHours', label: 'Current', sortable: false, collapsible: true },
        { key: 'lastCompleted', label: 'Last Completed', sortable: false, collapsible: true },
        { key: 'nextDue', label: 'Next Due', sortable: false, collapsible: true },
        { key: 'operationStatus', label: 'Operation Status', sortable: true },
        { key: 'serviceStatus', label: 'Service Status', sortable: false },
        { key: 'actions', label: 'Actions', sortable: false, collapsible: true }
      ]
    };
  },
  computed: {
    ...mapGetters({
      operationsForecast: OperationsForecastGetters.GET_OPERATIONS_FORECAST,
      unitCount: OperationsForecastGetters.GET_TOTAL_UNITS,
      isFeatureFlagEnabled: LookupGetters.IS_FEATURE_FLAG_ENABLED,
      showServiceOrderAside: UnitGetters.GET_UNIT_SERVICE_ORDERS_ASIDE_SHOW,
      operationStatusesList: LookupGetters.GET_OPERATION_STATUSES_LIST,
      unitServiceOrderHistory: UnitGetters.GET_UNIT_SERVICE_ORDER_HISTORY,
      locationsList: LookupGetters.GET_LOCATIONS_LIST,
      unitBranchesList: LookupGetters.GET_SERVICE_ORDER_BRANCHES_LIST,
      deferredJobsCount: DeferredJobGetters.GET_DEFERRED_JOBS_COUNT,
      dvirsCount: DvirGetters.GET_DVIRS_COUNT,
      hasDeferredJobs: DeferredJobGetters.HAS_DEFERRED_JOBS,
      hasDvirDefects: DvirGetters.HAS_DVIR_DEFECTS,
      userRoles: UserGetters.AUTHORIZE_ROLE,
      rows: OperationsForecastGetters.GET_TOTAL_ROWS,
      bulkServiceRequestModalFlag: OperationsForecastGetters.GET_BULK_SERVICE_REQUEST_MODAL_FLAG,
      uniqueUnits: OperationsForecastGetters.GET_OPERATIONS_FORECAST_UNIQUE_UNITS,
      config: ConfigGetters.GET_CONFIG
    }),
    ...mapGetters([UserGetters.AUTHORIZE_ROLE, OperationsForecastGetters.GET_IS_SEARCH_PM_CONTRACT_OPERATIONS]),
    searchFields() {
      const customerField = this.$isCustomer ? this.myCustomersField : this.customerField;
      const locationsField = this.$isCustomer ? this.myLocationsField : this.vamsMyLocationsField;
      return [
        {
          key: 'comingDue',
          label: 'Coming Due',
          radioOptions: [
            { text: 'All', value: null, tooltip: 'All managed units in branch and location.' },
            { text: '7 Day', value: '7', tooltip: 'Overdue, Due and Coming Due within 7 days.' },
            { text: '30 Day', value: '30', tooltip: 'Overdue, Due and Coming Due within 30 days.' },
            { text: '90 Day', value: '90', tooltip: 'Overdue, Due and Coming Due within 90 days.' },
            { text: '180 Day', value: '180', tooltip: 'Overdue, Due and Coming Due within 180 days.' }
          ]
        },
        { ...this.fleetUnitIdField },
        { ...this.vinField, collapsible: true, hidden: true },
        { ...customerField },
        { ...locationsField },
        {
          key: 'operationStatuses',
          label: 'Operation Status',
          options: this.operationStatusesList,
          placeholder: 'Select Operation Statuses'
        },
        { ...this.licensePlateField, collapsible: true, hidden: true },
        { ...this.unitTypesField, collapsible: true, hidden: true },
        { ...this.unitStatusesField, collapsible: true, hidden: true }
      ];
    },
    isSearchPMContractOperation: {
      get() {
        return this[OperationsForecastGetters.GET_IS_SEARCH_PM_CONTRACT_OPERATIONS];
      },
      set(value) {
        this[OperationsForecastMutations.SET_IS_SEARCH_PM_CONTRACT_OPERATIONS](value);
      }
    },
    totalUnits: {
      get() {
        return this.unitCount;
      },
      set(value) {
        this[OperationsForecastMutations.SET_PROP]({ key: 'totalUnits', value });
      }
    },
    totalRows: {
      get() {
        return this.rows;
      },
      set(value) {
        this[OperationsForecastMutations.SET_PROP]({ key: 'totalRows', value });
      }
    },
    showBulkServiceRequestModal: {
      get() {
        return this.bulkServiceRequestModalFlag;
      },
      set(value) {
        this[OperationsForecastMutations.SET_PROP]({ key: 'showBulkServiceRequestModal', value });
      }
    },
    operationsForecastUniqueUnits: {
      get() {
        return this.uniqueUnits;
      },
      set(value) {
        this[OperationsForecastMutations.SET_PROP]({ key: 'operationsForecastUniqueUnits', value });
      }
    },
    isRegionalCSM() {
      return this[UserGetters.AUTHORIZE_ROLE](['RegionalCSM']);
    },
    serviceRequestEnabled() {
      return (
        (this.isFeatureFlagEnabled('ServiceRequest') && !this.$isCustomer) ||
        (this.isFeatureFlagEnabled('VcpServiceRequest') && this.$isCustomer)
      );
    },
    isVcpUser() {
      return this.userRoles(['VcpUser']);
    },
    shopSchedulerUrl() {
      if (this.$isCustomer) return null;
      return this.config.shopSchedulerUrl;
    }
  },
  watch: {
    totalRows: function (value) {
      //Update totalUnits whenever totalRows is updated.
      let temp = value == this.operationsForecast.length ? this.operationsForecast : this.filteredItems;
      this.totalUnits = temp.map(item => item.vin).filter((value, index, self) => self.indexOf(value) === index).length;
    }
  },
  mounted() {
    if (this.$isCustomer) {
      this.filterOn = this.filterOn.filter(fieldName => fieldName !== 'customerId');
    }
    const query = this.$refs.search?.getQueryString();
    this.$router.replace({ query }).catch(() => {
      this.runSearch();
    });
  },
  methods: {
    ...mapActions([
      OperationsForecastActions.FETCH_OPERATIONS_FORECAST,
      UnitActions.HIDE_SERVICE_ORDER_HISTORY_ASIDE,
      UnitActions.SHOW_SERVICE_ORDER_HISTORY_ASIDE,
      UnitActions.FETCH_UNIT,
      UnitActions.FETCH_UNIT_STANDARD_OPERATION,
      UnitActions.FETCH_UNIT_STANDARD_OPERATIONS,
      UnitActions.FETCH_UNIT_SERVICE_ORDER_HISTORY,
      UnitActions.EXPORT_OPERATIONS_FORECAST,
      UnitActions.TOGGLE_SHOW_IN_OPS_FORECAST
    ]),
    ...mapMutations([
      OperationsForecastMutations.SET_PROP,
      OperationsForecastMutations.SET_IS_SEARCH_PM_CONTRACT_OPERATIONS
    ]),
    onFiltered(filteredItems) {
      this.filteredItems = filteredItems;
      this.totalRows = filteredItems.length; // Trigger pagination to update the number of buttons/pages due to filtering
      this.currentPage = 1;
    },
    multiunitServiceClick: function () {
      this.operationsForecastUniqueUnits = this.getOperationsForecastUniqueUnits();
      this.showBulkServiceRequestModal = true;
    },
    getOperationsForecastUniqueUnits() {
      var forecastUniqueUnits = [];

      this.filteredItems?.forEach(function (forecastOperation) {
        if (
          !forecastUniqueUnits.some(
            forecastUniqueUnitsOperation =>
              forecastUniqueUnitsOperation.unitId && forecastUniqueUnitsOperation.unitId === forecastOperation.unitId
          )
        ) {
          forecastUniqueUnits.push({
            unitId: forecastOperation.unitId,
            customerId: forecastOperation.customerId,
            customerName: forecastOperation.customerName,
            customer: forecastOperation.customerId + ' - ' + forecastOperation.customerName,
            fleetUnitId: forecastOperation.fleetUnitId,
            vin: forecastOperation.vin,
            branchId: forecastOperation.branchId,
            location: forecastOperation.location
          });
        }
      });

      return forecastUniqueUnits;
    },
    exportClick: async function () {
      try {
        this.exportIsBusy = true;
        var byteArray = await this[UnitActions.EXPORT_OPERATIONS_FORECAST](this.filteredItems);
        ExportService.downloadByteArray(byteArray, 'Operation Forecast Export', true, '.xlsx');
      } catch (error) {
        ErrorService.unknownExportError(this);
      } finally {
        this.exportIsBusy = false;
      }
    },
    async toggleSearchPMContractOperations() {
      this.runSearch();
    },
    openServiceOrderAside: async function (unitId, standardOperationId) {
      this[UnitActions.SHOW_SERVICE_ORDER_HISTORY_ASIDE]();
      await this.loadServiceOrderData(unitId, standardOperationId);
    },
    loadServiceOrderData: async function (unitId, standardOperationId) {
      this.asideIsBusy = true;
      await this[UnitActions.FETCH_UNIT](unitId);
      await this[UnitActions.FETCH_UNIT_STANDARD_OPERATION]({ unitId, standardOperationId });
      await this[UnitActions.FETCH_UNIT_STANDARD_OPERATIONS](unitId);
      await this[UnitActions.FETCH_UNIT_SERVICE_ORDER_HISTORY]({
        unitId,
        params: { completedOnly: true }
      });
      this.asideIsBusy = false;
    },
    async goToCreateRoute(unitId, standardOperationId) {
      await this[UnitActions.FETCH_UNIT_SERVICE_ORDER_HISTORY]({
        unitId: unitId,
        params: { completedOnly: false }
      });

      var serviceOrderHistory = this.unitServiceOrderHistory;

      var pathForward =
        serviceOrderHistory.filter(serviceOrder => !serviceOrder.invoiced).length > 0
          ? `/units/${unitId}/service-orders/warning/standard-operation-id/${standardOperationId}`
          : `/units/${unitId}/service-orders/create?mode=serviceOrder&standardOperationId=${standardOperationId}`;

      this.$router.push(pathForward);
    },
    serviceOrderCompleted: async function () {
      await this[UnitActions.HIDE_SERVICE_ORDER_HISTORY_ASIDE]();
      await this[OperationsForecastActions.FETCH_OPERATIONS_FORECAST](this.$route.query);
      this.totalRows = this.operationsForecast.length;
    },
    resetButtonClick: async function () {
      this.filter = '';
      this.sortBy = '';
      this.searchButtonClick(null);
    },
    searchButtonClick: async function (query) {
      await this.$router.push({ query }).catch(() => {
        this.runSearch();
      });
    },
    runSearch: async function () {
      try {
        this.searchIsBusy = true;
        await this[OperationsForecastActions.FETCH_OPERATIONS_FORECAST](this.$route.query);

        this.filteredItems = this.operationsForecast;
        this.totalRows = this.operationsForecast.length;
        this.currentPage = 1;
        this.searchFilter = '';
      } catch (error) {
        ErrorService.unknownSearchError(this);
      } finally {
        this.searchIsBusy = false;
      }
    },
    requestService(unitId) {
      this.$refs['serviceRequestModal'].showAndLoad(unitId, null);
    },
    async hideUnitFromOpsForecast(unitId) {
      try {
        await this[UnitActions.TOGGLE_SHOW_IN_OPS_FORECAST]({
          unitId: unitId,
          showInOpsForecast: false
        });
        await this.runSearch();

        SuccessService.createSuccessToast(this.$root, `Unit removed from Ops Forecast`);
      } catch {
        ErrorService.createErrorToast(this, 'Error removing from Ops Forecast');
      }
    }
  }
};
</script>
<style>
.ops-forecast-filter {
  width: 25vw;
}
.filter-pagination-row {
  display: flex;
  justify-content: space-between;
}
</style>
