<template>
  <div>
    <h5 v-if="!serviceorder">Mounted Equipment</h5>
    <div v-if="!serviceorder">
      <b-row>
        <b-col>
          <span class="required-legend float-right p-0">* Required</span>
        </b-col>
      </b-row>
      <div v-if="isCsrOrCsm">
        <b-row no-gutters>
          <b-form-group
            class="col-md-6"
            label="Mounted Equipment Type:"
            label-class="col-head"
            label-for="mountedEquipmentType"
          >
            <b-input-group>
              <v-select
                id="mountedEquipmentType"
                v-model="mountedEquipmentType"
                :options="mountedEquipmentTypesList"
                select-on-tab
                label="description"
                :taggable="true"
                :class="`${$v.mountedEquipmentType.$error && !$v.mountedEquipmentType.required ? 'is-invalid' : ''}`"
              >
                <template #option="equipmentType">
                  <span>{{ equipmentType.description }}</span>
                </template>
                <template #selected-option="equipmentType">
                  <span>{{ equipmentType.description }}</span>
                </template>
                <template #list-footer>
                  <li class="text-center">Click enter to add.</li>
                </template>
              </v-select>
              <span class="required-asterisk">*</span>
            </b-input-group>
          </b-form-group>
        </b-row>

        <b-row no-gutters>
          <b-form-group class="col-md-6" label="Serial Number:" label-class="col-head" label-for="serialNumber">
            <b-input-group>
              <b-input id="serialNumber" v-model="equipmentSerialNumber" class="rounded" />
              <span class="required-placeholder" />
            </b-input-group>
          </b-form-group>
        </b-row>

        <div class="d-flex">
          <b-form-group label="Make:" label-class="col-head" label-for="equipmentMake">
            <b-input-group>
              <b-input id="equipmentMake" v-model="equipmentMake" class="rounded" />
              <span class="required-placeholder" />
            </b-input-group>
          </b-form-group>

          <b-form-group label="Model:" label-class="col-head" label-for="equipmentModel">
            <b-input-group>
              <b-input id="equipmentModel" v-model="equipmentModel" class="rounded" />
              <span class="required-placeholder" />
            </b-input-group>
          </b-form-group>

          <b-form-group label="Manufactured Year:" label-class="col-head" label-for="equipmentYear">
            <b-input-group>
              <b-input id="equipmentYear" v-model="$v.equipmentYear.$model" class="rounded" />
              <span class="required-placeholder" />
            </b-input-group>
            <div v-show="$v.$dirty && $v.equipmentYear.$error" class="error">
              Year must be between 1753 and {{ new Date().getFullYear() + 1 }}
            </div>
          </b-form-group>
          <b-form-group class="mr-3" label="Manufactured Month:" label-class="col-head" label-for="equipmentMonth">
            <b-input-group>
              <v-select
                id="equipmentMonth"
                v-model="equipmentMonth"
                :options="months"
                :filter-by="filterMonths"
                :reduce="mo => mo.id"
                select-on-tab
                label="equipmentMonth"
                :get-option-label="mon => mon.label"
              >
                <template #option="month">
                  <span>{{ month.value }} - {{ month.id }}</span>
                </template>
                <template #selected-option="month">
                  <span>{{ month.value }} - {{ month.id }}</span>
                </template>
              </v-select>
            </b-input-group>
          </b-form-group>
        </div>
        <b-row no-gutters>
          <b-form-group
            class="col-md-6"
            label="Mounted Equipment Contract Status:"
            label-class="col-head"
            label-for="mountedEquipmentContractStatus"
          >
            <b-input-group>
              <v-select
                id="mountedEquipmentContractStatus"
                v-model="mountedEquipmentContractStatusId"
                :options="mountedEquipmentContractStatusesList"
                :reduce="mountedEquipmentContractStatus => mountedEquipmentContractStatus.id"
                label="description"
                select-on-tab
                :disabled="!isCsmOrRegionalCsm ? true : false"
              >
                <template #option="contractStatus">
                  <span>{{ contractStatus.description }}</span>
                </template>
                <template #selected-option="contractStatus">
                  <span>{{ contractStatus.description }}</span>
                </template>
              </v-select>
              <span class="required-placeholder" />
            </b-input-group>
          </b-form-group>
          <b-form-group class="col-md-6" label="Capacity:" label-class="col-head" label-for="capacity">
            <b-input-group>
              <b-input id="capacity" v-model="equipmentCapacity" class="rounded" />
            </b-input-group>
          </b-form-group>
        </b-row>
        <b-button variant="primary mt-2 mb-3" @click="addOrSave()">
          <div v-show="isSaving">
            <b-spinner small></b-spinner>
            Saving...
          </div>
          <div v-if="!isSaving && !isEditing">Add</div>
          <div v-else-if="!isSaving">Save</div>
        </b-button>
        <b-button v-if="isEditing" variant="secondary mt-2 mb-3 ml-2" @click="cancel()">Cancel</b-button>
      </div>
    </div>
    <b-overlay :show="isLoading" :opacity="0.6">
      <div v-if="serviceorder && hasMountedEquipment">
        <vams-collapse id="mounted-equipment" :ref="'mounted-equipment'" title="Mounted Equipment" :visible="visible">
          <b-table
            striped
            class="border"
            no-border-collapse
            :sticky-header="readonly ? '71vh' : '42vh'"
            :fields="serviceOrderFields"
            :items="mountedEquipments"
          >
            <template #cell(type)="{ item }">
              {{ getMountedEquipmentTypeDescription(item) }}
            </template>
            <template #cell(equipmentSerialNumber)="{ item }">
              <span class="text">{{ item.equipmentSerialNumber }}</span>
            </template>
            <template #cell(yearMakeModel)="{ item }">
              <div class="text">
                {{ item.equipmentMake }} / {{ item.equipmentModel }} / {{ item.equipmentYear }}
                <span v-if="item.equipmentMonth">&nbsp;- {{ getMonthString(item) }}</span>
              </div>
            </template>
            <template #cell(contractStatus)="{ item }">
              {{ getMountedEquipmentContractStatusDescription(item) }}
            </template>
            <template #cell(actions)="{ item }">
              <b-button class="mr-1" size="xsm" @click="editItem(item)"><font-awesome-icon icon="edit" /></b-button>
              <b-button size="xsm" @click="confirmDelete(item)"><font-awesome-icon icon="trash-alt" /></b-button>
            </template>
          </b-table>
        </vams-collapse>
      </div>
      <b-table
        v-if="!serviceorder"
        striped
        class="border"
        :sticky-header="readonly ? '71vh' : '42vh'"
        :fields="!$isCustomer && !serviceorder ? fields : vcpFields"
        :items="mountedEquipments"
        show-empty
        :empty-text="emptyText"
      >
        <template #cell(type)="{ item }">
          {{ getMountedEquipmentTypeDescription(item) }}
          <sup v-if="item.equipmentCapacity">
            <font-awesome-icon
              :id="`mounted_equipment_tooltip_${item.mountedEquipmentId}`"
              icon="info-circle"
              size="lg"
              class="text-primary align-self-center"
              display="inline-block"
            />
            <b-tooltip :target="`mounted_equipment_tooltip_${item.mountedEquipmentId}`" triggers="hover">
              Capacity: {{ item.equipmentCapacity }}
            </b-tooltip>
          </sup>
        </template>
        <template #cell(equipmentSerialNumber)="{ item }">
          <span class="text">{{ item.equipmentSerialNumber }}</span>
        </template>
        <template #cell(equipmentMake)="{ item }">
          <span class="text">{{ item.equipmentMake }}</span>
        </template>
        <template #cell(equipmentModel)="{ item }">
          <span class="text">{{ item.equipmentModel }}</span>
        </template>
        <template #cell(equipmentYear)="{ item }">
          <span class="text">{{ item.equipmentYear }}</span>
          <span v-if="item.equipmentMonth" class="text">- {{ getMonthString(item) }}</span>
        </template>
        <template #cell(contractStatus)="{ item }">
          {{ getMountedEquipmentContractStatusDescription(item) }}
        </template>
        <template #cell(actions)="{ item }">
          <b-button class="mr-1" size="xsm" @click="editItem(item)"><font-awesome-icon icon="edit" /></b-button>
          <b-button size="xsm" @click="confirmDelete(item)"><font-awesome-icon icon="trash-alt" /></b-button>
        </template>
      </b-table>
    </b-overlay>

    <warning-modal
      id="MountedEquipmentDeleteWarning"
      ref="DeleteWarning"
      title="Warning"
      :warning-text="deleteText"
      continue-btn-text="Delete Mounted Equipment"
      cancel-btn-text="Cancel"
    ></warning-modal>
  </div>
</template>

<script>
//vuex
import { mapGetters, mapState, mapActions, mapMutations } from 'vuex';
import { LookupGetters } from '@/shared/store/lookup/types';
import {
  MountedEquipmentActions,
  MountedEquipmentGetters,
  MountedEquipmentMutations
} from '@/shared/store/unit/mounted-equipment/types';
import { UserGetters } from '@/shared/store/user/types';
import { MONTHS } from '@/shared/store/lookup/state';

//components
import CollapseComponent from '@/shared/components/ui/CollapseComponent';
import vSelect from 'vue-select';
import WarningModal from '@/shared/components/WarningModal';

import { required, minValue, maxValue } from 'vuelidate/lib/validators';
import ErrorService from '@/shared/services/ErrorService';
import SuccessService from '@/shared/services/SuccessService';

export default {
  name: 'MountedEquipment',
  components: {
    'vams-collapse': CollapseComponent,
    'warning-modal': WarningModal,
    vSelect
  },
  props: {
    serviceorder: {
      type: Boolean,
      default: false
    },
    visible: Boolean()
  },
  data() {
    return {
      emptyText: 'No mounted equipment is associated with this unit.',
      deleteText: 'Are you sure you want to delete this Mounted Equipment. This cannot be undone.',
      NA: 'N/A',
      readonly: false,
      isLoading: false,
      isSaving: false,
      isEditing: false,
      months: MONTHS,
      fields: [
        { key: 'type', label: 'Type', sortable: true },
        { key: 'equipmentSerialNumber', label: 'Serial Number', sortable: true },
        { key: 'equipmentMake', label: 'Make', sortable: true },
        { key: 'equipmentModel', label: 'Model', sortable: true },
        { key: 'equipmentYear', label: 'Year - Mo', sortable: true },
        { key: 'contractStatus', label: 'Contract Status', sortable: true },
        { key: 'actions', label: 'Actions', sortable: false }
      ],
      vcpFields: [
        { key: 'type', label: 'Type', sortable: true },
        { key: 'equipmentSerialNumber', label: 'Serial Number', sortable: true },
        { key: 'equipmentMake', label: 'Make', sortable: true },
        { key: 'equipmentModel', label: 'Model', sortable: true },
        { key: 'equipmentYear', label: 'Year - Mo', sortable: true },
        { key: 'contractStatus', label: 'Contract Status', sortable: true }
      ],
      serviceOrderFields: [
        { key: 'type', label: 'Type', sortable: true },
        { key: 'equipmentSerialNumber', label: 'Serial Number', sortable: true },
        { key: 'yearMakeModel', label: ' Make / Model / Year-Mo ', sortable: true },
        { key: 'contractStatus', label: 'Contract Status', sortable: true }
      ]
    };
  },
  validations: {
    mountedEquipmentType: {
      description: {
        required
      }
    },
    equipmentYear: {
      minValue: minValue(1753),
      maxValue: maxValue(new Date().getFullYear() + 1)
    }
  },
  computed: {
    ...mapState('unit', ['unit']),
    ...mapGetters({
      mountedEquipmentTypesMap: LookupGetters.GET_MOUNTED_EQUIPMENT_TYPES_MAP,
      mountedEquipmentTypesList: LookupGetters.GET_MOUNTED_EQUIPMENT_TYPES_LIST,
      mountedEquipmentContractStatusesMap: LookupGetters.GET_MOUNTED_EQUIPMENT_CONTRACT_STATUSES_MAP,
      mountedEquipmentContractStatusesList: LookupGetters.GET_MOUNTED_EQUIPMENT_CONTRACT_STATUSES_LIST,
      mountedEquipment: MountedEquipmentGetters.GET_MOUNTED_EQUIPMENT,
      mountedEquipments: MountedEquipmentGetters.GET_MOUNTED_EQUIPMENTS
    }),
    ...mapGetters([MountedEquipmentGetters.HAS_MOUNTED_EQUIPMENT_CHANGES, UserGetters.AUTHORIZE_ROLE]),
    unitId: {
      get() {
        return this.unit.unitId;
      },
      set(value) {
        this[MountedEquipmentMutations.SET_MOUNTED_EQUIPMENT_PROP]({ key: 'unitId', value });
      }
    },
    mountedEquipmentType: {
      get() {
        return this.mountedEquipment.mountedEquipmentType;
      },
      set(value) {
        this[MountedEquipmentMutations.SET_MOUNTED_EQUIPMENT_PROP]({ key: 'mountedEquipmentType', value });
      }
    },
    mountedEquipmentContractStatusId: {
      get() {
        return this.mountedEquipment.mountedEquipmentContractStatusId;
      },
      set(value) {
        this[MountedEquipmentMutations.SET_MOUNTED_EQUIPMENT_PROP]({ key: 'mountedEquipmentContractStatusId', value });
      }
    },
    equipmentSerialNumber: {
      get() {
        return this.mountedEquipment.equipmentSerialNumber;
      },
      set(value) {
        this[MountedEquipmentMutations.SET_MOUNTED_EQUIPMENT_PROP]({ key: 'equipmentSerialNumber', value });
      }
    },
    equipmentCapacity: {
      get() {
        return this.mountedEquipment.equipmentCapacity;
      },
      set(value) {
        this[MountedEquipmentMutations.SET_MOUNTED_EQUIPMENT_PROP]({ key: 'equipmentCapacity', value });
      }
    },
    equipmentMake: {
      get() {
        return this.mountedEquipment.equipmentMake;
      },
      set(value) {
        this[MountedEquipmentMutations.SET_MOUNTED_EQUIPMENT_PROP]({ key: 'equipmentMake', value });
      }
    },
    equipmentModel: {
      get() {
        return this.mountedEquipment.equipmentModel;
      },
      set(value) {
        this[MountedEquipmentMutations.SET_MOUNTED_EQUIPMENT_PROP]({ key: 'equipmentModel', value });
      }
    },
    equipmentYear: {
      get() {
        return this.mountedEquipment.equipmentYear;
      },
      set(value) {
        this[MountedEquipmentMutations.SET_MOUNTED_EQUIPMENT_PROP]({ key: 'equipmentYear', value });
        if (value == null || value == '') {
          this[MountedEquipmentMutations.SET_MOUNTED_EQUIPMENT_PROP]({ key: 'equipmentMonth', value: null });
        }
      }
    },
    equipmentMonth: {
      get() {
        return parseInt(this.mountedEquipment.equipmentMonth);
      },
      set(value) {
        this[MountedEquipmentMutations.SET_MOUNTED_EQUIPMENT_PROP]({ key: 'equipmentMonth', value: value?.toString() });
      }
    },
    isCsrOrCsm() {
      return this[UserGetters.AUTHORIZE_ROLE](['CSR', 'CSM', 'RegionalCSM']);
    },
    isCsmOrRegionalCsm() {
      return this[UserGetters.AUTHORIZE_ROLE](['CSM', 'RegionalCSM']);
    },
    hasMountedEquipment() {
      return this.mountedEquipments.length > 0;
    }
  },
  beforeDestroy() {
    this[MountedEquipmentMutations.RESET_STATE]();
  },
  async created() {
    if (this.unit) {
      await this[MountedEquipmentActions.FETCH_MOUNTED_EQUIPMENTS](this.unit.unitId);
    }
  },
  methods: {
    ...mapActions([
      MountedEquipmentActions.CREATE_OR_UPDATE_MOUNTED_EQUIPMENT,
      MountedEquipmentActions.FETCH_MOUNTED_EQUIPMENTS,
      MountedEquipmentActions.DELETE_MOUNTED_EQUIPMENT
    ]),
    ...mapMutations([
      MountedEquipmentMutations.SET_MOUNTED_EQUIPMENT,
      MountedEquipmentMutations.SET_MOUNTED_EQUIPMENT_PROP,
      MountedEquipmentMutations.RESET_STATE
    ]),
    filterMonths(option, label, search) {
      return (
        option.id.toString().toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        option.value.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    },
    getMonthString(item) {
      if (item == null) return;
      var month = this.months?.find(m => m.id == parseInt(item.equipmentMonth));
      return month?.value;
    },
    getMountedEquipmentTypeDescription: function (item) {
      var type =
        item.mountedEquipmentType.id != null
          ? this.mountedEquipmentTypesMap[item.mountedEquipmentType.id]
          : item.mountedEquipmentType;
      return type.description;
    },
    getMountedEquipmentContractStatusDescription: function (item) {
      var status = this.mountedEquipmentContractStatusesMap[item.mountedEquipmentContractStatusId];
      return status?.description;
    },
    hasChanges() {
      return this[MountedEquipmentGetters.HAS_MOUNTED_EQUIPMENT_CHANGES];
    },
    editItem: function (item) {
      this.isEditing = true;
      this[MountedEquipmentMutations.SET_MOUNTED_EQUIPMENT](item);
    },
    cancel: async function () {
      this.$v.$reset();
      this.isLoading = true;
      this[MountedEquipmentMutations.RESET_STATE]();
      await this[MountedEquipmentActions.FETCH_MOUNTED_EQUIPMENTS](this.unit.unitId);
      this.isEditing = false;
      this.isLoading = false;
    },
    confirmDelete: function (item) {
      this.$refs.DeleteWarning.show(this, this.deleteItem.bind(this, item));
    },
    deleteItem: async function (item) {
      this.isLoading = true;
      await this[MountedEquipmentActions.DELETE_MOUNTED_EQUIPMENT](item);
      await this[MountedEquipmentActions.FETCH_MOUNTED_EQUIPMENTS](this.unit.unitId);
      this.isLoading = false;
    },

    addOrSave: async function () {
      try {
        this.isSaving = true;
        this.$v.$touch();
        if (this.$v.$anyError) {
          ErrorService.createErrorToast(this, 'Error saving Mounted Equipment. See indicated fields below.');
          return;
        }
        this[MountedEquipmentMutations.SET_MOUNTED_EQUIPMENT_PROP]({ key: 'unitId', value: this.unit.unitId });
        await this[MountedEquipmentActions.CREATE_OR_UPDATE_MOUNTED_EQUIPMENT]({
          mountedEquipment: this.mountedEquipment
        });
        await this[MountedEquipmentMutations.RESET_STATE]();
        this.$v.$reset();

        this.isEditing = false;
        await this[MountedEquipmentActions.FETCH_MOUNTED_EQUIPMENTS](this.unit.unitId);
        SuccessService.createSuccessToast(this.$root, 'Mounted Equipment Successfuly Saved.');
      } catch (error) {
        ErrorService.createErrorToast(this, 'Failed to save Mounted Equipment.');
      } finally {
        this.isSaving = false;
      }
    }
  }
};
</script>
