<template>
  <b-modal
    :id="`AvailablePartsModal`"
    :ref="`AvailablePartsModal`"
    title="Other Branches with Quantity on Hand"
    @hide="cancel"
  >
    <p>
      <b>{{ partNumberAndDescription }}</b>
    </p>
    <b-table striped :items="availableParts" :fields="fields" class="align-middle mt-3 border">
      <template #cell(branchId)="{ item }">
        <span class="text">{{ formatBranch(item.branchId) }}</span>
      </template>
      <template #cell(quantityOnHand)="{ item }">
        <span class="text">{{ item.quantityOnHand }}</span>
      </template>
      <template #cell(dateSale)="{ item }">
        <span class="text">{{ monthsSinceLastSale(item.dateSale) }}</span>
      </template>
    </b-table>

    <template #modal-footer>
      <b-button size="sm" @click="cancel">Close</b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { ServiceOrderGetters, ServiceOrderMutations } from '@/shared/store/service-order/types';
import { LookupGetters } from '@/shared/store/lookup/types';

import moment from 'moment';

export default {
  name: 'AvailablePartsModal',
  data: function () {
    return {};
  },
  computed: {
    ...mapGetters({
      availableParts: ServiceOrderGetters.GET_AVAILABLE_PARTS,
      locations: LookupGetters.GET_LOCATIONS_LIST
    }),
    fields() {
      const fields = [
        { key: 'branchId', label: 'Branch', thStyle: 'width: 240px' },
        { key: 'quantityOnHand', label: 'QTY OH', thStyle: 'width: 50px' },
        { key: 'dateSale', label: 'Months No Sales', thStyle: 'width: 50px' }
      ];
      return fields;
    },
    partNumberAndDescription() {
      return this.availableParts[0]?.partNumber + ' - ' + this.availableParts[0]?.description;
    }
  },
  methods: {
    ...mapMutations([ServiceOrderMutations.SET_AVAILABLE_PARTS]),
    monthsSinceLastSale(date) {
      const today = moment(moment().startOf('day').toDate());
      const dateSale = moment(date);
      return today.diff(dateSale, 'months');
    },
    formatPartNumber(partNumber) {
      return partNumber.substring(partNumber.indexOf('/') + 1, partNumber.length);
    },
    formatBranch(branchId) {
      var branch = this.locations.find(loc => loc.branchId == branchId);
      return branch.description;
    },
    async show() {
      this.$bvModal.show('AvailablePartsModal');
    },
    cancel() {
      this[ServiceOrderMutations.SET_AVAILABLE_PARTS]([]);
      this.$bvModal.hide('AvailablePartsModal');
    }
  }
};
</script>
