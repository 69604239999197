<template>
  <div>
    <b-card class="page-card">
      <h2 class="card-header">DVIR Defects</h2>
      <responsive-search
        v-if="!isVcpUser"
        ref="search"
        :fields="searchFields"
        :is-busy="searchIsBusy"
        storage-key="dvirSearch"
        @searchButtonClick="searchButtonClick"
        @resetButtonClick="resetButtonClick"
      />
      <b-row v-if="!$smallScreen">
        <b-form-group>
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              variant="position-relative"
              type="search"
              placeholder="Type to Filter"
              :debounce="500"
            ></b-form-input>
          </b-input-group>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="pageSize"
          aria-controls="my-table"
          align="right"
          class="ml-auto w-auto"
        ></b-pagination>
      </b-row>
      <mobile-table v-if="$smallScreen" :items="dvirs" :fields="fields">
        <template #[`fleetUnitId`]="{ item }">
          <label :class="`col-head`">Fleet Unit Id - VIN/Serial:</label>
          <safe-hyperlink :to="'/units/' + item.unitId">
            {{ item.fleetUnitId ? item.fleetUnitId + ' - ' : '' }}{{ item.vin }}
            <b-badge :variant="saftyBadgeVariant(item.isSafe)" class="mr-1" style="min-width: 50px">
              {{ item.isSafe ? 'Safe' : 'Unsafe' }}
            </b-badge>
          </safe-hyperlink>
        </template>
        <template #[`defects`]="{ item }">
          <label :class="`col-head`">Defects:</label>
          <div class="d-flex">
            <safe-hyperlink :to="'/units/' + item.unitId + '/dvir-defects'">{{ item.count }} Defect</safe-hyperlink>
            <span class="pl-1">
              - From {{ daysAgo(item.dateOldestDefectReported) }} day{{
                daysAgo(item.dateOldestDefectReported) == 1 ? '' : 's'
              }}
              ago.
            </span>
          </div>
        </template>
        <template #[`_showMore`]=""><span /></template>
        <template #[`vin`]=""><span /></template>
        <template #[`count`]=""><span /></template>
        <template #[`isSafe`]=""><span /></template>
        <template #[`dateOldestDefectReported`]=""><span /></template>
        <template #[`actions`]=""><span /></template>
      </mobile-table>
      <b-table
        v-else
        striped
        class="border"
        no-border-collapse
        :sticky-header="$isCustomer ? '73vh' : '63vh'"
        :items="dvirs"
        :fields="fields"
        :per-page="pageSize"
        :current-page="currentPage"
        :filter="filter"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No results found."
        empty-filtered-text="No results found."
        @filtered="onFiltered"
      >
        <template #head(_showMore)>
          <div class="text-center">
            <div class="btn-icon" @click="toggleExpandCollapseAll()">
              <img :src="iconString" />
            </div>
          </div>
        </template>
        <template #cell(_showMore)="data">
          <div class="text-center">
            <div class="btn-icon" @click="toggleMore(data.index)">
              <font-awesome-icon v-if="showingMore(data.index)" icon="minus" class="text-primary" />
              <font-awesome-icon v-else icon="plus" class="text-primary" />
            </div>
          </div>
        </template>
        <template #cell(vin)="{ item }">
          <safe-hyperlink :to="'/units/' + item.unitId">
            {{ item.vin }}
          </safe-hyperlink>
        </template>
        <template #cell(isSafe)="{ item }">
          <b-badge :variant="saftyBadgeVariant(item.isSafe)" class="mr-1" style="min-width: 50px">
            {{ safetyText(item.isSafe) }}
          </b-badge>
        </template>
        <template #cell(defects)="data">
          <table-cell-list
            :ref="`defect-list-${data.index}`"
            :row-index="data.index"
            :list="data.item.defects"
            :show-more.sync="data.item._showMore"
            @show="showMore(item)"
          >
            <template #default="{ item: defect }">
              <div>
                <b>{{ defect.name }}</b>
                <span class="text-muted">&nbsp;- {{ defect.dateReported | dateTime }}</span>
              </div>
              <div class="text-muted">{{ defect.remark }}</div>
            </template>
          </table-cell-list>
        </template>

        <template #cell(actions)="{ item }">
          <div class="text-center">
            <b-dropdown size="xsm" right variant="secondary" no-caret boundary="viewport">
              <template #button-content>
                <font-awesome-icon icon="ellipsis-h" />
              </template>
              <b-link v-if="!$isCustomer" :to="`/units/${item.unitId}/dvir-defects`" class="dropdown-item">
                View Defects
              </b-link>
              <b-link
                v-if="!$isCustomer"
                :to="{
                  path: `/units/${item.unitId}/service-orders/create?mode=serviceOrder`,
                  query: { fromDvir: true }
                }"
                class="dropdown-item"
              >
                Create Service Order
              </b-link>
            </b-dropdown>
          </div>
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
// components
import ResponsiveSearchComponent from '@/shared/components/ResponsiveSearchComponent';
import SafeHyperlinkComponent from '@/shared/components/ui/SafeHyperlinkComponent';
import TableCellListComponent from '@/shared/components/TableCellListComponent';
// mixins
import SearchMixin from '@/shared/mixins/SearchMixin';
// vuex
import { RootGetters } from '@/shared/store/types';
import { DvirActions } from '@/shared/store/dvir/types';
import { UserGetters } from '@/shared/store/user/types';
import { mapActions, mapGetters, mapState } from 'vuex';
// helpers
import ErrorService from '@/shared/services/ErrorService';
import moment from 'moment';

export default {
  name: 'DvirDefectsSearchPage',
  components: {
    'responsive-search': ResponsiveSearchComponent,
    'safe-hyperlink': SafeHyperlinkComponent,
    'table-cell-list': TableCellListComponent,
    'mobile-table': () => import('@/shared/components/MobileTableComponent')
  },
  mixins: [SearchMixin],
  beforeRouteUpdate(to, from, next) {
    next();
    this.runSearch();
  },
  data: function () {
    return {
      pageSize: 50,
      currentPage: 1,
      totalRows: 0,
      filter: null,
      sortBy: null,
      searchIsBusy: false,
      isExpanded: false,
      expandAllIcon: '/images/expand-all.svg',
      collapseAllIcon: '/images/collapse-all.svg',
      iconString: null,
      showList: []
    };
  },
  computed: {
    ...mapState(['dvir']),
    ...mapGetters({
      offline: RootGetters.GET_OFFLINE
    }),
    ...mapGetters([UserGetters.AUTHORIZE_ROLE]),
    dvirs() {
      return this.dvir.dvirSearchResults;
    },
    isVcpUser() {
      return this.$store.getters[UserGetters.AUTHORIZE_ROLE](['VcpUser']);
    },
    fields() {
      var fields = [
        { key: '_showMore', label: '', thStyle: 'width: 50px' },
        { key: 'fleetUnitId', label: 'Fleet Unit Id', sortable: true },
        { key: 'vin', label: 'VIN/Serial', sortable: true },
        { key: 'location', label: 'Location', sortable: true },
        {
          key: 'isSafe',
          label: 'Safety',
          sortable: true,
          filterByFormatted: true,
          formatter: value => {
            return this.safetyText(value);
          }
        },
        { key: 'count', label: 'Count', sortable: true },
        {
          key: 'dateOldestDefectReported',
          label: 'Days',
          sortable: true,
          filterByFormatted: true,
          formatter: value => {
            return this.daysAgo(value);
          }
        },
        { key: 'defects', label: 'Open Defects', sortable: true }
      ];
      if (!this.isVcpUser) {
        fields.push({ key: 'actions', label: 'Actions', sortable: false });
      }
      return fields;
    },
    searchFields() {
      const searchFields = [];
      searchFields.push(
        ...[
          {
            key: 'isSafe',
            label: 'Safety',
            radioOptions: [
              { text: 'All', value: null },
              { text: 'Safe', value: 'true' },
              { text: 'Unsafe', value: 'false' }
            ]
          },
          { ...this.fleetUnitIdField },
          { ...this.vinField },
          { ...this.locationsField }
        ]
      );
      return searchFields;
    }
  },
  created() {
    this.isExpanded = !this.isExpanded;
    this.iconString = this.isExpanded ? this.collapseAllIcon : this.expandAllIcon;
  },
  mounted() {
    const query = this.$refs.search?.getQueryString();
    this.$router.replace({ query }).catch(() => {
      this.runSearch();
    });
  },
  methods: {
    ...mapActions([DvirActions.SEARCH_DVIRS]),
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async resetButtonClick() {
      this.filter = '';
      this.sortBy = '';
      this.searchButtonClick(null);
    },
    async searchButtonClick(query) {
      await this.$router.push({ query }).catch(() => {
        this.runSearch();
      });
    },
    async runSearch() {
      try {
        this.searchIsBusy = true;
        await this[DvirActions.SEARCH_DVIRS](this.$route.query);
        this.totalRows = this.dvirs.length;
      } catch (error) {
        ErrorService.createErrorToast(this, 'Error searching DVIR Defects.');
      } finally {
        this.searchIsBusy = false;
      }
    },
    daysAgo(date) {
      return moment().diff(date, 'days');
    },
    toggleExpandCollapseAll() {
      this.setAllShowMores(this.isExpanded);
      this.isExpanded = !this.isExpanded;
      this.iconString = this.isExpanded ? this.collapseAllIcon : this.expandAllIcon;
    },
    setAllShowMores(value) {
      for (let i = 0; i < this.dvirs.length; i++) {
        this.$refs[`defect-list-${i}`].setShowMore(value);
      }
    },
    toggleMore(index) {
      this.setShowMore(index, !this.showingMore(index));
    },
    setShowMore(index, value) {
      this.$refs[`defect-list-${index}`].setShowMore(value);
    },
    showingMore(index) {
      return this.$refs[`defect-list-${index}`]?.showMore;
    },
    saftyBadgeVariant(isSafe) {
      if (isSafe === null) return 'secondary';
      return isSafe ? 'success' : 'danger';
    },
    safetyText(isSafe) {
      if (isSafe == null) return 'Unknown';
      return isSafe ? 'Safe' : 'Unsafe';
    }
  }
};
</script>
