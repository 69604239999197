<template>
  <div>
    <b-row>
      <b-col><div class="line-titles">LABOR</div></b-col>
    </b-row>

    <div
      v-for="(lbr, lbrIndex) in laborLines"
      :key="lbrIndex"
      :class="!readonly && [vLabor(lbrIndex).$model._showClock ? 'show-punch-data-active' : '']"
    >
      <b-row v-if="!lbr.toBeDeleted" :class="lbrIndex < laborLines.length - 1 ? 'pb-1' : ''">
        <b-col xl="2" cols="4">
          <b-form-group
            label="CODE:"
            :label-class="`col-head line-item labor ${lbrIndex > 0 ? '' : 'first-row'}`"
            :label-for="`op-${opId}-labor-${lbrIndex}-code`"
          >
            <div v-if="readonly" :id="`op-${opId}-labor-${lbrIndex}-code`" class="pl-0">
              {{ lbr.laborCode || NA }}
            </div>
            <div v-else>
              <b-input-group>
                <v-select
                  v-if="!lbr.complete"
                  :id="`op-${opId}-labor-${lbrIndex}-code`"
                  :value="lbr.laborCode"
                  :options="laborCodesList"
                  label="laborCode"
                  :clearable="false"
                  :filter-by="filterSearchedLaborCodes"
                  :state="hasError(vLabor(lbrIndex).laborCode, 'required') ? false : null"
                  @input="updateCode(lbrIndex, $event)"
                  @search="onLaborCodesSearch"
                >
                  <template #option="code">
                    <div class="select-list">
                      <span class="select-row text-wrap">
                        {{ code.laborCode }} - {{ code.description }} -- {{ code.hoursFlat }} HRS
                      </span>
                    </div>
                  </template>
                  <template #selected-option="code">
                    <span v-if="lbr.laborCode" class="text-uppercase">{{ code.laborCode }}</span>
                  </template>
                  <template #no-options>No Code Found</template>
                </v-select>
                <b-form-input v-else :id="`op-${opId}-labor-${lbrIndex}-code`" :value="lbr.laborCode" disabled />
              </b-input-group>
              <div v-if="hasError(vLabor(lbrIndex).laborCode, 'required')" class="error">Enter a valid Labor Code.</div>
            </div>
          </b-form-group>
        </b-col>

        <b-col xl="4" cols="8">
          <b-form-group
            label="DESCRIPTION:"
            :label-class="`col-head line-item labor ${lbrIndex > 0 ? '' : 'first-row'}`"
            :label-for="`op-${opId}-labor-${lbrIndex}-description`"
          >
            <div v-if="readonly" :id="`op-${opId}-labor-${lbrIndex}-description`" class="pl-0">
              {{ lbr.description || NA }}
            </div>
            <div v-else>
              <b-input-group>
                <b-form-input
                  :id="`op-${opId}-labor-${lbrIndex}-description`"
                  :value="lbr.description"
                  :state="hasError(vLabor(lbrIndex).description, 'required') ? false : null"
                  :disabled="lbr.complete"
                  @input="updateDescription(lbrIndex, $event)"
                />
              </b-input-group>
              <div v-if="hasError(vLabor(lbrIndex).description, 'required')" class="error">Enter a description.</div>
            </div>
          </b-form-group>
        </b-col>

        <b-col
          v-if="
            (!isPackagedPricing || !$isCustomer) &&
            !($isCustomer && !$store.state.serviceOrder.serviceOrder.invoiced && !isEstimate)
          "
          xl="1"
          md="2"
          cols="4"
        >
          <b-form-group
            label="Price:"
            :label-class="`col-head line-item labor ${lbrIndex > 0 ? '' : 'first-row'}`"
            :label-for="`op-${opId}-labor-${lbrIndex}-price`"
          >
            <div v-if="readonly" :id="`op-${opId}-labor-${lbrIndex}-price`" class="pl-0">
              {{ lbr.price || 0 | currency }}
            </div>
            <b-input-group v-else prepend="$">
              <b-form-input
                :id="`op-${opId}-labor-${lbrIndex}-price`"
                v-currency="currencyOptions"
                :value="lbr.price"
                :disabled="lbr.complete"
                @input="updatePrice(lbrIndex, $event)"
              />
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col v-if="!$isCustomer" xl="2" md="4" cols="8">
          <b-form-group
            label="TECH:"
            :label-class="`col-head line-item labor ${lbrIndex > 0 ? '' : 'first-row'}`"
            :label-for="`op-${opId}-labor-${lbrIndex}-tech`"
          >
            <div v-if="readonly" :id="`op-${opId}-labor-${lbrIndex}-tech`" class="pl-0">
              {{ showTech(lbr.technician) || NA }}
            </div>
            <b-input-group v-else>
              <b-form-input
                v-if="technicianReadOnly(lbr)"
                :id="`op-${opId}-labor-${lbrIndex}-tech`"
                :value="showTech(lbr.technician)"
                disabled
              />
              <v-select
                v-else
                :id="`op-${opId}-labor-${lbrIndex}-tech`"
                :value="lbr.technician"
                :options="techList"
                label="employeeId"
                :filter-by="filterTech"
                class="w-75"
                @input="addTech(lbrIndex, $event)"
              >
                <template #selected-option="tech">
                  <template v-if="tech.employeeId">
                    <span class="text-uppercase">{{ showTech(tech) }}</span>
                  </template>
                </template>
                <template #option="tech">
                  <span class="text-uppercase">{{ showTech(tech) }}</span>
                </template>
              </v-select>
            </b-input-group>
          </b-form-group>
        </b-col>

        <b-col v-if="!$isCustomer && !isEstimate" xl="1" md="2" cols="4">
          <b-form-group
            label="HRS FLAT:"
            :label-class="`col-head line-item labor ${lbrIndex > 0 ? '' : 'first-row'}`"
            :label-for="`op-${opId}-labor-${lbrIndex}-hours-flat`"
          >
            <template #label>
              <div>HRS FLAT:</div>
            </template>
            <div v-if="readonly" :id="`op-${opId}-labor-${lbrIndex}-hours-flat`" class="pl-0">
              {{ lbr.hoursFlat || 0 }}
            </div>
            <b-form-input
              v-else
              :id="`op-${opId}-labor-${lbrIndex}-hours-flat`"
              :value="lbr.hoursFlat"
              :disabled="lbr.complete"
              @keypress="NumberFieldHelper.isDecimal($event)"
              @blur="updateHoursFlat(lbrIndex, $event.target.value)"
            />
          </b-form-group>
        </b-col>

        <b-col v-if="!$isCustomer" xl="1" md="2" cols="4">
          <b-form-group
            :label="isEstimate ? 'HRS EST:' : 'HRS BILL:'"
            :label-class="`col-head line-item labor ${lbrIndex > 0 ? '' : 'first-row'}`"
            :label-for="`op-${opId}-labor-${lbrIndex}-hours-bill`"
          >
            <div v-if="readonly" :id="`op-${opId}-labor-${lbrIndex}-hours-bill`" class="pl-0">
              {{ lbr.hoursBill || 0 }}
            </div>
            <b-input-group v-else>
              <b-form-input
                :id="`op-${opId}-labor-${lbrIndex}-hours-bill`"
                :value="lbr.hoursBill"
                class="rounded"
                :disabled="lbr.complete"
                @keypress="NumberFieldHelper.isDecimal($event)"
                @blur="updateHoursBill(lbrIndex, $event.target.value)"
              />
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col v-if="!readonly" xl="1" md="2" cols="4">
          <b-form-group
            label="ACTIONS:"
            :label-class="`col-head line-item labor invisible ${lbrIndex > 0 ? '' : 'first-row'}`"
            :label-for="`op-${opId}-labor-${lbrIndex}-actions`"
          >
            <div class="d-block text-nowrap">
              <b-button v-b-tooltip size="xsm" class="m-1" title="Duplicate" @click="duplicateLaborLine(lbr)">
                <font-awesome-icon icon="clone" flip="vertical" />
              </b-button>
              <b-button
                v-if="serviceOrder.featureFlagTimeClock && lbr.jobLaborTimeClock.showJobLaborTimeClock && !isEstimate"
                v-b-tooltip
                size="xsm"
                class="m-1"
                title="Time Punch"
                :variant="lbr.jobLaborTimeClock.isActive ? 'secondary-reversed' : null"
                @click="toggleClock(lbrIndex)"
              >
                <font-awesome-icon v-if="!lbr.jobLaborTimeClock.isComplete" icon="clock" />
                <font-awesome-icon v-else icon="check" />
              </b-button>
              <b-button
                v-if="allowRemove(lbr)"
                v-b-tooltip
                size="xsm"
                class="m-1"
                title="Remove"
                @click="removeLaborLine(lbrIndex)"
              >
                <font-awesome-icon icon="trash-alt" />
              </b-button>
            </div>
          </b-form-group>
        </b-col>
        <div class="line-wrap-border labor" />
      </b-row>
      <div
        v-if="
          serviceOrder.featureFlagTimeClock &&
          ((readonly && !$isCustomer) || (!readonly && vLabor(lbrIndex).$model._showClock))
        "
      >
        <service-order-labor-line-clock
          :job-key="jobKey"
          :lbr-index="lbrIndex"
          :readonly="readonly"
        ></service-order-labor-line-clock>
      </div>
    </div>
    <b-row v-if="!readonly" no-gutters>
      <b-col offset-xl="9" xl="2" offset="10" cols="2">
        <b-button class="p-0 float-right mt-neg4px" variant="link" size="xsm" @click="addLaborLine()">
          <font-awesome-icon icon="plus" />
          Add Row
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import vSelect from 'vue-select';
import { parse } from 'vue-currency-input';
import { mapState, mapMutations, mapGetters, mapActions } from 'vuex';
import { ServiceOrderGetters, ServiceOrderMutations, ServiceOrderActions } from '@/shared/store/service-order/types';
import debounce from 'lodash.debounce';
import NumberFieldHelper from '@/shared/helpers/number-field-helper';
import { hasError } from '@/shared/helpers/validator-helper.js';
import ServiceOrderLaborLineClockComponent from './ServiceOrderLaborLineClockComponent';

export default {
  name: 'ServiceOrderLaborLineComponent',
  components: {
    vSelect,
    'service-order-labor-line-clock': ServiceOrderLaborLineClockComponent
  },
  inject: {
    validator: {
      from: 'validator',
      default: () => null
    }
  },
  props: {
    jobKey: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isPackagedPricing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      NumberFieldHelper: NumberFieldHelper,
      NA: 'N/A',
      currencyOptions: {
        currency: null,
        locale: 'en-US'
      }
    };
  },
  computed: {
    ...mapState('serviceOrder', ['techList', 'laborCodesList']),
    ...mapGetters([ServiceOrderGetters.GET_TECH_LIST, ServiceOrderGetters.HAS_LABOR_OR_SUBLET]),
    ...mapGetters({
      defaultTech: ServiceOrderGetters.GET_DEFAULT_TECH,
      jobs: ServiceOrderGetters.GET_JOBS,
      serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER,
      isEstimate: ServiceOrderGetters.GET_IS_ESTIMATE,
      techList: ServiceOrderGetters.GET_TECH_LIST
    }),
    opId() {
      return this.jobs[this.jobKey].operationId;
    },
    laborLines() {
      return this.jobs[this.jobKey].details.laborLines;
    },
    laborLineValidation() {
      return this.validator.jobs.$each[this.jobKey].details.laborLines.$each.$iter;
    }
  },
  methods: {
    ...mapMutations([
      ServiceOrderMutations.REMOVE_LABOR_LINE,
      ServiceOrderMutations.SET_TECH,
      ServiceOrderMutations.SET_LABOR_DESCRIPTION,
      ServiceOrderMutations.SET_LABOR_PRICE,
      ServiceOrderMutations.SET_HOURS_BILL,
      ServiceOrderMutations.SET_HOURS_FLAT,
      ServiceOrderMutations.SET_SHOW_CLOCK
    ]),
    ...mapActions([
      ServiceOrderActions.ADD_LABOR_LINE,
      ServiceOrderActions.DUPLICATE_LABOR_LINE,
      ServiceOrderActions.SEARCH_LABOR_CODES,
      ServiceOrderActions.SELECT_LABOR_CODES
    ]),
    technicianReadOnly(lbr) {
      return lbr.complete || lbr.jobLaborTimeClock?.laborPunches.length > 0;
    },
    allowRemove(labor) {
      return (
        this[ServiceOrderGetters.HAS_LABOR_OR_SUBLET](this.jobKey) &&
        !labor.jobLaborTimeClock.isComplete &&
        !labor.jobLaborTimeClock.laborPunches.length > 0
      );
    },
    updateDescription(index, description) {
      this[ServiceOrderMutations.SET_LABOR_DESCRIPTION]({ jobKey: this.jobKey, index, description });
    },
    addLaborLine() {
      this[ServiceOrderActions.ADD_LABOR_LINE]({ jobKey: this.jobKey });
    },
    duplicateLaborLine(laborLine) {
      this[ServiceOrderActions.DUPLICATE_LABOR_LINE]({ jobKey: this.jobKey, laborLine });
    },
    filterSearchedLaborCodes(option, label, search) {
      return this.dropdownSearchFilter(option.laborCode, option.description, search);
    },
    dropdownSearchFilter(laborCode, description, search) {
      search = search.toLowerCase();
      laborCode = laborCode != null ? laborCode.toLowerCase().includes(search) : laborCode;
      description = description != null ? description.toLowerCase().includes(search) : description;
      return laborCode || description;
    },
    addTech(index, technician) {
      this[ServiceOrderMutations.SET_TECH]({ jobKey: this.jobKey, index, technician });
    },
    removeLaborLine(index) {
      this[ServiceOrderMutations.REMOVE_LABOR_LINE]({ jobKey: this.jobKey, index: index });
      this[ServiceOrderMutations.SET_SHOW_CLOCK]({
        jobKey: this.jobKey,
        lbrIndex: index,
        _showClock: false
      });
    },
    updateCode(index, laborCode) {
      const job = this.jobs[this.jobKey];
      this[ServiceOrderActions.SELECT_LABOR_CODES]({
        jobKey: this.jobKey,
        index,
        unitId: this.serviceOrder.unitId,
        serviceOrderId: job.serviceOrderId,
        operationId: job.operationId,
        laborCode: laborCode.laborCode,
        description: laborCode.description,
        hoursFlat: laborCode.hoursFlat
      });
      this.vLabor(index).laborCode.$touch();
    },
    updatePrice(index, lbrPrice) {
      let price = parse(lbrPrice, this.currencyOptions);
      this[ServiceOrderMutations.SET_LABOR_PRICE]({ jobKey: this.jobKey, index, price });
    },
    updateHoursBill(index, hours) {
      const hoursBill = hours ? parseFloat(hours) : null;
      this[ServiceOrderMutations.SET_HOURS_BILL]({ jobKey: this.jobKey, index, hoursBill });
    },
    updateHoursFlat(index, hours) {
      const hoursFlat = hours ? parseFloat(hours) : null;
      this[ServiceOrderMutations.SET_HOURS_FLAT]({ jobKey: this.jobKey, index, hoursFlat });
    },
    filterTech(option, label, search) {
      return (
        option.employeeId.toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        option.name.toLowerCase().indexOf(search.toLowerCase()) > -1
      );
    },
    showTech(tech) {
      if (!tech || !tech.employeeId) return null;
      return `${tech.employeeId} - ${tech.name}`;
    },
    vLabor(index) {
      return this.laborLineValidation[index];
    },
    async onLaborCodesSearch(search) {
      if (search.length > 2) {
        await this.searchLaborCodes(search, this);
      }
    },
    toggleClock(lbrIndex) {
      var clockStatus = this.vLabor(lbrIndex).$model._showClock;
      this[ServiceOrderMutations.SET_SHOW_CLOCK]({
        jobKey: this.jobKey,
        lbrIndex: lbrIndex,
        _showClock: !clockStatus
      });
    },
    searchLaborCodes: debounce(async (search, vm) => {
      return await vm[ServiceOrderActions.SEARCH_LABOR_CODES](search);
    }, 300),
    hasError: hasError
  }
};
</script>
<style scoped>
.select-row {
  text-transform: uppercase;
  margin-bottom: 0;
}
.select-list {
  padding: 8px 0;
}
.select-list span {
  display: block;
  max-width: 300px;
}
.show-punch-data-active {
  border: 1px solid #bdc1c4;
  padding-top: 8px;
  margin-bottom: 8px;
  margin-top: 5px;
  border-radius: 0.25rem;
}
</style>
