import axios from 'axios';

const API = '/api/epayrequest';
const EPAYMENT_DENIED_PAYMENT_ENDING = 'denied';
const EPAYMENT_SUCCESS_PAYMENT_ENDING = 'success';

class EPaymentService {
  async processDenial(parameters) {
    const url = `${API}/${EPAYMENT_DENIED_PAYMENT_ENDING}`;
    const result = await axios.post(url, parameters);
    return result;
  }

  async processSuccess(parameters) {
    const url = `${API}/${EPAYMENT_SUCCESS_PAYMENT_ENDING}`;
    const result = await axios.post(url, parameters);
    return result;
  }
}

export default new EPaymentService();
