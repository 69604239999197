import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, OperationsForecastGetters } from './types';

const _getters = namespaceHelper.removeNamespace(namespace, OperationsForecastGetters);

export default {
  [_getters.GET_OPERATIONS_FORECAST]: state => {
    return state.operationsForecast.map(op => {
      return { ...op, key: `u${op.unitId}-so${op.standardOperationId}` };
    });
  },
  [_getters.GET_CURRENT_PAGE]: state => {
    return state.currentPage;
  },
  [_getters.GET_TOTAL_UNITS]: state => {
    return state.totalUnits;
  },
  [_getters.GET_TOTAL_ROWS]: state => {
    return state.totalRows;
  },
  [_getters.GET_OPERATIONS_FORECAST_UNIQUE_UNITS]: state => {
    return state.operationsForecastUniqueUnits;
  },
  [_getters.GET_BULK_SERVICE_REQUEST_MODAL_FLAG]: state => {
    return state.showBulkServiceRequestModal;
  },
  [_getters.GET_IS_SEARCH_PM_CONTRACT_OPERATIONS]: state => {
    return state.isSearchPMContractOperations;
  }
};
