<template>
  <div>
    <b-card class="page-card">
      <div class="page-header">
        <h2>Service Orders</h2>
      </div>
      <responsive-search
        ref="search"
        :fields="searchFields"
        :is-busy="searchIsBusy"
        :storage-key="storageKey"
        :reset-data="resetData"
        @searchButtonClick="searchButtonClick"
        @resetButtonClick="resetButtonClick"
      >
        <template #assignedToEmployeeIds="props">
          <div>
            <label class="col-head">Assigned To:</label>
            <b-input-group>
              <employee-select
                v-model="props.data.assignedToEmployeeIds"
                :reduce="e => e.employeeId"
                class="min-w195px search-field-select"
                multiple
                placeholder="Name or ID"
                :show-email="false"
                :options="myAssociatedEmployeesList"
              />
            </b-input-group>
          </div>
        </template>
      </responsive-search>

      <b-row v-if="!$smallScreen">
        <b-form-group>
          <b-input-group>
            <b-form-input
              id="filterInput"
              v-model="filter"
              variant="position-relative"
              type="search"
              placeholder="Type to Filter"
              :debounce="500"
            ></b-form-input>
            <b-input-group-append v-if="filteredItems && filteredItems.length > 0">
              <div v-show="exportIsBusy">
                <b-button variant="primary" disabled class="ml-2">
                  <b-spinner small></b-spinner>
                  EXPORTING...
                </b-button>
              </div>
              <div class="ml-4 pr-2">
                <b-button v-show="!exportIsBusy" :disabled="searchIsBusy" variant="secondary" @click="exportClick">
                  Export
                </b-button>
              </div>
            </b-input-group-append>
          </b-input-group>
        </b-form-group>
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="pageSize"
          aria-controls="my-table"
          align="right"
          class="ml-auto w-auto"
        ></b-pagination>
      </b-row>
      <mobile-table v-if="$smallScreen" :items="serviceOrders" :fields="fields">
        <template #[`serviceOrderId`]="{ item }">
          <label :class="`col-head`">Service Order ID:</label>
          <safe-hyperlink :to="'/units/' + item.unitId + '/service-orders/' + item.serviceOrderId">
            {{ item.serviceOrderId }}
          </safe-hyperlink>
        </template>
        <template #[`vin`]="{ item }">
          <label :class="`col-head`">VIN/Serial:</label>
          <safe-hyperlink :to="'/units/' + item.unitId">
            {{ item.vin }}
          </safe-hyperlink>
        </template>
        <template #[`customerId`]="{ item }">
          <label :class="`col-head`">Customer:</label>
          <safe-hyperlink :to="'/customers/' + item.customerId">
            {{ item.customerId }} - {{ item.customerName }}
          </safe-hyperlink>
        </template>
        <template #[`branchId`]="{ item }">
          <label :class="`col-head`">Branch:</label>
          <div>{{ item.branchId }} - {{ item.branchName }}</div>
        </template>
        <template #[`createdDate`]="{ item }">
          <label :class="`col-head`">Created:</label>
          <div>{{ item.createdDate | date }}</div>
        </template>
        <template #[`invoiceDate`]="{ item }">
          <label :class="`col-head`">Invoiced:</label>
          <div>{{ item.invoiceDate | date }}</div>
        </template>
        <template #[`assignedToEmployeeName`]="{ item }">
          <label :class="`col-head`">Assigned To:</label>
          <employee-display :employee="{ name: item.assignedToEmployeeName }" />
        </template>
        <template #[`repairReason`]=""><span /></template>
        <template v-if="!$isCustomer" #[`repairStatus`]=""><span /></template>
      </mobile-table>
      <b-table
        v-else
        class="border"
        no-border-collapse
        striped
        sticky-header="63vh"
        :items="serviceOrders"
        :fields="fields"
        :per-page="pageSize"
        :current-page="currentPage"
        :filter="filter"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No results found."
        empty-filtered-text="No results found."
        @filtered="onFiltered"
      >
        <template #cell(serviceOrderId)="{ item }">
          <safe-hyperlink :to="'/units/' + item.unitId + '/service-orders/' + item.serviceOrderId">
            {{ item.serviceOrderId }}
          </safe-hyperlink>
        </template>
        <template #cell(vin)="{ item }">
          <safe-hyperlink :to="'/units/' + item.unitId">
            {{ item.vin }}
          </safe-hyperlink>
        </template>
        <template #cell(customerId)="{ item }">
          <safe-hyperlink :to="'/customers/' + item.customerId">
            {{ item.customerId }}
          </safe-hyperlink>
        </template>
        <template #cell(branchId)="item">
          {{ formatBranch(item) }}
        </template>
        <template #cell(createdDate)="item">
          <span v-if="item.value">{{ item.value | date }}</span>
        </template>
        <template #cell(invoiceDate)="item">
          <span v-if="item.value">{{ item.value | date }}</span>
        </template>
        <template #cell(invoiced)="item">
          {{ item.value ? 'INVOICED' : 'OPEN' }}
        </template>
        <template #cell(assignedToEmployeeName)="item">
          <employee-display :employee="{ name: item.value }" />
        </template>
        <template #cell(subTotal)="item">
          {{ item.value | currency }}
        </template>
        <template #cell(notes)="{ item }">
          <b-button :id="item.unitId" class="noteButton" title="Notes" @click="toggleAside(item)">
            <img src="/images/notes-blue.svg" width="25px" height="auto" alt="Notes" />
          </b-button>
        </template>
        <template #cell(total)="{ item }">
          <div v-if="($isCustomer && item.invoiced) || !$isCustomer">{{ item.total | currency }}</div>
        </template>
      </b-table>
    </b-card>
    <b-sidebar
      id="sidebar-1"
      v-model="sidebar"
      sidebar-class="vams-aside"
      right
      shadow="lg"
      no-header
      backdrop
      lazy
      no-close-on-esc
      no-close-on-route-change
    >
      <div class="d-flex">
        <div class="flex w-100 p-4">
          <div class="float-right mb-2 mr-2 mt-neg10">
            <button type="button" class="close" aria-label="Close" @click="toggleAside()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="clearfix"></div>
          <div class="d-inline-block w-100">
            <notes
              :ref="`aside-${ASIDES.NOTES}`"
              :title="`Service Order ID: ${noteSoId}`"
              label="Service Order"
              :is-service-order="true"
              :path="soPath"
              :branch="branchId"
            />
          </div>
        </div>
      </div>
    </b-sidebar>
  </div>
</template>

<script>
// components
import ResponsiveSearchComponent from '@/shared/components/ResponsiveSearchComponent';
import SafeHyperlinkComponent from '@/shared/components/ui/SafeHyperlinkComponent';
import EmployeeDisplayComponent from '@/shared/components/ui/EmployeeDisplayComponent';
import EmployeeSelectComponent from '@/shared/components/ui/EmployeeSelectComponent';
import NotesComponent from '@/shared/components/floating-actions/NotesComponent';
// mixins
import SearchMixin from '@/shared/mixins/SearchMixin';
import AsideMixin from '@/shared/mixins/AsideMixin';
// vuex
import { mapActions, mapGetters } from 'vuex';
import { ServiceOrderActions, ServiceOrderGetters } from '@/shared/store/service-order/types';
import { LookupGetters } from '@/shared/store/lookup/types';
import { UserGetters } from '@/shared/store/user/types';
import { NoteActions } from '@/shared/store/note/types';
import { CustomerActions } from '@/shared/store/customer/types';

// helpers
import ErrorService from '@/shared/services/ErrorService';
import ExportService from '@/shared/services/ExportService';

export default {
  name: 'ServiceOrdersSearchPage',
  components: {
    'employee-display': EmployeeDisplayComponent,
    'employee-select': EmployeeSelectComponent,
    'responsive-search': ResponsiveSearchComponent,
    'safe-hyperlink': SafeHyperlinkComponent,
    'mobile-table': () => import('@/shared/components/MobileTableComponent'),
    notes: NotesComponent
  },
  mixins: [SearchMixin, AsideMixin],
  beforeRouteUpdate(to, from, next) {
    next();
    this.$refs.search?.loadSearchParameters(to.query);
    this.runSearch();
  },
  data() {
    return {
      totalRows: 0,
      pageSize: 50,
      currentPage: 1,
      filteredItems: [],
      filter: null,
      sortBy: '',
      searchIsBusy: false,
      exportIsBusy: false,
      storageKey: 'serviceOrderSearch',
      sidebar: false,
      soPath: '',
      noteSoId: null,
      branchId: null,
      noteCustomerId: null
    };
  },
  computed: {
    ...mapGetters({
      serviceOrders: ServiceOrderGetters.GET_SO_SEARCH_RESULTS,
      user: UserGetters.GET_USER_PROFILE,
      repairStatuses: LookupGetters.GET_SELECTABLE_REPAIR_STATUSES,
      myAssociatedEmployeesList: LookupGetters.GET_MY_ASSOCIATED_EMPLOYEES_LIST,
      serviceOrderBranchesList: LookupGetters.GET_SERVICE_ORDER_BRANCHES_LIST,
      repairReasonsList: LookupGetters.GET_REPAIR_REASONS_LIST,
      uiConfig: LookupGetters.GET_UI_CONFIG
    }),
    activeRepairReasons() {
      return this.repairReasonsList.filter(r => !r.inactive);
    },
    isVcpUser() {
      return this.$store.getters[UserGetters.AUTHORIZE_ROLE](['VcpUser']);
    },
    repairStatusesSorted() {
      return this.repairStatuses
        .filter(status => status.selectable)
        .sort((a, b) => a.repairStatus.localeCompare(b.repairStatus));
    },
    searchFields() {
      let searchFields = [];

      if (!this.$isMobileApp && !this.isVcpUser) {
        searchFields.push({
          key: 'soStatus',
          label: 'SO Status',
          radioOptions: [
            { text: 'All', value: 'All' },
            { text: 'Open', value: 'Open' },
            { text: 'Invoiced', value: 'Invoiced' }
          ]
        });
      } else if (this.uiConfig.showOpenSOs) {
        searchFields.push({
          key: 'soStatus',
          label: 'SO Status',
          radioOptions: [
            { text: 'All', value: 'All' },
            { text: 'Open', value: 'Open' },
            { text: 'Invoiced', value: 'Invoiced' }
          ]
        });
      }
      let branchList = {};
      if (!this.$isCustomer) {
        searchFields.push({ key: 'assignedToEmployeeIds', label: 'Assigned To' }); // displayed via slot
      } else {
        branchList = { options: this.serviceOrderBranchesList };
      }

      const customerField = this.$isCustomer ? this.myCustomersField : this.customerField;
      searchFields.push(
        ...[
          { key: 'serviceOrderId', label: 'Service Order ID' },
          { ...this.fleetUnitIdField },
          { ...this.vinField, collapsible: true },
          { ...customerField, collapsible: true },
          { ...this.branchField, ...branchList, collapsible: true },
          { ...this.licensePlateField, collapsible: true, hidden: true },
          { ...this.unitTypesField, collapsible: true, hidden: true },
          { ...this.unitStatusesField, collapsible: true, hidden: true },
          {
            key: 'repairReasons',
            label: 'Reason For Repair',
            placeholder: 'Select Reasons For Repair',
            options: this.activeRepairReasons,
            reduce: x => x.repairReason,
            selectLabel: 'repairReason',
            collapsible: true,
            hidden: true
          }
        ]
      );

      if (!this.$isCustomer) {
        searchFields.push({
          key: 'repairStatuses',
          label: 'Repair Status',
          placeholder: 'Select Repair Statuses',
          options: this.repairStatusesSorted,
          reduce: x => x.repairStatus,
          selectLabel: 'repairStatus',
          collapsible: true,
          hidden: true
        });
      }

      return searchFields;
    },
    fields() {
      let fields = [];
      fields.push({ key: 'serviceOrderId', label: 'Service Order ID', sortable: true });
      fields.push({ key: 'fleetUnitId', label: 'Fleet Unit ID', sortable: true });
      fields.push({ key: 'vin', label: 'VIN/Serial', sortable: true });

      if (!this.$isCustomer) {
        fields.push({ key: 'customerId', label: 'Customer ID', sortable: true, collapsible: true });

        if (!this.$smallScreen) {
          fields.push({ key: 'customerName', label: 'Customer', sortable: true, collapsible: true });
        }
        fields.push({ key: 'branchId', label: 'Branch', sortable: true, collapsible: true });
        fields.push({ key: 'createdDate', label: 'Created', sortable: true, collapsible: true });
        fields.push({ key: 'invoiceDate', label: 'Invoiced', sortable: true, collapsible: true });
        fields.push({ key: 'repairStatus', label: 'Repair Status', sortable: true, collapsible: true });
        fields.push({ key: 'repairReason', label: 'Reason For Repair', sortable: true, collapsible: true });
        fields.push({ key: 'assignedToEmployeeName', label: 'Assigned To', sortable: true, collapsible: true });

        if (!this.$isMobileApp) {
          fields.push({ key: 'subTotal', label: 'Subtotal', sortable: true, collapsible: true });
        }
      }
      if (this.$isCustomer) {
        fields.push({ key: 'createdDate', label: 'Created', sortable: true, collapsible: true });
        fields.push({ key: 'invoiceDate', label: 'Invoiced', sortable: true, collapsible: true });
        fields.push({ key: 'repairStatus', label: 'Repair Status', sortable: true, collapsible: true });
        fields.push({ key: 'repairReason', label: 'Reason For Repair', sortable: true, collapsible: true });
        if (this.uiConfig.showOpenSOs) {
          fields.push({ key: 'notes', label: 'Notes', sortable: false, collapsible: true });
        }
        fields.push({ key: 'total', label: 'Total', sortable: true, collapsible: true });
      }

      return fields;
    },
    resetData() {
      const query = {};
      if (!this.$isMobileApp && !this.$isCustomer) {
        query.soStatus = 'All';
      }
      return query;
    }
  },
  watch: {
    noteCustomerId: {
      handler() {
        if (this.noteCustomerId) {
          this[CustomerActions.FETCH_APPROVERS_FOR_CUSTOMER](this.noteCustomerId);
          this[CustomerActions.FETCH_USERS_FOR_CUSTOMER](this.noteCustomerId);
        }
      },
      immediate: true
    }
  },
  mounted() {
    const query = this.$refs.search?.getQueryString();
    if (query != null || this.$isCustomer || !this.user.employeeId) {
      this.$router.replace({ query }).catch(() => {
        this.runSearch();
      });
      return;
    }
    this.defaultSearchParameters();
  },
  methods: {
    ...mapActions([
      ServiceOrderActions.SEARCH_SERVICE_ORDERS,
      ServiceOrderActions.EXPORT,
      NoteActions.FETCH_NOTES,
      CustomerActions.FETCH_APPROVERS_FOR_CUSTOMER,
      CustomerActions.FETCH_USERS_FOR_CUSTOMER
    ]),
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.filteredItems = filteredItems;
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    resetButtonClick: async function () {
      this.filter = '';
      this.sortBy = '';
      this.searchButtonClick(null);
    },
    searchButtonClick: async function (query) {
      await this.$router.push({ query }).catch(() => {
        this.runSearch();
      });
    },
    async runSearch() {
      try {
        this.searchIsBusy = true;
        await this[ServiceOrderActions.SEARCH_SERVICE_ORDERS](this.$route.query);
        this.filteredItems = this.serviceOrders;
        this.totalRows = this.serviceOrders.length;
      } catch (error) {
        ErrorService.unknownSearchError(this);
      } finally {
        this.searchIsBusy = false;
      }
    },
    formatBranch(row) {
      return `${row.item.branchId} - ${row.item.branchName}`;
    },
    defaultSearchParameters() {
      let searchData = { assignedToEmployeeIds: this.user.employeeId };
      if (!this.$isMobileApp && !this.$isCustomer) {
        searchData.soStatus = 'Open';
      }

      this.$refs.search.data = searchData;
      this.$refs.search?.searchButtonClick();
    },
    exportClick: async function () {
      try {
        this.exportIsBusy = true;
        var byteArray = await this[ServiceOrderActions.EXPORT](this.filteredItems);
        ExportService.downloadByteArray(byteArray, 'Service Order Export', true, '.xlsx');
      } catch (error) {
        ErrorService.unknownExportError(this);
      } finally {
        this.exportIsBusy = false;
      }
    },
    async toggleAside(serviceOrder) {
      this.noteCustomerId = serviceOrder?.customerId ? serviceOrder?.customerId : null;
      if (serviceOrder != null) {
        this.noteSoId = serviceOrder.serviceOrderId;
        this.branchId = serviceOrder.branchId;
        this.soPath = `/units/${serviceOrder.unitId}/service-orders/${serviceOrder.serviceOrderId}`;
      } else {
        this.noteSoId = null;
      }
      this.sidebar = !this.sidebar;
    }
  }
};
</script>
<style scoped>
.noteButton {
  border: none;
  background: none;
}
</style>
