import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, InspectionGetters } from './types';

const _getters = namespaceHelper.removeNamespace(namespace, InspectionGetters);

export default {
  [_getters.GET_INSPECTION]: state => {
    return state.details;
  },
  [_getters.GET_DB_INSPECTION]: state => {
    return state.dbDetails;
  },
  [_getters.GET_INSPECTION_ITEM]: state => id => {
    return state.itemMap[id];
  },
  [_getters.GET_AXLE_INSPECTIONS]: state => {
    return state.axleInspections;
  },
  [_getters.GET_TIRE_INSPECTION_ITEM]: state => (inspectionItemId, tireConfigurationId) => {
    return state.tireItemMap[`${inspectionItemId}_${tireConfigurationId}`];
  },
  [_getters.GET_BRAKE_INSPECTION_ITEM]: state => (inspectionItemId, brakeConfigurationId) => {
    return state.brakeItemMap[`${inspectionItemId}_${brakeConfigurationId}`];
  },
  [_getters.GET_STAGE]: state => {
    return state.stage;
  },
  [_getters.HAS_CHANGES]: state => {
    const replacer = (k, v) => {
      if (v === '') return null;
      if (Array.isArray(v) && v.every(o => JSON.stringify(o) === '{}')) return null;
      return v;
    };
    return JSON.stringify(state.dbDetails, replacer) !== JSON.stringify(state.details, replacer);
  }
};
