export const getDefaultState = () => {
  return {
    loading: false,
    lookupFetchDate: null,
    lookupReloadCache: false,
    myCustomerReloadCache: false,

    lookups: {
      featureFlags: {},
      appointmentStatusMap: {},
      axleConfigurationsList: [],
      brakeStrokesMap: {},
      breakdownReasonsMap: {},
      commentCodesList: [],
      customerIdNameList: [],
      customerBreakdownBranchesList: [],
      customerEstimateBranchesList: [],
      customerServiceOrderBranchesList: [],
      customerUnitLocationsList: [],
      dmvStatesMap: {},
      fuelTypesMap: {},
      inspectionResultTypesMap: {},
      inspectionsList: [],
      jobOperationStatusesMap: {},
      loadRangesMap: {},
      locationsMap: {},
      mileageRangesList: [],
      mountedEquipmentContractStatusesMap: {},
      mountedEquipmentTypesMap: {},
      myAssociatedEmployeesMap: [],
      myBranchesMap: {},
      myLocationsList: [],
      nonScheduledOperationsList: [],
      operationStatusesList: [],
      ownershipTypesMap: {},
      padMaterialPercentsMap: {},
      partsRequestTypesList: [],
      printFormsList: [],
      repairPrioritiesMap: {},
      repairReasonsMap: {},
      repairSitesMap: {},
      repairStatusesMap: {},
      salesTypesMap: {},
      standardOperationExcedeJobsMap: {},
      standardOperationsMap: {},
      standardOperationsList: [],
      statesAndProvincesList: [],
      subletCodesList: [],
      supportedTypesList: [],
      tireSizesMap: {},
      treadDepthsMap: {},
      uiConfig: {},
      unitStatusDispositionsMap: {},
      unitStatusesMap: {},
      unitTypesMap: {},
      warrantyDocumentTypesMap: {},
      warrantyStatusesMap: {},
      warrantySubmissionTypesMap: {},
      warrantyTypesMap: {}
    },
    employeeList: [],
    ePayRequestTypeList: [],
    ePayRequestStatusList: [],
    vmrs: {
      dateFetched: null,
      hierarchy: []
    }
  };
};

export const JOB_OPERATION_STATUS_IDS = {
  ESTIMATE: 1,
  APPROVED: 2,
  REJECTED: 3,
  DEFERRED: 4,
  SERVICE_ORDER_APPROVED: 5,
  SERVICE_ORDER_SOLD: 6
};
export const MONTHS = [
  { id: 1, value: 'JAN', label: 'January' },
  { id: 2, value: 'FEB', label: 'February' },
  { id: 3, value: 'MAR', label: 'March' },
  { id: 4, value: 'APR', label: 'April' },
  { id: 5, value: 'MAY', label: 'May' },
  { id: 6, value: 'JUN', label: 'June' },
  { id: 7, value: 'JUL', label: 'July' },
  { id: 8, value: 'AUG', label: 'August' },
  { id: 9, value: 'SEP', label: 'September' },
  { id: 10, value: 'OCT', label: 'October' },
  { id: 11, value: 'NOV', label: 'November' },
  { id: 12, value: 'DEC', label: 'December' }
];
export default {
  ...getDefaultState()
};
