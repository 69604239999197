<template>
  <div class="d-flex justify-content-between">
    <div :class="`flex w-100 ${$smallScreen ? '' : 'mr-2'}`">
      <b-card class="page-card">
        <div v-if="notFound">Unit not found.</div>
        <div v-else-if="pageLoading" class="text-center py-4">
          <b-spinner></b-spinner>
        </div>
        <div v-else>
          <unit-summary-component></unit-summary-component>
          <div class="row tab-container d-flex justify-content-between">
            <b-nav tabs :class="`flex-grow-1 align-self-end order-1 order-md-0 ${$smallScreen ? 'mobile-tabs' : ''}`">
              <b-nav-item
                :to="unitPageLink + encodeURIComponent($router.currentRoute.params.unitId)"
                :active="isActivePill('UnitDetails')"
              >
                UNIT DETAILS
              </b-nav-item>
              <b-nav-item
                :to="unitPageLink + encodeURIComponent($router.currentRoute.params.unitId) + '/scheduled-operations'"
                :active="isActivePill('UnitScheduledOperations')"
              >
                SCHEDULED OPERATIONS
              </b-nav-item>
              <b-nav-item
                :to="unitPageLink + encodeURIComponent($router.currentRoute.params.unitId) + '/service-orders'"
                :active="isActivePill('UnitServiceOrders')"
              >
                SERVICE HISTORY
              </b-nav-item>
              <b-nav-item
                v-if="!$isCustomer"
                :to="unitPageLink + encodeURIComponent($router.currentRoute.params.unitId) + '/inspections'"
                :active="isActivePill('UnitInspections')"
              >
                INSPECTIONS
              </b-nav-item>
              <b-nav-item
                v-if="$isVAMSApp"
                :to="unitPageLink + encodeURIComponent($router.currentRoute.params.unitId) + '/dvir-defects'"
                :active="isActivePill('UnitDvirDefects')"
              >
                DVIR DEFECTS
              </b-nav-item>
            </b-nav>
            <b-form-group class="d-inline-block order-0 order-md-1">
              <b-input-group>
                <b-dropdown id="actions" variant="secondary" no-caret right :disabled="unitLoading">
                  <template v-if="unitLoading" #button-content><b-spinner small /></template>
                  <template v-else #button-content>Create...</template>
                  <b-dropdown-item
                    v-if="!$isCustomer"
                    :to="{
                      path: pathForward
                    }"
                  >
                    New Service Order
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-if="!$isCustomer"
                    :to="{
                      path: `/units/${unit.unitId}/service-orders/create?mode=estimate`
                    }"
                  >
                    New Estimate
                  </b-dropdown-item>

                  <b-dropdown-item
                    :to="{
                      path: `/units/${unit.unitId}/breakdowns/create`
                    }"
                  >
                    New Breakdown
                  </b-dropdown-item>
                  <b-dropdown-item v-if="serviceRequestEnabled" @click="requestService(unit.unitId)">
                    Service Request
                  </b-dropdown-item>
                </b-dropdown>
              </b-input-group>
            </b-form-group>
          </div>
          <router-view></router-view>
        </div>
      </b-card>
    </div>
    <div v-if="!$smallScreen && !notFound">
      <div style="width: 54px">
        <floating-actions :path="`/units/${unitId}`" @change="openAside" />
      </div>
      <b-sidebar
        id="sidebar-2"
        :visible="showAside"
        right
        shadow="lg"
        no-header
        lazy
        no-close-on-esc
        sidebar-class="vams-aside"
      >
        <div class="d-flex">
          <floating-actions in-sidebar @change="onAsideChange" @cancel="changeAside(ASIDES.CLOSED)" />
          <div class="flex w-100 p-4">
            <div class="float-right mb-2 mr-2 mt-neg10">
              <button type="button" class="close" aria-label="Close" @click="onAsideChange(ASIDES.CLOSED)">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="clearfix"></div>
            <div class="d-inline-block w-100">
              <attachments-component
                v-if="activeAside === ASIDES.ATTACHMENTS"
                :title="attachmentsOrNotesTitle"
                :path="`/units/${unitId}`"
                label="Unit"
                :is-unit-aside="true"
              />
              <notes
                v-else-if="activeAside === ASIDES.NOTES"
                :title="attachmentsOrNotesTitle"
                :path="`/units/${unitId}`"
                label="Unit"
              />
              <unit-out-of-service
                v-else-if="activeAside === ASIDES.OUT_OF_SERVICE"
                id="outOfService"
                ref="OutOfService"
                @close="closeAside()"
              />
              <unit-status
                v-else-if="activeAside === ASIDES.UNIT_STATUS"
                id="unitStatus"
                ref="UnitStatus"
                @close="closeAside()"
              />
              <unit-transfer
                v-else-if="activeAside === ASIDES.UNIT_TRANSFER"
                id="unitTransfer"
                ref="UnitTransfer"
                @close="closeAside()"
              />
              <mounted-equipment
                v-else-if="activeAside === ASIDES.MOUNTED_EQUIPMENT"
                id="mountedEquipment"
                ref="MountedEquipment"
                @close="closeAside()"
              />
              <paired-units
                v-else-if="activeAside === ASIDES.PAIRED_UNITS"
                id="mountedEquipment"
                ref="MountedEquipment"
                @close="closeAside()"
              />
            </div>
          </div>
        </div>
      </b-sidebar>
    </div>
    <unsaved-changes-modal
      ref="UnsavedChangesModal"
      :has-changes="hasChanges"
      :continue-btn-text="asideTitle"
    ></unsaved-changes-modal>
    <warning-modal
      id="NoLocationWarningForServiceRequests"
      ref="NoLocationWarningForServiceRequests"
      warning-text="Location assignment is required for creating service requests. Please contact a VehiCare representative."
      continue-btn-text="Okay"
      hide-cancel-btn
    ></warning-modal>

    <service-request-modal v-if="serviceRequestEnabled" ref="serviceRequestModal" />
  </div>
</template>

<script>
// components
import UnitTransfer from './floating-actions/UnitTransferComponent';
import UnitStatus from './floating-actions/UnitStatusComponent';
import UnitOutOfService from './floating-actions/UnitOutOfServiceComponent';
import AttachmentsComponent from '@/shared/components/floating-actions/AttachmentsComponent';
import NotesComponent from '@/shared/components/floating-actions/NotesComponent';
import FloatingActionsComponent from '@/shared/components/floating-actions/FloatingActionsComponent';
import UnitSummaryComponent from '@/shared/components/unit/UnitSummaryComponent';
import UnsavedChangesModal from '@/shared/components/UnsavedChangesModal';
import WarningModal from '@/shared/components/WarningModal';
import MountedEquipment from '@/shared/components/unit/MountedEquipmentsComponent';
import PairedUnitsComponent from '@/shared/components/floating-actions/PairedUnitsComponent.vue';
// vuex
import { CustomerActions } from '@/shared/store/customer/types';
import { UnitActions, UnitGetters, UnitMutations } from '@/shared/store/unit/types';
import { OutOfServiceGetters } from '@/shared/store/unit/out-of-service/types';
import { AttachmentGetters } from '@/shared/store/attachment/types';
import { NoteGetters } from '@/shared/store/note/types';
import { LookupActions, LookupGetters, LookupMutations } from '@/shared/store/lookup/types';
import { mapActions, mapGetters, mapMutations } from 'vuex';
// helpers
import { ASIDES } from '@/shared/components/floating-actions/FloatingActionsComponent';
import AsideMixin from '@/shared/mixins/AsideMixin';

export default {
  name: 'UnitPage',
  components: {
    'unit-summary-component': UnitSummaryComponent,
    notes: NotesComponent,
    'floating-actions': FloatingActionsComponent,
    'unit-out-of-service': UnitOutOfService,
    'attachments-component': AttachmentsComponent,
    'unsaved-changes-modal': UnsavedChangesModal,
    'warning-modal': WarningModal,
    'unit-status': UnitStatus,
    'unit-transfer': UnitTransfer,
    'mounted-equipment': MountedEquipment,
    'paired-units': PairedUnitsComponent,
    'service-request-modal': () => import('@/shared/components/ServiceRequestModal')
  },
  mixins: [AsideMixin],
  beforeRouteLeave(to, from, next) {
    if (this.hasChanges()) {
      this.$refs.UnsavedChangesModal.show(this, next);
    } else {
      next();
    }
  },
  data: function () {
    return {
      unitPageLink: '/units/',
      unitPageNav: {},
      notFound: false,
      pageLoading: false,
      unitLoading: false
    };
  },
  computed: {
    ...mapGetters([AttachmentGetters.HAS_CHANGES]),
    ...mapGetters([NoteGetters.HAS_CHANGES]),
    ...mapGetters([OutOfServiceGetters.HAS_ANY_CHANGES]),
    ...mapGetters({
      unit: UnitGetters.GET_UNIT,
      unitHasChanges: UnitGetters.HAS_CHANGES,
      unitStandardOperations: UnitGetters.GET_UNIT_STANDARD_OPERATIONS,
      unitServiceOrderHistory: UnitGetters.GET_UNIT_SERVICE_ORDER_HISTORY,
      isFeatureFlagEnabled: LookupGetters.IS_FEATURE_FLAG_ENABLED,
      locationsMap: LookupGetters.GET_LOCATIONS_MAP
    }),
    pathForward() {
      if (this.unitServiceOrderHistory.filter(serviceOrder => !serviceOrder.invoiced).length > 0) {
        return `/units/${this.unit.unitId}/service-orders/warning`;
      } else {
        return `/units/${this.unit.unitId}/service-orders/create?mode=serviceOrder`;
      }
    },
    attachmentsOrNotesTitle() {
      return this.unit.fleetUnitId ? `Unit Fleet Id: ${this.unit.fleetUnitId}` : `Unit Vin: ${this.unit.vin}`;
    },
    asideTitle() {
      const title = 'Continue With ';
      if (this.activeAside == ASIDES.CLOSED) return title + 'Unit';
      if (this.activeAside == ASIDES.ATTACHMENTS) return title + 'Attachments';
      if (this.activeAside == ASIDES.NOTES) return title + 'Notes';
      if (this.activeAside == ASIDES.OUT_OF_SERVICE) return title + 'Out of Service';
      if (this.activeAside == ASIDES.UNIT_STATUS) return title + 'Unit Status';
      if (this.activeAside == ASIDES.UNIT_TRANSFER) return title + 'Transfer Unit';
      if (this.activeAside == ASIDES.MOUNTED_EQUIPMENT) return title + 'Mounted Equipment';
      return title + 'Aside';
    },
    serviceRequestEnabled() {
      return this.isFeatureFlagEnabled('VcpServiceRequest') && this.$isCustomer;
    },
    unitId() {
      return this.$route.params.unitId;
    }
  },
  watch: {
    '$route.path'() {
      // Clear active aside in case it was opened when navigating within the unit
      this.activeAside = ASIDES.CLOSED;
    },
    'unit.locationId'() {
      this.fetchEmployeesByBranch();
    },
    'unit.customerId': {
      handler() {
        this.fetchUsersForCustomer();
      },
      immediate: true
    }
  },
  created: async function () {
    try {
      this.pageLoading = this.unitLoading = true;
      var unitId = this.$router.currentRoute.params.unitId;
      await this[UnitActions.FETCH_UNIT](unitId);
      this.pageLoading = false;
      await this[UnitActions.FETCH_UNIT_SERVICE_ORDER_HISTORY]({
        unitId: unitId,
        params: { completedOnly: false }
      });
      await this[UnitActions.FETCH_UNIT_STANDARD_OPERATIONS](unitId);
      this.unitLoading = false;
    } catch (error) {
      if (error.response && error.response.status == 404) {
        this.notFound = true;
      }
    }

    this.fetchUsersForCustomer();
    this.fetchEmployeesByBranch();
  },
  methods: {
    ...mapActions([
      UnitActions.FETCH_UNIT,
      UnitActions.FETCH_UNIT_SERVICE_ORDER_HISTORY,
      CustomerActions.FETCH_USERS_FOR_CUSTOMER,
      LookupActions.FETCH_EMPLOYEES_BY_BRANCH,
      UnitActions.FETCH_UNIT_STANDARD_OPERATIONS
    ]),
    ...mapMutations([LookupMutations.SET_EMPLOYEE_LIST, UnitMutations.SET_LOADING]),
    isActivePill: function (route) {
      if (this.$router.currentRoute.name === route) {
        return 'active';
      }
      return null;
    },
    beforeNavigation(nextFunc) {
      this.$refs.UnsavedChangesModal.show(this, nextFunc);
    },
    onAsideChange(asideNumber) {
      if (asideNumber === this.activeAside) return;

      if (this.hasChanges()) {
        this.beforeNavigation(this.changeAside.bind({}, asideNumber));
      } else {
        this.activeAside = asideNumber;
      }
    },
    fetchUsersForCustomer() {
      if (this.unit.customerId) {
        this[CustomerActions.FETCH_USERS_FOR_CUSTOMER](this.unit.customerId);
      }
    },
    fetchEmployeesByBranch() {
      if (this.unit.locationId !== null) {
        const unitLocation = this.locationsMap[this.unit.locationId];
        const unitBranchId = unitLocation?.branchId;
        return this[LookupActions.FETCH_EMPLOYEES_BY_BRANCH](unitBranchId);
      } else {
        return this[LookupMutations.SET_EMPLOYEE_LIST]([]);
      }
    },
    openAside(asideNumber) {
      if (this.unitHasChanges) {
        const discard = (cont = true) => {
          if (cont) {
            this.$store.commit(UnitMutations.DISCARD_CHANGES);
            this.activeAside = asideNumber;
          }
        };
        this.$refs.UnsavedChangesModal.show(this, discard);
      } else {
        this.activeAside = asideNumber;
      }
    },
    hasChanges() {
      return (
        (this.activeAside === ASIDES.ATTACHMENTS && this[AttachmentGetters.HAS_CHANGES]) ||
        (this.activeAside === ASIDES.OUT_OF_SERVICE && this[OutOfServiceGetters.HAS_ANY_CHANGES]) ||
        (this.activeAside === ASIDES.NOTES && this[NoteGetters.HAS_CHANGES]) ||
        (this.activeAside === ASIDES.UNIT_STATUS && this.$refs['UnitStatus'].hasChanges()) ||
        (this.activeAside === ASIDES.UNIT_TRANSFER && this.$refs['UnitTransfer'].hasChanges()) ||
        (this.activeAside === ASIDES.MOUNTED_EQUIPMENT && this.$refs['MountedEquipment'].hasChanges())
      );
    },
    requestService(unitId) {
      if (!this.$isCustomer) return;
      if (!this.unit.locationId) {
        this.$refs.NoLocationWarningForServiceRequests.show(this);
      } else {
        this.$refs['serviceRequestModal'].show(unitId);
      }
    }
  }
};
</script>
