import namespaceHelper from '@/shared/helpers/namespace-helper';
import { namespace, UserGetters } from './types';

const _getters = namespaceHelper.removeNamespace(namespace, UserGetters);

export default {
  [_getters.GET_NAVIGATION]: state => {
    return state.navigation;
  },
  [_getters.GET_USER_PROFILE]: state => {
    return state.userProfile;
  },
  [_getters.AUTHORIZE_ROLE]: state => checkRoles => {
    if (!state.userProfile.roles) return false;
    const matchedRoles = state.userProfile.roles.filter(role => checkRoles.includes(role));
    return matchedRoles.length > 0;
  },
  [_getters.GET_LOADING]: state => {
    return state.loading;
  },
  [_getters.IS_AUTHENTICATED]: state => {
    if (!state.msalAccount) return false;
    const timestamp = Math.floor(new Date().getTime() / 1000);
    const expires = state.msalAccount.idTokenClaims.exp;
    return expires > timestamp;
  },
  [_getters.IS_M2M]: state => {
    return state.isM2M;
  },
  [_getters.IS_CUK]: state => {
    return state.isCUK;
  },
  [_getters.IS_INVOICE_ONLY]: state => {
    return state.isInvoiceOnly;
  }
};
