import axios from 'axios';

const PARTS_REQUEST_PATH = '/api/parts-request';
const EMPLOYEES_PATH_ENDING = '/employees';
const MATERIALS_EMPLOYEE_PATH_ENDING = '/materials';
const SEARCH_PATH_ENDING = '/search';
const SERVICE_ORDER_PATH = '/serviceorder';
const SAVE_PATH = '/save';
const EXPORT_PATH = '/export';

class PartsRequestService {
  async sendPartsRequest(parameters) {
    const url = `${PARTS_REQUEST_PATH}`;
    const requestId = await axios.put(url, parameters);
    return requestId;
  }
  async savePartsRequest(parameters) {
    const url = `${PARTS_REQUEST_PATH}${SAVE_PATH}`;
    await axios.put(url, parameters);
  }

  async fetchRequestedParts(serviceOrderId) {
    const url = `${PARTS_REQUEST_PATH}${SERVICE_ORDER_PATH}/${encodeURIComponent(serviceOrderId)}`;
    const requestedParts = await axios.get(url);
    return requestedParts;
  }
  async fetchPartsRequest(partsRequestId) {
    const url = `${PARTS_REQUEST_PATH}/${encodeURIComponent(partsRequestId)}`;
    const requestedParts = await axios.get(url);
    return requestedParts;
  }

  async fetchAllOpsEmployees() {
    const url = `${PARTS_REQUEST_PATH}${EMPLOYEES_PATH_ENDING}`;
    const employees = await axios.get(url);
    return employees;
  }
  async fetchMaterialsEmployees() {
    const url = `${PARTS_REQUEST_PATH}${MATERIALS_EMPLOYEE_PATH_ENDING}`;
    const materialsEmployees = await axios.get(url);
    return materialsEmployees;
  }
  async searchPartsRequests(parameters) {
    const url = `${PARTS_REQUEST_PATH}${SEARCH_PATH_ENDING}`;
    const result = await axios.get(url, {
      params: parameters
    });
    return result;
  }
  async exportPartsRequestSearchResults(parameters) {
    const url = `${PARTS_REQUEST_PATH}${EXPORT_PATH}`;
    const result = await axios.post(url, parameters, {
      responseType: 'blob'
    });
    return result;
  }
}

export default new PartsRequestService(); // singleton object
