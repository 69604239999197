<template>
  <div v-if="!auditRunError">
    <hr />
    <div class="d-flex justify-content-between">
      <h2>
        <b-badge variant="warning mr-1">!</b-badge>
        Audit found {{ auditIssuesCount > 0 ? auditIssuesCount : '0' }} issue{{
          auditIssuesCount > 1 || auditIssuesCount == 0 ? 's' : ''
        }}
      </h2>
      <b-button class="issues-button" @click="toggleShowIssues">{{ hideShow }} Issues</b-button>
    </div>
    <div v-if="showIssues">
      <li v-for="point in auditResults" :key="point.message">{{ point.message }}</li>
    </div>
    <hr />
  </div>
</template>

<script>
import { ServiceOrderGetters } from '@/shared/store/service-order/types';
import { mapGetters } from 'vuex';
export default {
  name: 'AuditPointResults',
  components: {},
  data() {
    return {
      showIssues: false,
      hideShow: 'SHOW',
      displayAlerts: [],
      customerType: '',
      title: 'ALERTS',
      fields: [
        { key: 'employee', label: 'POSTED BY', tdClass: 'align-middle', thStyle: 'width: 20px' },
        { key: 'updateDate', label: 'DATE', tdClass: 'align-middle' },
        { key: 'note', label: 'MESSAGE', tdClass: 'align-middle' }
      ]
    };
  },
  computed: {
    ...mapGetters({
      auditResults: ServiceOrderGetters.GET_SERVICE_ORDER_AUDIT_RESULTS,
      auditRunError: ServiceOrderGetters.GET_AUDIT_RUN_ERROR
    }),
    auditIssuesCount() {
      return this.auditResults?.length;
    }
  },
  methods: {
    toggleShowIssues() {
      this.showIssues = !this.showIssues;
      if (this.showIssues) {
        this.hideShow = 'Hide';
      } else {
        this.hideShow = 'Show';
      }
    }
  }
};
</script>
<style>
.issues-button {
  border: none;
  padding: 0;
  background: none;
}
</style>
