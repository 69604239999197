import axios from 'axios';
export const UNIT_PATH = '/api/units';
export const UNIT_SERVICE_ORDERS_PATH_ENDING = 'service-orders';
export const UNIT_SERVICE_ORDER_OPERATIONS_PATH_ENDING = 'operations';
export const UNIT_SERVICE_ORDER_ITEMS_PATH_ENDING = 'items';
export const UNIT_SERVICE_ORDER_ITEMS_LABOR_DEFAULTS = 'labor/defaults';

const LOOKUPS_PATH = '/api/lookups';
const UNIT_MILAGE_ENGINE_HOURS_EXPORT_ENDING = 'export-mileage-engine-hours';
const FLEET_LIST_EXPORT_ENDING = 'export-fleet-list';
const UNIT_MILAGE_ENGINE_HOURS_IMPORT_ENDING = 'import-mileage-engine-hours';
const OPERATIONS_PATH_ENDING = 'operations';
const WORKFLOW_PATH_ENDING = 'workflow';
const ASSIGN_PATH_ENDING = 'assign';
const QUICK_ACTION_PATH = 'quick-actions';
const REQUEST_APPROVAL = 'request-approval';
const UNIT_READY = 'unit-ready';
const UNIT_STANDARD_OPERATIONS_FORECAST_PATH = '/api/operations-forecast';
const STANDARD_OPERATIONS_FORECAST_EXPORT_PATH = '/api/operations-forecast/export';
const UNIT_STANDARD_OPERATIONS_PATH_ENDING = 'standard-operations';
const UNIT_INSPECTIONS_PATH_ENDING = 'inspections';
const UNIT_DVIR_DEFECTS_PATH_ENDING = 'dvir-defects';
const VIN_CHECK_PATH = 'vin';
const UNIT_STATUS_PATH = 'unitStatus';
const UNIT_TRANSFER_PATH = 'unitTransfer';
const PAIR_PATH = 'pair';
const UNPAIR_PATH = 'unpair';
const SEARCH_PATH_ENDING = 'search';
const TOGGLE_PATH = 'toggle';
const E_PAY_ENDING = 'epay';
const E_CARD_PAY_ENDING = 'epay-card';

const skipCacheOptions = {
  headers: {
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0'
  }
};

class UnitService {
  async getLookups(skipCache) {
    const url = LOOKUPS_PATH;
    const options = skipCache ? skipCacheOptions : {};
    const lookupResponse = axios.get(url, options);
    return lookupResponse;
  }
  async exportOperationsForecast(parameters) {
    const url = STANDARD_OPERATIONS_FORECAST_EXPORT_PATH;
    const operationsForecastExportResponse = axios.post(url, parameters, {
      responseType: 'blob'
    });
    return operationsForecastExportResponse;
  }
  async exportFleetList(parameters) {
    const url = `${UNIT_PATH}/${FLEET_LIST_EXPORT_ENDING}`;
    const fleetListExportResponse = axios.post(url, parameters, {
      responseType: 'blob'
    });
    return fleetListExportResponse;
  }
  async exportMileageEngineHoursUpdateSheet(parameters) {
    const url = `${UNIT_PATH}/${UNIT_MILAGE_ENGINE_HOURS_EXPORT_ENDING}`;
    const mileageEngineHourResponse = axios.post(url, parameters, {
      responseType: 'blob'
    });
    return mileageEngineHourResponse;
  }
  async importMileageEngineHoursUpdateSheet(importedMileageAndEngineHours) {
    const url = `${UNIT_PATH}/${UNIT_MILAGE_ENGINE_HOURS_IMPORT_ENDING}`;
    let formData = new FormData();
    formData.append('file', importedMileageAndEngineHours);
    const mileageEngineHourResponse = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return mileageEngineHourResponse;
  }
  async createServiceOrder(parameters) {
    const url = `${UNIT_PATH}/${encodeURIComponent(parameters.unitId)}/${UNIT_SERVICE_ORDERS_PATH_ENDING}`;
    const createServiceOrderResponse = axios.post(url, parameters.unitServiceOrder);
    return createServiceOrderResponse;
  }
  async createLaborTimePunchIn(parameters) {
    const url = `${UNIT_PATH}/${encodeURIComponent(
      parameters.unitId
    )}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/time-punch-in/${encodeURIComponent(parameters.empId)}/${encodeURIComponent(
      parameters.laborItemId
    )}`;
    const createLaborTimePunchInResponse = await axios.post(url, parameters);
    return createLaborTimePunchInResponse;
  }
  async saveUnitServiceOrderLaborTechRemarks(parameters) {
    const url = `${UNIT_PATH}/${encodeURIComponent(
      parameters.unitId
    )}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/save-tech-remarks/${encodeURIComponent(parameters.serviceOrderId)}`;
    let techRemarksUpdate = {
      operationId: parameters.operationId,
      laborItemId: parameters.laborItemId,
      empId: parameters.empId,
      techRemarks: parameters.techRemarks
    };
    const timeClockData = await axios.post(url, techRemarksUpdate);
    return timeClockData;
  }
  async getJobLaborTechRemarks(parameters) {
    const url = `${UNIT_PATH}/${encodeURIComponent(
      parameters.unitId
    )}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/get-tech-remarks/${encodeURIComponent(
      parameters.serviceOrderId
    )}/${encodeURIComponent(parameters.operationId)}`;
    const techRemarks = await axios.get(url);
    return techRemarks;
  }
  async createLaborTimePunchOut(parameters) {
    const url = `${UNIT_PATH}/${encodeURIComponent(
      parameters.unitId
    )}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/time-punch-out`;
    const createLaborTimePunchOutResponse = await axios.post(url, parameters);
    return createLaborTimePunchOutResponse;
  }
  async completeUnitServiceOrderLabor(parameters) {
    const url = `${UNIT_PATH}/${encodeURIComponent(
      parameters.unitId
    )}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/complete-unit-service-order-labor`;
    const completeLaborResponse = await axios.post(url, parameters);
    return completeLaborResponse;
  }
  async updateServiceOrder(parameters) {
    const url = `${UNIT_PATH}/${encodeURIComponent(
      parameters.unitId
    )}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/${encodeURIComponent(parameters.serviceOrderId)}`;
    const updateServiceOrderResponse = axios.put(url, parameters.unitServiceOrder);
    return updateServiceOrderResponse;
  }
  async partialUpdateServiceOrder(parameters) {
    const url = `${UNIT_PATH}/${encodeURIComponent(
      parameters.unitId
    )}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/${encodeURIComponent(parameters.serviceOrderId)}/partial`;
    const updateServiceOrderResponse = axios.put(url, parameters.unitServiceOrder);
    return updateServiceOrderResponse;
  }
  async getLaborItemDefaults(parameters) {
    const url = `${UNIT_PATH}/${encodeURIComponent(
      parameters.unitId
    )}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/${encodeURIComponent(
      parameters.serviceOrderId
    )}/${UNIT_SERVICE_ORDER_OPERATIONS_PATH_ENDING}/${encodeURIComponent(
      parameters.operationId
    )}/${UNIT_SERVICE_ORDER_ITEMS_PATH_ENDING}/${encodeURIComponent(
      parameters.laborCode
    )}/${UNIT_SERVICE_ORDER_ITEMS_LABOR_DEFAULTS}`;

    const response = await axios.get(url);
    return response;
  }
  async addJobsToServiceOrder(parameters) {
    const url = `${UNIT_PATH}/${encodeURIComponent(
      parameters.unitId
    )}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/${encodeURIComponent(parameters.serviceOrderId)}/${OPERATIONS_PATH_ENDING}`;
    const addJobsResponse = axios.post(url, parameters);
    return addJobsResponse;
  }
  async deleteJobFromServiceOrder(parameters) {
    const url = `${UNIT_PATH}/${encodeURIComponent(parameters.unitId)}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/${
      parameters.serviceOrderId
    }/${parameters.operationId}`;
    const deleteResponse = axios.delete(url, parameters);
    return deleteResponse;
  }

  async getWorkflow(parameters) {
    const url = `${UNIT_PATH}/${encodeURIComponent(
      parameters.unitId
    )}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/${encodeURIComponent(parameters.serviceOrderId)}/${WORKFLOW_PATH_ENDING}`;
    const response = await axios.get(url);
    return response;
  }
  async assignEmployeeToServiceOrder(parameters) {
    const url = `${UNIT_PATH}/${encodeURIComponent(
      parameters.unitId
    )}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/${encodeURIComponent(parameters.serviceOrderId)}/${ASSIGN_PATH_ENDING}`;
    const response = await axios.put(url, parameters.workflow);
    return response;
  }

  async requestApproval(parameters) {
    const url = `${UNIT_PATH}/${encodeURIComponent(
      parameters.unitId
    )}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/${encodeURIComponent(
      parameters.serviceOrderId
    )}/${QUICK_ACTION_PATH}/${REQUEST_APPROVAL}`;
    const response = await axios.post(url, parameters.approvalRequest);
    return response;
  }

  async sendUnitReady(parameters) {
    const url = `${UNIT_PATH}/${encodeURIComponent(
      parameters.unitId
    )}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/${encodeURIComponent(
      parameters.serviceOrderId
    )}/${QUICK_ACTION_PATH}/${UNIT_READY}`;
    const response = await axios.post(url, parameters.unitReady);
    return response;
  }
  async toggleShowInOpsForecast(params) {
    const url = `${UNIT_PATH}//${encodeURIComponent(params.unitId)}/${TOGGLE_PATH}/${encodeURIComponent(
      params.showInOpsForecast
    )}`;
    await axios.get(url);
  }
  async getUnitOperationsForecast(unitId) {
    const url = `${UNIT_STANDARD_OPERATIONS_FORECAST_PATH}/${encodeURIComponent(unitId)}`;
    const unitOperationsForecastResponse = await axios.get(url);
    return unitOperationsForecastResponse;
  }
  async getServiceOrder(unitId, serviceOrderId, clone = false) {
    const url = `${UNIT_PATH}/${encodeURIComponent(unitId)}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/${encodeURIComponent(
      serviceOrderId
    )}`;
    const serviceOrder = await axios.get(url, { params: { clone } });
    return serviceOrder;
  }
  async getLinkedServiceOrder(unitId, serviceOrderId, estimateId) {
    let url = `${UNIT_PATH}/${encodeURIComponent(unitId)}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/${encodeURIComponent(
      serviceOrderId
    )}/link`;
    if (estimateId) url += `/${encodeURIComponent(estimateId)}`;
    const serviceOrder = await axios.get(url);
    return serviceOrder;
  }
  async getServiceOrders(unitId, params) {
    const url = `${UNIT_PATH}/${encodeURIComponent(unitId)}/${UNIT_SERVICE_ORDERS_PATH_ENDING}`;
    const serviceOrdersResponse = await axios.get(url, {
      params
    });
    return serviceOrdersResponse;
  }
  async getUnit(unitId) {
    const url = `${UNIT_PATH}/${encodeURIComponent(unitId)}`;
    const unitResponse = await axios.get(url);
    return unitResponse;
  }
  async getUnits(parameters) {
    const url = UNIT_PATH;
    const unitsResponse = await axios.get(url, {
      params: parameters
    });
    return unitsResponse;
  }
  async getUnitStandardOperation(unitId, standardOperationId) {
    const standardOperationsUrl = `${UNIT_PATH}/${encodeURIComponent(unitId)}/${UNIT_STANDARD_OPERATIONS_PATH_ENDING}`;
    const url = `${standardOperationsUrl}/${encodeURIComponent(standardOperationId)}`;
    const unitResponse = await axios.get(url);
    return unitResponse;
  }
  async getUnitStandardOperations(unitId) {
    const url = `${UNIT_PATH}/${encodeURIComponent(unitId)}/${UNIT_STANDARD_OPERATIONS_PATH_ENDING}`;
    const unitResponse = await axios.get(url);
    return unitResponse;
  }
  async getUnitInspections(unitId) {
    const url = `${UNIT_PATH}/${encodeURIComponent(unitId)}/${UNIT_INSPECTIONS_PATH_ENDING}`;
    const response = await axios.get(url);
    return response;
  }
  async getUnitDvirDefects(unitId) {
    const url = `${UNIT_PATH}/${encodeURIComponent(unitId)}/${UNIT_DVIR_DEFECTS_PATH_ENDING}`;
    const response = await axios.get(url);
    return response;
  }
  async updateUnit(unit) {
    const url = `${UNIT_PATH}/${encodeURIComponent(unit.unitId)}`;
    const updatedUnit = await axios.put(url, unit);
    return updatedUnit;
  }
  async updateUnitStandardOperation(unitId, standardOperationId, unitStandardOperation) {
    const url = `${UNIT_PATH}/${encodeURIComponent(
      unitId
    )}/${UNIT_STANDARD_OPERATIONS_PATH_ENDING}/${encodeURIComponent(standardOperationId)}`;

    const updatedUnitStandardOperations = await axios.put(url, unitStandardOperation);
    return updatedUnitStandardOperations;
  }

  async updateUnitStandardOperations(unitId, unitStandardOperations) {
    const url = `${UNIT_PATH}/${encodeURIComponent(unitId)}/${UNIT_STANDARD_OPERATIONS_PATH_ENDING}`;
    const updatedUnitStandardOperations = await axios.put(url, unitStandardOperations);
    return updatedUnitStandardOperations;
  }

  async checkVin(vin) {
    const url = `${UNIT_PATH}/${VIN_CHECK_PATH}/${encodeURIComponent(vin)}`;
    const unitResponse = await axios.get(url);
    return unitResponse;
  }

  async createUnit(unit) {
    const url = `${UNIT_PATH}/create`;
    const unitResponse = await axios.post(url, unit);
    return unitResponse;
  }

  async updateUnitStatus(unitId, newUnitStatus) {
    const url = `${UNIT_PATH}/${UNIT_STATUS_PATH}/${encodeURIComponent(unitId)}`;
    const response = await axios.put(url, newUnitStatus);
    return response;
  }

  async updateUnitLocation(unitId, newUnitLocation) {
    const url = `${UNIT_PATH}/${UNIT_TRANSFER_PATH}/${encodeURIComponent(unitId)}`;
    const response = await axios.put(url, newUnitLocation);
    return response;
  }
  async createPairedUnits(payload) {
    const url = `${UNIT_PATH}/${encodeURIComponent(payload.customerId)}/${encodeURIComponent(
      payload.unitId
    )}/${PAIR_PATH}/${encodeURIComponent(payload.pairedUnitId)}`;
    const unit = await axios.put(url);
    return unit;
  }
  async deletePairedUnits(payload) {
    const url = `${UNIT_PATH}/${encodeURIComponent(payload.unitId)}/${UNPAIR_PATH}/${encodeURIComponent(
      payload.pairedUnitId
    )}`;
    const unit = await axios.put(url);
    return unit;
  }
  async searchUnits(customerId, search) {
    const url = `${UNIT_PATH}/${encodeURIComponent(customerId)}/${SEARCH_PATH_ENDING}${
      search ? '?searchCriteria=' + encodeURIComponent(search) : ''
    }`;
    const result = await axios.get(url);
    return result;
  }
  async sendPaymentRequest(params) {
    const url = `${UNIT_PATH}/${encodeURIComponent(
      params.unitId
    )}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/${encodeURIComponent(params.serviceOrderId)}/${E_PAY_ENDING}`;
    const updateServiceOrderResponse = axios.put(url, params.ePayRequest);
    return updateServiceOrderResponse;
  }
  async sendCardPaymentRequest(params) {
    const url = `${UNIT_PATH}/${encodeURIComponent(
      params.unitId
    )}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/${encodeURIComponent(params.serviceOrderId)}/${E_CARD_PAY_ENDING}`;
    const updateServiceOrderResponse = axios.put(url, params.ePayRequest);
    return updateServiceOrderResponse;
  }
}

export default new UnitService(); // singleton object
