<template>
  <b-modal :id="id" :ref="id" :title="title" header-class="alert-warning">
    <p>{{ warningText }}</p>
    <div v-if="collectpo">
      Enter a PO number this service &nbsp;
      <b-form-group label="PO #:" label-for="po-number" class="modal-input-group">
        <b-form-input id="po-number" v-model="purchaseOrder" class="modal-input" :maxlength="maxLength"></b-form-input>
        <div class="note modal-input">Maximum Length: {{ maxLength }} characters</div>
      </b-form-group>
    </div>
    <div v-if="isNote">
      <label for="note-message" class="d-block col-head">
        Reason:
        <span class="required-asterisk">*</span>
      </label>
      <div>
        <div role="group" class="input-group">
          <textarea
            id="note-message"
            v-model="message"
            wrap="soft"
            class="overflow-auto rounded form-control form-control-sm"
            style="resize: none; overflow-y: scroll; height: 53px"
          ></textarea>
        </div>
      </div>
    </div>
    <template #modal-footer="{ ok, cancel }">
      <b-button v-if="!hideContinueBtn" size="sm" variant="primary" :disabled="isEmptyMessage && isNote" @click="ok">
        {{ continueBtnText }}
      </b-button>
      <b-button v-if="!hideCancelBtn" size="sm" @click="cancel">
        {{ cancelBtnText }}
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { NoteActions, NoteGetters, NoteMutations } from '@/shared/store/note/types';
import { ServiceOrderGetters, ServiceOrderMutations } from '@/shared/store/service-order/types';
import ErrorService from '@/shared/services/ErrorService';

export default {
  name: 'WarningModal',
  props: {
    id: {
      type: String,
      default: 'warningModal'
    },
    title: {
      type: String,
      default: 'Warning'
    },
    continueBtnText: {
      type: String,
      default: 'Continue'
    },
    cancelBtnText: {
      type: String,
      default: 'Close'
    },
    warningText: {
      type: String,
      default: ''
    },
    isNote: {
      type: Boolean,
      default: false
    },
    collectpo: {
      type: Boolean,
      default: false
    },
    path: {
      type: String(),
      default() {
        return this.$route.path;
      }
    },
    hideContinueBtn: Boolean,
    hideCancelBtn: Boolean
  },
  data: function () {
    return {
      maxLength: 30,
      poNum: null
    };
  },
  computed: {
    ...mapGetters({
      newNote: NoteGetters.GET_NEW_NOTE
    }),
    message: {
      get() {
        return this.newNote.message;
      },
      set(value) {
        this.setData('message', value);
      }
    },
    purchaseOrder: {
      get() {
        return this.$store.getters[ServiceOrderGetters.GET_PURCHASE_ORDER];
      },
      set(value) {
        this.poNum = value;
      }
    },
    isEmptyMessage() {
      return !this.message ? true : false;
    }
  },
  methods: {
    ...mapActions([NoteActions.POST_NOTE]),
    ...mapMutations([NoteMutations.SET_NEW_NOTE, NoteMutations.RESET_STATE]),
    /**
     * @param that - scope of component that is using the modal
     * @param save - async function that saves data
     */
    show(that, save) {
      this.$bvModal.show(this.id);
      this.$nextTick(() => {
        this.$refs[this.id].$once('hide', bvEvent => {
          this.$refs[this.id].$once('hidden', async () => handleHide(bvEvent, this, save, this.postNote));
        });
      });
    },
    setData(key, newValue) {
      let value = { ...this.newNote };
      value[key] = newValue;
      this[NoteMutations.SET_NEW_NOTE](value);
    },
    async postNote() {
      if (this.isNote) {
        try {
          await this[NoteActions.POST_NOTE]({
            route: this.path,
            note: {
              internal: this.newNote.isInternal,
              message: this.message,
              recipients: [
                ...this.newNote.internalRecipients,
                ...this.newNote.externalRecipients,
                ...this.newNote.vcpRecipients
              ],
              isRejectionNote: true
            }
          });
          this[NoteMutations.RESET_STATE]();
        } catch (error) {
          ErrorService.createErrorToast(this, 'Failed to post note.');
        } finally {
          this.posting = this.loading = false;
        }
      }
    }
  }
};
async function handleHide(bvEvent, that, save, postNote) {
  if (bvEvent.trigger === 'ok' && save != null) {
    if (that.collectpo) {
      that.$store.commit(ServiceOrderMutations.SET_PROP, { key: 'purchaseOrder', value: that.poNum });
    }
    await postNote();
    await save();
  }
}
</script>
<style>
.modal-input-group {
  margin-top: 5px;
  display: flex;
  align-items: center;
}
.modal-input {
  margin-left: 10px;
}
</style>
