var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"page-card"},[_c('h2',{staticClass:"card-header"},[_vm._v("DVIR Defects")]),(!_vm.isVcpUser)?_c('responsive-search',{ref:"search",attrs:{"fields":_vm.searchFields,"is-busy":_vm.searchIsBusy,"storage-key":"dvirSearch"},on:{"searchButtonClick":_vm.searchButtonClick,"resetButtonClick":_vm.resetButtonClick}}):_vm._e(),(!_vm.$smallScreen)?_c('b-row',[_c('b-form-group',[_c('b-input-group',[_c('b-form-input',{attrs:{"id":"filterInput","variant":"position-relative","type":"search","placeholder":"Type to Filter","debounce":500},model:{value:(_vm.filter),callback:function ($$v) {_vm.filter=$$v},expression:"filter"}})],1)],1),_c('b-pagination',{staticClass:"ml-auto w-auto",attrs:{"total-rows":_vm.totalRows,"per-page":_vm.pageSize,"aria-controls":"my-table","align":"right"},model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1):_vm._e(),(_vm.$smallScreen)?_c('mobile-table',{attrs:{"items":_vm.dvirs,"fields":_vm.fields},scopedSlots:_vm._u([{key:"fleetUnitId",fn:function(ref){
var item = ref.item;
return [_c('label',{class:"col-head"},[_vm._v("Fleet Unit Id - VIN/Serial:")]),_c('safe-hyperlink',{attrs:{"to":'/units/' + item.unitId}},[_vm._v(" "+_vm._s(item.fleetUnitId ? item.fleetUnitId + ' - ' : '')+_vm._s(item.vin)+" "),_c('b-badge',{staticClass:"mr-1",staticStyle:{"min-width":"50px"},attrs:{"variant":_vm.saftyBadgeVariant(item.isSafe)}},[_vm._v(" "+_vm._s(item.isSafe ? 'Safe' : 'Unsafe')+" ")])],1)]}},{key:"defects",fn:function(ref){
var item = ref.item;
return [_c('label',{class:"col-head"},[_vm._v("Defects:")]),_c('div',{staticClass:"d-flex"},[_c('safe-hyperlink',{attrs:{"to":'/units/' + item.unitId + '/dvir-defects'}},[_vm._v(_vm._s(item.count)+" Defect")]),_c('span',{staticClass:"pl-1"},[_vm._v(" - From "+_vm._s(_vm.daysAgo(item.dateOldestDefectReported))+" day"+_vm._s(_vm.daysAgo(item.dateOldestDefectReported) == 1 ? '' : 's')+" ago. ")])],1)]}},{key:"_showMore",fn:function(){return [_c('span')]},proxy:true},{key:"vin",fn:function(){return [_c('span')]},proxy:true},{key:"count",fn:function(){return [_c('span')]},proxy:true},{key:"isSafe",fn:function(){return [_c('span')]},proxy:true},{key:"dateOldestDefectReported",fn:function(){return [_c('span')]},proxy:true},{key:"actions",fn:function(){return [_c('span')]},proxy:true}],null,true)}):_c('b-table',{staticClass:"border",attrs:{"striped":"","no-border-collapse":"","sticky-header":_vm.$isCustomer ? '73vh' : '63vh',"items":_vm.dvirs,"fields":_vm.fields,"per-page":_vm.pageSize,"current-page":_vm.currentPage,"filter":_vm.filter,"sort-by":_vm.sortBy,"show-empty":"","empty-text":"No results found.","empty-filtered-text":"No results found."},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"head(_showMore)",fn:function(){return [_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"btn-icon",on:{"click":function($event){return _vm.toggleExpandCollapseAll()}}},[_c('img',{attrs:{"src":_vm.iconString}})])])]},proxy:true},{key:"cell(_showMore)",fn:function(data){return [_c('div',{staticClass:"text-center"},[_c('div',{staticClass:"btn-icon",on:{"click":function($event){return _vm.toggleMore(data.index)}}},[(_vm.showingMore(data.index))?_c('font-awesome-icon',{staticClass:"text-primary",attrs:{"icon":"minus"}}):_c('font-awesome-icon',{staticClass:"text-primary",attrs:{"icon":"plus"}})],1)])]}},{key:"cell(vin)",fn:function(ref){
var item = ref.item;
return [_c('safe-hyperlink',{attrs:{"to":'/units/' + item.unitId}},[_vm._v(" "+_vm._s(item.vin)+" ")])]}},{key:"cell(isSafe)",fn:function(ref){
var item = ref.item;
return [_c('b-badge',{staticClass:"mr-1",staticStyle:{"min-width":"50px"},attrs:{"variant":_vm.saftyBadgeVariant(item.isSafe)}},[_vm._v(" "+_vm._s(_vm.safetyText(item.isSafe))+" ")])]}},{key:"cell(defects)",fn:function(data){return [_c('table-cell-list',{ref:("defect-list-" + (data.index)),attrs:{"row-index":data.index,"list":data.item.defects,"show-more":data.item._showMore},on:{"update:showMore":function($event){return _vm.$set(data.item, "_showMore", $event)},"update:show-more":function($event){return _vm.$set(data.item, "_showMore", $event)},"show":function($event){return _vm.showMore(_vm.item)}},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var defect = ref.item;
return [_c('div',[_c('b',[_vm._v(_vm._s(defect.name))]),_c('span',{staticClass:"text-muted"},[_vm._v(" - "+_vm._s(_vm._f("dateTime")(defect.dateReported)))])]),_c('div',{staticClass:"text-muted"},[_vm._v(_vm._s(defect.remark))])]}}],null,true)})]}},{key:"cell(actions)",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-center"},[_c('b-dropdown',{attrs:{"size":"xsm","right":"","variant":"secondary","no-caret":"","boundary":"viewport"},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('font-awesome-icon',{attrs:{"icon":"ellipsis-h"}})]},proxy:true}],null,true)},[(!_vm.$isCustomer)?_c('b-link',{staticClass:"dropdown-item",attrs:{"to":("/units/" + (item.unitId) + "/dvir-defects")}},[_vm._v(" View Defects ")]):_vm._e(),(!_vm.$isCustomer)?_c('b-link',{staticClass:"dropdown-item",attrs:{"to":{
                path: ("/units/" + (item.unitId) + "/service-orders/create?mode=serviceOrder"),
                query: { fromDvir: true }
              }}},[_vm._v(" Create Service Order ")]):_vm._e()],1)],1)]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }