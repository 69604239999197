<template>
  <b-row class="no-gutters">
    <b-col sm="5" lg="4" xl="3">
      <div v-if="serviceOrder.isEstimate" class="ml-1 note">Rejected and Deferred jobs not included in Subtotal</div>
      <div v-if="showPricingInfo">
        <b-form-group
          :label-cols="labelCols"
          label="Subtotal:"
          label-for="subtotal"
          class="m-0"
          label-class="font-weight-bold py-1"
        >
          <div id="subtotal" class="col-form-label text-right py-1">
            <b>{{ serviceOrder.subtotal || 0 | currency }}</b>
          </div>
        </b-form-group>
        <b-form-group
          :label-cols="labelCols"
          label="Shop Supplies:"
          label-for="shopSupplies"
          class="m-0"
          label-class="py-1"
        >
          <div id="shopSupplies" class="col-form-label text-right py-1">
            {{ serviceOrder.shopSupplies || 0 | currency }}
          </div>
        </b-form-group>
        <b-form-group :label-cols="labelCols" label="EPA Charge:" label-for="epaCharge" class="m-0" label-class="py-1">
          <div id="epaCharge" class="col-form-label text-right py-1">
            {{ serviceOrder.epaCharge || 0 | currency }}
          </div>
        </b-form-group>
        <b-form-group :label-cols="labelCols" label-for="salesTax" class="m-0" label-class="py-1">
          <template #label>
            Sales Tax:
            <sup v-if="!$isCustomer && !serviceOrder.invoiced">
              <font-awesome-icon
                id="sale_tax_tooltip"
                icon="question-circle"
                size="lg"
                class="mx-1 text-primary align-self-center"
                display="inline-block"
              />
              <b-tooltip :target="`sale_tax_tooltip`" triggers="hover">Click Save to recalculate tax.</b-tooltip>
            </sup>
          </template>
          <div v-show="!calculatingTaxes" id="salesTax" class="col-form-label text-right py-1">
            {{ serviceOrder.salesTax || 0 | currency }}
          </div>
          <div v-show="calculatingTaxes" id="salesTax" class="col-form-label text-right py-1">
            <b-spinner small />
          </div>
        </b-form-group>
        <b-form-group
          :label-cols="labelCols"
          label="Total:"
          label-for="total"
          class="m-0 pt-2"
          label-class="font-weight-bold"
        >
          <div v-show="!calculatingTaxes" id="total" class="col-form-label text-right">
            <b>{{ serviceOrder.total || 0 | currency }}</b>
          </div>
          <div v-show="calculatingTaxes" id="total" class="col-form-label text-right py-1">
            <b-spinner small />
          </div>
        </b-form-group>
      </div>
    </b-col>
    <b-col class="mt-3" sm="12" lg="12">
      <billing-and-payment
        v-if="FORM_STAGES.EDIT_HEADER"
        :readonly="readonly"
        visible
        @updateServiceOrder="updateServiceOrder"
      />
    </b-col>
  </b-row>
</template>

<script>
import { ServiceOrderGetters } from '@/shared/store/service-order/types';
import { mapGetters } from 'vuex';
import BillingAndPaymentComponent from './ServiceOrderBillingAndPaymentComponent';
import { FORM_STAGES } from '@/shared/store/service-order/state';

export default {
  name: 'ServiceOrderFooterComponent',
  components: {
    'billing-and-payment': BillingAndPaymentComponent
  },
  props: {
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      labelCols: 6,
      FORM_STAGES: FORM_STAGES
    };
  },
  computed: {
    ...mapGetters({
      serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER,
      calculatingTaxes: ServiceOrderGetters.GET_CALCULATING_TAXES
    }),
    showPricingInfo() {
      if (!this.$isCustomer) return true;
      else if (this.serviceOrder.isEstimate || this.serviceOrder.invoiced) return true;
      else return false;
    }
  },
  methods: {
    async updateServiceOrder() {
      this.$emit('updateServiceOrder');
    }
  }
};
</script>
