import axios from 'axios';

export const UNIT_PATH = '/api/units';
export const UNIT_SERVICE_ORDERS_PATH_ENDING = 'service-orders';
const QUICK_ACTION_PATH = 'quick-actions';
const REPAIR_CONTACT_PATH_ENDING = 'repair-contact';

class RepairContactService {
  async getRepairContact(unitId, serviceOrderId) {
    const url = `${UNIT_PATH}/${encodeURIComponent(unitId)}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/${encodeURIComponent(
      serviceOrderId
    )}/${QUICK_ACTION_PATH}/${REPAIR_CONTACT_PATH_ENDING}`;
    const repairContact = await axios.get(url);
    return repairContact;
  }

  async updateRepairContact(unitId, serviceOrderId, repairContact) {
    const url = `${UNIT_PATH}/${encodeURIComponent(unitId)}/${UNIT_SERVICE_ORDERS_PATH_ENDING}/${encodeURIComponent(
      serviceOrderId
    )}/${QUICK_ACTION_PATH}/${REPAIR_CONTACT_PATH_ENDING}`;
    const updatedRepairContact = await axios.post(url, repairContact);
    return updatedRepairContact;
  }
}

export default new RepairContactService();
