<template>
  <div>
    <service-order-summary />
    <hr />

    <service-order-header readonly />

    <div class="pt-3" />

    <div>
      <service-order-line-items
        readonly
        :has-posted-linked-service-order="hasPostedLinkedServiceOrder"
        @updateApprovalChangesFlag="updateApprovalChangesFlag"
      />

      <service-order-footer />
      <b-row v-if="isVcpUser && (serviceOrder.invoiced || isEstimate)" class="my-3 pb-3">
        <b-button
          v-if="!hasPostedLinkedServiceOrder"
          :disabled="stage !== FORM_STAGES.EDIT_SO || (!hasApprovalChangesFlag && !hasPO)"
          variant="primary"
          @click="updateServiceOrder"
        >
          <div v-show="stage == FORM_STAGES.SAVING_SO">
            <b-spinner small></b-spinner>
            Saving...
          </div>
          <div v-show="stage != FORM_STAGES.SAVING_SO">Save Estimate</div>
        </b-button>
      </b-row>
    </div>

    <warning-modal
      id="ApprovalRejectionWarningModal"
      ref="ApprovalRejectionWarningModal"
      title="Save Changes"
      warning-text="Changes cannot be altered after saving.  Are you sure you want to Save?"
      continue-btn-text="Save changes"
      :collectpo="collectPO"
    ></warning-modal>
  </div>
</template>

<script>
// components
import ServiceOrderSummaryComponent from './ServiceOrderSummaryComponent';
import ServiceOrderHeaderComponent from './ServiceOrderHeaderComponent';
import ServiceOrderFooterComponent from './ServiceOrderFooterComponent';
import ServiceOrderLineItemsComponent from './line-items/ServiceOrderLineItemsComponent';
import WarningModal from '@/shared/components/WarningModal';

import { UserGetters } from '@/shared/store/user/types';
import { ServiceOrderActions, ServiceOrderGetters, ServiceOrderMutations } from '@/shared/store/service-order/types';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { CustomerGetters } from '@/shared/store/customer/types';

import { FORM_STAGES } from '@/shared/store/service-order/state';
import SuccessService from '@/shared/services/SuccessService';
import ErrorService from '@/shared/services/ErrorService';

export default {
  name: 'ServiceOrderDetails',
  components: {
    'service-order-summary': ServiceOrderSummaryComponent,
    'service-order-header': ServiceOrderHeaderComponent,
    'service-order-line-items': ServiceOrderLineItemsComponent,
    'warning-modal': WarningModal,
    'service-order-footer': ServiceOrderFooterComponent
  },
  data: function () {
    return {
      FORM_STAGES: FORM_STAGES,
      hasApprovalChangesFlag: false,
      hasPostedLinkedServiceOrder: false
    };
  },
  computed: {
    ...mapGetters({
      serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER,
      serviceOrderId: ServiceOrderGetters.GET_SERVICE_ORDER_ID,
      estimateId: ServiceOrderGetters.GET_ESTIMATE_ID,
      customer: CustomerGetters.GET_CUSTOMER,
      jobs: ServiceOrderGetters.GET_JOBS,
      oldEstimorder: ServiceOrderGetters.GET_OLD_ESTIMORDER,
      isEstimate: ServiceOrderGetters.GET_IS_ESTIMATE
    }),
    ...mapGetters([ServiceOrderGetters.GET_FORM_STAGE]),
    stage: {
      get() {
        return this[ServiceOrderGetters.GET_FORM_STAGE];
      },
      set(value) {
        this[ServiceOrderMutations.SET_FORM_STAGE](value);
      }
    },
    unitId() {
      return this.$router.currentRoute.params.unitId;
    },
    collectPO() {
      return this.serviceOrder.customer.poRequired && !this.hasPO;
    },
    hasPO() {
      return this.serviceOrder.purchaseOrder != null && this.serviceOrder.purchaseOrder != '';
    },
    isVcpUser() {
      return this.$store.getters[UserGetters.AUTHORIZE_ROLE](['VcpUser']);
    }
  },
  created: function () {
    this.stage = FORM_STAGES.EDIT_SO;

    this.hasPostedLinkedServiceOrder = this.serviceOrder.readonly;
  },
  methods: {
    ...mapActions([ServiceOrderActions.UPDATE_SERVICE_ORDER]),
    ...mapMutations([ServiceOrderMutations.SET_FORM_STAGE]),
    updateApprovalChangesFlag(flagValue) {
      this.hasApprovalChangesFlag = flagValue;
    },
    async updateServiceOrder() {
      var isVcpApprover = this.$store.getters[UserGetters.AUTHORIZE_ROLE](['VcpApprover']);

      if (this.isVcpUser && !isVcpApprover) return;

      if (this.stage !== FORM_STAGES.EDIT_SO) {
        return;
      }
      if (this.hasApprovalChangesFlag || this.serviceOrder.purchaseOrder != this.oldEstimorder.purchaseOrder) {
        this.$refs.ApprovalRejectionWarningModal.show(this, this.handleUpdateServiceOrder.bind(this, true));
      } else {
        this.handleUpdateServiceOrder();
      }
    },
    async handleUpdateServiceOrder() {
      try {
        this.stage = FORM_STAGES.SAVING_SO;

        const estimate = {
          estimateId: this.estimateId,
          jobs: Object.values(this.jobs)
        };

        const response = await this[ServiceOrderActions.UPDATE_SERVICE_ORDER]({
          unitId: this.unitId,
          serviceOrderId: this.serviceOrderId,
          unitServiceOrder: estimate
        });
        SuccessService.createSuccessToast(this.$root, `Estimate #${response.serviceOrderId} saved successfully.`);
      } catch (error) {
        ErrorService.createErrorToast(this.$root, 'Error updating estimate.');
      } finally {
        this.stage = FORM_STAGES.EDIT_SO;
        this.updateApprovalChangesFlag(false);
      }
    }
  }
};
</script>
