import { RootGetters } from './types';
import { SIZES } from './state';

export default {
  [RootGetters.GET_SIDEBAR]: state => {
    return state.sidebar;
  },

  [RootGetters.GET_ONLINE]: state => {
    return state.online;
  },
  [RootGetters.GET_OFFLINE]: state => {
    return !state.online;
  },
  [RootGetters.IS_SMALL_SCREEN]: state => {
    return state.screenWidth < SIZES.lg;
  },
  [RootGetters.GET_URL]: state => {
    return state.url;
  }
};
