<template>
  <div>
    <b-row>
      <b-col><div class="line-titles">PARTS</div></b-col>
    </b-row>
    <div v-for="(part, partIndex) in parts" :key="partIndex">
      <b-row v-if="!part.toBeDeleted">
        <b-col :xl="part._searchingParts ? 5 : 2" :cols="part._searchingParts ? 12 : 4">
          <b-form-group
            class="parts-dropdown"
            label="Number:"
            :label-class="`col-head line-item part ${partIndex > 0 ? '' : ' first-row'}`"
            :label-for="`op-${opId}-part-${partIndex}-number`"
          >
            <div
              v-if="readonly"
              :id="`op-${opId}-part-${partIndex}-number`"
              :ref="`op-${opId}-part-${partIndex}-number`"
              class="pl-0"
            >
              {{ part.partNumber || NA }}
            </div>
            <div v-else>
              <b-input-group>
                <b-input-group-prepend is-text>
                  <font-awesome-icon v-if="part.itemType === ITEM_TYPES.CORE" icon="recycle" />
                  <piston-icon v-else width="14" height="14" />
                </b-input-group-prepend>
                <v-select
                  v-if="part._local"
                  :id="`op-${opId}-part-${partIndex}-number`"
                  :ref="`op-${opId}-part-${partIndex}-number`"
                  :class="hasError(vPart(partIndex).partNumber, 'required') ? 'is-invalid' : ''"
                  :value="part"
                  label="partNumber"
                  :options="partList"
                  :clearable="false"
                  :filterable="false"
                  :title="part.partNumber"
                  @input="selectPart(partIndex, $event)"
                  @search="onPartSearch"
                  @search:focus="togglePartSearch(partIndex, true)"
                  @search:blur="togglePartSearch(partIndex, false)"
                >
                  <template #option="partOption">
                    <span class="text-uppercase">
                      {{ partOption.sourceId }}{{ partOption.vendorPartId }} - {{ partOption.description }}
                    </span>
                    <span v-if="partOption.isTapePart" class="text-uppercase font-weight-bold">&nbsp;- (Tape)</span>
                    <span v-else class="text-muted">&nbsp;- (On Hand: {{ partOption.quantityOnHand }})</span>
                  </template>
                  <template #no-options>No Parts Found</template>
                </v-select>
                <div v-else :id="`op-${opId}-part-${partIndex}-number`">
                  <b-form-input :ref="`op-${opId}-part-${partIndex}-number`" :value="part.partNumber" disabled />
                  <b-tooltip
                    v-if="part.partNumber"
                    :target="`op-${opId}-part-${partIndex}-number`"
                    triggers="hover"
                    custom-class="parts-tooltip"
                  >
                    {{ part.partNumber }}
                  </b-tooltip>
                </div>
              </b-input-group>
              <div v-if="hasError(vPart(partIndex).partNumber, 'required')" class="error">
                Enter a valid Part Number.
              </div>
              <div v-if="part.partType === PART_TYPES.HAS_CORE" class="text-success text-uppercase p-1">
                <font-awesome-icon icon="recycle" />
                Core Charge Added On Save
              </div>
            </div>
          </b-form-group>
        </b-col>

        <b-col v-show="!part._searchingParts" :xl="readonly ? 4 : 3" :cols="readonly ? 8 : 7">
          <b-form-group
            label="Description:"
            :label-class="`col-head line-item part ${partIndex > 0 ? '' : ' first-row'}`"
            :label-for="`op-${opId}-part-${partIndex}-description`"
          >
            <div v-if="readonly" :id="`op-${opId}-part-${partIndex}-description`" class="pl-0">
              {{ part.description || NA }}
              <b-tooltip
                v-if="part.description && !serviceOrder.invoiced"
                :target="`op-${opId}-part-${partIndex}-description`"
                triggers="hover"
                custom-class="parts-tooltip"
              >
                {{ part.description }}
              </b-tooltip>
            </div>
            <div v-else :id="`op-${opId}-part-${partIndex}-description`">
              <b-form-input
                :value="part.description"
                :disabled="!part._local"
                @input="updateDescription(partIndex, $event)"
              />
              <b-alert :show="part.isWarrantyAlert" class="alert alert-warning" dismissible>
                {{ partWarrantyAlert }}
              </b-alert>
              <b-tooltip
                v-if="part.description && !serviceOrder.invoiced"
                :target="`op-${opId}-part-${partIndex}-description`"
                triggers="hover"
                custom-class="parts-tooltip"
              >
                {{ part.description }}
              </b-tooltip>
            </div>
          </b-form-group>
        </b-col>

        <b-col v-if="!$isCustomer" xl="1" md="2" cols="4">
          <b-form-group
            label="Bin:"
            :label-class="`col-head line-item part ${partIndex > 0 ? '' : ' first-row'}`"
            :label-for="`op-${opId}-part-${partIndex}-bin`"
          >
            <div v-if="readonly" :id="`op-${opId}-part-${partIndex}-bin`" class="pl-0">
              {{ part.bin }}
            </div>
            <b-form-input v-else :id="`op-${opId}-part-${partIndex}-bin`" :value="part.bin" disabled />
          </b-form-group>
        </b-col>

        <b-col
          v-if="
            (!isPackagedPricing || !$isCustomer) &&
            !($isCustomer && !$store.state.serviceOrder.serviceOrder.invoiced && !isEstimate)
          "
          :xl="readonly ? 2 : 1"
          :md="readonly ? 3 : 2"
          :cols="readonly ? 5 : 4"
        >
          <b-form-group
            label="Price:"
            :label-class="`col-head line-item part ${partIndex > 0 ? '' : ' first-row'}`"
            :label-for="`op-${opId}-part-${partIndex}-price`"
          >
            <div v-if="readonly" :id="`op-${opId}-part-${partIndex}-price`" class="pl-0">
              {{ part.price || 0 | currency }}
            </div>
            <div v-else>
              <b-input-group prepend="$">
                <b-form-input
                  v-show="!part._loadingPart"
                  :id="`op-${opId}-part-${partIndex}-price`"
                  v-currency="currencyOptions"
                  :disabled="part.itemType === ITEM_TYPES.CORE"
                  :value="part.price"
                  class="rounded-right"
                  @input="updatePrice(partIndex, $event)"
                />
                <div v-show="part._loadingPart" class="form-control disabled" disabled>
                  <b-spinner small></b-spinner>
                </div>
              </b-input-group>
            </div>
          </b-form-group>
        </b-col>

        <b-col v-if="showCost" xl="1" md="2" cols="4">
          <b-form-group
            label="Cost:"
            :label-class="`col-head line-item part ${partIndex > 0 ? '' : ' first-row'}`"
            :label-for="`op-${opId}-part-${partIndex}-cost`"
          >
            <div v-if="readonly" :id="`op-${opId}-part-${partIndex}-cost`" class="pl-0">
              {{ part.cost || 0 | currency }}
            </div>
            <div v-else>
              <b-input-group>
                <b-input-group-prepend is-text class="">$</b-input-group-prepend>
                <b-form-input
                  v-show="!part._loadingPart"
                  :id="`op-${opId}-part-${partIndex}-cost`"
                  :value="part.cost"
                  :disabled="true"
                  class="rounded-right"
                />
                <div v-show="part._loadingPart" class="form-control disabled" disabled>
                  <b-spinner small></b-spinner>
                </div>
              </b-input-group>
            </div>
          </b-form-group>
        </b-col>

        <b-col v-if="showCost" xl="1" md="2" cols="4">
          <b-form-group
            label="Margin:"
            :label-class="`col-head line-item part ${partIndex > 0 ? '' : ' first-row'}`"
            :label-for="`op-${opId}-part-${partIndex}-margin`"
          >
            <div v-if="readonly" :id="`op-${opId}-part-${partIndex}-margin`" class="pl-0">
              {{ part.margin | nullValueToNA }}
            </div>
            <div v-else>
              <b-input-group>
                <b-form-input
                  v-show="!part._loadingPart"
                  :id="`op-${opId}-part-${partIndex}-margin`"
                  :value="part.margin"
                  :disabled="true"
                  class="rounded-right"
                />
                <div v-show="part._loadingPart" class="form-control disabled" disabled>
                  <b-spinner small></b-spinner>
                </div>
              </b-input-group>
            </div>
          </b-form-group>
        </b-col>

        <b-col v-if="!$isCustomer && !readonly" xl="1" md="2" cols="4">
          <b-form-group
            label="Qty On Hand:"
            :label-class="`text-nowrap overflow-hidden col-head line-item part ${partIndex > 0 ? '' : ' first-row'}`"
            :label-for="`op-${opId}-part-${partIndex}-qty-on-hand`"
          >
            <b-form-input
              :id="`op-${opId}-part-${partIndex}-qty-on-hand`"
              :value="part.quantityOnHand"
              :disabled="true"
            />
          </b-form-group>
        </b-col>

        <b-col xl="1" md="2" cols="4">
          <b-form-group
            label="Qty:"
            :label-class="`col-head line-item part ${partIndex > 0 ? '' : ' first-row'}`"
            :label-for="`op-${opId}-part-${partIndex}-qty`"
          >
            <div v-if="readonly" :id="`op-${opId}-part-${partIndex}-qty`" class="pl-0">
              {{ part.quantityRequested || NA }}
            </div>
            <div v-else>
              <b-input-group>
                <!-- :disabled="!part._local" -->
                <b-form-input
                  :id="`op-${opId}-part-${partIndex}-qty`"
                  :value="part.quantityRequested"
                  :disabled="part.itemType === ITEM_TYPES.CORE"
                  :class="`rounded ${part._local && part.quantityRequested > part.quantityOnHand ? 'warning' : ''}`"
                  :state="hasError(vPart(partIndex).quantityRequested, 'required') ? false : null"
                  @keypress="NumberFieldHelper.isDecimal($event, true)"
                  @blur="updateQty(partIndex, $event.target.value)"
                />
              </b-input-group>
              <div v-if="hasError(vPart(partIndex).quantityRequested, 'required')" class="error">Enter a quantity.</div>
            </div>
          </b-form-group>
        </b-col>

        <b-col v-if="!readonly" xl="1" md="2" cols="4" class="d-flex">
          <b-form-group
            label="ACTIONS:"
            :label-class="`col-head line-item part invisible ${partIndex > 0 ? '' : 'first-row'}`"
            :label-for="`op-${opId}-part-${partIndex}-actions`"
          >
            <div class="d-block">
              <b-button
                v-if="part._local"
                v-b-tooltip
                size="xsm"
                class="m-1"
                title="Remove"
                @click="removePart(partIndex)"
              >
                <font-awesome-icon icon="trash-alt" />
              </b-button>
              <img
                v-show="formStage == FORM_STAGES.EDIT_SO && checkQtyExceeded(partIndex)"
                :id="`op-${opId}-part-${partIndex}-exceeds-qty-warning`"
                src="/images/warning-triangle.svg"
                height="15px"
                width="auto"
                class="ml-2"
              />
              <b-tooltip :target="`op-${opId}-part-${partIndex}-exceeds-qty-warning`" triggers="hover">
                Exceeds Quantity on hand.
              </b-tooltip>
            </div>
          </b-form-group>
        </b-col>
        <div class="line-wrap-border parts" />
      </b-row>
    </div>
    <b-row v-if="!readonly" no-gutters>
      <b-col offset-xl="9" xl="2" offset="10" cols="2">
        <b-button class="p-0 float-right mt-neg4px" variant="link" size="xsm" @click="addParts()">
          <font-awesome-icon icon="plus" />
          Add Row
        </b-button>
      </b-col>
    </b-row>
    <available-parts-modal ref="AvailablePartsModal"></available-parts-modal>
  </div>
</template>

<script>
// components
import PistonIcon from '@/shared/svg/PistonIcon';
import AvailablePartsModal from '@/shared/components/AvailablePartsModal';
// vuex
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { ServiceOrderActions, ServiceOrderGetters, ServiceOrderMutations } from '@/shared/store/service-order/types';
import { FORM_STAGES, ITEM_TYPES, PART_TYPES } from '@/shared/store/service-order/state';
import { UserGetters } from '@/shared/store/user/types';
// helpers
import { parse } from 'vue-currency-input';
import ErrorService from '@/shared/services/ErrorService';
import vSelect from 'vue-select';
import debounce from 'lodash.debounce';
import NumberFieldHelper from '@/shared/helpers/number-field-helper';
import { hasError } from '@/shared/helpers/validator-helper.js';

export default {
  name: 'ServiceOrderPartSearch',
  components: {
    'piston-icon': PistonIcon,
    'available-parts-modal': AvailablePartsModal,
    vSelect
  },
  inject: {
    validator: {
      from: 'validator',
      default: () => null
    }
  },
  props: {
    jobKey: {
      type: String,
      required: true
    },
    readonly: {
      type: Boolean,
      default: false
    },
    isPackagedPricing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      FORM_STAGES: FORM_STAGES,
      ITEM_TYPES: ITEM_TYPES,
      PART_TYPES: PART_TYPES,
      NumberFieldHelper: NumberFieldHelper,
      partWarrantyAlert: 'Same Part installed on Unit within the last 12 months. Part may be under Warranty',
      currencyOptions: {
        currency: null,
        locale: 'en-US'
      }
    };
  },
  computed: {
    ...mapState('serviceOrder', ['partList']),
    ...mapState('unit', ['unit']),
    ...mapGetters([ServiceOrderGetters.GET_PARTS]),
    ...mapGetters([UserGetters.AUTHORIZE_ROLE]),
    ...mapGetters({
      serviceOrder: ServiceOrderGetters.GET_SERVICE_ORDER,
      jobs: ServiceOrderGetters.GET_JOBS,
      branchId: ServiceOrderGetters.GET_BRANCH_ID,
      formStage: ServiceOrderGetters.GET_FORM_STAGE,
      isEstimate: ServiceOrderGetters.GET_IS_ESTIMATE,
      availableParts: ServiceOrderGetters.GET_AVAILABLE_PARTS
    }),
    opId() {
      return this.jobs[this.jobKey].operationId;
    },
    parts() {
      return this[ServiceOrderGetters.GET_PARTS](this.jobKey);
    },
    partsValidation() {
      return this.validator.jobs.$each[this.jobKey].details.parts.$each.$iter;
    },
    showCost() {
      return !this.$isCustomer && this.isCsrOrCsm;
    },
    isCsrOrCsm() {
      return this[UserGetters.AUTHORIZE_ROLE](['CSR', 'CSM', 'RegionalCSM']);
    }
  },
  methods: {
    ...mapActions([
      ServiceOrderActions.SEARCH_PARTS,
      ServiceOrderActions.SELECT_PART,
      ServiceOrderActions.SET_PART_PRICE
    ]),
    ...mapMutations([
      ServiceOrderMutations.ADD_PART,
      ServiceOrderMutations.REMOVE_PART,
      ServiceOrderMutations.SET_PART_SEARCH,
      ServiceOrderMutations.SET_PART_DESCRIPTION,
      ServiceOrderMutations.SET_PART_QTY
    ]),
    addParts() {
      let missingPart = false;
      for (let index in this.partsValidation) {
        const v = this.partsValidation[index];
        v.partNumber.$touch();
        if (!v.partNumber.required) missingPart = true;
      }
      if (missingPart) {
        const errorMessage = `Error adding a part. Enter valid Part Number before adding another.`;
        ErrorService.createErrorToast(this, errorMessage);
      } else {
        this[ServiceOrderMutations.ADD_PART](this.jobKey);
        this.vPart(this.parts.length - 1).$reset();
        document.activeElement.blur();
        this.$nextTick(() => {
          this.$refs[`op-${this.opId}-part-${this.parts.length - 1}-number`][0].$refs.search.focus();
        });
      }
    },
    checkQtyExceeded(partIndex) {
      let part = this.vPart(partIndex);
      if (!part.quantityRequested.$dirty) {
        return false;
      }
      part = part.$model;
      if (part._local) {
        return part.quantityRequested > part.quantityOnHand;
      } else {
        return part.quantityRequested > part.quantityShip + part.quantityOnHand;
      }
    },
    removePart(index) {
      this[ServiceOrderMutations.REMOVE_PART]({ jobKey: this.jobKey, index: index });
    },
    onPartSearch(search, loading) {
      if (search.length > 0) {
        loading(true);
        this.searchParts(this.branchId, search, loading, this);
      } else {
        loading(false);
      }
    },
    searchParts: debounce(async (branchId, search, loading, vm) => {
      await vm[ServiceOrderActions.SEARCH_PARTS]({ branchId, search });
      loading(false);
    }, 500),
    async selectPart(partIndex, part) {
      await this[ServiceOrderActions.SELECT_PART]({
        jobKey: this.jobKey,
        partIndex,
        part
      });
      this.vPart(partIndex).partNumber.$touch();
      if (this.availableParts.length > 0) {
        this.$refs.AvailablePartsModal.show();
      }
    },
    togglePartSearch(partIndex, value) {
      this[ServiceOrderMutations.SET_PART_SEARCH]({ jobKey: this.jobKey, partIndex, value });
    },
    updateDescription(partIndex, description) {
      this[ServiceOrderMutations.SET_PART_DESCRIPTION]({ jobKey: this.jobKey, partIndex, description });
    },
    updateQty(partIndex, qty) {
      const quantityRequested = qty ? parseFloat(qty) : qty;
      this[ServiceOrderMutations.SET_PART_QTY]({ jobKey: this.jobKey, partIndex, quantityRequested });
      this.vPart(partIndex).quantityRequested.$touch();
    },
    updatePrice(partIndex, partPrice) {
      const price = parse(partPrice, this.currencyOptions);
      this[ServiceOrderActions.SET_PART_PRICE]({ jobKey: this.jobKey, partIndex, price });
    },
    vPart(partIndex) {
      return this.partsValidation[partIndex];
    },
    hasError: hasError
  }
};
</script>
<style>
.parts-tooltip > .tooltip-inner {
  max-width: none;
}
</style>
