import axios from 'axios';

const INVOICE_PATH = '/api/invoice';
const PURCHASE_ORDER_PATH = '/pos';
const SEARCH_PATH = '/search';
const SEARCH_FIRE_PATH = '/search-fire';
const PUT_REASSIGN_INVOICES_PATH = '/reassign';

const GET_PATH = '/get';
const SAVE_PATH = '/save';
const APPROVE_PATH = '/approve';
const REJECT_PATH = '/reject';
const AP_PROCCESORS_ENDING = '/processors';
const APPROVERS_ENDING = '/approvers';
const VENDORS_ENDING = '/vendors';
const ACCOUNTS_ENDING = '/accounts';
const REQUEST_PATH = '/request';
const SEND_PATH = '/send';
const VOUCHER_PATH = '/voucher';
const VALIDATE_PATH = '/validate';
const APP_E_URL_PATH = '/appEUrl';
const DOWNLOAD_PATH = '/download';
const UI_CONFIG_PATH = '/uiConfig';
const JURISDICTIONS_ENDING = '/jurisdictions';
const DATA_SYNC_PATH = '/data-sync';
const INVOICES_COUNT_PATH = '/invoices-count';

class InvoiceService {
  async getFireInvoiceUiConfig() {
    const url = `${INVOICE_PATH}${UI_CONFIG_PATH}`;
    const config = await axios.get(url);
    return config;
  }
  async searchInvoices(parameters) {
    if (parameters.category === 'history' || parameters.category === 'review' || parameters.category == 'reassign') {
      const url = `${INVOICE_PATH}${SEARCH_PATH}/${encodeURIComponent(parameters.category)}`;
      const invoices = await axios.get(url, {
        params: parameters
      });
      return invoices;
    } else {
      const url = `${INVOICE_PATH}${SEARCH_PATH}/${encodeURIComponent(parameters.category)}`;
      const invoices = await axios.get(url);
      return invoices;
    }
  }
  async searchFireInvoices(parameters) {
    if (parameters.category === 'history' || parameters.category === 'review' || parameters.category == 'reassign') {
      const url = `${INVOICE_PATH}${SEARCH_FIRE_PATH}/${encodeURIComponent(parameters.category)}`;
      const invoices = await axios.get(url, {
        params: parameters
      });
      return invoices;
    } else {
      const url = `${INVOICE_PATH}${SEARCH_FIRE_PATH}/${encodeURIComponent(parameters.category)}`;
      const invoices = await axios.get(url);
      return invoices;
    }
  }
  async putReassignInvoices(param) {
    const url = `${INVOICE_PATH}${PUT_REASSIGN_INVOICES_PATH}/`;
    const response = await axios.put(url, param);
    return response;
  }
  async fetchInvoice(parameters) {
    const url = `${INVOICE_PATH}/${encodeURIComponent(parameters.division)}/${encodeURIComponent(
      parameters.category
    )}/${encodeURIComponent(parameters.docId)}`;
    const invoice = await axios.get(url);
    return invoice;
  }
  async fetchAPProcessors() {
    const url = `${INVOICE_PATH}${GET_PATH}${AP_PROCCESORS_ENDING}`;
    const processors = await axios.get(url);
    return processors;
  }
  async fetchDivisionAPProcessors(param) {
    const url = `${INVOICE_PATH}${GET_PATH}/${param}${AP_PROCCESORS_ENDING}`;
    const processors = await axios.get(url);
    return processors;
  }
  async fetchApprovers() {
    const url = `${INVOICE_PATH}${GET_PATH}${APPROVERS_ENDING}`;
    const processors = await axios.get(url);
    return processors;
  }
  async fetchDivisionApprovers(param) {
    const url = `${INVOICE_PATH}${GET_PATH}/${param}${APPROVERS_ENDING}`;
    const processors = await axios.get(url);
    return processors;
  }
  async fetchTaxJurisdictions(params) {
    const url = `${INVOICE_PATH}${GET_PATH}/${params.division}${JURISDICTIONS_ENDING}`;
    const processors = await axios.get(url);
    return processors;
  }
  async searchApprovers(params) {
    const url = `${INVOICE_PATH}${GET_PATH}/${params.division}/search-approvers`;
    const approversList = await axios.get(url, { params });
    return approversList;
  }
  async searchVendors(params) {
    const url = `${INVOICE_PATH}${GET_PATH}/${params.division}${VENDORS_ENDING}`;
    const vendorsList = await axios.get(url, { params });
    return vendorsList;
  }
  async searchPOs(params) {
    const url = `${INVOICE_PATH}${GET_PATH}${PURCHASE_ORDER_PATH}/${encodeURIComponent(params.vendorId)}`;
    const POs = await axios.get(url);
    return POs;
  }
  async searchFirePOs(params) {
    const url = `${INVOICE_PATH}${GET_PATH}${PURCHASE_ORDER_PATH}/${encodeURIComponent(
      params.division
    )}/${encodeURIComponent(params.vendorId)}`;
    const POs = await axios.get(url);
    return POs;
  }
  async searchAccounts(params) {
    const url = `${INVOICE_PATH}${GET_PATH}/${params.division}${ACCOUNTS_ENDING}`;
    const accountsList = await axios.get(url, { params });
    return accountsList;
  }
  async saveInvoice(parameters) {
    const url = `${INVOICE_PATH}${SAVE_PATH}`;
    await axios.put(url, parameters);
  }
  async approveInvoice(parameters) {
    const url = `${INVOICE_PATH}${APPROVE_PATH}`;
    await axios.put(url, parameters);
  }
  async rejectInvoice(parameters) {
    const url = `${INVOICE_PATH}${REJECT_PATH}`;
    await axios.put(url, parameters);
  }
  async requestInvoiceApproval(parameters) {
    const url = `${INVOICE_PATH}${REQUEST_PATH}`;
    await axios.put(url, parameters);
  }
  async sendInvoice(parameters) {
    const url = `${INVOICE_PATH}${SEND_PATH}`;
    await axios.put(url, parameters);
  }
  async voucherInvoice(parameters) {
    const url = `${INVOICE_PATH}${VOUCHER_PATH}`;
    await axios.put(url, parameters);
  }
  async validateFullInvoice(parameters) {
    const url = `${INVOICE_PATH}${VALIDATE_PATH}/full`;
    await axios.put(url, parameters);
  }
  async validateHeaderInvoice(parameters) {
    const url = `${INVOICE_PATH}${VALIDATE_PATH}/header`;
    await axios.put(url, parameters);
  }
  async fetchAppEUrl(params) {
    const url = `${INVOICE_PATH}${GET_PATH}${APP_E_URL_PATH}/${encodeURIComponent(params)}`;
    const POs = await axios.get(url);
    return POs;
  }
  async fetchDownload(parameters) {
    const url = `${INVOICE_PATH}${GET_PATH}${DOWNLOAD_PATH}/${encodeURIComponent(
      parameters.division
    )}/${encodeURIComponent(parameters.objectId)}`;
    return await axios.get(url, {
      responseType: 'blob'
    });
  }
  async fetchDataSync() {
    const url = `${INVOICE_PATH}${GET_PATH}${DATA_SYNC_PATH}`;
    return await axios.get(url);
  }
  async runDataSync() {
    const url = `${INVOICE_PATH}${SEND_PATH}${DATA_SYNC_PATH}`;
    return await axios.put(url);
  }
  async getInvoicesCount(parameters) {
    const url = `${INVOICE_PATH}${INVOICES_COUNT_PATH}/${encodeURIComponent(parameters)}`;
    const invoicesCount = await axios.get(url);
    return invoicesCount;
  }
}

export default new InvoiceService(); // singleton object
