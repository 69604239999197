export const namespace = 'config/';

export const ConfigGetters = {
  GET_CONFIG: namespace + 'getConfig'
};

export const ConfigActions = {
  SET_VAMS_SYSTEM_UP: namespace + 'setVamsSystemUp',
  SET_FIRE_SYSTEM_UP: namespace + 'setFireSystemUp'
};

export const ConfigMutations = {
  SET_CONFIG: namespace + 'setConfig',
  SET_VAMS_SYSTEM_UP: namespace + 'setVamsSystemUp',
  SET_FIRE_SYSTEM_UP: namespace + 'setFireSystemUp'
};
