export const getDefaultState = () => {
  const mountedEquipmentPrototype = {
    unitId: null,
    mountedEquipmentTypeId: null,
    mountedEquipmentType: null,
    mountedEquipmentContractStatusId: null,
    equipmentMake: null,
    equipmentModel: null,
    equipmentYear: null,
    equipmentMonth: null,
    equipmentSerialNumber: null,
    equipmentCapacity: null
  };

  return {
    mountedEquipments: [],
    mountedEquipment: {
      ...mountedEquipmentPrototype
    },
    dbMountedEquipment: {
      ...mountedEquipmentPrototype
    }
  };
};

export default {
  ...getDefaultState()
};
