import axios from 'axios';
const USER_PROFILE_PATH = '/api/credentials/profile';
const FIRE_USER_PROFILE_PATH = '/api/credentials/fire-profile';

const LOGIN_PATH = '/api/credentials/login';
const LOGIN_FIRE_PATH = '/api/credentials/login-fire';
const LOGOUT_PATH = '/api/credentials/logout';

class UserService {
  async getUserProfile() {
    let userProfile = await axios.get(USER_PROFILE_PATH);
    return userProfile;
  }
  async getFireUserProfile() {
    let userProfile = await axios.get(FIRE_USER_PROFILE_PATH);
    return userProfile;
  }
  async login(userCredentials) {
    let userProfile = await axios.post(LOGIN_PATH, userCredentials);
    return userProfile;
  }

  async loginFire(userCredentials) {
    let userProfile = await axios.post(LOGIN_FIRE_PATH, userCredentials);
    return userProfile;
  }

  async logout() {
    return await axios.post(LOGOUT_PATH);
  }

  allow401ErrorsForUrl(url) {
    const whitelist = [USER_PROFILE_PATH, LOGIN_PATH];
    return whitelist.includes(url);
  }
}

export default new UserService(); // singleton object
