<template>
  <b-form class="py-2">
    <div class="section-title">Operations</div>

    <!-- Standard Operations -->
    <div v-if="unitStandardOperations.length > 0">
      <vams-collapse title="Scheduled Operations" :visible="visible">
        <b-table
          class="border"
          :items="Object.values($v.unitStandardOperations.$each.$iter)"
          :fields="fields"
          table-variant="blue"
          thead-tr-class="text-small"
          tbody-tr-class="bg-half-alpha"
        >
          <!-- Select Column -->
          <template #head(selected)>
            <div v-show="Object.keys(assignedJobs).length == 0" class="text-center">
              <input v-model="selectAll" type="checkbox" />
            </div>
          </template>
          <template #cell(selected)="{ item }">
            <div class="text-center">
              <input v-show="!item.$model.isAssigned" v-model="selectedStdOps" type="checkbox" :value="item.$model" />
              <font-awesome-icon v-show="item.$model.isAssigned" icon="check" class="text-success" />
            </div>
          </template>

          <!-- Excede Job Dropdown Column -->
          <template #cell(excedeJobId)="{ item }">
            <b-input-group v-if="!item.$model.isAssigned">
              <v-select
                v-model="item.$model.excedeJob"
                :filter-by="excedeJobDropdownSearchFilter"
                placeholder="Select"
                :class="item.excedeJob.$error ? 'is-invalid' : ''"
                :options="item.$model.excedeJobOptions"
                :clearable="false"
                label="excedeJobId"
                select-on-tab
              >
                <template #option="excedeJob">
                  <span class="text-uppercase">{{ excedeJob.excedeJobId }} - {{ excedeJob.description }}</span>
                </template>
                <template #selected-option="selectedExcedeJob">
                  <span class="text-uppercase">
                    {{ selectedExcedeJob.excedeJobId }} - {{ selectedExcedeJob.description }}
                  </span>
                </template>
              </v-select>
              <span :style="{ visibility: item.$model.isSelected ? 'visible' : 'hidden' }" class="required-asterisk">
                *
              </span>
            </b-input-group>
            <div v-if="item.$model.isAssigned">
              {{ item.$model.excedeJob.excedeJobId }} - {{ item.$model.excedeJob.description }}
            </div>
          </template>

          <!-- Current Column -->
          <template #cell(current)>
            <div class="text-left d-flex flex-column">
              <div>{{ unit.mileage | number }} Miles</div>
              <div>{{ unit.engineHours | number }} Hours</div>
            </div>
          </template>

          <!-- Last Completed Column -->
          <template #cell(lastCompleted)="{ item }">
            <div class="text-left d-flex flex-column">
              <div v-if="item.$model.lastCompletedDate">{{ item.$model.lastCompletedDate | date }}</div>
              <div v-if="item.$model.lastCompletedMileage">{{ item.$model.lastCompletedMileage | number }} Miles</div>
              <div v-if="item.$model.lastCompletedEngineHours">
                {{ item.$model.lastCompletedEngineHours | number }} Hours
              </div>
            </div>
          </template>

          <!-- Next Due Column -->
          <template #cell(nextDue)="{ item }">
            <div class="text-left d-flex flex-column">
              <div v-if="item.$model.durationDue && item.$model.lastCompletedDate">
                {{ getNextDueDate(item.$model) | date }}
              </div>
              <div v-if="item.$model.mileageDue && item.$model.lastCompletedMileage">
                {{ getNextDueMileage(item.$model) | number }} Miles
              </div>
              <div v-if="item.$model.engineHoursDue && item.$model.lastCompletedEngineHours">
                {{ getNextDueEngineHours(item.$model) | number }} Hours
              </div>
            </div>
          </template>

          <!-- Operation Status Column -->
          <template #[`cell($model.operationStatus)`]="{ value }">
            <b-badge v-show="value" :variant="CssHelper.getOperationStatusCssClass(value)" class="mr-1">
              {{ value }}
            </b-badge>
          </template>
        </b-table>
      </vams-collapse>
    </div>

    <!-- Deferred Jobs -->
    <div v-if="formStage >= FORM_STAGES.EDIT_SO && hasDeferredJobs" class="pb-3">
      <service-order-add-deferred-jobs :visible="visible"></service-order-add-deferred-jobs>
    </div>

    <b-row no-gutters class="pt-3" cols-lg="3">
      <b-col>
        <v-select
          v-model="selectedNonScheduledOp"
          label="description"
          placeholder="Select Operation"
          :options="nonScheduledOperationsList"
          :clearable="true"
          :filterable="true"
          :filter-by="operationDropdownSearchFilter"
        >
          <template #selected-option="operation">
            <span class="text-uppercase">{{ operation.id }} - {{ operation.description }}</span>
          </template>
          <template #option="operation">
            <span class="text-uppercase">{{ operation.id }} - {{ operation.description }}</span>
          </template>
        </v-select>
      </b-col>
      <div class="ml-3">
        <b-button variant="primary" :disabled="formStage !== FORM_STAGES.EDIT_SO" @click="assignJobs">
          <span v-show="formStage !== FORM_STAGES.ADDING_JOBS">Add To {{ estimorderDisplayText }}</span>
          <div v-show="formStage === FORM_STAGES.ADDING_JOBS">
            <b-spinner small></b-spinner>
            Adding...
          </div>
        </b-button>
      </div>
    </b-row>
  </b-form>
</template>

<script>
// Components
import ServiceOrderAddDeferredJobs from './ServiceOrderAddDeferredJobsComponent';
import vSelect from 'vue-select';
import CollapseComponent from '@/shared/components/ui/CollapseComponent';
// Vuex
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { FORM_STAGES } from '@/shared/store/service-order/state';
import { LookupGetters } from '@/shared/store/lookup/types';
import { UnitGetters } from '@/shared/store/unit/types';
import { DeferredJobActions, DeferredJobGetters, DeferredJobMutations } from '@/shared/store/deferred-job/types';
import { ServiceOrderActions, ServiceOrderGetters, ServiceOrderMutations } from '@/shared/store/service-order/types';

// Helpers
import { helpers } from 'vuelidate/lib/validators';
import Cloner from '@/shared/helpers/cloner';
import CssHelper from '@/shared/helpers/operation-status-css-class-helper';
import { generateThreeCs } from '@/shared/helpers/service-order-helper';
import NextDueMixin from '@/shared/mixins/NextDueMixin';
import ErrorService from '@/shared/services/ErrorService';

export default {
  name: 'ServiceOrderAddOperations',
  components: {
    vSelect,
    'service-order-add-deferred-jobs': ServiceOrderAddDeferredJobs,
    'vams-collapse': CollapseComponent
  },
  mixins: [NextDueMixin],
  props: {
    preselectDueOperations: {
      type: Boolean,
      default: false
    },
    preselectedStandardOperationId: {
      type: Number,
      required: false,
      default: null
    },
    visible: Boolean()
  },
  data: function () {
    return {
      FORM_STAGES: FORM_STAGES,
      fields: [
        { key: 'selected', label: '', sortable: false, tdClass: 'align-middle' },
        { key: '$model.name', label: 'Scheduled Operation', sortable: true, tdClass: 'align-middle' },
        { key: 'excedeJobId', label: 'Excede Job', sortable: false, tdClass: 'align-middle' },
        { key: 'current', label: 'Current', sortable: false, tdClass: 'align-middle' },
        { key: 'lastCompleted', label: 'Last Completed', sortable: false, tdClass: 'align-middle' },
        { key: 'nextDue', label: 'Next Due', sortable: false, tdClass: 'align-middle' },
        { key: '$model.operationStatus', label: 'Operation Status', sortable: true, tdClass: 'align-middle' }
      ],
      selectedStdOps: [],
      selectedNonScheduledOp: null
    };
  },
  validations: {
    unitStandardOperations: {
      $each: {
        excedeJob: {
          requiredIfSelected
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      unit: UnitGetters.GET_UNIT,
      unitStdOps: UnitGetters.GET_UNIT_STANDARD_OPERATIONS,
      nonScheduledOperationsList: LookupGetters.GET_NON_SCHEDULED_OPERATIONS_LIST,
      formStage: ServiceOrderGetters.GET_FORM_STAGE,
      deferredJobs: DeferredJobGetters.GET_DEFERRED_JOBS,
      selectedDeferredJobs: DeferredJobGetters.GET_SELECTED_DEFERRED_JOBS,
      assignedJobs: ServiceOrderGetters.GET_JOBS,
      isEstimate: ServiceOrderGetters.GET_IS_ESTIMATE,
      estimorderDisplayText: ServiceOrderGetters.GET_ESTIMORDER_DISPLAY_TEXT,
      serviceOrderId: ServiceOrderGetters.GET_SERVICE_ORDER_ID,
      branchId: ServiceOrderGetters.GET_BRANCH_ID
    }),
    hasDeferredJobs() {
      return this.deferredJobs.length > 0;
    },
    selectAll: {
      get() {
        return this.selectedStdOps.length == this.unitStandardOperations.length;
      },
      set(value) {
        this.selectedStdOps = [];
        if (value) {
          this.unitStandardOperations.forEach(item => {
            this.selectedStdOps.push(item);
          });
        }
      }
    },
    unitStandardOperations() {
      return Cloner.deepClone(this.unitStdOps);
    }
  },
  watch: {
    assignedJobs: {
      handler() {
        this.selectedStdOps = [];
        this.unitStandardOperations.map(operation => {
          const isAssigned = operation.excedeJob ? this.isAssigned(operation.excedeJob) : false;
          this.$set(operation, 'isAssigned', isAssigned);
          return operation;
        });
      },
      immediate: true
    },
    selectedStdOps() {
      this.unitStandardOperations.map(operation => {
        const isSelected = this.isSelected(operation);
        this.$set(operation, 'isSelected', isSelected);
        return operation;
      });
    }
  },
  async created() {
    this.CssHelper = CssHelper;

    this.prepStandardOperations();
    await this[DeferredJobActions.FETCH_DEFERRED_JOBS]({
      unitId: this.$route.params.unitId,
      params: { serviceOrderId: this.serviceOrderId }
    });
  },
  methods: {
    ...mapActions([
      ServiceOrderActions.ADD_JOBS_TO_SERVICE_ORDER,
      ServiceOrderActions.SWITCH_STAGE,
      DeferredJobActions.FETCH_DEFERRED_JOBS
    ]),
    ...mapMutations([DeferredJobMutations.SET_SELECTED_DEFERRED_JOBS, ServiceOrderMutations.SET_OLD_JOBS]),
    async switchStage(newStage) {
      return await this[ServiceOrderActions.SWITCH_STAGE]({ newStage, that: this });
    },
    prepStandardOperations() {
      if (this.preselectDueOperations) {
        for (const operation of this.unitStandardOperations) {
          // Set selection if Due or Overdue
          if (
            operation.operationStatus === 'Due' ||
            operation.operationStatus === 'Overdue' ||
            operation.standardOperationId === this.preselectedStandardOperationId
          ) {
            this.selectedStdOps.push(operation);
          }
        }
      }
    },
    isSelected(standardOperation) {
      return (
        this.selectedStdOps.findIndex(
          operation => operation.standardOperationId == standardOperation.standardOperationId
        ) !== -1
      );
    },
    isAssigned(standardOperation) {
      const jobIds = Object.values(this.assignedJobs).map(job => job.jobId);
      return jobIds.includes(standardOperation.excedeJobId);
    },
    async assignJobs() {
      if (this.selectedStdOps.length == 0 && !this.selectedNonScheduledOp && this.selectedDeferredJobs.length == 0) {
        ErrorService.createErrorToast(
          this,
          `Error adding to a ${this.estimorderDisplayText}. Select an Operation to add to the ${this.estimorderDisplayText}.`
        );
        return;
      }

      if (this.formStage !== FORM_STAGES.EDIT_SO) return;
      const switched = await this.switchStage(FORM_STAGES.ADDING_JOBS);
      if (!switched) return;

      const jobList = [];
      if (this.selectedStdOps.length > 0) {
        jobList.push(
          ...this.selectedStdOps.map(operation => {
            return {
              jobId: operation.excedeJob.excedeJobId,
              ...generateThreeCs(operation)
            };
          })
        );
      }

      if (this.selectedNonScheduledOp) {
        jobList.push({
          jobId: this.selectedNonScheduledOp.id,
          complaint: this.selectedNonScheduledOp.description
        });
      }
      this.selectedNonScheduledOp = null;

      if (this.selectedDeferredJobs.length > 0) {
        jobList.push(
          ...this.selectedDeferredJobs.map(operation => {
            return {
              jobId: operation.jobId,
              complaint: operation.complaint,
              cause: operation.cause,
              correction: operation.correction,
              wasDeferred: true,
              deferredJobs: [{ jobSlsId: operation.jobSlsId, jobOpsId: operation.jobOpsId }]
            };
          })
        );
        await this[DeferredJobMutations.SET_SELECTED_DEFERRED_JOBS]([]);
      }

      try {
        await this[ServiceOrderActions.ADD_JOBS_TO_SERVICE_ORDER]({
          serviceOrderId: this.serviceOrderId,
          isEstimate: this.isEstimate,
          jobs: jobList,
          branchId: this.branchId,
          unitId: this.unit.unitId
        });
      } catch (err) {
        const errorMessage = `Error adding jobs to ${this.estimorderDisplayText}.`;
        ErrorService.createErrorToast(this, errorMessage);
        throw Error(errorMessage);
      } finally {
        await this[DeferredJobActions.FETCH_DEFERRED_JOBS]({
          unitId: this.$route.params.unitId,
          params: { serviceOrderId: this.serviceOrderId }
        });
        await this.switchStage(FORM_STAGES.EDIT_SO);
      }
    },
    dropdownSearchFilter(id, description, search) {
      search = search.toLowerCase();
      id = id.toLowerCase();
      description = description.toLowerCase();

      return id.indexOf(search) > -1 || description.indexOf(search) > -1;
    },
    excedeJobDropdownSearchFilter(option, label, search) {
      return this.dropdownSearchFilter(option.excedeJobId, option.description, search);
    },
    operationDropdownSearchFilter(option, label, search) {
      return this.dropdownSearchFilter(option.id, option.description, search);
    }
  }
};

function requiredIfSelected(value, parentVm) {
  return parentVm.isSelected ? helpers.req(value) : true;
}
</script>
