<template>
  <div class="part-rows">
    <b-row v-if="index == 0" class="header-row d-flex no-gutters">
      <b-col class="header-cell" :class="{ 'col-2': isPage, 'col-3': !isPage }">Part No.</b-col>
      <b-col class="header-cell" :class="{ 'col-6': isModal, 'col-2': isPage, 'col-3': !isPage }">
        Description
        <span v-if="!isPage">*</span>
      </b-col>
      <b-col v-if="!isPage" class="header-cell" :class="{ 'col-1': !isPage }">OEM</b-col>
      <b-col v-if="!isPage && !isModal" class="header-equip" :class="{ 'col-1': !isPage }">Mount.</b-col>
      <b-col v-if="!isModal && !isPage" class="header-cell col-2">
        Job No.
        <span v-if="!isPage">*</span>
      </b-col>
      <b-col v-if="isPage" class="header-cell col-1">
        Job # | Sys
        <span v-if="!isPage">*</span>
      </b-col>
      <b-col class="header-cell col-1">
        Qty
        <span v-if="!isPage">*</span>
      </b-col>
      <b-col v-if="!isModal && isPage" class="header-cell col-1">Cost</b-col>
      <b-col v-if="!isModal && isPage" class="header-cell col-1">Freight</b-col>
      <b-col v-if="!isModal && isPage" class="header-cell col-2">Notes</b-col>
      <b-col v-if="!isModal && isPage" class="header-cell col-1">ETA</b-col>
      <b-col class="header-cell col-1"></b-col>
    </b-row>
    <b-row
      class="d-flex no-gutters align-items-stretch"
      :class="{ 'part-row-even': index % 2, 'part-row-odd': !(index % 2) }"
    >
      <b-col class="part-cell" :class="{ 'col-2': isPage, 'col-3': !isPage, 'part-cell-is-page': isPage }">
        <b-input-group v-if="!isPage">
          <v-select
            :id="partNumber"
            ref="partNumber"
            label="partNumber"
            :v-model="partNumber"
            :options="partList"
            :clearable="false"
            :filterable="false"
            style="max-width: 100%"
            @search="onPartSearch"
            @input="selectPart($event)"
          >
            <template #option="partOption">
              <span class="text-uppercase">
                {{ partOption.sourceId }}{{ partOption.vendorPartId }} - {{ partOption.description }}
              </span>
              <span v-if="partOption.isTapePart" class="text-uppercase font-weight-bold">&nbsp;- (Tape)</span>
              <span v-else class="text-muted">&nbsp;- (On Hand: {{ partOption.quantityOnHand }})</span>
            </template>
            <template #selected-option="partOption">
              <span v-if="partNumber == null" class="text-uppercase">
                {{ partOption.sourceId }}{{ partOption.vendorPartId }} - {{ partOption.description }}
              </span>
              <span v-else>
                {{ partNumber }}
              </span>
            </template>
            <template #no-options>No Parts Found</template>
          </v-select>
        </b-input-group>
        <b-input v-else id="partNumber" :value="partNumber" class="rounded" @input="updateNumber($event)" />
      </b-col>
      <b-col
        class="part-cell"
        :class="{ 'col-6': isModal, 'col-2': isPage, 'col-3': !isPage, 'part-cell-is-page': isPage }"
      >
        <b-textarea
          id="partDescription"
          :class="`${$v.partDescription.$error && !$v.partDescription.required ? 'is-invalid' : ''}`"
          :value="partDescription"
          class="rounded"
          @input="updateDescription($event)"
        />
        <img v-if="isPage && isMounted" src="/images/is-mounted.svg" alt="Mounted Equipment" class="ml-1" />
        <img v-if="isPage && isOEM" src="/images/is-oem.svg" alt="Original Equipment Manufacturer" class="ml-1" />
      </b-col>
      <b-col v-if="!isPage" class="part-cell-checkbox part-cell-checkbox" :class="{ 'col-1': !isPage }">
        <input v-model="isOEM" :value="checked" :unchecked-value="unchecked" type="checkbox" />
      </b-col>
      <b-col v-if="!isPage && !isModal" class="part-cell-checkbox part-cell-checkbox" :class="{ 'col-1': !isPage }">
        <input v-model="isMounted" :value="checked" :unchecked-value="unchecked" type="checkbox" />
      </b-col>
      <b-col
        v-if="!isModal"
        class="part-cell"
        :class="{ 'col-1': isPage, 'col-2': !isPage, 'part-cell-is-page': isPage }"
      >
        <v-select
          v-if="!isPage"
          id="opsId"
          v-model="$v.opsId.$model"
          :options="opsIdList"
          :clearable="false"
          :class="`${$v.opsId.$error && !$v.opsId.required ? 'is-invalid' : ''}`"
        ></v-select>
        <div v-else style="display: flex">
          <b-input id="opdId" v-model="$v.opsId.$model" class="rounded-left col-6"></b-input>
          <b-input id="systemCode" v-model="systemCode" class="rounded-right col-6" maxlength="3"></b-input>
        </div>
      </b-col>
      <b-col
        class="part-cell col-1"
        :class="{
          'part-cell-is-page': isPage
        }"
      >
        <b-input
          id="qty"
          v-model="$v.qty.$model"
          class="rounded"
          :class="`${$v.qty.$error && !$v.qty.required ? 'is-invalid' : ''}`"
        ></b-input>
      </b-col>
      <b-col v-if="isPage" class="part-cell part-cell-is-page col-1">
        <b-input-group>
          <template #prepend>
            <b-input-group-text class="" style="padding: 0.1rem">$</b-input-group-text>
          </template>
          <b-form-input id="cost" v-model="cost" v-currency="currencyOptions" class="rounded-right"></b-form-input>
        </b-input-group>
      </b-col>
      <b-col v-if="isPage" class="part-cell part-cell-is-page col-1">
        <b-input-group>
          <template #prepend>
            <b-input-group-text class="" style="padding: 0.1rem">$</b-input-group-text>
          </template>
          <b-form-input
            id="freight"
            v-model="freight"
            v-currency="currencyOptions"
            class="rounded-right"
          ></b-form-input>
        </b-input-group>
      </b-col>
      <b-col v-if="isPage" class="part-cell part-cell-is-page col-2">
        <b-input id="notes" v-model="notes" class="rounded" maxlength="50"></b-input>
      </b-col>
      <b-col v-if="isPage" class="part-cell part-cell-is-page col-1">
        <date-time-input
          id="eta"
          v-model="eta"
          :validator="$v.eta"
          :disabled-dates="disabledDates"
          hide-time
          label=""
          style="margin-top: 4px"
        >
          <template #default>
            <div v-show="$v.$dirty && $v.eta.$error" class="error">
              Date must be formatted as MM/DD/YYYY and on or after {{ today.toLocaleDateString() }}
            </div>
          </template>
        </date-time-input>
      </b-col>
      <b-col class="part-cell-end col-1" :class="{ 'part-cell-is-page': isPage }">
        <b-button :disabled="disableRemove" @click="removePart()">
          <font-awesome-icon icon="trash-alt" />
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { PartsRequestGetters, PartsRequestMutations } from '@/shared/store/parts-request/types';
import { ServiceOrderGetters } from '@/shared/store/service-order/types';

import debounce from 'lodash.debounce';
import { ServiceOrderActions, ServiceOrderMutations } from '@/shared/store/service-order/types';
import ErrorService from '@/shared/services/ErrorService';

import { requiredIf, minValue } from 'vuelidate/lib/validators';
import { mapGetters, mapState, mapMutations, mapActions } from 'vuex';
import DateTimeInput from '@/shared/components/ui/DateTimeInput';
import vSelect from 'vue-select';
import moment from 'moment';
import partsRequest from '../../../store/parts-request';

export default {
  name: 'RequestedPart',
  components: {
    'date-time-input': DateTimeInput,
    vSelect
  },
  props: {
    index: { type: Number, required: true },
    part: {
      type: Object,
      default: () => {}
    },
    opsIdList: {
      type: Array,
      default: () => null
    },
    isModal: {
      type: Boolean,
      default: () => false
    },
    isPage: {
      type: Boolean,
      default: () => false
    },
    branchId: {
      type: String,
      default: () => null
    }
  },
  data() {
    return {
      today: new Date(),
      currencyOptions: {
        currency: null,
        locale: 'en-US',
        valueRange: { min: -99999999999.99, max: 99999999999.99 }
      }
    };
  },
  validations: {
    partDescription: {
      required: requiredIf(function () {
        return !this.isPage;
      })
    },
    opsId: {
      required: requiredIf(function () {
        return !this.isModal && !this.isPage;
      })
    },
    qty: {
      required: requiredIf(function () {
        return !this.isPage;
      })
    },
    eta: {
      minValue: minValue(new Date())
    }
  },
  computed: {
    ...mapGetters({
      partsRequest: PartsRequestGetters.GET_REQUEST_PARTS_REQUEST,
      requestParts: PartsRequestGetters.GET_REQUEST_PARTS,
      jobs: ServiceOrderGetters.GET_JOBS
    }),
    ...mapState('serviceOrder', ['partList']),
    partDescription: {
      get() {
        return this.part?.partDescription;
      },
      set(value) {
        this[PartsRequestMutations.SET_PART_PROP]({ index: this.index, key: 'partDescription', value: value });
      }
    },
    partNumber: {
      get() {
        return this.part?.partNumber;
      },
      set(value) {
        this[PartsRequestMutations.SET_PART_PROP]({ index: this.index, key: 'partNumber', value: value });
      }
    },
    isOEM: {
      get() {
        return this.part.isOEM;
      },
      set(value) {
        this[PartsRequestMutations.SET_PART_PROP]({ index: this.index, key: 'isOEM', value: value });
      }
    },
    isMounted: {
      get() {
        return this.part.isMounted;
      },
      set(value) {
        this[PartsRequestMutations.SET_PART_PROP]({ index: this.index, key: 'isMounted', value: value });
      }
    },
    opsId: {
      get() {
        return this.part?.opsId;
      },
      set(value) {
        this[PartsRequestMutations.SET_PART_PROP]({ index: this.index, key: 'opsId', value: value });
        if (this.jobs != null) {
          var vmrsCode = Object.values(this.jobs).find(x => x.operationId == value).vmrsCode;
          this[PartsRequestMutations.SET_PART_PROP]({
            index: this.index,
            key: 'systemCode',
            value: vmrsCode.systemCode ?? null
          });
        }
      }
    },
    systemCode: {
      get() {
        return this.part?.systemCode;
      },
      set(value) {
        this[PartsRequestMutations.SET_PART_PROP]({ index: this.index, key: 'systemCode', value: value });
      }
    },
    qty: {
      get() {
        return this.part?.qty;
      },
      set(value) {
        this[PartsRequestMutations.SET_PART_PROP]({ index: this.index, key: 'qty', value: value });
      }
    },
    cost: {
      get() {
        return this.part?.cost;
      },
      set(value) {
        if (value == '') {
          value = null;
        }
        this[PartsRequestMutations.SET_PART_PROP]({ index: this.index, key: 'cost', value: value });
      }
    },
    freight: {
      get() {
        return this.part?.freight;
      },
      set(value) {
        if (value == '') {
          value = null;
        }
        this[PartsRequestMutations.SET_PART_PROP]({ index: this.index, key: 'freight', value: value });
      }
    },
    notes: {
      get() {
        return this.part?.notes;
      },
      set(value) {
        this[PartsRequestMutations.SET_PART_PROP]({ index: this.index, key: 'notes', value: value });
      }
    },
    eta: {
      get() {
        return this.part?.eta;
      },
      set(value) {
        this[PartsRequestMutations.SET_PART_PROP]({ index: this.index, key: 'eta', value: value });
      }
    },
    checked() {
      return (partsRequest.isMounted = true);
    },
    unchecked() {
      return (partsRequest.isMounted = false);
    },
    disableRemove() {
      return this.requestParts.length === 1;
    },
    disabledDates() {
      return { to: this.minimumDate };
    },
    minimumDate() {
      return moment(this.dateCreate).startOf('day').toDate();
    }
  },
  methods: {
    ...mapMutations([
      PartsRequestMutations.REMOVE_REQUEST_PART,
      PartsRequestMutations.SET_PART_PROP,
      ServiceOrderMutations.SET_PART_DESCRIPTION
    ]),
    ...mapActions([ServiceOrderActions.SEARCH_PARTS, ServiceOrderActions.SELECT_PART]),
    errorCheck() {
      this.$v.$touch();
      return this.$v.$anyError;
    },
    errorReset() {
      this.$v.$reset();
    },
    removePart() {
      this[PartsRequestMutations.REMOVE_REQUEST_PART](this.index);
    },
    onPartSearch(search, loading) {
      if (search.length > 0) {
        if (this.branchId != null) {
          loading(true);
          this.searchParts(this.branchId, search, loading, this);
        } else {
          ErrorService.createErrorToast(this, 'Please select a branch before performing a part search.');
        }
      } else {
        loading(false);
      }
    },
    searchParts: debounce(async (branchId, search, loading, vm) => {
      await vm[ServiceOrderActions.SEARCH_PARTS]({ branchId, search });
      loading(false);
    }, 500),
    async selectPart(part) {
      this.partNumber = part.partNumber;
      this.partDescription = part.description;
      this[PartsRequestMutations.SET_PART_PROP]({ index: this.index, key: 'vendorPartId', value: part.vendorPartId });
      this[PartsRequestMutations.SET_PART_PROP]({ index: this.index, key: 'sourceId', value: part.sourceId });
      this[PartsRequestMutations.SET_PART_PROP]({ index: this.index, key: 'partType', value: part.partType });
    },
    updateDescription(description) {
      this.partDescription = description;
    },
    updateNumber(number) {
      this.partNumber = number;
    },
    setPartNumberFocus() {
      this.$refs.partNumber.$refs.search.focus();
    }
  }
};
</script>

<style scoped>
.part-cell-checkbox {
  align-items: center;
  display: flex;
  justify-content: center;
  zoom: 1.5;
}
.header-row {
  text-align: left !important;
  background-color: white;
  border: 1px solid #cccccc;
  color: #999999;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 0.8rem;
}
.header-cell {
  border-right: 1px solid #cccccc;
  padding-bottom: 0.5%;
  padding-top: 0.5%;
  padding-left: 1.5%;
}
.header-equip {
  border-right: 1px solid #cccccc;
  padding-bottom: 0.5%;
  padding-top: 0.5%;
  padding-left: 0.5%;
  font-weight: 500;
  font-size: 0.8rem;
}
.part-rows {
  text-align: center;
}
.part-row-even {
  background-color: white;
  border: 1px solid #cccccc;
}
.part-row-odd {
  background-color: #f2f2f2;
  border: 1px solid #cccccc;
}
.part-cell {
  border-right: 1px solid #cccccc;
  padding-bottom: 0.5%;
  padding-top: 0.5%;
  padding-right: 1.5%;
  padding-left: 1.5%;
}
.part-cell-checkbox {
  border-right: 1px solid #cccccc;
}
.part-cell-is-page {
  display: flex;
  align-items: center;
}
.part-cell.part-cell-is-page {
  padding-right: 0.5%;
  padding-left: 0.5%;
}

.part-cell input {
  border-radius: 0;
}
.part-cell-end {
  padding-top: 0.5%;
}
.part-cell-end.part-cell-is-page {
  padding-top: 0;
  justify-content: center;
}
.form-control {
  padding: 0.25rem;
}
</style>
