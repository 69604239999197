export const namespace = 'customer/';

export const CustomerGetters = {
  GET_CUSTOMER: namespace + 'getCustomer',
  GET_CUSTOMER_PREVIEW: namespace + 'getCustomerPreview',
  GET_APPROVER_LIST: namespace + 'getApproverList',
  GET_VCP_USER_LIST: namespace + 'getVcpUserList',
  GET_BRANCH_ID: namespace + 'getBranchId',
  GET_CUSTOMER_NAME: namespace + 'getCustomerName',
  GET_ADDRESS: namespace + 'getAddress',
  GET_ADDRESS_2: namespace + 'getAddress2',
  GET_CITY: namespace + 'getCity',
  GET_STATE: namespace + 'getState',
  GET_ZIP_CODE: namespace + 'getZipCode',
  GET_PHONE: namespace + 'getPhone',
  GET_FAX: namespace + 'getFax',
  GET_EMAIL: namespace + 'getEmail',
  HAS_CHANGES: namespace + 'hasChanges',
  GET_SEARCH_RESULTS: namespace + 'getSearchResults',
  GET_TO_EXISTING_CUSTOMER: namespace + 'getToExistingCustomer'
};

export const CustomerActions = {
  SEARCH_CUSTOMERS: namespace + 'searchCustomers',
  FETCH_CUSTOMER: namespace + 'fetchCustomer',
  FETCH_CUSTOMER_PREVIEW: namespace + 'fetchCustomerPreview',
  FETCH_APPROVERS_FOR_CUSTOMER: namespace + 'fetchApproversForCustomer',
  FETCH_USERS_FOR_CUSTOMER: namespace + 'fetchUsersForCustomer',
  CREATE_CUSTOMER: namespace + 'createCustomer',
  SAVE_CUSTOMER: namespace + 'saveCustomer',
  NEW_CUSTOMER: namespace + 'newCustomer'
};

export const CustomerMutations = {
  SET_CUSTOMER_SEARCH_RESULTS: namespace + 'setCustomerSearchResults',
  SET_CUSTOMER: namespace + 'setCustomer',
  SET_CUSTOMER_PREVIEW: namespace + 'setCustomerPreview',
  RESET_STATE: namespace + 'resetState',
  SET_APPROVER_LIST: namespace + 'setApproverList',
  SET_VCP_USER_LIST: namespace + 'setVcpUserList',
  SET_PROP: namespace + 'setProp',
  SET_TO_EXISTING_CUSTOMER: namespace + 'setToExistingCustomer'
};
